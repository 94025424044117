import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  missionSelectedId: null,
}

export const counterSlice = createSlice({
  name: 'skillMatrix',
  initialState,
  reducers: {
    setMissionSelectedStore: (state, action) => {
      state.missionSelectedId= action.payload 
    }
  },
})

// Action creators are generated for each case reducer function
export const { setMissionSelectedStore } = counterSlice.actions

export default counterSlice.reducer