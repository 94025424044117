import { useSelector, useDispatch } from "react-redux";

// export function useGetRyOHistoryRows(){
//     const getRyOHistoryRows = async ({RyOListAPI, visibilidadModalHistoricoRyO, RyOFilaSeleccionada})=>{
//         if(!RyOListAPI.length || !visibilidadModalHistoricoRyO) return
//         const currentRyOFather =  RyOListAPI.find(ryo=> ryo.id === RyOFilaSeleccionada)?.padreRiskOpportunity
//         let fatherRyOSelected = RyOListAPI.find(ryo=> ryo.id == currentRyOFather);
//         let RyOHistory = []
//         while (!!fatherRyOSelected){
//           RyOHistory = [...RyOHistory, fatherRyOSelected];
//           fatherRyOSelected = RyOListAPI.find(ryo => ryo.id == fatherRyOSelected?.padreRiskOpportunity);
//         };
//         return RyOHistory.sort((a,b) => a.id - b.id);
//     }

//     return {getRyOHistoryRows}
// }


// // ? Get my RyOs
// export const useGetMyRyOs =()=>{
//   const dispatch = useDispatch();
//   const RyOList = useSelector(state => state.fuse.RyOComponente.RyOListAPI);
//   const {myAnfesList, notMyAnfesList} = useSelector(state => state.fuse.anfeComponente);
//   const myAnfesListIds = myAnfesList.map(({id})=> id)
//   const notMyAnfesListIds = notMyAnfesList.map(({id})=> id)
//   const anfesICanSeeIds = [...myAnfesListIds, ...notMyAnfesListIds]

//   const RyONoReevaluated = useMemo(()=>{
//       if(!RyOList.length || !anfesICanSeeIds.length) return []
//       const RyORev = RyOList
//           .filter(ryo => ryo.padreRiskOpportunity !== null)
//           .map(ryo => parseInt(ryo.padreRiskOpportunity));

//       // Ç Mostramos y guardamos en el store los ryos no reevaluados nuestros y no nuestros (si no tenemos can see all risk => no nuestros siempre [])
//       const myAndNotMyRyOsNoReevaluated = RyOList.reduce((acc, ryo)=>{
//           if(RyORev.includes(ryo.id)) return acc
//           if(myAnfesListIds.includes(ryo.id_risk_management)) acc.myRyOs = [...acc.myRyOs, ryo ]
//           else if(notMyAnfesListIds.includes(ryo.id_risk_management)) acc.notmyRyOs = [...acc.notmyRyOs, ryo ]
//           return acc;
//       }, {myRyOs: [], notmyRyOs:[]})

      
//       const RyONoReevaluatedCalc = RyOList.filter(ryo => !RyORev.includes(ryo.id) && anfesICanSeeIds.includes(ryo.id_risk_management))
//       .sort((a, b) => parseInt(b.npr) - parseInt(a.npr))
//       .map(ryo => ({
//          ...ryo, 
//          redRow: parseInt(ryo.npr) > 100 ? true : false, 
//          organgeRow: parseInt(ryo.npr) > 80 && parseInt(ryo.npr) <= 100 ? true : false 
//         }));

//       // dispatch(setMyRyoOAction(RyONoReevaluatedCalc))
      
//       dispatch(setMyRyoOAction(myAndNotMyRyOsNoReevaluated.myRyOs))
//       dispatch(setNotMyRyOsAction(myAndNotMyRyOsNoReevaluated.notmyRyOs))
//       return RyONoReevaluatedCalc

//   },[RyOList])

//   return {RyONoReevaluated}
// }