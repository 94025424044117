import axios from "axios"
import { getCookie } from 'app/js/generalFunctions'
import store from "app/store"
import { getAllStaffFromMissionAndSubmission } from "app/js/generalFunctions"
import { method } from "lodash"
import { useEffect, useRef, useState } from "react"
import { showMessage } from "app/store/fuse/messageSlice"
import { useDispatch, useSelector } from "react-redux"
import { setFilterSearchButtom } from "components/Filter/store/actions"
import { trainingRequestAction } from "../store/actions"
import { TRAINING_STATE } from "./consts"
import { MoodleTrainningTags } from "components/TabsExcel/SkillMatrix/Components/Tags"
import { CourseLinearProgress } from "components/TabsExcel/SkillMatrix/Components/CourseLinearProgress"





export const useTrainingRequest = () => {
    const dispatch = useDispatch();
    
    const { staffForSearch, sectionsAreCompleted, launchSearch } = useSelector(state => state.fuse.filterComponente)
    const staffForSearchControl = staffForSearch.join()
    const prevStaffForSearch = useRef(null)
    const [trainingData, setTrainingData] = useState({
        fRequestMade: false,
        loading: false,
        error: false,
        data: []
    })

    useEffect(()=>{


        if(!staffForSearch.length || !launchSearch) return

        if (prevStaffForSearch?.current === staffForSearchControl) {
            
            dispatch(showMessage({ variant: 'error', message: 'This request has same values as the previous one' }))
            dispatch(setFilterSearchButtom(false))
            return
        }

        if (!sectionsAreCompleted && launchSearch) {
            dispatch(showMessage({ variant: 'error', message: `In order to get training data: you must select a submission. If you can't see submissions filter is because your sections selected have no related submissions` }))
            dispatch(setFilterSearchButtom(false))
            return
        }
        
        // dispatch(trainingRequestAction(staffForSearch))
        const makeRequest = async()=>{
            setTrainingData(prev =>( {...prev, loading: true, error: false}))

            const trainingRequestData =  await trainingRequestAction(staffForSearch)
            const {data, error} = trainingRequestData

            let trainingData ;
            if(!error) {
                trainingData = data.map(obj => ({...obj, registered_courses_names:  <MoodleTrainningTags tagsList={obj.registered_courses_names} />, progress: <CourseLinearProgress value={obj.progress}/> }))
            }
            else trainingData= data

            setTrainingData({ data: trainingData, error, loading: false, fRequestMade: true })
            dispatch(setFilterSearchButtom(false))

        }
        makeRequest()
        prevStaffForSearch.current = staffForSearch.join()
    }, [staffForSearchControl, sectionsAreCompleted, launchSearch]);

    return {trainingData}

}

