import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    setFirstRenderAction,
    setvisibilityAction
} from "../store/actions.js"


export const FilterIcon =()=>{
    const dispatch = useDispatch();
    const {filterVisibility} = useSelector(state => state.fuse.filterComponente)

    const showHideFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setFirstRenderAction(false))
        dispatch(setvisibilityAction(!filterVisibility));
    };

    return(
        <div className="position-absolute" style={{ zIndex: 500, right: "20px", width: "auto" }} onClick={(e) => { showHideFilter(e) }}>

        <FilterListIcon
            style={{ fontSize: '50px', cursor: "pointer", backgroundColor: "transparent" }}
        />
    </div>
    )
}