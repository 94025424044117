import Button from '@mui/material/Button';
import { setFilterSearchButtom, setSummaryRequestDone } from 'components/Filter/store/actions';
import { useDispatch } from 'react-redux';
export const FilterSearchButtom =()=>{
    const dispatch = useDispatch()
    const setSearchTrue =()=> {
        dispatch(setFilterSearchButtom(true))
        dispatch(setSummaryRequestDone(false))
    }
    return (
        <Button onClick={setSearchTrue} variant="contained">Search</Button>
    )
}