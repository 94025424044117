import {

    
    MOSTRAR_RYO_ACCION,
    MOSTRAR_RYO_ACCION_EXITO,
    MOSTRAR_RYO_ACCION_ERROR,

    CAMBIAR_ESTADO_RYO_ACCION,
    CAMBIAR_ESTADO_RYO_ACCION_EXITO,
    CAMBIAR_ESTADO_RYO_ACCION_ERROR,

    INSERTAR_RYO_ACCION,
    INSERTAR_RYO_ACCION_EXITO,
    INSERTAR_RYO_ACCION_ERROR,

    UPDATE_RYO_ACCION,
    UPDATE_RYO_ACCION_EXITO,
    UPDATE_RYO_ACCION_ERROR,

    RESET_STATES_RYO_ACCION,
    RESET_STATES_RYO_ACCION_EXITO,
    RESET_STATES_RYO_ACCION_ERROR,

    DELETE_RYO_ACCION,
    DELETE_RYO_ACCION_EXITO,
    DELETE_RYO_ACCION_ERROR,

    CAMBIAR_VALOR_SELECCION_ACTIONS,

    MOSTRAR_MY_ACTIONS_RYO_ACCION,
MOSTRAR_MY_ACTIONS_RYO_ACCION_EXITO,
MOSTRAR_MY_ACTIONS_RYO_ACCION_ERROR,

CONSULTA_RM_REGISTRO_MODAL_INSERTAR,
 CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO,
CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR,

CHANGE_EDIT_MODE,
SET_MY_ACTIONS,
SET_NOT_MY_ACTIONS,
SET_ACTIONS_STRUCTURE




} from './types';

import store from "app/store/index"
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'


//Constantes
const urlRyOAccionApi = process.env.REACT_APP_URL_DJANGO + "/api/rm_action/"

const urlrYoApi = process.env.REACT_APP_URL_DJANGO + "/api/rm_risk_opportunity/"

//************************ MOSTRAR RYO_ACCION API **********************************************

export function deleteRyOAccionAPIAction(idAccion, id_record) {

    return async (dispatch) => {
        dispatch (deleteRyOAccionAPI())

              await axios({
                    method: "DELETE",
                    url: urlRyOAccionApi + idAccion,
                    headers: {
                            'Authorization': `Token ${getCookie('token')}` 
                              }
                        
                            })
                             .then(response=>{
                                dispatch (deleteRyOAccionAPIExito(response.data))

                                dispatch(
                                    showMessage({
                                        message: "Action successfully deleted",
                                        variant: "success"
                                    })
                                 ) 

                                dispatch(mostrarRyOAccionAPIAction(id_record));
                            })
                            .catch(error => {
                                console.log(error.response)
                                dispatch(
                                    showMessage({
                                        message: "An error has occurred",
                                        variant: "success"
                                    })
                                 ) 
                                dispatch (deleteRyOAccionAPIError(true))
                            })
   
      }
}

const deleteRyOAccionAPI = () => ({
    type: DELETE_RYO_ACCION,
    payload: true

})

const deleteRyOAccionAPIExito = rm_acciones => ({
    type: DELETE_RYO_ACCION_EXITO,
    payload:rm_acciones

})

const deleteRyOAccionAPIError = estado => ({
  type: DELETE_RYO_ACCION_ERROR,
  payload: estado
})

//************************ MOSTRAR RYO_ACCION API **********************************************

export function mostrarRyOAccionAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarRyOAccionAPI())

              await axios({
                    method: "GET",
                    // url: urlRyOAccionApi + '?id_record=' + id_record,
                    url: urlRyOAccionApi,
                    headers: {
                            'Authorization': `Token ${getCookie('token')}` 
                              }
                        
                            })
                             .then(response=>{
                                dispatch (mostrarRyOAccionAPIExito(response.data))
                            })
                            .catch(error => {
                                console.log(error.response)
                                dispatch (mostrarRyOAccionAPIError(true))
                            })
   
      }
}


export function mostrarMyActionsRyOAccionAPIAction(idPerson) {

    return async (dispatch) => {
        dispatch (mostrarMyActionsRyOAccionAPI())

              await axios({
                    method: "GET",
                    url: urlRyOAccionApi + 'myActions/' + idPerson,
                    headers: {
                            'Authorization': `Token ${getCookie('token')}` 
                              }
                        
                            })
                             .then(response=>{
                                dispatch (mostrarMyActionsRyOAccionAPIExito(response.data))
                            })
                            .catch(error => {
                                console.log(error.response)
                                dispatch (mostrarMyActionsRyOAccionAPIError(true))
                            })
   
      }
}

const mostrarMyActionsRyOAccionAPI = () => ({
    type: MOSTRAR_MY_ACTIONS_RYO_ACCION,
    payload: true

})

const mostrarMyActionsRyOAccionAPIExito = rm_acciones => ({
    type: MOSTRAR_MY_ACTIONS_RYO_ACCION_EXITO,
    payload:rm_acciones

})

const mostrarMyActionsRyOAccionAPIError = estado => ({
  type: MOSTRAR_MY_ACTIONS_RYO_ACCION_ERROR,
  payload: estado
})



//************************ CAMBIAR ESTADO RYO_ACCION  **********************************************

export function cambiarEstadoRyOTasksAction(nombreEstado, valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarEstadoRyOTasks())


        try {
            dispatch (cambiarEstadoRyOTasksExito(nombreEstado, valorNuevo))

            // if (nombreEstado == 'visibilidad' && valorNuevo == false) {

            //     dispatch (resetEstadosRyOTasksAction())
            //     // dispatch (resetEstadosRyOTasksAction())
            // }

        } catch (error) {

            dispatch (cambiarEstadoRyOTasksError(true))
        }
    }
}

const cambiarEstadoRyOTasks = () => ({
    type: CAMBIAR_ESTADO_RYO_ACCION,
    payload: true

})

const cambiarEstadoRyOTasksExito = (nombreEstado, valorNuevo) => ({
    type: CAMBIAR_ESTADO_RYO_ACCION_EXITO,
    nombre: nombreEstado,
    payload: valorNuevo
    

})

const cambiarEstadoRyOTasksError = estado => ({
  type:  CAMBIAR_ESTADO_RYO_ACCION_ERROR,
  payload: estado
})




//************************ INSERTA RYO_ACCION **********************************************

export function insertarRyOAccionAction(rm_accion) {

    return async (dispatch) => {
        dispatch (insertarRyOAccion())

             axios({
                    method: "POST",
                    url: urlRyOAccionApi,
                    data: rm_accion,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (insertarRyOAccionExito(response.data))

                 
                 dispatch(
                    showMessage({
                        message: "Action successfully created",
                        variant: "success"
                    })
                 ) 
                
                 dispatch(mostrarRyOAccionAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (insertarRyOAccionError(true))

                dispatch(
                    showMessage({
                        message: "Error creating Action",
                        variant: "error"
                    })
                )
            })

    }
}

const insertarRyOAccion = (rm_accion) => ({
    type: INSERTAR_RYO_ACCION,
    payload: rm_accion

})

const insertarRyOAccionExito = estado => ({
    type: INSERTAR_RYO_ACCION_EXITO,
    payload: estado

})

const insertarRyOAccionError = estado => ({
    type:  INSERTAR_RYO_ACCION_ERROR,
    payload: estado
})

//************************ UPDATE RYO_ACCION **********************************************

export function updateRyOAccionAction(id, json) {

    return async (dispatch) => {
        dispatch (updateRyOAccion())

             axios({
                    method: "PUT",
                    url: urlRyOAccionApi + id,
                    data: json,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (updateRyOAccionExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Updated Action",
                        variant: "success"
                    })
                 ) 



                 dispatch(mostrarRyOAccionAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (updateRyOAccionError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating Action",
                        variant: "error"
                    })
                )
            })

    }
}

const updateRyOAccion = (rm_accion) => ({
    type: UPDATE_RYO_ACCION,
    payload: rm_accion

})

const updateRyOAccionExito = estado => ({
    type: UPDATE_RYO_ACCION_EXITO,
    payload: estado

})

const updateRyOAccionError = estado => ({
    type:  UPDATE_RYO_ACCION_ERROR,
    payload: estado
})

// //************************ RESET ESTADOS  **********************************************

// export function resetEstadosRyOTasksAction() {

//     return (dispatch) => {
//         dispatch (resetEstadosRyOTasks())


//         try {
//             dispatch (resetEstadosRyOTasksExito(true))


//         } catch (error) {

//             dispatch (resetEstadosRyOTasksError(true))
//         }
//     }
// }

// const resetEstadosRyOTasks = () => ({
//     type: RESET_STATES_RYO_ACCION,
//     payload: true

// })

// const resetEstadosRyOTasksExito = estado => ({
//     type: RESET_STATES_RYO_ACCION_EXITO,
//     payload: estado
    

// })

// const resetEstadosRyOTasksError = estado => ({
//   type:  RESET_STATES_RYO_ACCION_ERROR,
//   payload: estado
// })


export function cambiarValorSeleccionAccionesAction(value){
    return async (dispatch) =>{
        dispatch(cambiarValorSeleccionAccionesExito(value))
    }
}

const cambiarValorSeleccionAccionesExito = value =>{
    return{
        type: CAMBIAR_VALOR_SELECCION_ACTIONS,
        payload: value
    }
}



// !!!!!!!!!!!!!!!

export function mostrarRyOAccionByPersonAPIAction(idLogin) {

    return async (dispatch) => {
        dispatch (consultaRmRegistros())

              await axios({
                    method: "GET",
                    url: urlRyOAccionApi + "persona/"+ idLogin,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                    
                        })
                            .then(response=>{
                            dispatch (consultaRmRegistrosExito(response.data))
                        })
                        .catch(error => {
                            console.log(error.response)
                            dispatch (consultaRmRegistrosError(true))
                        })
   
    }
}

const consultaRmRegistros = () => ({
    type: CONSULTA_RM_REGISTRO_MODAL_INSERTAR,
    payload: true

})

const consultaRmRegistrosExito = programas => ({
    type: CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO,
    payload: programas

})

const consultaRmRegistrosError = estado => ({
  type: CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR,
  payload: estado
})




const mostrarRyOAccionAPI = () => ({
    type: MOSTRAR_RYO_ACCION,
    payload: true

})

const mostrarRyOAccionAPIExito = rm_acciones => ({
    type: MOSTRAR_RYO_ACCION_EXITO,
    payload:rm_acciones

})

const mostrarRyOAccionAPIError = estado => ({
  type: MOSTRAR_RYO_ACCION_ERROR,
  payload: estado
})



export const changeActionsEditMode = booleam=>({
    type: CHANGE_EDIT_MODE,
    payload: booleam
})

// ************************ SET MY ACTIONS AND NOT MY ACTIONS ************************

export const setMyActionsAction=(myActions)=>({
    type: SET_MY_ACTIONS,
    payload: myActions,
})
export const setNotMyActionsAction=(notMyActions)=>({
    type: SET_NOT_MY_ACTIONS,
    payload: notMyActions,
})

//************************ CAMBIAR ESTADO RM_ACCION  **********************************************

// export function cambiarEstadoRyOTasksAction(nombreEstado, valorNuevo) {
//     console.log("🚀 ~ file: actions.js:146 ~ cambiarEstadoRyOTasksAction ~ nombreEstado, valorNuevo:", nombreEstado, valorNuevo)
    
//         return (dispatch) => {
//             dispatch (cambiarEstadoRyOTasks())
    
    
//             try {
//                 dispatch (cambiarEstadoRyOTasksExito(nombreEstado, valorNuevo))
    
//                 if (nombreEstado == 'visibilidad' && valorNuevo == false) {
    
//                     dispatch (resetEstadosRyOTasksAction())
//                 }
    
//             } catch (error) {
    
//                 dispatch (cambiarEstadoRyOTasksError(true))
//             }
//         }
//     }
    
//     const cambiarEstadoRyOTasks = () => ({
//         type: CAMBIAR_ESTADO_RyO_ACCION,
//         payload: true
    
//     })
    
//     const cambiarEstadoRyOTasksExito = (nombreEstado, valorNuevo) => ({
//         type: CAMBIAR_ESTADO_RyO_ACCION_EXITO,
//         nombre: nombreEstado,
//         payload: valorNuevo
        
    
//     })
    
//     const cambiarEstadoRyOTasksError = estado => ({
//       type:  CAMBIAR_ESTADO_RyO_ACCION_ERROR,
//       payload: estado
//     })

export const setActionsStructure =(structure)=>{
    return (dispatch)=>{
        dispatch({
            type: SET_ACTIONS_STRUCTURE,
            payload: structure
        })
    }
}