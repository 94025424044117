export const SUBMISSIONS_TYPES = {
    SET_CREATE_MODAL: "SET_CREATE_MODAL",
    SET_EDIT_MODAL: "SET_EDIT_MODAL", 
    SET_JOB_MODAL: "SET_JOB_MODAL",
    SET_PEOPLE_MOAL: "SET_PEOPLE_MOAL",
    SET_SUBMISSIONS_SELECTED: "SET_SUBMISSIONS_SELECTED",
    POST_SUBMISSION: "POST_SUBMISSION",
    PUT_SUBMISSION: 'PUT_SUBMISSION',
    POST_SUBMISSION_SUCCESS: "POST_SUBMISSION_SUCCESS",
    POST_SUBMISSION_ERROR: "POST_SUBMISSION_ERROR",
    SET_JOB_IN_SUBMISSION_SELECTED: "SET_JOB_IN_SUBMISSION_SELECTED",
    SET_JOB_IN_SUBMISSION_TO_SWITCH: "SET_JOB_IN_SUBMISSION_TO_SWITCH",
    GET_PEOPLE_IN_SUBMISSION_SELECTED: "GET_PEOPLE_IN_SUBMISSION_SELECTED",
    GET_PEOPLE_IN_SUBMISSION_SWITCH: "GET_PEOPLE_IN_SUBMISSION_SWITCH",
    GET_ALL_SUBMISSIONS: "GET_ALL_SUBMISSIONS",
    RESET_SUBMISSION_STORE_VALUES: "RESET_SUBMISSION_STORE_VALUES"
}
