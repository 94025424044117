import {

    MODAL_SIN_PERMISOS,
    MODAL_SIN_PERMISOS_EXITO,
    MODAL_SIN_PERMISOS_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {
        error: null,
        loading: false,
        visibilidadModalSinPermisos: false,
}


export default function(state = initialState, action) {

    switch(action.type) {
     //********** MOSTRAR MODAL SIN PERMISOS**********************************/ 

    case MODAL_SIN_PERMISOS:
        return {
            ...state,
            loading: action.payload
        }
    
    case MODAL_SIN_PERMISOS_EXITO:
        return {
            ...state,
            loading: false,
            visibilidadModalSinPermisos: action.payload
        }
    
    case MODAL_SIN_PERMISOS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }

        default: 
            return state

    }

}