import {
    GET_SALES_VOLUME_DATA,
    SET_ERROR_SALES_VOLUME,
    SET_SALES_VOLUME_LOADING,
    SET_SALES_VOLUME_DATA_EMPTY
} from './types';


const initialState = {
    loading: false,
    salesVolumeData : null,
    error: false,
    fRequestMade: false
}


export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 

        case SET_SALES_VOLUME_LOADING:
            return {
                ...state,
                loading: true
            }

        case GET_SALES_VOLUME_DATA:
            return {
                ...state,
                salesVolumeData: action.payload,
                loading: false,
                error:false,
                fRequestMade: true
            }

        case SET_ERROR_SALES_VOLUME:
            return{
                error: true,
                loading: false,
                salesVolumeData: null,
            }

        case SET_SALES_VOLUME_DATA_EMPTY:
            return{
                error: false,
                loading: false,
                salesVolumeData: [],
            }


        default:
            return state

    }

}