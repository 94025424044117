import { createContext } from 'react';
import { useImprovementReducer } from './internalReducer';
export const improvementContext = createContext(null);


export const ImprovementContextProvider =({children})=>{
    
    const { improvementState, setPeopleModalVisibility,setCompleteImprovementModalVisibility,setPeopleModalValues, setInsertModalVisibility, setInsertModalEditMode, setImprovementSelectedRow, setInserModalValues, resetImprovementModalsValues,setModalValuesInEditMode, setCompleteModalValues, setAcceptDeclineModalVisibility, setCompleteModalAllValues, setAcceptDeclineValues,setMftTeam } = useImprovementReducer()
    
    const { insertModalVisibility, peopleModalVisibility, peopleModalValues, improvementSelectedRow, insertModalInEditMode, insertModalValues, completeImprovementModalVisibility, completeModalValues, acceptDeclineModalVisibility, acceptDeclineModalValues } = improvementState


    return (
        <improvementContext.Provider value={{insertModalVisibility, peopleModalVisibility, peopleModalValues, improvementSelectedRow, insertModalInEditMode, insertModalValues, completeImprovementModalVisibility, completeModalValues, acceptDeclineModalVisibility, acceptDeclineModalValues, mftTeam : acceptDeclineModalValues.mftTeam, setPeopleModalVisibility,setCompleteImprovementModalVisibility, setPeopleModalValues, setInsertModalVisibility, setInsertModalEditMode, setImprovementSelectedRow, setInserModalValues, resetImprovementModalsValues,setModalValuesInEditMode, setCompleteModalValues, setAcceptDeclineModalVisibility, setCompleteModalAllValues, setAcceptDeclineValues,setMftTeam}}>
            {children}
        </improvementContext.Provider>
    )
}