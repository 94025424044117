import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_DIVISION,
    CAMBIAR_VALOR_VISIBILIDAD_DIVISION_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_DIVISION_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_DIVISION,
    CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR,

    ELIMINAR_DIVISION,
    ELIMINAR_DIVISION_EXITO,
    ELIMINAR_DIVISION_ERROR,

    MOSTRAR_DIVISION_API,
    MOSTRAR_DIVISION_EXITO,
    MOSTRAR_DIVISION_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR,

    INSERTAR_DIVISION_MODAL_INSERTAR,
    INSERTAR_DIVISION_MODAL_INSERTAR_EXITO,
    INSERTAR_DIVISION_MODAL_INSERTAR_ERROR,

    ACTUALIZAR_DIVISION_MODAL_INSERTAR,
    ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {

        visibilidad: true,
        divisionesListAPI: [],
        filaSeleccionadaGrid: '',
        visibilidadModalInsertar: false,
        modo: '',
        error: null,
        loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********VISIBILIDAD**********************************/

        case CAMBIAR_VALOR_VISIBILIDAD_DIVISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_DIVISION_EXITO:
            return {
                ...state,
                loading: false,
                visibilidad: action.payload

            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


     //**********FILA SELECIONADA GRID **********************************/

         case CAMBIAR_VALOR_SELECCION_GRID_DIVISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO:
            return {
                ...state,
                loading: false,
                filaSeleccionadaGrid: action.payload

            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

     //********** ELIMINAR PROGRAMA **********************************/ 


     case ELIMINAR_DIVISION:
        return {
            ...state,
            loading: action.payload
        }
    
    case ELIMINAR_DIVISION_EXITO:
        return {
            ...state,
            loading: false,
            error: null
            

        }
    
    case ELIMINAR_DIVISION_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


     //********** MOSTRAR PROGRAMAS API**********************************/ 


        case MOSTRAR_DIVISION_API:
            return {
                ...state,
                loading: action.payload
            }
        
        case MOSTRAR_DIVISION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                divisionesListAPI: action.payload

            }
        
        case MOSTRAR_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
    
     //**********VISIBILIDAD MODAL INSERTAR**********************************/

       case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertar: action.payload,
                modo: action.visibilidadModal
            }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        

      
     //********** INSERTAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case INSERTAR_DIVISION_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case INSERTAR_DIVISION_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case INSERTAR_DIVISION_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** ACTUALIZAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case ACTUALIZAR_DIVISION_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

}