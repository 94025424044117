import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { rolN3, rolN4 } from 'global.js';
import { getIdsFromArr } from "app/js/generalFunctions";
import { getN2andN1FromStructure, getProgramsFromStructure } from "../store/AuthOfInspectorsThunk";

export const useKnowIfPersonLoginCanAddInspectors = () => {
    const { person } = useSelector(({ fuse }) => fuse.userComponente)
    const canAdd = useMemo(() => person.rolUser === rolN3 || person.rolUser === rolN4, [person])
    return canAdd
}


// !! BORRAR
export const useGetPossiblesInspectorsIds = () => {
    const { departamentalAndNoDepartamentalWpsInfo: wpInfo, departamentalAndNoDepartamentalMissionsInfo: missionsInfo, departamentalAndNoDepartamentalSubMissionsInfo: subsInfo, person } = useSelector(({ fuse }) => fuse.userComponente)
    const [possibleInspectorsList, setPossibleInspectorsList] = useState([])

    useEffect(() => {
        const getPossiblesInspectors = async () => {
            if (!missionsInfo.length && !subsInfo.length && !wpInfo.length) return;
            const mergeInfoLists = [...wpInfo, ...missionsInfo, ...subsInfo];
            const allPeople = mergeInfoLists.flatMap(({ staff }) => [...staff.managers, ...staff.employees]);
            const uniqueArray = [...new Map(allPeople.map(item => [item.id, item])).values()];



            // const response = await dispatch(getN2andN1FromStructure(peopleIds));
            setPossibleInspectorsList(uniqueArray)

        }
        getPossiblesInspectors()
    }, [missionsInfo, subsInfo, wpInfo]);
    return possibleInspectorsList
}

export const useGetPrograms = () => {
    const dispatch = useDispatch()

    const { departamentalAndNoDepartamentalWpsInfo: wpInfo, departamentalAndNoDepartamentalWpsIds: wpsIds, departamentalAndNoDepartamentalMissionsInfo: missionsInfo, departamentalAndNoDepartamentalMissionsIds: missionsIds, departamentalAndNoDepartamentalSubMissionsInfo: subsInfo} = useSelector(({ fuse }) => fuse.userComponente)
    const[programsList, setProgramsList] = useState([])


    useEffect(() => {
        if (!missionsInfo.length && !subsInfo.length && !wpInfo.length) return;

        const getPrograms =async()=>{
            const missionsIdsFromSubmissions = subsInfo.map(({ id_mision }) => id_mision)
            const allMissionsIds = [...new Set([...missionsIds, ...missionsIdsFromSubmissions])]
            const wpsIdsFromMissions = missionsInfo.map(({ id_workPackage }) => id_workPackage)
            const allWpsIds = [...new Set([...wpsIds, ...wpsIdsFromMissions])]
            const programsRequest = await dispatch(getProgramsFromStructure({allWpsIds, allMissionsIds}))
            setProgramsList(programsRequest.payload)
        }
        getPrograms()
    }, [missionsInfo, subsInfo]);
    return {programsList}

}