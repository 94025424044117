import {

    OBTENER_USER_DETAILS,
    OBTENER_USER_DETAILS_EXITO,
    OBTENER_USER_DETAILS_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_DETALLES,
    CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR,

    OBTENER_SUB_MISION_DIRECTAS,
    OBTENER_SUB_MISION_DIRECTAS_EXITO,
    OBTENER_SUB_MISION_DIRECTAS_ERROR,

    OBTENER_MISION_DIRECTAS,
    OBTENER_MISION_DIRECTAS_EXITO,
    OBTENER_MISION_DIRECTAS_ERROR,

    OBTENER_WP_DIRECTAS,
    OBTENER_WP_DIRECTAS_EXITO,
    OBTENER_WP_DIRECTAS_ERROR,

    OBTENER_DEPARTAMENTOS_DIRECTAS,
    OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO,
    OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR,

    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS,
    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO,
    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR,

    OBTENER_SUB_DIVISIONES_DIRECTAS,
    OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO,
    OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {
    subMisionesDirectas: [],
    misionesDirectas: [],
    WPDirectas: [],
    departamentosDirectas: [],
    direccionDepartamenalDirectas: [],
    subDivisionDirectas: [],
    userSeleccionado: '',
    visibilidadModalDetalles: false,
    error: null,
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        //********** OBTENER SUB MISIONES USER API**********************************/ 

        case OBTENER_SUB_MISION_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_SUB_MISION_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                subMisionesDirectas: action.payload
            }

        case OBTENER_SUB_MISION_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** OBTENER MISIONES USER API**********************************/ 

        case OBTENER_MISION_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_MISION_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                misionesDirectas: action.payload
            }

        case OBTENER_MISION_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** OBTENER WP USER API**********************************/ 

        case OBTENER_WP_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_WP_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                WPDirectas: action.payload
            }

        case OBTENER_WP_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** OBTENER DEPARTAMENTOS USER API**********************************/ 

        case OBTENER_DEPARTAMENTOS_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                departamentosDirectas: action.payload
            }

        case OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** OBTENER DIRECCION DEPARTAMENTAL USER API**********************************/ 

        case OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                direccionDepartamenalDirectas: action.payload
            }

        case OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** OBTENER DIRECCION DEPARTAMENTAL USER API**********************************/ 

        case OBTENER_SUB_DIVISIONES_DIRECTAS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                subDivisionDirectas: action.payload
            }

        case OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** MOSTRAR RM_ACCION API**********************************/ 

        case OBTENER_USER_DETAILS:
            return {
                ...state,
                loading: action.payload
            }

        case OBTENER_USER_DETAILS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                userSeleccionado: action.payload

            }

        case OBTENER_USER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********VISIBILIDAD MODAL INSERTAR**********************************/

        case CAMBIAR_MODAL_VISIBILIDAD_DETALLES:
            return {
                ...state,
                loading: action.payload
            }

        case CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalDetalles: action.payload

            }

        case CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default:
            return state

    }

}