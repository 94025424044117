import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_TAB_PEOPLE,
    CAMBIAR_VALOR_TAB_PEOPLE_EXITO,
    CAMBIAR_VALOR_TAB_PEOPLE_ERROR,

    VER_MODAL_SOLICITAR_PERSONAL,
    VER_MODAL_SOLICITAR_PERSONAL_EXITO,
    VER_MODAL_SOLICITAR_PERSONAL_ERROR,

    POST_CORREO_SOLICITUD,
    POST_CORREO_SOLICITUD_EXITO,
    POST_CORREO_SOLICITUD_ERROR,

    POST_NUEVA_NOTIFICACION,
    POST_NUEVA_NOTIFICACION_EXITO,
    POST_NUEVA_NOTIFICACION_ERROR,

    POST_INVENTARIO_PCS,
    POST_INVENTARIO_PCS_EXITO,
    POST_INVENTARIO_PCS_ERROR,

    GET_NOTIFICACION,
    GET_NOTIFICACION_EXITO,
    GET_NOTIFICACION_ERROR,

    VER_MODAL_ANADIR_PERSONAL,
    VER_MODAL_ANADIR_PERSONAL_EXITO,
    VER_MODAL_ANADIR_PERSONAL_ERROR,

    POST_NUEVA_PERSONA_TEAM,
    POST_NUEVA_PERSONA_TEAM_EXITO,
    POST_NUEVA_PERSONA_TEAM_ERROR,

    GET_MY_GROUP,
    GET_MY_GROUP_EXITO,
    GET_MY_GROUP_ERROR,

    ELIMINAR_PERSON_TEAM,
    ELIMINAR_PERSON_TEAM_EXITO,
    ELIMINAR_PERSON_TEAM_ERROR,

    GET_SOLICITUD,
    GET_SOLICITUD_EXITO,
    GET_SOLICITUD_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR,

    VER_MODAL_INSERTAR_OBSERVACIONES,
    VER_MODAL_INSERTAR_OBSERVACIONES_EXITO,
    VER_MODAL_INSERTAR_OBSERVACIONES_ERROR,

    PUT_NOTIFICACION,
    PUT_NOTIFICACION_EXITO,
    PUT_NOTIFICACION_ERROR,

    GET_USER_ACCESO_PEOPLE_MANAGEMENT,
    GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO,
    GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR,

    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION,
    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO,
    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR,

    SET_MEMBER_SELECT_PEOPLE,
    SET_MEMBER_SELECT_PEOPLE_EXITO,
    SET_MEMBER_SELECT_PEOPLE_ERROR,

    SET_SUB_MISION_SELECT_MY_TEAM,
    SET_SUB_MISION_SELECT_MY_TEAM_EXITO,
    SET_SUB_MISION_SELECT_MY_TEAM_ERROR,

    GET_MY_MANAGER,
    GET_MY_MANAGER_EXITO,
    GET_MY_MANAGER_ERROR,

    GET_ESTRUCTURA_PERSONAL,
    GET_ESTRUCTURA_PERSONAL_EXITO,
    GET_ESTRUCTURA_PERSONAL_ERROR,

    GET_USER_ESTRUCTURA_PERSONAL,
    GET_USER_ESTRUCTURA_PERSONAL_EXITO,
    GET_USER_ESTRUCTURA_PERSONAL_ERROR,

    GET_ESTRUCTURA_PERSONAL_SECUNDARIA,
    GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO,
    GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR,

    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA,
    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO,
    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR,


} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import {getCookie} from 'app/js/generalFunctions'

//Constantes globales
const urlNotificacionesApi  = process.env.REACT_APP_URL_DJANGO + "/api/notifications/"
const urlPostCorreo  = process.env.REACT_APP_URL_DJANGO + "/api/sendEmail/"
const urlInventoryPcs = process.env.REACT_APP_URL_DJANGO + "/api/external_data/"
const urlTeamAPI = process.env.REACT_APP_URL_DJANGO + "/api/user/"
const urlGetUserPermisosPeopleManagment = process.env.REACT_APP_URL_DJANGO + "/api/workPackage/getUserResponsablesWP/"
const urlGetResponsablesMisionesAsociadasSubMisiones = process.env.REACT_APP_URL_DJANGO + "/api/subMision/misSubmisiones/"

//************************ CAMBIAR SELECCION CARD PERSONAL MEMBERS **********************************************

export function setSubMisionEstructuraSeleccionadaAPIAction(valorNuevo) {
    return (dispatch) => {
        dispatch (setSubMisionEstructuraSeleccionadaAPI())

        try {
            dispatch (setSubMisionEstructuraSeleccionadaAPIExito(valorNuevo))

        } catch (error) {

            dispatch (setSubMisionEstructuraSeleccionadaAPIError(true))
        }

    }

}

const setSubMisionEstructuraSeleccionadaAPI = () => ({
    type: SET_SUB_MISION_ESTRUCTURA_SELECCIONADA,
    payload: true

})

const setSubMisionEstructuraSeleccionadaAPIExito = valorNuevo => ({
    type: SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO,
    payload: valorNuevo

})

const setSubMisionEstructuraSeleccionadaAPIError = estado => ({
  type: SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR,
  payload: estado
})

//************************ GET RESPONSABLES DE SUBMISION ASOCIADA A MISION**********************************************

export function getEstructuraPersonalSecundariaAPIAction(idPerson) {

    return async (dispatch) => {
        dispatch (getEstructuraPersonalSecundariaAPI())
             axios({
                    method: "GET",
                    url: urlTeamAPI + "arbolPersonalSecundario/" + idPerson,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getEstructuraPersonalSecundariaAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getEstructuraPersonalSecundariaAPIError(true))
            })

    }
}

const getEstructuraPersonalSecundariaAPI = (user) => ({
    type: GET_ESTRUCTURA_PERSONAL_SECUNDARIA,
    payload: user

})

const getEstructuraPersonalSecundariaAPIExito = notification => ({
    type: GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO,
    payload: notification

})

const getEstructuraPersonalSecundariaAPIError = estado => ({
    type: GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR,
    payload: estado
})


//************************ GET USER DE LA ESTRUCTURA SELECCIONADA**********************************************
//idBloque => es el id de la submision, mision, wp, departamento o direccion departamental de la cual queremos saber su personal
//tipoBloque => saber que tipo de bloque es, si es mision, wp...
export function getUserEstructuraPersonalAPIAction(idBloque, tipoBloque) {

    return async (dispatch) => {
        dispatch (getUserEstructuraPersonalAPI())
             axios({
                    method: "GET",
                    url: urlTeamAPI + "userEstructuraPersonal"+tipoBloque+"/" + idBloque,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getUserEstructuraPersonalAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getUserEstructuraPersonalAPIError(true))
            })

    }
}

const getUserEstructuraPersonalAPI = (user) => ({
    type: GET_USER_ESTRUCTURA_PERSONAL,
    payload: user

})

const getUserEstructuraPersonalAPIExito = notification => ({
    type: GET_USER_ESTRUCTURA_PERSONAL_EXITO,
    payload: notification

})

const getUserEstructuraPersonalAPIError = estado => ({
    type: GET_USER_ESTRUCTURA_PERSONAL_ERROR,
    payload: estado
})

//************************ GET RESPONSABLES DE SUBMISION ASOCIADA A MISION**********************************************

export function getEstructuraPersonalAPIAction(idPerson) {

    return async (dispatch) => {
        dispatch (getEstructuraPersonalAPI())
             axios({
                    method: "GET",
                    url: urlTeamAPI + "arbolPersonal/" + idPerson,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getEstructuraPersonalAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getEstructuraPersonalAPIError(true))
            })

    }
}

const getEstructuraPersonalAPI = (user) => ({
    type: GET_ESTRUCTURA_PERSONAL,
    payload: user

})

const getEstructuraPersonalAPIExito = notification => ({
    type: GET_ESTRUCTURA_PERSONAL_EXITO,
    payload: notification

})

const getEstructuraPersonalAPIError = estado => ({
    type: GET_ESTRUCTURA_PERSONAL_ERROR,
    payload: estado
})

//************************ SELECCIONAR SUB MISION SOBRE LA QUE SE ESTA TRABAJANDO EN MY TEAM **********************************************

export function setSubMisionSelectAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (setSubMisionSelectAPI())

        try {
            dispatch (setSubMisionSelectAPIExito(valorNuevo))

        } catch (error) {

            dispatch (setSubMisionSelectAPIError(true))
        }

    }

}

const setSubMisionSelectAPI = () => ({
    type: SET_SUB_MISION_SELECT_MY_TEAM,
    payload: true

})

const setSubMisionSelectAPIExito = valorNuevo => ({
    type: SET_SUB_MISION_SELECT_MY_TEAM_EXITO,
    payload: valorNuevo

})

const setSubMisionSelectAPIError = estado => ({
  type: SET_SUB_MISION_SELECT_MY_TEAM_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION CARD PERSONAL MEMBERS **********************************************

export function setMemberSelectAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (setMemberSelectAPI())

        try {
            dispatch (setMemberSelectAPIExito(valorNuevo))

        } catch (error) {

            dispatch (setMemberSelectAPIError(true))
        }

    }

}

const setMemberSelectAPI = () => ({
    type: SET_MEMBER_SELECT_PEOPLE,
    payload: true

})

const setMemberSelectAPIExito = valorNuevo => ({
    type: SET_MEMBER_SELECT_PEOPLE_EXITO,
    payload: valorNuevo

})

const setMemberSelectAPIError = estado => ({
  type: SET_MEMBER_SELECT_PEOPLE_ERROR,
  payload: estado
})

//************************ GET RESPONSABLES DE SUBMISION ASOCIADA A MISION**********************************************

export function getResponsablesMisionAsociadaSubMisionAPIAction(idSubMision) {

    return async (dispatch) => {
        dispatch (getResponsablesMisionAsociadaSubMisionAPI())
             axios({
                    method: "GET",
                    url: urlGetResponsablesMisionesAsociadasSubMisiones + idSubMision,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getResponsablesMisionAsociadaSubMisionAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getResponsablesMisionAsociadaSubMisionAPIError(true))
            })

    }
}

const getResponsablesMisionAsociadaSubMisionAPI = (user) => ({
    type: GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION,
    payload: user

})

const getResponsablesMisionAsociadaSubMisionAPIExito = notification => ({
    type: GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO,
    payload: notification

})

const getResponsablesMisionAsociadaSubMisionAPIError = estado => ({
    type: GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR,
    payload: estado
})

//************************ GET USER QUE TIENEN GESTION DE PEOPLE MANAGMENT**********************************************

export function getUserPeopleManagmentAPIAction() {

    return async (dispatch) => {
        dispatch (getUserPeopleManagmentAPI())
             axios({
                    method: "GET",
                    url: urlGetUserPermisosPeopleManagment,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getUserPeopleManagmentAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getUserPeopleManagmentAPIError(true))
            })

    }
}

const getUserPeopleManagmentAPI = (user) => ({
    type: GET_USER_ACCESO_PEOPLE_MANAGEMENT,
    payload: user

})

const getUserPeopleManagmentAPIExito = notification => ({
    type: GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO,
    payload: notification

})

const getUserPeopleManagmentAPIError = estado => ({
    type: GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR,
    payload: estado
})

//************************ CAMBIAR SELECCION GRID SOLICITUD**********************************************

export function cambiarValorSeleccionAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR,
  payload: estado
})

//************************ GET MY MANAGERS**********************************************

export function getMyManagersAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch (getMyManagersAPI())
             axios({
                    method: "GET",
                    url: urlTeamAPI + "estructuraPersonalManager/" + idPersona,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getMyManagersAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getMyManagersAPIError(true))
            })

    }
}

const getMyManagersAPI = (user) => ({
    type: GET_MY_MANAGER,
    payload: user

})

const getMyManagersAPIExito = notification => ({
    type: GET_MY_MANAGER_EXITO,
    payload: notification

})

const getMyManagersAPIError = estado => ({
    type: GET_MY_MANAGER_ERROR,
    payload: estado
})

//************************ GET MY GROUPS**********************************************

export function getMyGroupsAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch (getMyGroupsAPI())
             axios({
                    method: "GET",
                    url: urlTeamAPI + "estructuraPersonalACargo/" + idPersona,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getMyGroupsAPIExito(response.data)) 

             })
             .catch(error => {
                console.log(error.response)
                dispatch (getMyGroupsAPIError(true))
            })

    }
}

const getMyGroupsAPI = (user) => ({
    type: GET_MY_GROUP,
    payload: user

})

const getMyGroupsAPIExito = notification => ({
    type: GET_MY_GROUP_EXITO,
    payload: notification

})

const getMyGroupsAPIError = estado => ({
    type: GET_MY_GROUP_ERROR,
    payload: estado
})


//************************ GET NOTIFICACION**********************************************

export function getNotificationAPIAction(idDato) {

    return async (dispatch) => {
        if(!idDato) return
        dispatch (getNotificationAPI())
             axios({
                    method: "GET",
                    url: urlNotificacionesApi + "myNotifications/" + idDato,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (getNotificationAPIExito(response.data)) 

             })
             .catch(error => {
                // console.log(error.response)
                dispatch (getNotificationAPIError(true))
            })

    }
}

const getNotificationAPI = (user) => ({
    type: GET_NOTIFICACION,
    payload: user

})

const getNotificationAPIExito = notification => ({
    type: GET_NOTIFICACION_EXITO,
    payload: notification

})

const getNotificationAPIError = estado => ({
    type: GET_NOTIFICACION_ERROR,
    payload: estado
})

//************************ INSERTA NUEVA NOTIFICACION**********************************************

export function insertarNewNotificationAPIAction(notificacion) {

    return async (dispatch) => {
        dispatch (insertarNewNotification())
             axios({
                    method: "POST",
                    url: urlNotificacionesApi,
                    data: notificacion,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                
                 dispatch (insertarNewNotificationExito(response.data))

             })
             .catch(error => {
                console.log(error.response)
                dispatch (insertarNewNotificationError(true))

                dispatch(
                    showMessage({
                        message: "Error creating Notifications",
                        variant: "error"
                    })
                )
            })

    }
}

const insertarNewNotification = (user) => ({
    type: POST_NUEVA_NOTIFICACION,
    payload: user

})

const insertarNewNotificationExito = notification => ({
    type: POST_NUEVA_NOTIFICACION_EXITO,
    payload: notification

})

const insertarNewNotificationError = estado => ({
    type: POST_NUEVA_NOTIFICACION_ERROR,
    payload: estado
})

//************************ PUT NOTIFICACION**********************************************

export function putNotificationAPIAction(idNotificacion, notificacion, personLoginID) {

    return async (dispatch) => {
        dispatch (putNotificationAPI())
             axios({
                    method: "PUT",
                    url: urlNotificacionesApi + idNotificacion,
                    data: notificacion,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (putNotificationAPIExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Notification deleted",
                        variant: "success"
                    })
                 ) 

                 dispatch(getNotificationAPIAction(personLoginID))

             })
             .catch(error => {
                console.log(error.response)
                dispatch (putNotificationAPIError(true))

                dispatch(
                    showMessage({
                        message: "Error change Notifications",
                        variant: "error"
                    })
                )
            })

    }
}

const putNotificationAPI = (user) => ({
    type: PUT_NOTIFICACION,
    payload: user

})

const putNotificationAPIExito = notification => ({
    type: PUT_NOTIFICACION_EXITO,
    payload: notification

})

const putNotificationAPIError = estado => ({
    type: PUT_NOTIFICACION_ERROR,
    payload: estado
})

//************************ ENVIAR CORREO DE SOLICITUD DE PERSONAL**********************************************

export function postCorreoSolicitudPersonalAPIAction(cuerpoMensaje) {

    return (dispatch) => {
         dispatch (postCorreoSolicitudPersonalAPI(true))

            
             axios({
                    method: "POST",
                    url: urlPostCorreo,
                    data: cuerpoMensaje,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
              })   
              .then(response => {
                dispatch (postCorreoSolicitudPersonalAPIExito(true))

                dispatch(
                    showMessage({
                            message: "Application successfully submitted.",
                            variant: "success"
                    })
                )
             })
             .catch(error => {
                    console.log(error)
                    dispatch(
                        showMessage({
                        message: "Error in sending the email",
                        variant: "error",
                        })
                    )
              
                    dispatch (postCorreoSolicitudPersonalAPIError(true))
            })

    }
}

const postCorreoSolicitudPersonalAPI = loading => ({
    type: POST_CORREO_SOLICITUD,
    payload: loading

})

const postCorreoSolicitudPersonalAPIExito = result => ({
  
    type: POST_CORREO_SOLICITUD_EXITO,
    payload: result

})

const postCorreoSolicitudPersonalAPIError = error => ({
    type:   POST_CORREO_SOLICITUD_ERROR,
    payload: error
})


//************************ MODAL ANADIR PERSONAL**********************************************
export function verModalAnadirPersonalAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (verModalAnadirPersonalAPI())

        try {
            dispatch (verModalAnadirPersonalAPIExito(valorNuevo))
            

        } catch (error) {

            dispatch (verModalAnadirPersonalAPIError(true))
        }

    }

}

const verModalAnadirPersonalAPI = () => ({
    type: VER_MODAL_ANADIR_PERSONAL,
    payload: true

})

const verModalAnadirPersonalAPIExito = valorNuevo => ({
    type: VER_MODAL_ANADIR_PERSONAL_EXITO,
    payload: valorNuevo

})

const verModalAnadirPersonalAPIError = estado => ({
  type: VER_MODAL_ANADIR_PERSONAL_ERROR,
  payload: estado
})

//************************ MODAL SOLICITUD PERSONAL**********************************************
export function verModalSolicitudPersonalAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (verModalSolicitudPersonalAPI())

        try {
            dispatch (verModalSolicitudPersonalAPIExito(valorNuevo))
            

        } catch (error) {

            dispatch (verModalSolicitudPersonalAPIError(true))
        }

    }

}

const verModalSolicitudPersonalAPI = () => ({
    type: VER_MODAL_SOLICITAR_PERSONAL,
    payload: true

})

const verModalSolicitudPersonalAPIExito = valorNuevo => ({
    type: VER_MODAL_SOLICITAR_PERSONAL_EXITO,
    payload: valorNuevo

})

const verModalSolicitudPersonalAPIError = estado => ({
  type: VER_MODAL_SOLICITAR_PERSONAL_ERROR,
  payload: estado
})

//************************ MODAL INSERTAR OBSERVACIONES**********************************************
export function verModalInsertarObservacionesAPIAction(modoDialogo, valorNuevo) {

    return (dispatch) => {
        dispatch (verModalInsertarObservacionesAPI())

        try {
            dispatch (verModalInsertarObservacionesAPIExito(modoDialogo, valorNuevo))
            

        } catch (error) {

            dispatch (verModalInsertarObservacionesAPIError(true))
        }

    }

}

const verModalInsertarObservacionesAPI = () => ({
    type: VER_MODAL_INSERTAR_OBSERVACIONES,
    payload: true

})

const verModalInsertarObservacionesAPIExito = (modoDialogo, valorNuevo) => ({
    type: VER_MODAL_INSERTAR_OBSERVACIONES_EXITO,
    payload: valorNuevo,
    modoDialogo: modoDialogo

})

const verModalInsertarObservacionesAPIError = estado => ({
  type: VER_MODAL_INSERTAR_OBSERVACIONES_ERROR,
  payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorTabPeopleAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorTabPeople())

        try {
            dispatch (cambiarValorTabPeopleExito(valorNuevo))
            

        } catch (error) {

            dispatch (cambiarValorTabPeopleError(true))
        }

    }

}

const cambiarValorTabPeople = () => ({
    type: CAMBIAR_VALOR_TAB_PEOPLE,
    payload: true

})

const cambiarValorTabPeopleExito = valorNuevo => ({
    type: CAMBIAR_VALOR_TAB_PEOPLE_EXITO,
    payload: valorNuevo

})

const cambiarValorTabPeopleError = estado => ({
  type: CAMBIAR_VALOR_TAB_PEOPLE_ERROR,
  payload: estado
})

//************************ GET DATOS INVENTARIO PCS **********************************************

export function getInventoryPcsAPIAction(idUsers) {

    return async (dispatch) => {
        dispatch (getInventoryPcsAPI())

              await axios({
                    method: "POST",
                    url: urlInventoryPcs,
                    data: {
                        users: idUsers //['7365', '7122']
                    },
                    headers: {
                            'Authorization': `Token ${getCookie('token')}` 
                              }
                        
                            })
                             .then(response=>{
                                dispatch (getInventoryPcsAPIExito(response.data))
                            })
                            .catch(error => {
                                console.log(error.response)
                                dispatch (getInventoryPcsAPIError(true))
                            })
   
      }
}

const getInventoryPcsAPI = () => ({
    type: POST_INVENTARIO_PCS,
    payload: true

})

const getInventoryPcsAPIExito = listInventoryPcs => ({
    type: POST_INVENTARIO_PCS_EXITO,
    payload:listInventoryPcs

})

const getInventoryPcsAPIError = estado => ({
  type: POST_INVENTARIO_PCS_ERROR,
  payload: estado
})
