import { SUBMISSION_URL, STAFF_IN_SUBMISSION_URL, CONTRACT_USER_URL, JOB_IN_SUBMISSION_URL, USER_CONTRACTS_URL } from "./consts"
import { getCookie } from 'app/js/generalFunctions'

const getAuth = () => ({ 'Authorization': `Token ${getCookie('token')}` })


export const POST_SUBMISSION_REQUEST_OBJ = (submission) => ({
    method: "POST",
    url: SUBMISSION_URL,
    data: submission,
    headers: getAuth()

})

export const PUT_SUBMISSION_REQUEST_OBJ = (idSubmission, submissionEdited) => ({
    method: "PUT",
    url: `${SUBMISSION_URL}${idSubmission}`,
    data: submissionEdited,
    headers: getAuth()

})

export const GET_JOB_IN_SUBMISSION_REQUEST_OBJ = (submissionId) => ({
    method: "GET",
    url: `${JOB_IN_SUBMISSION_URL}mySubMision/${submissionId}`,
    headers: getAuth()
})

export const POST_JOB_IN_SUBMISSION_REQUEST_OBJ = (job) => ({
    method: "POST",
    url: JOB_IN_SUBMISSION_URL,
    data: job,
    headers: getAuth()
})

export const PUT_JOB_IN_SUBMISSION_REQUEST_OBJ = (job) => ({
    method: "PUT",
    url: `${JOB_IN_SUBMISSION_URL}${job.id}`,
    data: job,
    headers: getAuth()
})

export const POST_CONTRACT_USER = (contractUserData) => ({
    method: "POST",
    url: CONTRACT_USER_URL,
    data: contractUserData,
    headers: getAuth()
})

export const PUT_CONTRACT_USER_REQUEST_OBJ =({idContractUser, contractUserData})=>({
    method: "PUT",
    url: `${CONTRACT_USER_URL}${idContractUser}`,
    data: contractUserData,
    headers: getAuth()
    
        

})

export const GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ = (submissionId) => ({
    method: "GET",
    url: `${STAFF_IN_SUBMISSION_URL}${submissionId}`,
    headers: getAuth()
})

export const DELETE_CONTRACT_USER_REQUEST_OBJ = (userAndSubmissionIds) => ({
    method: "DELETE",
    url: STAFF_IN_SUBMISSION_URL,
    data: userAndSubmissionIds,
    headers: getAuth()
})

export const GET_ALL_SUBMISSIONS_REQUEST_OBJ = () => ({
    method: "GET",
    url: SUBMISSION_URL,
    headers: getAuth()
})

export const GET_USER_CONTRACTS_REQUEST_OBJ=(idUser)=> ({
    method: "GET",
    url: `${USER_CONTRACTS_URL}${idUser}`,
    headers: getAuth()
})