import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react';
import { mostrarRyOAccionAPIAction, setActionsStructure} from '../../store2/actions/actions.js'
import { setMyActionsAction, setNotMyActionsAction } from '../../store2/actions/actions.js';
import { getIdsFromArr } from 'app/js/generalFunctions.js';


// ? Get actions
export function useGetActions() {
    const dispatch = useDispatch();
    const mostrarActions =  () => dispatch(mostrarRyOAccionAPIAction());
    
    return { mostrarActions };
}

export const useSetActions =()=>{
    const dispatch = useDispatch()

    const anfeListAPI = useSelector(state => state.fuse.anfeComponente.anfeListAPI);
    const {myAnfesList, notMyAnfesList} = useSelector(state => state.fuse.anfeComponente);
    const RyOList = useSelector(state => state.fuse.RyOComponente.RyOListAPI);
    const actionsList = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI)
    const {
        RyOsICanSee,
        myRyOsList,
    
        noRevRyOICanSee,
        myNoRevRyO,
        notMyNoRevRyO,

    } =useSelector(state => state.fuse.RyOComponente);

        // ? Get section rows. Actions from no reevaluated RyO
    // Ç Mostramos acciones de ryos no reevaluados y que deriven de anfes que nos pertenecen

    const ryoICanSeeIds = useMemo(()=> getIdsFromArr(RyOsICanSee), [RyOsICanSee])
    const myRyOsIds = useMemo(()=> getIdsFromArr(myRyOsList), [myRyOsList])
    // const notMyRyOIds = useMemo(()=> getIdsFromArr(notMyRyOList), [notMyRyOList])

    const noRevRyOICanSeeIds = useMemo(()=> getIdsFromArr(noRevRyOICanSee), [noRevRyOICanSee])
    const myNoRevRyOIds = useMemo(()=> getIdsFromArr(myNoRevRyO), [myNoRevRyO])
    const notMyNoRevRyOIds = useMemo(()=> getIdsFromArr(notMyNoRevRyO), [notMyNoRevRyO])


    // Ç Muestro acciones de ryos i can see no rev
    useEffect(()=>{
        const actionsStructure = actionsList.reduce((acc, action) => {
            const actionRyOId = action.id_record
            if (ryoICanSeeIds.includes(actionRyOId)) {
                acc.actionsIcanSee = [...acc.actionsIcanSee, action]
                if (noRevRyOICanSeeIds.includes(actionRyOId)) acc.actionsICanSeeNoRev = [...acc.actionsICanSeeNoRev, action]
                else acc.actionsICanSeeRev = [...acc.actionsICanSeeRev, action]
            }

            if (myRyOsIds.includes(actionRyOId)) {
                acc.myActions = [...acc.myActions, action]
                if (myNoRevRyOIds) acc.myActionsNoRev = [...acc.myActionsNoRev, action]
                else acc.myActionsRev = [...acc.myActionsRev, action]
            }
            else {
                acc.notMyActions = [...acc.notMyActions, action]
                if (notMyNoRevRyOIds) acc.notMyActionsNoRev = [...acc.notMyActionsNoRev, action]
                else acc.notMyActionsRev = [...acc.notMyActionsRev, action]
            }

            return acc
        }, {
            actionsIcanSee: [], myActions: [], notMyActions: [],
            actionsICanSeeNoRev: [], myActionsNoRev: [], notMyActionsNoRev: [],
            actionsICanSeeRev: [], myActionsRev: [], notMyActionsRev: []
        })

        // console.log("🚀 ~ useEffect ~ actionsStructure:", actionsStructure)
        dispatch(setActionsStructure(actionsStructure))

    }, [actionsList,RyOsICanSee,
        myRyOsList,
        noRevRyOICanSee,
        myNoRevRyO,
        notMyNoRevRyO])


    // const actionsRows = useMemo(() => {
    //     if(!myAnfesList.length || !myRyOsList.length || !actionsList.length) return []
    //     const anfesICanSeeIds = [...myAnfesList, ...notMyAnfesList].map(({id})=>id)
    //     const RyOsICanSee = [...myRyOsList, ...notMyRyOList]
    //     if(!anfesICanSeeIds.length || !RyOsICanSee.length) return []

    //     const myRyOsListIds= myRyOsList.map(({id})=>id)
    //     const notMyRyOsListIds= notMyRyOList.map(({id})=>id)

    //     const myActionsVsNotMyActions = actionsList.reduce((acc, action)=>{
    //         if(myRyOsListIds.includes(action.id_record)) acc.myActions = [...acc.myActions , action]
    //         else if(notMyRyOsListIds.includes(action.id_record)) acc.notMyActions = [...acc.notMyActions , action]
    //         return acc
    //     },{myActions:[], notMyActions:[]})

    //     dispatch(setMyActionsAction(myActionsVsNotMyActions.myActions))
    //     dispatch(setNotMyActionsAction(myActionsVsNotMyActions.notMyActions))

    //     const actionsRows = [...myActionsVsNotMyActions.myActions, ...myActionsVsNotMyActions.notMyActions]
    //     return actionsRows
    // }, [anfeListAPI, RyOList, actionsList])
    // return {actionsRows}
}