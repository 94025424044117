import {

    GET_SUB_CONJUNTO,
    GET_SUB_CONJUNTO_EXITO,
    GET_SUB_CONJUNTO_ERROR,

    POST_SUB_CONJUNTO,
    POST_SUB_CONJUNTO_EXITO,
    POST_SUB_CONJUNTO_ERROR,

    PUT_SUB_CONJUNTO,
    PUT_SUB_CONJUNTO_EXITO,
    PUT_SUB_CONJUNTO_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO,
    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO,
    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {
    listSubConjuntosAPI: [],
    visibilidadModalInsertarSubConjunto: false,
    error: null,
    loading: false,
    filaSeleccionadaGrid: '',
    modo: ''
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********GET CONJUNTOS**********************************/

        case GET_SUB_CONJUNTO:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_SUB_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listSubConjuntosAPI: action.payload
            }
        
        case GET_SUB_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


     //**********POST CONJUNTO**********************************/

         case POST_SUB_CONJUNTO:
            return {
                ...state,
                loading: action.payload
            }
        
        case POST_SUB_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case POST_SUB_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

     //********** PUT CONJUNTO **********************************/ 


     case PUT_SUB_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case PUT_SUB_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            error: null
        }
    
    case PUT_SUB_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }
    //**********VISIBILIDAD**********************************/

    case CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            visibilidad: action.payload

        }
    
    case CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


 //**********FILA SELECIONADA GRID **********************************/

     case CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            filaSeleccionadaGrid: action.payload

        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }
    //**********VISIBILIDAD MODAL INSERTAR**********************************/

    case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertarSubConjunto: action.payload,
                modo: action.modo

            }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

}