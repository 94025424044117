import {

    OBTENER_ULTIMO_ID_RO,
    OBTENER_ULTIMO_ID_RO_EXITO,
    OBTENER_ULTIMO_ID_RO_ERROR,

    //MODAL EDITAR ACCIONES
    UPDATE_RM_ACCION,
    UPDATE_RM_ACCION_EXITO,
    UPDATE_RM_ACCION_ERROR,

    CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION,
    CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO,
    CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR,

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_SELECCION_ANFE_GRID,
    CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO,
    CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR,

    ELIMINAR_ANFE,
    ELIMINAR_ANFE_EXITO,
    ELIMINAR_ANFE_ERROR,

    MOSTRAR_ANFE,
    MOSTRAR_ANFE_EXITO,
    MOSTRAR_ANFE_ERROR,

    CAMBIAR_STATE_INSERTADO_ANFE,
    CAMBIAR_STATE_INSERTADO_ANFE_EXITO,
    CAMBIAR_STATE_INSERTADO_ANFE_ERROR,

    UPDATE_ANFE,
    UPDATE_ANFE_EXITO,
    UPDATE_ANFE_ERROR,


    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR,
    CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR,

    INSERTAR_ANFE_MODAL_INSERTAR,
    INSERTAR_ANFE_MODAL_INSERTAR_EXITO,
    INSERTAR_ANFE_MODAL_INSERTAR_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR,
    CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR,

    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR,

    CAMBIAR_ESTADO_ANFE,
    CAMBIAR_ESTADO_ANFE_EXITO,
    CAMBIAR_ESTADO_ANFE_ERROR,

    CAMBIAR_VALOR_TAB,
    CAMBIAR_VALOR_TAB_EXITO,
    CAMBIAR_VALOR_TAB_ERROR,

    SET_MY_ANFES,
    SET_NOT_MY_ANFES,
    SET_ANFES_STRUCTURE

} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getCookie } from 'app/js/generalFunctions'
import { mostrarRyOAPIAction } from '../RyO/actions';
//Constantes globales
const urlAnfeApi = process.env.REACT_APP_URL_DJANGO + "/api/risk_management/"
const urlAnfeByContractApi = process.env.REACT_APP_URL_DJANGO + "/api/risk_management/persona/"
const urlSubdivisionApi = process.env.REACT_APP_URL_DJANGO + "/api/subdivision/"
const urlRmAccionesApi = process.env.REACT_APP_URL_DJANGO + "/api/rm_action/"
const urlRmRO = process.env.REACT_APP_URL_DJANGO + "/api/rm_risk_opportunity/"

//************************ MOSTRAR RISK_MANAGEMENT API GET **********************************************

export function obtenerUltimoIdROAction() {
    return async (dispatch) => {
        dispatch(obtenerUltimoIdRO())

        await axios({
            method: "GET",
            url: urlRmRO + "lastId/",
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }
        })
            .then(response => {
                dispatch(obtenerUltimoIdROExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerUltimoIdROError(true))
            })
    }
}

const obtenerUltimoIdRO = () => ({
    type: OBTENER_ULTIMO_ID_RO,
    payload: true
})

const obtenerUltimoIdROExito = (id) => ({
    type: OBTENER_ULTIMO_ID_RO_EXITO,
    payload: id
})

const obtenerUltimoIdROError = (error) => ({
    type: OBTENER_ULTIMO_ID_RO_ERROR,
    payload: error
})

export function MostarAnfesByContractAPIAction(idPersona) {
    return async (dispatch) => {
        dispatch(MostarAnfesAPI(true))


        await axios({
            method: "GET",
            url: urlAnfeByContractApi + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(MostarAnfesAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(MostarAnfesAPIError(true))
            })

    }
}

//MISMAS FUNCIONES QUE GET NORMAL


//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAnfeAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarValorSeleccionAnfe())

        try {
            dispatch(cambiarValorSeleccionAnfeExito(valorNuevo))


        } catch (error) {

            dispatch(cambiarValorSeleccionAnfeError(true))
        }

    }

}

const cambiarValorSeleccionAnfe = () => ({
    type: CAMBIAR_VALOR_SELECCION_ANFE_GRID,
    payload: false

})

const cambiarValorSeleccionAnfeExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_ANFE_GRID_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionAnfeError = estado => ({
    type: CAMBIAR_VALOR_SELECCION_ANFE_GRID_ERROR,
    payload: estado
})


//************************ ELIMINAR RISK MANAGEMENT **********************************************

export function eliminarAnfeAction(id, idPerson) {

    return async (dispatch) => {
        dispatch(eliminarAnfe(true))


        await axios({
            method: "DELETE",
            url: urlAnfeApi + id,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(eliminarAnfeExito(false))

                dispatch(
                    showMessage({
                        message: "Correctly removed",
                        variant: "success"
                    })
                )

                dispatch(MostarAnfesByContractAPIAction(idPerson))

            })

            .catch(error => {
                console.log(error.response)
                dispatch(eliminarAnfeError(false))
                dispatch(
                    showMessage({
                        message: "Error when deleting",
                        variant: "error"
                    })
                )
            })


    }
}





const eliminarAnfe = (estado) => ({
    type: ELIMINAR_ANFE,
    payload: estado

})

const eliminarAnfeExito = estado => ({
    type: ELIMINAR_ANFE_EXITO,
    payload: estado

})

const eliminarAnfeError = estado => ({
    type: ELIMINAR_ANFE_ERROR,
    payload: estado
})


//************************ MOSTRAR RISK_MANAGEMENT API GET **********************************************

export function MostarAnfesAPIAction() {
    return async (dispatch) => {
        dispatch(MostarAnfesAPI(true))


        await axios({
            method: "GET",
            url: urlAnfeApi,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(MostarAnfesAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(MostarAnfesAPIError(true))
            })

    }
}

const MostarAnfesAPI = (estado) => ({
    type: MOSTRAR_ANFE,
    payload: true

})

const MostarAnfesAPIExito = risk_managements => ({

    type: MOSTRAR_ANFE_EXITO,
    payload: risk_managements

})

const MostarAnfesAPIError = estado => ({
    type: MOSTRAR_ANFE_ERROR,
    payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL EDITAR ACCION*****************************************/

export function cambiarVisibilidadModalEditarAccionAction(valorNuevo) {
    return (dispatch) => {
        dispatch(cambiarVisibilidadModalEditarAccion())

        try {
            dispatch(cambiarVisibilidadModalEditarAccionExito(valorNuevo))
        } catch (error) {
            dispatch(cambiarVisibilidadModalEditarAccionError(true))
        }
    }
}

const cambiarVisibilidadModalEditarAccion = () => ({
    type: CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION,
    payload: true
})

const cambiarVisibilidadModalEditarAccionExito = valorNuevo => ({
    type: CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_EXITO,
    payload: valorNuevo
})

const cambiarVisibilidadModalEditarAccionError = estado => ({
    type: CAMBIAR_VISIBILIDAD_MODAL_EDITAR_ACCION_ERROR,
    payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL INSERTAR**********************************************

export function cambiarVisibilidadModalInsertarAnfeAction(valorNuevo) {


    return (dispatch) => {
        dispatch(cambiarVisibilidadModalInsertarAnfe())

        try {
            dispatch(cambiarVisibilidadModalInsertarAnfeExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarVisibilidadModalInsertarAnfeError(true))
        }
    }
}

const cambiarVisibilidadModalInsertarAnfe = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR,
    payload: true

})

const cambiarVisibilidadModalInsertarAnfeExito = valorNuevo => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_EXITO,
    payload: valorNuevo

})

const cambiarVisibilidadModalInsertarAnfeError = estado => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_INSERTAR_ERROR,
    payload: estado
})
//************************CAMBIAR VISIBILIDAD MODAL ELIMINAR**********************************************

export function cambiarVisibilidadModalEliminarAnfeAction(valorNuevo) {
    return (dispatch) => {
        dispatch(cambiarVisibilidadModalEliminarAnfe())

        try {
            dispatch(cambiarVisibilidadModalEliminarAnfeExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarVisibilidadModalEliminarAnfeError(true))
        }
    }
}

const cambiarVisibilidadModalEliminarAnfe = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR,
    payload: true

})

const cambiarVisibilidadModalEliminarAnfeExito = valorNuevo => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_EXITO,
    payload: valorNuevo

})

const cambiarVisibilidadModalEliminarAnfeError = estado => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_ANFE_ELIMINAR_ERROR,
    payload: estado
})

//************************CAMBIAR STATE INSERTADOAnfe**********************************************

export function cambiarStateInsertadoAnfeAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarStateInsertadoAnfe())

        try {
            dispatch(cambiarStateInsertadoAnfeExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarStateInsertadoAnfeError(true))
        }
    }
}

const cambiarStateInsertadoAnfe = () => ({
    type: CAMBIAR_STATE_INSERTADO_ANFE,
    payload: true

})

const cambiarStateInsertadoAnfeExito = valorNuevo => ({
    type: CAMBIAR_STATE_INSERTADO_ANFE_EXITO,
    payload: valorNuevo

})

const cambiarStateInsertadoAnfeError = estado => ({
    type: CAMBIAR_STATE_INSERTADO_ANFE_ERROR,
    payload: estado
})

//************************ UPDATE RM ACCION ****************************************************

export function updateRmAccionAction(id, json, idPerson, veAllAcciones) {
    return async (dispatch) => {
        dispatch(updateRmAccion())
        axios({
            method: "PUT",
            url: urlRmAccionesApi + id,
            data: json,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }
        }).then(response => {
            dispatch(updateRmAccionExito(response.data))
            dispatch(
                showMessage({
                    message: "Updated Accion",
                    variant: "success"
                })
            )
        }).catch(error => {
            console.log(error.response)
            dispatch(updateRmAccionError(true))
            dispatch(
                showMessage({
                    message: "Error when updating Accion",
                    variant: "error"
                })
            )
        })
    }
}

const updateRmAccion = (rm_accion) => ({
    type: UPDATE_RM_ACCION,
    payload: rm_accion
})

const updateRmAccionExito = (estado) => ({
    type: UPDATE_RM_ACCION_EXITO,
    payload: estado
})

const updateRmAccionError = (estado) => ({
    type: UPDATE_RM_ACCION_ERROR,
    payload: estado
})

//************************ UPDATE RISK_MANAGEMENT **********************************************

export function updateAnfeAction(id, json, idPerson, veAllRisk) {

    return async (dispatch) => {
        dispatch(updateAnfe())

        axios({
            method: "PUT",
            url: urlAnfeApi + id,
            data: json,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(updateAnfeExito(response.data))

                if (response.data['active'] == false) {
                    dispatch(
                        showMessage({
                            message: "FMEA deleted",
                            variant: "success"
                        })
                    )
                }
                else {
                    dispatch(
                        showMessage({
                            message: "FMEA updated",
                            variant: "success"
                        })
                    )
                }

                if (veAllRisk == true) {
                    dispatch(MostarAnfesAPIAction())
                }
                else {
                    dispatch(MostarAnfesByContractAPIAction(idPerson))
                }

                dispatch(mostrarRyOAPIAction())


            })
            .catch(error => {
                console.log(error.response)
                dispatch(updateAnfeError(true))

                dispatch(
                    showMessage({
                        message: "Error when updating R&O plan",
                        variant: "error"
                    })
                )
            })

    }
}

const updateAnfe = (rm_accion) => ({
    type: UPDATE_ANFE,
    payload: rm_accion

})

const updateAnfeExito = estado => ({
    type: UPDATE_ANFE_EXITO,
    payload: estado

})

const updateAnfeError = estado => ({
    type: UPDATE_ANFE_ERROR,
    payload: estado
})

//************************ INSERTA RISK_MANAGEMENT MODAL INSERTAR**********************************************

export function insertarAnfeModalInsertarAction(risk_management, idPerson, veAllRisk) {

    return async (dispatch) => {
        dispatch(insertarAnfeModalInsertar(true))


        await axios({
            method: "POST",
            url: urlAnfeApi,
            data: risk_management,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(insertarAnfeModalInsertarExito(response.data))

                dispatch(
                    showMessage({
                        message: "Risk Management successfully created",
                        variant: "success"
                    })

                )

                // if (veAllRisk == true) {
                    dispatch(MostarAnfesAPIAction())
                // }
                // else {
                //     dispatch(MostarAnfesByContractAPIAction(idPerson))
                // }



            })
            .catch(error => {
                console.log(error.response)
                dispatch(insertarAnfeModalInsertarError(false))
                dispatch(
                    showMessage({
                        message: "Error creating Risk Management",
                        variant: "error"
                    })
                )
            })
    }
}

const insertarAnfeModalInsertar = (estado) => ({
    type: INSERTAR_ANFE_MODAL_INSERTAR,
    payload: estado

})

const insertarAnfeModalInsertarExito = idCreado => ({
    type: INSERTAR_ANFE_MODAL_INSERTAR_EXITO,
    payload: idCreado

})

const insertarAnfeModalInsertarError = estado => ({
    type: INSERTAR_ANFE_MODAL_INSERTAR_ERROR,
    payload: estado
})

//************************ CONSULTA SUBDIVISIONES MODAL **********************************************

// export function consultaSubdivisionesAction() {

//     return async (dispatch) => {
//         dispatch(consultaSubdivisiones())


//         await axios.get(urlSubdivisionApi)
//             .then(response => {
//                 dispatch(consultaSubdivisionesExito(response.data))
//             })
//             .catch(error => {
//                 console.log(error.response)
//                 dispatch(consultaSubdivisionesError(true))
//             })

//     }
// }

// const consultaSubdivisiones = () => ({
//     type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR,
//     payload: true

// })

// const consultaSubdivisionesExito = programas => ({
//     type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO,
//     payload: programas

// })

// const consultaSubdivisionesError = estado => ({
//     type: CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR,
//     payload: estado
// })


//************************ CAMBIAR ESTADO RISK_MANAGEMENT  **********************************************

export function cambiarEstadoAnfeAction(valorNuevo) {
    
    return (dispatch) => {
        dispatch(cambiarEstadoAnfe())

        try {
            dispatch(cambiarEstadoAnfeExito(valorNuevo))
            
        } catch (error) {
            
            dispatch(cambiarEstadoAnfeError(true))
        }
    }
}

const cambiarEstadoAnfe = () => ({
    type: CAMBIAR_ESTADO_ANFE,
    payload: true
    
})

const cambiarEstadoAnfeExito = (valorNuevo) => ({
    type: CAMBIAR_ESTADO_ANFE_EXITO,
    payload: valorNuevo
    
    
})

const cambiarEstadoAnfeError = estado => ({
    type: CAMBIAR_ESTADO_ANFE_ERROR,
    payload: estado
})


//************************ SET_MY_ANFES  **********************************************


export const setAnfesStructure =(anfesStructure)=>{
    return (dispatch) => {
        dispatch({
            type: SET_ANFES_STRUCTURE,
            payload: anfesStructure
        })
    }
}


// export const setMyAnfesAction =(myAnfes) =>{
//     return(dispatch)=>{
//         dispatch(setMyAnfes(myAnfes))
//     }
// }

// const setMyAnfes =(myAnfes)=>({
//     type: SET_MY_ANFES,
//     payload: myAnfes
// })

// export const setNoMyAnfesAction =(notMyAnfesList)=>({
//     type: SET_NOT_MY_ANFES,
//     payload: notMyAnfesList
// })