import * as global from 'global'
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setvisibilityAction, setAgressoCodeSelectedAction, setAgressoCodesAndVTGroups, setVisualTimeGroupFromMissionAction, setMissionsManagersFromSiteManagerWPAction, setMissionManagerSelectedAction, setMissionManagerSelectedIdResAction, setSaveMissionsIMustSeeAction, setSaveSubMissionsIMustSeeAction, setMissionsAndSubmissionsPeopleAction, setPersonLoginIsSiteManagerAction, setPersonLoginCanSeeAllBAAction,knowIfPersonLoginIsSiteManagerOrCanSeeAllBAAction } from "../store/actions"
import { getMissionSelectedInfo, getSubMissionTempVsPerm, getMissionAndSubmissionSelectedStaff, getAllStaffFromMissionsAndSubmissionIMustSee, getAllSubmissionStaffFromMissionSelected, getSubmissionSelectedInfo, setNotRepeatAndNotNullValues } from "../utils/filterFunctions"
import { mostrarAllWorkPackage } from "components/Gestion/PaqueteTrabajo/store/actions"
import { mostrarAllMisionAPIAction } from "components/Gestion/Mision/store/actions"
import { getSubMisionAPIAction } from "components/Gestion/SubMision/store/actions"
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks'
import { getAllWpsAction } from 'components/Managment/Contract/WorkPackage/store/actions'
import { getAllMissionsAction } from 'components/Managment/Contract/Missions/store/actions'
import { getAllSubmissionsAction } from 'components/Managment/Contract/Submissions/store/actions'

// ? Clase para efecto aparicion - desaparicion
export const useFilterClassName = () => {
    const { firstRender, filterVisibility } = useSelector(state => state.fuse.filterComponente)
    if (!filterVisibility && firstRender) return "d-none"
    else if (filterVisibility && !firstRender) return "row m-3 slide-in-left visible-filter"
    else return "row m-3 slide-in-right visible-filter"

}

// ? Pasamos visibilidad del filtro a false cuando tocamos fuera de él
export const useHideFilterClickBackground = () => {
    const dispatch = useDispatch()
    const filterBackgroundFunction = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setvisibilityAction(false))
    }
    return { filterBackgroundFunction }
}

// ? Dont do the logic if person login cant see managers
const useDoLogic =()=>{
    const { personLoginIsSiteManager, personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente);
    const doLogic = (!personLoginIsSiteManager && !personLoginCanSeeAllBA) ? false : true
    return {doLogic}
}

// ************************************ MissionsManagersFilter ************************************
// ? Know if person login is N4 and dispatch the filter value if affirmative
// Ç Esta funcion nos dice si la persona logeada es N4 o tiene el permiso de ver todo en BA
// Ç Si es N4 tendra el filtro de managers si esta de manager en algun WP
// Ç Si tiene el permiso le saldra el filtro con todas los responsables de las misiones y tendra acceso a todas las misiones
export const useKnowIfPersonLoginIsSiteManagerOrCanSeeAllBA = () => {
    const dispatch = useDispatch()
    const { getViewAllPermission } = useGetPermissions()
    const personLoginCanSeeAllBA = getViewAllPermission("BA")

    const {person: personLogin} = useSelector(state => state.fuse.userComponente);
    const personLoginIsSiteManager = personLogin?.rolUser === global.rolN4

    useEffect(() => {
        dispatch(knowIfPersonLoginIsSiteManagerOrCanSeeAllBAAction({
            isN4: personLoginIsSiteManager,
            canSeeAllBA: personLoginCanSeeAllBA,
            personLoginId: personLogin.id,
            personLoginIDRes: personLogin.IDRes
        }))
    }, [])
}


// ? Dispatch all missions
export const useDispatchAllSectionsIfUserCanSeeAllBA = () => {
    const dispatch = useDispatch()
    const { personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente)
    const { allWpsList } = useSelector(state => state.fuse.workPackageReducer)
    const { allMissionsList } = useSelector(state => state.fuse.missionsReducer)
    const { allSubmissionsList } = useSelector(state => state.fuse.submissionsReducer)
    useEffect(() => {
        if(!personLoginCanSeeAllBA) return
        !allWpsList.length && dispatch(getAllWpsAction())
        !allMissionsList.length && dispatch(getAllMissionsAction());
        !allSubmissionsList.length && dispatch(getAllSubmissionsAction())
    }, [personLoginCanSeeAllBA]);
}



// ? Get the users managers from wp the person login is manager
// ? If have view all permission BA get all managers from missions
export const useGetMissionsManagers = () => {
    const dispatch = useDispatch()
    const {doLogic} = useDoLogic()
    const {person: personLogin} = useSelector(state => state.fuse.userComponente);
    const { departamentalAndNoDepartamentalWpsInfo, departamentalAndNoDepartamentalMissionsInfo } = useSelector(state => state.fuse.userComponente);
    const {allMissionsList} = useSelector(state => state.fuse.missionsReducer);
    const { personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente);
    const logicWasMade = useRef(false)
    // Ç doLogic: No hacemos esta logica si la persona no debe ver este filtro porque no es N4 o no tiene el permiso especial
    // Ç logicWasMade para controlar que se haga solo una vez
    
    useEffect(() => {
        if ( !doLogic || logicWasMade.current) return
        let missionsManagers = []
        if(!personLoginCanSeeAllBA && departamentalAndNoDepartamentalWpsInfo.length){

            // Ç Cogemos de los wps que nos llegan los que estamos como managers
            const wpsIdsImManager = departamentalAndNoDepartamentalWpsInfo.filter(wp => wp.responsableWP.includes(personLogin.id)).map(wp => wp.id)

            // Ç Cogemos los managers de las misiones que dependen del wp de los que somos managers
            let missionsManagersFromMyWps = departamentalAndNoDepartamentalMissionsInfo.filter(mission => wpsIdsImManager.includes(mission.id_workPackage)).flatMap(mission => mission.staff.managers.flatMap(manager => ({ id: manager.id, IDRes: manager.IDRes, first_name: manager.first_name, last_name: manager.last_name })))

            // Ç Eliminamos managers duplicados
            const missionsManagersFromMyWpsIds = [...new Set(missionsManagersFromMyWps.map(manager => manager.id))]
            missionsManagers = missionsManagersFromMyWpsIds.map(managerId => missionsManagersFromMyWps.find(manager => manager.id === managerId))

            logicWasMade.current = true
        }
        else if (personLoginCanSeeAllBA && allMissionsList.length){

            // Ç Cogemos los managers de todas las misiones y eliminamos duplicados
            const allMissionsManagers = allMissionsList.flatMap(mission => mission.staff.managers)
            const allMissionsManagersIds = [...new Set(allMissionsManagers.map(manager => manager.id))]
            missionsManagers= allMissionsManagersIds.map(id => allMissionsManagers.find(manager => manager.id === id))

            logicWasMade.current = true
        }

        missionsManagers.length &&  dispatch(setMissionsManagersFromSiteManagerWPAction(missionsManagers))

    }, [departamentalAndNoDepartamentalWpsInfo, departamentalAndNoDepartamentalMissionsInfo, allMissionsList, doLogic, personLoginCanSeeAllBA, logicWasMade])



}

// ? Select specific mission manager and select "all missions" is there is other option selected from previous manager
export const useSetMissionManager = () => {
    const dispatch = useDispatch()
    const setMissionManager = (id) =>  id && dispatch(setMissionManagerSelectedAction(id))
    const setMissionManagerIdRes =(IDRes)=>  dispatch(setMissionManagerSelectedIdResAction(IDRes))
    return { setMissionManager, setMissionManagerIdRes }
}



// ? Get the filter missions from manager

export const useSetFilterMissionsIMustSee = () => {
    // ç Si la persona logeada no es N4 se le establece como manager en  useKnowIfPersonLoginIsSiteManager()
    const dispatch = useDispatch()
    const {  departamentalAndNoDepartamentalMissionsInfo, departamentalAndNoDepartamentalSubMissionsInfo } = useSelector(state => state.fuse.userComponente);
    const { managersFromSiteManagerMissions, filterManagerSelected, personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente)

    const {allMissionsList} = useSelector(state => state.fuse.missionsReducer);
    const {allSubmissionsList} = useSelector(state => state.fuse.submissionsReducer);


    useEffect(() => {
        if ( filterManagerSelected=== null) return
        // Ç Dependiendo de si puede o no ver todo BA, determinamos si todas o las de sections_views

        const itsAManagerSelected = filterManagerSelected !== -1

        const getMissionsIMustSee = (missionsList) => {
            let missions;
            // console.log("MANAGER", filterManagerSelected)
            if (!itsAManagerSelected) missions = missionsList;
            else missions = missionsList.filter(mission => mission.responsables.includes(filterManagerSelected))
            // console.log("MISSSIONSSS", missions)
            dispatch(setSaveMissionsIMustSeeAction(missions))

        }

        // Ç Cogemos solo las misiones que tengan submisiones
        const getAllMissionsWithSubmissions=(missions, submissions)=>{
            const subsFathersIds = submissions.map(s => s.id_mision)
            return missions.filter(m => subsFathersIds.includes(m.id))
        }

        // Ç Establecemos la lista de misiones que debemos coger. Si tenemos el permiso BA seran todas las misiones, si no, las que tenemos en departamentalAndNoDepartamentalMissionsInfo
        let missionsWithSubmissions;
        if(personLoginCanSeeAllBA){
            if(!allMissionsList.length || !allSubmissionsList.length) return
            missionsWithSubmissions = getAllMissionsWithSubmissions(allMissionsList, allSubmissionsList)
            getMissionsIMustSee(missionsWithSubmissions)
        }
        else{
            if(!departamentalAndNoDepartamentalMissionsInfo.length || !departamentalAndNoDepartamentalSubMissionsInfo.length) return
            missionsWithSubmissions = getAllMissionsWithSubmissions(departamentalAndNoDepartamentalMissionsInfo, departamentalAndNoDepartamentalSubMissionsInfo)
            getMissionsIMustSee(missionsWithSubmissions)
        }

    }, [managersFromSiteManagerMissions, filterManagerSelected, departamentalAndNoDepartamentalMissionsInfo, departamentalAndNoDepartamentalSubMissionsInfo, allMissionsList, allSubmissionsList])


}

// ? Get submissions from missions
export const useSetSubmissionsIMustSee = () => {
    const dispatch = useDispatch()
    const { departamentalAndNoDepartamentalSubMissionsInfo } = useSelector(state => state.fuse.userComponente);
    const {filterMissionSelected, missionsIMustSee, missionsStringId, personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente);
    const {allSubmissionsList} = useSelector(state => state.fuse.submissionsReducer);


    useEffect(() => {
        
        if (!missionsStringId) return

        // Ç Si las misiones estan vacias significa que el manager seleccionado tiene misiones, pero esas misiones no tienen submisiones, por lo que enviamos la submisiones vacias, y en consecuencia no tendremos personas en peopleInMissionAndSubmission
        // if(!missionsIMustSee.length) {console.log("MISIONES VACIAS");dispatch(setSaveSubMissionsIMustSeeAction([])); return}

        const submissionList = personLoginCanSeeAllBA ? allSubmissionsList : departamentalAndNoDepartamentalSubMissionsInfo


        let submissions = []
        const itsMissionSelected = filterMissionSelected !== -1

        // Ç Si hay mision seleccionada => submisiones que pertenezcan a esa mision
        // Ç Si no hay mision seleccionada => submisiones que pertenezcan a las misiones del manager
        if(itsMissionSelected) {
            submissions = submissionList.filter(submission => submission.id_mision === filterMissionSelected)
        }
        else{
            const missionsIMustSeeIds = missionsIMustSee.map(({ id }) => id)
            submissions = submissionList.filter(sub => missionsIMustSeeIds.includes(sub.id_mision))
        }

        dispatch(setSaveSubMissionsIMustSeeAction(submissions))

    }, [missionsStringId])

}



export const useGetPeopleInSubmission = () => {
    const { missionsAndSubmissionsStringId, filterMissionSelected, filterSubMissionSelected, missionsIMustSee, subMissionsIMustSee, filterManagerSelected, personLoginIsSiteManager,personLoginCanSeeAllBA } = useSelector(state => state.fuse.filterComponente)
    const personLogin = useSelector(state => state.fuse.userComponente.person);
    
    const dispatch = useDispatch()
    useEffect(() => {
        // ? Person in missions and submissions
        // Ç Si la persona es manager de la mision ve: managers mision, empleados mision, managers sub mision, empleados sub mision
        // Ç Si la persona es employee de la mision ve: employees mision, managers sub mision, empleados sub mision
        // Ç Si la persona es manager de la submision ve: managers sub mision, empleados sub mision
        // Ç Si la persona es employee de la submision ve: empleados de la submision

        if (!missionsAndSubmissionsStringId) return
        // console.log("MISSSSSSSSSSSIONS", missionsIMustSee)
        if(!missionsIMustSee.length) {dispatch(setMissionsAndSubmissionsPeopleAction({permanent_IDRes: [], temporary_IDRes:[]})); return}

        const thereIsMissionSelected = filterMissionSelected !== -1
        const thereIsSubMissionSelected = filterSubMissionSelected !== -1

        if (personLoginIsSiteManager || personLoginCanSeeAllBA) {

            if (filterManagerSelected !== -1) {

                if (thereIsMissionSelected) {

                    const missionSelectedInfo = missionsIMustSee.find(mission => mission.id === filterMissionSelected)

                    if (thereIsSubMissionSelected) {
                        // ? Get mision and submision selected staff
                        const {allStaffToSearch} = getMissionAndSubmissionSelectedStaff({missionSelectedInfo, subMissionsIMustSee, filterSubMissionSelected})
                        dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))

                    }
                    else {
                        // ? Get staff from all sub missions
                        const {allStaffToSearch} = getAllSubmissionStaffFromMissionSelected({missionSelectedInfo, subMissionsIMustSee})
                        dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                    }
                }
                
                else if (!thereIsMissionSelected) {
                    // ? Get all staff from manager missions and submissions
                    const {allStaffToSearch} = getAllStaffFromMissionsAndSubmissionIMustSee({missionsIMustSee, subMissionsIMustSee})
                    dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                }
            }
            else {
                // ! NO HAY MISION SELECCIONADA Ni manager => igual que arriba
                const {allStaffToSearch} = getAllStaffFromMissionsAndSubmissionIMustSee({missionsIMustSee, subMissionsIMustSee})
                dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))

            }
        }
        else {
            if (thereIsMissionSelected) {

                const {missionSelectedInfo, personLoginIsMissionManager, personLoginIsMissionEmployee} = getMissionSelectedInfo({missionsIMustSee, filterMissionSelected, personLoginId: personLogin.id})

                if (thereIsSubMissionSelected) {

                    const {personLoginIsSubMissionManager, personLoginIsSubMissionEmployee, subMissionAllStaff, subMissionEmployees} = getSubmissionSelectedInfo({subMissionsIMustSee, filterSubMissionSelected, personLoginId: personLogin.id})

                    if (personLoginIsMissionManager || personLoginIsMissionEmployee || personLoginIsSubMissionManager) {
                      
                        const { temporary_IDRes: submissionAllStaffTemporalIDRes, permanentIDRes: submissionAllStaffPermanentIDRes } = getSubMissionTempVsPerm(subMissionAllStaff)

                        if (personLoginIsMissionManager) {
                            // Ç Soy manager de la mision, asi que obtengo a todo el staff de la mision y a todo el staff de la sub seleccionada
                            const missionStaff = [...missionSelectedInfo.staff.managers.flatMap(m => m.IDRes), ...missionSelectedInfo.staff.employees.flatMap(e => e.IDRes)]
                            const allStaffToSearch = {
                                permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionStaff])],
                                temporary_IDRes: submissionAllStaffTemporalIDRes
                            }
                            dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                        }
                        else if (personLoginIsMissionEmployee) {
                            // Ç Soy employee de la mision, asi que obtengo employees de la mision y a todo el staff de la sub seleccionada
                            const missionEmployees = missionSelectedInfo.staff.employees.map(e => e.IDRes)
                            const allStaffToSearch = {
                                permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionEmployees])],
                                temporary_IDRes: submissionAllStaffTemporalIDRes
                            }
                            dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                        }
                        else if (personLoginIsSubMissionManager) {
                            const allStaffToSearch = {
                                permanent_IDRes: submissionAllStaffPermanentIDRes,
                                temporary_IDRes: submissionAllStaffTemporalIDRes
                            }
                            dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                        }
                    }
                    else if (personLoginIsSubMissionEmployee) {
                        const { temporary_IDRes: submissionEmployeesTemporalIDRes, permanentIDRes: submissionEmployeesPermanentIDRes } = getSubMissionTempVsPerm(subMissionEmployees)
                        const allStaffToSearch = {
                            permanent_IDRes: submissionEmployeesPermanentIDRes,
                            temporary_IDRes: submissionEmployeesTemporalIDRes
                        }
                        dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                    }
                }
                else {
                    // Ç NO HAY SUBMISION SELECCIONADA
                    // ! Si soy manager mision => todo kiski mision submision
                    // ! Si soy employee mision => employees mision kisko sub
                    // ! Si soy manager sub => kiski sub
                    // ! Si soy employee sub => employees sub

                    const missionStaff = [...missionSelectedInfo.staff.managers.flatMap(m => m.IDRes), ...missionSelectedInfo.staff.employees.flatMap(e => e.IDRes)]

                    const subMissionsStaff = subMissionsIMustSee.flatMap(sub => [...sub.staff.managers, ...sub.staff.employees]).flatMap(e => e)

                    const { temporary_IDRes: submissionAllStaffTemporalIDRes, permanentIDRes: submissionAllStaffPermanentIDRes } = getSubMissionTempVsPerm(subMissionsStaff)
                    const allStaffToSearch = {
                        permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionStaff])],
                        temporary_IDRes: submissionAllStaffTemporalIDRes
                    }
                    dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))
                }

            } 
            else {
                // ? Get all staff from manager missions and submissions
                const { allStaffToSearch } = getAllStaffFromMissionsAndSubmissionIMustSee({ missionsIMustSee, subMissionsIMustSee })
                dispatch(setMissionsAndSubmissionsPeopleAction(allStaffToSearch))

            }
        }


    }, [missionsAndSubmissionsStringId])


}

// ? Get missions agresso and vtg
export const useGetMissionsAgressoAndVTG = () => {

    const dispatch = useDispatch();
    const { filterMissionSelected, missionsIMustSee, missionsAndSubmissionsStringId } = useSelector(state => state.fuse.filterComponente);

    useEffect(() => {
        if (!missionsAndSubmissionsStringId) return

        if (!missionsIMustSee.length) {
            dispatch(setAgressoCodesAndVTGroups({ agresso: [], vtg: [] }))
            return
        }

        const filterAllMissionsOrMissionSelected = missionsIMustSee.filter(mission => {
            if (filterMissionSelected !== -1) return mission.id === filterMissionSelected
            else return mission
        })

        const missionsAgressoAndVTG = filterAllMissionsOrMissionSelected.reduce((acc, entry) => {
            acc.agresso = setNotRepeatAndNotNullValues(entry.code_agresso, acc.agresso);
            acc.vtg = setNotRepeatAndNotNullValues(entry.visual_time_group, acc.vtg);
            return acc;
        }, { agresso: [], vtg: [] })

        const { agresso, vtg } = missionsAgressoAndVTG

        dispatch(setAgressoCodesAndVTGroups({ agresso, vtg }))

    }, [missionsAndSubmissionsStringId])

}




