
export const GET_CONJUNTO = 'GET_CONJUNTO'
export const GET_CONJUNTO_EXITO = 'GET_CONJUNTO_EXITO'
export const GET_CONJUNTO_ERROR = 'GET_CONJUNTO_ERROR'

export const POST_CONJUNTO = 'POST_CONJUNTO'
export const POST_CONJUNTO_EXITO = 'POST_CONJUNTO_EXITO'
export const POST_CONJUNTO_ERROR = 'POST_CONJUNTO_ERROR'

export const PUT_CONJUNTO = 'PUT_CONJUNTO'
export const PUT_CONJUNTO_EXITO = 'PUT_CONJUNTO_EXITO'
export const PUT_CONJUNTO_ERROR = 'PUT_CONJUNTO_ERROR'

export const CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO = 'CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO'
export const CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO = 'CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_ERROR = 'CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO'

export const CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO = 'CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO'
export const CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR'