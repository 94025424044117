import { createContext } from 'react';
import { useSetRequirementsAndTeamTrainingData,useSetExperienceData } from '../Hooks/hooks';
export const skillMatrixContext = createContext(null);

export const SkillMatrixProvider =({children, missionSelected})=>{


    const { loading, requirements, missionWithNoVTG, errorSkillMatrix, errorTeamTraining, noContentSkillMatrix, noContentTeamTrainig, teamTraining } = useSetRequirementsAndTeamTrainingData(missionSelected)

    const {loadingExperience, missionWithNoAgressoCode, noContentExperience, errorExperience, experienceData} = useSetExperienceData(missionSelected)
    
    

    return (
        <skillMatrixContext.Provider value={{ loading, requirements, missionWithNoVTG, errorSkillMatrix, errorTeamTraining, noContentSkillMatrix, noContentTeamTrainig, teamTraining, loadingExperience, missionWithNoAgressoCode, noContentExperience, errorExperience, experienceData }}>
            {children}
        </skillMatrixContext.Provider>
    )
}