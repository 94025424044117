//***IMPORTACIONES********************************** */

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { React, useEffect, useState, useRef, Fragment } from 'react'
import TaskAlt from '@mui/icons-material/TaskAlt';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import {  useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
//Redux importaciones
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';


import {

  updateRyOAccionAction,
  cambiarEstadoRyOTasksAction,
  deleteRyOAccionAPIAction,
  changeActionsEditMode
} from '../../store2/actions/actions.js'

import {
  insertarLogRiskAPIAction
} from '../../../../Managment/LogRisk/store/actions'
import dayjs from "dayjs";
import { useSetActions } from '../../hooks/actions/actionsHooks.js';


const useStyles = makeStyles({

  customDialogTitle: {
    backgroundColor: 'rgb(37, 47, 62)',
    color: 'rgb(255, 255, 255)',
    marginBottom: '0.5em'
  }

});


//END_IMPORTACIONES *********************************************



function TasksList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState('1');
  const [actionSelected, setActionSelected] = useState(null)
  const [actionsCompleted, setActionsCompleted] = useState([]);
  const [actionsNoCompleted, setActionsNoCompleted] = useState([]);
  const [disabledRemoveActions, setDisabledRemoveActions] = useState(true);
  const [disabledEditActions, setDisabledEditActions] = useState(true);
  const [visibilidadDialogoConfirmacion, setVisibilidadDialogoConfirmacion] = useState(false)
  const [visibilidadDialogoEliminar, setVisibilidadDialogoEliminar] = useState(false)
  const [itemDelete, setItemDelete] = useState('');
  const AMFEList = useSelector(state => state.fuse.anfeComponente.anfeListAPI)
  const RyOList = useSelector(state => state.fuse.RyOComponente.RyOListAPI)
  const {
    RyOsICanSee,
    myRyOsList,

    noRevRyOICanSee,
    myNoRevRyO,
    notMyNoRevRyO,

} =useSelector(state => state.fuse.RyOComponente);

const {
  actionsIcanSee, myActions, notMyActions,
  actionsICanSeeNoRev, myActionsNoRev, notMyActionsNoRev,
  actionsICanSeeRev, myActionsRev, notMyActionsRev
}= useSelector(state => state.fuse.tasksAccionesComponente)

  const rmAccionesListAPI = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI)

  const personLogin = useSelector(state => state.fuse.userComponente.person)
  const personLoginPermisos = useSelector(state => state.fuse.userComponente.personPermisos)

  //OBTIENE EL TASK SELECCIONADO DE LA LISTA
  const filaSeleccionadaActions = useSelector(state => state.fuse.tasksAccionesComponente.filaSeleccionada)
  const visibilidadModalTasks = useSelector(state => state.fuse.tasksAccionesComponente.visibilidad)
  const deleteRyOAccionAPI = (idAccion, id_record) => dispatch(deleteRyOAccionAPIAction(idAccion, id_record))
  const insertarLogRiskAPI = (json) => dispatch(insertarLogRiskAPIAction(json))
  const isEditMode = useSelector(state => state.fuse.tasksAccionesComponente.editMode)



  
  const filaSeleccionadaGridRyOStore = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const idRyOWhenIsNoSelectedrow = useSelector(state => state.fuse.RyOComponente.idRyOWhenNoSelectedRow);
  const [filaSeleccionadaGridRyO, setfilaSeleccionadaGridRyO] = useState(null)
  
  useEffect(()=>{
    if (!visibilidadModalTasks) return
    setfilaSeleccionadaGridRyO(filaSeleccionadaGridRyOStore || idRyOWhenIsNoSelectedrow)
},[visibilidadModalTasks, idRyOWhenIsNoSelectedrow, filaSeleccionadaGridRyOStore])

  //estados locales del formulario





  let numAccionesAbiertas = 0


  useEffect(() => {


    if (personLoginPermisos.length > 0) {

      if (personLoginPermisos.find((item) => item['name'] == "Can delete rm action") == undefined) {
        setDisabledRemoveActions(false)
      }

      if (personLoginPermisos.find((item) => item['name'] == "Can change rm action") == undefined) {
        setDisabledEditActions(false)
      }
    }

  }, [personLoginPermisos])

  useEffect(() => {
    //FILTRAR COMPLETADAS
    const idRyOSelected = RyOsICanSee.find(RyO=> RyO.id === filaSeleccionadaGridRyO)?.id
    const RyOActions = actionsICanSeeNoRev.reduce((acc, entry)=>{
      if(entry.id_record === idRyOSelected){
        entry.completed ? acc.completed.push(entry) : acc.noCompleted.push(entry);
      }
      return acc;
    },{completed: [], noCompleted:[]})
    const {completed, noCompleted} = RyOActions
    setActionsCompleted(completed)
    setActionsNoCompleted(noCompleted);

  }, [actionsICanSeeNoRev, RyOsICanSee])


  useEffect(()=>{
    if(!visibilidadModalTasks || !filaSeleccionadaActions || !actionsCompleted ||!actionsNoCompleted) return;
    const actionIsCompleted = actionsCompleted.find(action => action.id === filaSeleccionadaActions);
    setValue(actionIsCompleted ? "2" : "1")
    // dispatch(cambiarEstadoRyOTasksAction('filaSeleccionada', ''))
    
  },[visibilidadModalTasks, filaSeleccionadaActions, actionsCompleted, actionsNoCompleted])





  if (rmAccionesListAPI.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center h-full">
        <Typography color="text.secondary" >
          There are no tasks!
        </Typography>
      </div>
    );
  }



  function colorDinamic(estado) {

    if (!estado) {
      numAccionesAbiertas++

      return "gray"

    } else {
      return "green"

    }


  }



  function deleteAction() {
    //DELETE ACCION
    
    deleteRyOAccionAPI(itemDelete.id, filaSeleccionadaGridRyO)

    insertarLogRiskAPI({
      persona: personLogin.id,
      fecha_accion: new Date().toISOString().split("T")[0],
      hora_accion: new Date().toLocaleTimeString(),
      accion: "Delete Action",
      ro_relacionado: filaSeleccionadaGridRyO,
      descripcion: "Action delete with id: " + itemDelete.id
    })


  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(cambiarEstadoRyOTasksAction('filaSeleccionada', ''));
    dispatch(changeActionsEditMode(false))
  };




  //function completarAccion(idSelect, nuevoEstado){
  function completarAccion() {

    // let fechaActual = new Date()
    // let fechaseleccionada = fechaActual.toISOString()
    // let arrayFecha = fechaseleccionada.split("T")

    dispatch(
      updateRyOAccionAction(actionSelected.id,
        {
          id_record: actionSelected.id_record,
          proposal: actionSelected.proposal,
          manager: actionSelected.manager,
          d_planned: actionSelected.d_planned,
          d_closed: dateCompleteAction,
          observations: actionSelected.observations,
          rev: actionSelected.rev,
          completed: (actionSelected.estado == true ? false : true)
        }
      )
    )

    //AÑADIR REGISTRO DE EDICION
    insertarLogRiskAPI({
      persona: personLogin.id,
      fecha_accion: new Date().toISOString().split("T")[0],
      hora_accion: new Date().toLocaleTimeString(),
      accion: "Close",
      accion_relacionado: actionSelected.id,
      descripcion: "Action close with id: " + actionSelected.id
    })

    
  }

  function editarAccion(idElemento) {
    if (disabledEditActions) {
      dispatch(cambiarEstadoRyOTasksAction('filaSeleccionada', idElemento))
      dispatch(changeActionsEditMode(!isEditMode))
    }

  }

  const [dateCompleteAction,setDateCompleteAction] = useState(dayjs().format('YYYY-MM-DD'))


  return (
    <>

      <TabContext value={value}>
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.default }}>
          <TabList onChange={handleChange} centered>
            <Tab label="Pending" value="1" />
            <Tab label="Completed" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {/*LISTA DE NO COMPLETADOS */}
          <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
            {
              actionsNoCompleted.map(action=>(
                  <Fragment key={action.id} sx={{paddingLeft:0, paddingRight:0}}>
                  <ListItem style={{ cursor: 'pointer', width: '100%', height: '100%', backgroundColor: "#ffb6b6", marginBottom:"10px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ListItemIcon>
                        <Tooltip title="Mark as complete">
                          <Button style={{ pointerEvents: action.completed ? 'none' : 'auto' }} onClick={() => { setActionSelected(action), setVisibilidadDialogoConfirmacion(true) }}>
                            <TaskAlt style={{ color: colorDinamic(action.completed) }} />
                          </Button>
                        </Tooltip>
                      </ListItemIcon>

                      <ListItemText id="switch-list-label-wifi" >
                        <Tooltip title={action.proposal}>
                          <span value={action.id}> {action.proposal.length >= 40 ? action.proposal.substring(0, 40) + "..." : action.proposal} </span>
                        </Tooltip>
                      </ListItemText>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent:"flex-end"}}>
                      <ListItemIcon>
                        <Tooltip title="Delete">
                          <Button style={disabledRemoveActions  ? { display: "inline", float: "right" } : { display: "none" }}
                            onClick={() => { setVisibilidadDialogoEliminar(true); setItemDelete(action) }}>
                            <DeleteIcon />
                          </Button>
                        </Tooltip>
                      </ListItemIcon>

                      <ListItemIcon>
                        <Tooltip title="Edit">
                          <Button style={disabledEditActions ? { display: "inline", float: "right" } : { display: "none" }}
                            onClick={() => editarAccion(action.id)}>
                            <EditIcon />
                          </Button>
                        </Tooltip>
                      </ListItemIcon>
                    </div>
  
  
                  </ListItem>
                </ Fragment>
                )
              )
            }

          </ List>

          {/*LISTA DE NO COMPLETADOS

          FIN LISTA DE NO COMPLETADOS */}
        </TabPanel>
        <TabPanel value="2">
          {/*LISTA DE NO COMPLETADOS */}
          <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
            {actionsCompleted.map(action => (


              <Fragment sx={{paddingLeft:0, paddingRight:0}}>
                <ListItem style={{ cursor: 'pointer', width: '100%', height: '100%', marginBottom:"10px", display:"flex", justifyContent:"space-between", alignItems:"center",backgroundColor: "#beffcf" }}>
                  <ListItemIcon>
                    <Tooltip title="Completed">
                      <Button style={{ pointerEvents: action.completed ? 'none' : 'auto' }} onClick={() => { setActionSelected(action), setVisibilidadDialogoConfirmacion(true) }}>
                        <TaskAlt style={{ color: colorDinamic(action.completed) }} />
                      </Button>
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText id="switch-list-label-wifi" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
                      <span value={action.id} > {action.proposal.length >= 40 ? action.proposal.substring(0, 40) + "..." : action.proposal} </span>
                  </ListItemText>
                    <Tooltip title={action.proposal}>
                      <Button style={disabledRemoveActions == true ? { display: "inline" } : { display: "none" }}
                        onClick={() => editarAccion(action.id)}>
                        <EditIcon />
                      </Button>

                    </Tooltip>
                </ListItem>
              </Fragment>
            ))}
          </List>

        </TabPanel>
      </TabContext>


      <Dialog open={visibilidadDialogoConfirmacion} fullWidth maxWidth='xs'>

        <DialogTitle classes={{ root: classes.customDialogTitle }} >
          Confirmation
        </DialogTitle>
        <DialogContent>
        Please select the date the action was completed.
        </DialogContent>
                    <FormControl 
                    sx={{
                      width:"100%", display: "flex", justifyContent:"center", flexDirection:"row" ,

                      '& .muiltr-4nncer-MuiFormControl-root-MuiTextField-root': {
                        width:"90%",
                    },
                    }}
                  >
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <DatePicker
                                           
                                                label="Target date"
                                                fullWidth
                                                id="fechaDeteccion"
                                                inputFormat="yyyy-MM-dd"
                                                format="yyyy-MM-dd"
                                                value={dateCompleteAction}
                                                onChange={(newValue) => {
                                                    if (newValue == "Invalid Date" || !newValue)  setDateCompleteAction(newValue)
                                                    else {
                                                        const ryoDate = dayjs(newValue).format('YYYY-MM-DD').toString()
                                                        setDateCompleteAction(ryoDate);
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                          '& .MuiFormControl-root': {
                                                              width:"90%",
                                                              margin: 0,
                                                          },
                                                          '& .MuiTextField-root': {
                                                              backgroundColor: 'lightblue',
                                                          },
                                                      }}
                                                    />
                                                }
                                            />
                                        </LocalizationProvider>

            </FormControl>
        <DialogActions>

          <Button variant="outlined" onClick={() => setVisibilidadDialogoConfirmacion(false)}>Decline</Button>
          <Button variant="outlined" onClick={() => { completarAccion(), setVisibilidadDialogoConfirmacion(false) }}> Confirm</Button>

        </DialogActions>

      </Dialog>

      <Dialog open={visibilidadDialogoEliminar} fullWidth maxWidth='xs'>

        <DialogTitle classes={{ root: classes.customDialogTitle }} >
          Confirmation
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>

          <Button variant="outlined" onClick={() => setVisibilidadDialogoEliminar(false)}>Decline</Button>
          <Button variant="outlined" onClick={() => { deleteAction(), setVisibilidadDialogoEliminar(false) }}> Confirm</Button>

        </DialogActions>

      </Dialog>


    </>
  );
}

export default TasksList;
