import { React, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableModules from '../../../tables/TableModules'
import { RYO_COLUMNS } from '../utils/sectionColumns.js';
import { cambiarValorSeleccionRyOAction } from '../store2/RyO/actions'
import { ModalInsertarRyO } from '../modals2/RyO/insertarRyO.js'
import { ModalEliminarRyO } from '../modals2/RyO/eliminarRyO.js'
import { ModalRyOHistory } from '../modals2/RyO/historialRyO.js'
import DetallesLogRisk from '../../../Managment/LogRisk/modals/detallesLogRisk.js'
import { HistorialActions } from '../modals2/RyO/historialActions.js'
import { ModalRyOActions } from '../modals2/tasks/TasksApp'
import { useGetRyOs,useGetMyRyOs } from '../hooks/RyO/RyOMainSectionHooks'
import { BotonesSuperioresRyO } from '../components/BotonesSuperiores/BotonesRyO';
import { useEffect, useState } from 'react';
import { visibilidadModalActionRequired, saveRyOIdAction, actionIsRequiredAction, setMyRyoOAction } from '../store2/RyO/actions.js'
import { PedingActionAdvise } from "../modals2/tasks/PendingActionAdvise.jsx"
import { mostrarRyOAccionAPIAction } from '../store2/actions/actions';
export default function RyO() {

    const dispatch = useDispatch();
    const { mostrarRyOAPI } = useGetRyOs();

    // ? States / Refs for the component
    const neddToTakeActionRef = useRef(null)

    // ? Store selectors
    const loadingRO = useSelector(state => state.fuse.RyOComponente.loading);
    const {RyOListAPI: RyOList, noRevRyOICanSee} = useSelector(state => state.fuse.RyOComponente);

    const personLogin = useSelector(state => state.fuse.userComponente.person);
    const anfeListAPI = useSelector(state => state.fuse.anfeComponente.anfeListAPI);
    const {myAnfesList, notMyAnfesList} = useSelector(state => state.fuse.anfeComponente);
    // const anfesIMustSee = [...myAnfesList, ...notMyAnfesList]
    const actionsList = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI);
    const visibilidadModalTasks = useSelector(state => state.fuse.tasksAccionesComponente.visibilidad)
    const visibilidadModalInsertarRO = useSelector(state => state.fuse.RyOComponente.visibilidadModalInsertar)

    // ? Dispatch functions
    const cambiarValorSeleccionRO = (valor) => dispatch(cambiarValorSeleccionRyOAction(valor));

    // ? Call list api
    useEffect(() => {
        !RyOList.length && mostrarRyOAPI()
        !actionsList.length && dispatch(mostrarRyOAccionAPIAction())
    }, [])

    // ? Reset the selected row
    useEffect(() => cambiarValorSeleccionRO(""), []);

    // ? Get no reevaluated RyO for show

    // ? Get not reevaluated ryos must be seen
    useGetMyRyOs()



    // ? Know if there is pending actions
    // Ç Buscamos los anfes donde el manager es la persona logeada, buscamos los ryos asociados con npr > 100 sin acciones y si hay obligamos a tomar accion o editar el ryo
    useEffect(() => {
        if (!noRevRyOICanSee.length || !actionsList.length || neddToTakeActionRef.current || visibilidadModalTasks || visibilidadModalInsertarRO) return;


        const ryoIdsNprHigherThan100 = noRevRyOICanSee.filter(ryo => parseInt(ryo.npr) > 100);
        const ryoIdsWithActions = actionsList.map(action => action.id_record)
        const ryoNoHaveActions = ryoIdsNprHigherThan100.filter(ryo => !ryoIdsWithActions.includes(ryo.id) && ryo)
        neddToTakeActionRef.current = ryoNoHaveActions

        if (ryoNoHaveActions.length && (!visibilidadModalTasks || !visibilidadModalInsertarRO)) {
            dispatch(visibilidadModalActionRequired(true));
            dispatch(saveRyOIdAction(neddToTakeActionRef.current[0].id))
            dispatch(actionIsRequiredAction(true))
            neddToTakeActionRef.current = null
        }
        else if (!ryoNoHaveActions.length) {
            dispatch(visibilidadModalActionRequired(false));
        }
        
    }, [noRevRyOICanSee, actionsList, visibilidadModalTasks, visibilidadModalInsertarRO])

      

    // ? Return the component
    return (
        <div style={{ width: '100%' }}>
             <BotonesSuperioresRyO />
            <TableModules rowsProp={noRevRyOICanSee} columnsProp={RYO_COLUMNS} loadingRO={loadingRO} funcionSetValue={cambiarValorSeleccionRO} tableWidth={"240vw"} />
            <ModalInsertarRyO />
            <ModalEliminarRyO />
            <ModalRyOHistory />
            <DetallesLogRisk />
            <ModalRyOActions />
            <HistorialActions />
            <PedingActionAdvise />
        </div>
    )
}