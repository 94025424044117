import { getUsersStringsInfo, getUserStringsInfo } from 'app/js/generalFunctions'
import { useReducer} from 'react'




const INITIAL_STATE = {
    improvementSelectedRow: null,
    insertModalVisibility: false,
    insertModalInEditMode: false,
    peopleModalVisibility: false,
    completeImprovementModalVisibility: false,
    acceptDeclineModalVisibility: false,
    actionPlanModalVisibility: false,

    // mftTeam: {
    //     manager: null,
    //     team: []
    // },

    acceptDeclineModalValues: {
        improvementAccepted: null,
        declineReason: null,
        mftTeam: {
            manager: null,
            team: []
        },
    },

    completeModalValues: {
        category: null,
        cost: null,
        benefits: null,
    },

    peopleModalValues: {
        missionManagers: [],
        applicantImprovement: null,
        membersImprovement: [],
        departmentsManagers: [],
    },

    insertModalValues: {
        title: null,
        submission: null,
        mainUser: null,
        secondaryUser: [],
        currentSituation: null,
        futureSituation: null,
        observations: null,
    }

}


// ? Actions types
const ACTIONS_TYPES = {

    SET_INSERT_MODAL_VISIBILITY: 'SET_INSERT_MODAL_VISIBILITY',

    SET_INSERT_MODAL_EDIT_MODE: 'SET_INSERT_MODAL_EDIT_MODE',

    SET_ALL_VALUES_COMPLETE_MODAL: 'SET_ALL_VALUES_COMPLETE_MODAL',

    SET_PEOPLE_MODAL_VISIBILITY: 'SET_PEOPLE_MODAL_VISIBILITY',

    SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY: 'SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY',

    SET_ACCEPT_DECLINE_MODAL_VISIBILITY: 'SET_ACCEPT_DECLINE_MODAL_VISIBILITY',

    SET_ACTION_PLAN_MODAL_VISIBILITY: 'SET_ACTION_PLAN_MODAL_VISIBILITY',

    SET_IMPROVEMENT_SELECTED_ROW: 'SET_IMPROVEMENT_SELECTED_ROW',

    SET_PEOPLE_MODAL_VALUES: 'SET_PEOPLE_MODAL_VALUES',

    SET_INSERT_MODAL_VALUES: 'SET_INSERT_MODAL_VALUES' ,

    SET_MODAL_VALUES_IN_EDIT_MODE: 'SET_MODAL_VALUES_IN_EDIT_MODE',

    RESET_MODALS_VALUES: 'RESET_MODALS_VALUES',

    SET_COMPLETE_MODAL_VALUES: 'SET_COMPLETE_MODAL_VALUES',

    SET_ACCEPT_DECLINE_VALUES: 'SET_ACCEPT_DECLINE_VALUES',

    SET_MFT_TEAM: 'SET_MFT_TEAM',



}


// ? Actions
const IMPROVEMENT_ACTIONS = {

    // * Insert modal visibility
    [ACTIONS_TYPES.SET_INSERT_MODAL_VISIBILITY]: (state, payload) => ({...state, insertModalVisibility: payload}),

    // * Set insert modal edit mode
    [ACTIONS_TYPES.SET_INSERT_MODAL_EDIT_MODE]: (state, payload) => ({...state, insertModalInEditMode: payload}),


    // * People modal visibility
    [ACTIONS_TYPES.SET_PEOPLE_MODAL_VISIBILITY]: (state, payload) => ({...state, peopleModalVisibility: payload}),

    // * Complete improvement modal visibility
    [ACTIONS_TYPES.SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY]: (state, payload) => ({...state, completeImprovementModalVisibility: payload}),

    // * Accept / Decline improvement modal visibility
    [ACTIONS_TYPES.SET_ACCEPT_DECLINE_MODAL_VISIBILITY]: (state, payload) => ({ ...state, acceptDeclineModalVisibility: payload }),

    // * Action plan modal visibility
    [ACTIONS_TYPES.SET_ACTION_PLAN_MODAL_VISIBILITY]: (state, payload) => ({ ...state, actionPlanModalVisibility: payload }),

    // * Set selected row
    [ACTIONS_TYPES.SET_IMPROVEMENT_SELECTED_ROW]: (state, payload) => ({...state, improvementSelectedRow: payload}),

    // * People modal values
    [ACTIONS_TYPES.SET_PEOPLE_MODAL_VALUES] : (state, payload) => {

        const { missionManagersPayload, applicantImprovementPayload,membersImprovementPayload, departmentsManagersPayload } = payload

        const missionManagers = getUsersStringsInfo(missionManagersPayload)
        const applicantImprovement =  getUserStringsInfo(applicantImprovementPayload)
        const membersImprovement = getUsersStringsInfo(membersImprovementPayload)
        const departmentsManagers = getUsersStringsInfo(departmentsManagersPayload)
        const newPeopleModalValues = {missionManagers, applicantImprovement, membersImprovement, departmentsManagers}
        return {...state, peopleModalValues: newPeopleModalValues}
    },

    // * Set insert modal values
    [ACTIONS_TYPES.SET_INSERT_MODAL_VALUES] : (state, payload) => {

        const { value, inputName } = payload
        const newInsertModalValues = {...state.insertModalValues, [inputName]: value}

        return {...state, insertModalValues: newInsertModalValues}
    },

    // * Set complete modal values
    [ACTIONS_TYPES.SET_COMPLETE_MODAL_VALUES] : (state, payload) => {

        const { value, inputName } = payload
        const newCompleteModalValues = {...state.completeModalValues, [inputName]: value}

        return {...state, completeModalValues: newCompleteModalValues}
    },

    // * People modal values
    [ACTIONS_TYPES.RESET_MODALS_VALUES] : (state) => ({...state, insertModalValues: INITIAL_STATE.insertModalValues, completeModalValues: INITIAL_STATE.completeModalValues, acceptDeclineModalValues: INITIAL_STATE.acceptDeclineModalValues}),

    // * Set modal values in edit mode
    [ACTIONS_TYPES.SET_MODAL_VALUES_IN_EDIT_MODE] : (state, payload) => {

        const {titulo, submission_info, main_user_info, secundary_users_info, situacion_actual, situacion_futura, observations} = payload
        const insertValuesFromSelectedRow = {
            title: titulo,
            submission: submission_info,
            mainUser: main_user_info,
            secondaryUser: secundary_users_info,
            currentSituation: situacion_actual,
            futureSituation: situacion_futura,
            observations: observations,
        }
        return {...state, insertModalValues: insertValuesFromSelectedRow}
    },

    // * Set complete modal values (accept / decline modal on)
    [ACTIONS_TYPES.SET_ALL_VALUES_COMPLETE_MODAL]: (state, payload) => {
        const {category, ...otherValues} = payload
        return {...state, completeModalValues: {category,...otherValues}}
    },


    // * Set accept / decline modal values
    [ACTIONS_TYPES.SET_ACCEPT_DECLINE_VALUES]: (state, payload) => {
        const {key, value} = payload
        const newAcceptDeclineModalValues = {...state.acceptDeclineModalValues, [key]: value}

        return {...state, acceptDeclineModalValues: newAcceptDeclineModalValues}
    },

    // * Set MFT Team
    [ACTIONS_TYPES.SET_MFT_TEAM]: (state, payload) => {
        const {key, value} = payload

        const newAcceptDeclineModalValues = {...state.acceptDeclineModalValues, mftTeam:{...state.acceptDeclineModalValues.mftTeam,  [key]: value}}

        return {...state, acceptDeclineModalValues: newAcceptDeclineModalValues}
    },



}


// ? Reducer
const improvementReducer =(state, action)=>{
    const {type, payload} = action
    return IMPROVEMENT_ACTIONS[type] ? IMPROVEMENT_ACTIONS[type](state, payload) : state
}



// ? UseReducer
export const useImprovementReducer = () => {

    const [improvementState, imporvementDispatch] = useReducer(improvementReducer, INITIAL_STATE)

    const setInsertModalVisibility =(bool)=> imporvementDispatch({
        type: ACTIONS_TYPES.SET_INSERT_MODAL_VISIBILITY,
        payload: bool
    })

    const setInsertModalEditMode =(bool)=> imporvementDispatch({
        type: ACTIONS_TYPES.SET_INSERT_MODAL_EDIT_MODE,
        payload: bool
    })

    const setPeopleModalVisibility = (bool) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_PEOPLE_MODAL_VISIBILITY,
        payload: bool
    })

    const setCompleteImprovementModalVisibility = (bool) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_COMPLETE_IMPROVEMENT_MODAL_VISIBILITY,
        payload: bool
    })

    const setAcceptDeclineModalVisibility = (bool) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_ACCEPT_DECLINE_MODAL_VISIBILITY,
        payload: bool
    })

    const setActionPlanModalVisibility = (bool) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_ACTION_PLAN_MODAL_VISIBILITY,
        payload: bool
    })

    const setImprovementSelectedRow = (rowObj) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_IMPROVEMENT_SELECTED_ROW,
        payload: rowObj
    })

    const setPeopleModalValues = (peopleModalValues) => imporvementDispatch({
        type: ACTIONS_TYPES.SET_PEOPLE_MODAL_VALUES,
        payload: peopleModalValues
    })

    const setInserModalValues =({value, inputName})=>{
        imporvementDispatch({
            type: ACTIONS_TYPES.SET_INSERT_MODAL_VALUES,
            payload: {value, inputName}
        })
    }

    const setCompleteModalValues =({value, inputName})=>{
        imporvementDispatch({
            type: ACTIONS_TYPES.SET_COMPLETE_MODAL_VALUES,
            payload: {value, inputName}
        })
    }

    const setCompleteModalAllValues =({ category, cost, benefits })=>{
        imporvementDispatch({
            type: ACTIONS_TYPES.SET_ALL_VALUES_COMPLETE_MODAL,
            payload: { category, cost, benefits }
        })

    }

    const setAcceptDeclineValues =(payload)=> imporvementDispatch({
            type: ACTIONS_TYPES.SET_ACCEPT_DECLINE_VALUES,
            payload: payload
        })

    const setMftTeam =(payload)=> imporvementDispatch({
            type: ACTIONS_TYPES.SET_MFT_TEAM,
            payload: payload
        })





    const setModalValuesInEditMode =(rowSelected)=> imporvementDispatch({type: ACTIONS_TYPES.SET_MODAL_VALUES_IN_EDIT_MODE , payload: rowSelected})

    const resetImprovementModalsValues =()=> imporvementDispatch({type: ACTIONS_TYPES.RESET_MODALS_VALUES})





    return {
        improvementState,
        setPeopleModalVisibility,
        setCompleteImprovementModalVisibility,
        setAcceptDeclineModalVisibility,
        setActionPlanModalVisibility,
        setPeopleModalValues,
        setInsertModalVisibility,
        setInsertModalEditMode,
        setImprovementSelectedRow,
        setInserModalValues,
        resetImprovementModalsValues,
        setModalValuesInEditMode,
        setCompleteModalValues,
        setCompleteModalAllValues,
        setAcceptDeclineValues,
        setMftTeam
    }
}
