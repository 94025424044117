export const WPS_TYPES = {
    SET_CREATE_MODAL: "SET_CREATE_MODAL",
    SET_EDIT_MODAL: "SET_EDIT_MODAL", 
    SET_WP_SELECTED: "SET_WP_SELECTED",
    POST_WP: "POST_WP",
    PUT_WP: "PUT_WP",
    POST_WP_SUCCESS: "POST_WP_SUCCESS",
    POST_WP_ERROR: "POST_WP_ERROR",
    GET_ALL_WPS: "GET_ALL_WPS"
}
