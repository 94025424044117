import {

    DESCARGAR_ADJUNTO_NOTIFICACION,
    DESCARGAR_ADJUNTO_NOTIFICACION_EXITO,
    DESCARGAR_ADJUNTO_NOTIFICACION_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {
    archivoDescargado: '',
    error: null,
    loading: false,
    modoDialogKpi: '',
}


export default function (state = initialState, action) {

    switch (action.type) {

        //********** MOSTRAR LOG_RISK API**********************************/ 

        case DESCARGAR_ADJUNTO_NOTIFICACION:
            return {
                ...state,
                loading: action.payload
            }

        case DESCARGAR_ADJUNTO_NOTIFICACION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                archivoDescargado: action.payload
            }

        case DESCARGAR_ADJUNTO_NOTIFICACION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default:
            return state

    }

}