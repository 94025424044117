import {

    MOSTRAR_KPI_AUDIT,
    MOSTRAR_KPI_AUDIT_EXITO,
    MOSTRAR_KPI_AUDIT_ERROR,
    DATOS_CONSULTA_AUDIT,
    GET_PEOPLE_WITH_AUDITS,
    GET_AUDITS_SUMMARY_ALL_BA

} from './types';


// cada reducer tiene su propio state
const initialState = {
    fodAuditData : [],
    prlAuditData: [],
    error: null,
    loading: false,
    peopleWithAudits: [],
    datosConsulta: {
        user: null,
        year: null,
    },
    prlSummaryAllBA: null,
fodSummaryAllBA: null
}


export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 

        case DATOS_CONSULTA_AUDIT:
            return {
                ...state,
                datosConsulta: {
                    user: action.payload.idUser,
                    year: action.payload.year
                }
            }

        case MOSTRAR_KPI_AUDIT:
            return {
                ...state,
                fodAuditData : [],
                prlAuditData: [],
                loading: action.payload,
            }

        case GET_PEOPLE_WITH_AUDITS:
            const {peopleWithAudits} = action
            return {
                ...state,
                peopleWithAudits
            }

        case MOSTRAR_KPI_AUDIT_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                fodAuditData: action.payload[0],

                prlAuditData: action.payload[1],
            }

        case MOSTRAR_KPI_AUDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case MOSTRAR_KPI_AUDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_AUDITS_SUMMARY_ALL_BA:
            const {prlSummaryAllBA,fodSummaryAllBA} = action.payload
            return {
                ...state,
                prlSummaryAllBA, fodSummaryAllBA
            }


        default:
            return state

    }

}