import {

    DESCARGAR_ADJUNTO_NOTIFICACION,
    DESCARGAR_ADJUNTO_NOTIFICACION_EXITO,
    DESCARGAR_ADJUNTO_NOTIFICACION_ERROR


} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getCookie, getIdsFromArr, getRequestAuthHeader } from 'app/js/generalFunctions'
import store from 'app/store';
import dayjs from 'dayjs';

//Constantes globales
const urlNotificacionAPI = process.env.REACT_APP_URL_DJANGO + "/api/notifications/"

//************************ MOSTRAR LOG_RISK API GET **********************************************
export function descargarAdjuntoNotificacionAPIAction(idNotificacion) {

    return async (dispatch) => {
        dispatch(descargarAdjuntoNotificacionAPI(true))

        await axios({
            method: "GET",
            url: urlNotificacionAPI + "descarga/" + idNotificacion,
            headers: getRequestAuthHeader()

        })
            .then(response => {

                dispatch(descargarAdjuntoNotificacionAPIExito(response.data))
            })
            .catch(error => {
                console.log(error.response)
                dispatch(descargarAdjuntoNotificacionAPIError(true))
            })

    }
}

const descargarAdjuntoNotificacionAPI = (estado) => ({
    type: DESCARGAR_ADJUNTO_NOTIFICACION,
    payload: true

})

const descargarAdjuntoNotificacionAPIExito = customers => ({
    type: DESCARGAR_ADJUNTO_NOTIFICACION_EXITO,
    payload: customers

})

const descargarAdjuntoNotificacionAPIError = estado => ({
    type: DESCARGAR_ADJUNTO_NOTIFICACION_ERROR,
    payload: estado
})


// export function postNotification(notificacion) {

//     return async (dispatch) => {
//         dispatch (insertarNewNotification())

//              axios({
//                     method: "POST",
//                     url: urlNotificacionesApi,
//                     data: notificacion,
//                     headers: {
//                         'Authorization': `Token ${getCookie('token')}` 
//                             }
                       
//               })   
//               .then(response => {
                
//                  dispatch (insertarNewNotificationExito(response.data))

//              })
//              .catch(error => {
//                 console.log(error.response)
//                 dispatch (insertarNewNotificationError(true))

//                 dispatch(
//                     showMessage({
//                         message: "Error creating Notifications",
//                         variant: "error"
//                     })
//                 )
//             })

//     }
// }