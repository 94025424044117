//UPDATE RISK_MANAGEMENT
export const GET_MANAGEMENT_TABLE_DATA = 'GET_MANAGEMENT_TABLE_DATA'
export const SET_LOADING = "SET_LOADING"
export const SET_ERROR = "SET_ERROR"
export const SET_TABLE_ROW_SELECTED = "SET_TABLE_ROW_SELECTED"
export const SET_INSERT_MODAL_VISIBILITY = "SET_INSERT_MODAL_VISIBILITY"
export const SET_KPI_NAME_SELECTED = "SET_KPI_NAME_SELECTED"
export const SET_EDIT_MODE_ON = "SET_EDIT_MODE_ON"
export const SET_DELETE_MODE_ON = "SET_DELETE_MODE_ON"
export const SET_PRL_AUDIT_LENGTH = "SET_PRL_AUDIT_LENGTH" 
export const SET_FOD_AUDIT_LENGTH = "SET_FOD_AUDIT_LENGTH" 


