//**********************IMPORTACIONES****************************

import { useEffect, useState, useRef } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';

//Grid importaciones

import Grid from '@mui/material/Grid';

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux'

import {
    mostrarKanbanCardsAPIAction,
    cambiarValorVisibilidadEstadoIncidenciasAction
} from '../store/actions'

import TableModules from '../../../tables/TableModules'

//**********************END_IMPORTACIONES ***********************/


const useStyles = makeStyles({

    customDialogTitle: {
        backgroundColor: 'rgb(37, 47, 62)',
        color: 'rgb(255, 255, 255)',
        marginBottom: '2em'
    }

});

export default function MisIncidencias() {

    const classes = useStyles();
    const dispatch = useDispatch()

    const location = useLocation();

    const [numPagination, setNumPagination] = useState(10)

    const columnasDataTable = [
        { Header: "Card ID", accessor: "card_id", sortable: true, type: 'string' },
        { Header: "Title", accessor: "title", sortable: true, type: 'string' },
        { Header: "Type", accessor: "type_id", sortable: true, type: 'list' },
        { Header: "State", accessor: "state", sortable: true, type: 'list' }
    ]

    // Obtener los states de Redux
    const tarjetasKanbanListAPI = useSelector(state => state.fuse.kanbanComponente.tarjetasKanbanListAPI)
    const visibilidadModalEstadoIncidencias = useSelector(state => state.fuse.kanbanComponente.visibilidadModalEstadoIncidencias)
    const personLogin = useSelector(state => state.fuse.userComponente.person)
    const loading = useSelector(state => state.fuse.kanbanComponente.loading)

    //Creamos funciones para hacer uso de Actions Redux
    const cambiarValorVisibilidadEstadoIncidencias = (valorNuevo) => dispatch(cambiarValorVisibilidadEstadoIncidenciasAction(valorNuevo))
    const mostrarKanbanCardsAPI = (data) => dispatch(mostrarKanbanCardsAPIAction(data))

    useEffect(() => {
        if (personLogin != undefined && personLogin.id != undefined) {
            //mostrarKanbanCardsAPI(personLogin.id)
        }
    }, [personLogin])

    useEffect(() => {
        if(visibilidadModalEstadoIncidencias == true){
            mostrarKanbanCardsAPI(personLogin.id)
        }
    }, [visibilidadModalEstadoIncidencias])

    function tipoIncidencia(idTipo) {
        let titulo = ""
        if (idTipo == 1553) {
            titulo = "Bug"
        }
        if (idTipo == 54471) {
            //Improvement
            titulo = "Improvement"
        }
        if (idTipo == 54472) {
            //Opinion
            titulo = "Opinion"
        }

        return (
            <>
                <Tooltip title={titulo}>
                    <span>{titulo}</span>
                </Tooltip>
            </>
        );
    }

    return (
        <Dialog open={visibilidadModalEstadoIncidencias} onClose={() => cambiarValorVisibilidadEstadoIncidencias(false)} fullWidth maxWidth='lg'>
            <DialogTitle classes={{ root: classes.customDialogTitle }} >
                Status of reported incidents
            </DialogTitle>
            <DialogContent>

                <TableModules rowsProp={tarjetasKanbanListAPI} columnsProp={columnasDataTable} loading={loading} />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => cambiarValorVisibilidadEstadoIncidencias(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

