//CONSULTA A API RYO_ACCION 
export const MOSTRAR_RYO_ACCION = 'MOSTRAR_RYO_ACCION'
export const MOSTRAR_RYO_ACCION_EXITO = 'MOSTRAR_RYO_ACCION_EXITO'
export const MOSTRAR_RYO_ACCION_ERROR = 'MOSTRAR_RYO_ACCION_ERROR'

//CAMBIAR ESTADO DEL COMPONENTE
export const CAMBIAR_ESTADO_RYO_ACCION = 'CAMBIAR_ESTADO_RYO_ACCION'
export const CAMBIAR_ESTADO_RYO_ACCION_EXITO = 'CAMBIAR_ESTADO_RYO_ACCION_EXITO'
export const CAMBIAR_ESTADO_RYO_ACCION_ERROR = 'CAMBIAR_ESTADO_RYO_ACCION_ERROR'


//INSERTAR RYO ACCION
export const INSERTAR_RYO_ACCION = 'INSERTAR_RYO_ACCION'
export const INSERTAR_RYO_ACCION_EXITO = 'INSERTAR_RYO_ACCION_EXITO'
export const INSERTAR_RYO_ACCION_ERROR = 'INSERTAR_RYO_ACCION_ERROR'

//UPDATE RYO ACCION
export const UPDATE_RYO_ACCION = 'UPDATE_RYO_ACCION'
export const UPDATE_RYO_ACCION_EXITO = 'UPDATE_RYO_ACCION_EXITO'
export const UPDATE_RYO_ACCION_ERROR = 'UPDATE_RYO_ACCION_ERROR'

//RESET STATES RYO ACCION
export const RESET_STATES_RYO_ACCION = 'RESET_STATES_RYO_ACCION'
export const RESET_STATES_RYO_ACCION_EXITO = 'RESET_STATES_RYO_ACCION_EXITO'
export const RESET_STATES_RYO_ACCION_ERROR = 'RESET_STATES_RYO_ACCION_ERROR'

//RESET STATES RYO ACCION
export const DELETE_RYO_ACCION = 'DELETE_RYO_ACCION'
export const DELETE_RYO_ACCION_EXITO = 'DELETE_RYO_ACCION_EXITO'
export const DELETE_RYO_ACCION_ERROR = 'DELETE_RYO_ACCION_ERROR'

//CAMBIAR VALOR SELECCION
export const CAMBIAR_VALOR_SELECCION_ACTIONS = "CAMBIAR_VALOR_SELECCION_ACTIONS";


export const MOSTRAR_MY_ACTIONS_RYO_ACCION = "MOSTRAR_MY_ACTIONS_RYO_ACCION"
export const MOSTRAR_MY_ACTIONS_RYO_ACCION_EXITO= "MOSTRAR_MY_ACTIONS_RYO_ACCION_EXITO"
export const MOSTRAR_MY_ACTIONS_RYO_ACCION_ERROR= "MOSTRAR_MY_ACTIONS_RYO_ACCION_ERROR"


export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR = "CONSULTA_RM_REGISTRO_MODAL_INSERTAR";
 export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO = "CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO";
export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR = "CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR";

export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE"
export const SET_MY_ACTIONS = "SET_MY_ACTIONS"
export const SET_NOT_MY_ACTIONS = "SET_not_MY_ACTIONS"
export const SET_ACTIONS_STRUCTURE = "SET_ACTIONS_STRUCTURE"

