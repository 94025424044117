// * FilterMissions

import { getIdsFromArr, getUniqueValuesFromObjArrWithId } from "app/js/generalFunctions"
import { ALL_MANAGERS_OPTION, ALL_RECORDS_OPTION } from "./filterConsts"




export const getSubMissionTempVsPerm = (submissionStafflist) => {
    const subMissionStaffPermanentVsTemporal = submissionStafflist.reduce((acc, person) => {
        if (!person?.IDRes) return acc
        !!person.permanent ?
            acc.permanentIDRes = [...acc.permanentIDRes, person.IDRes]
            :
            acc.temporary_IDRes = [...acc.temporary_IDRes, person.IDRes]
        return acc
    }, { temporary_IDRes: [], permanentIDRes: [] })
    return subMissionStaffPermanentVsTemporal
}

export const getMissionSelectedInfo =({missionsIMustSee, filterMissionSelected, personLoginId})=>{
    const missionSelectedInfo = missionsIMustSee.find(mission => mission.id === filterMissionSelected)
    const personLoginIsMissionManager = missionSelectedInfo.staff.managers.find(person => person.id === personLoginId);
    const personLoginIsMissionEmployee = missionSelectedInfo.staff.employees.find(person => person.id === personLoginId);

    return {missionSelectedInfo, personLoginIsMissionManager, personLoginIsMissionEmployee}
}

export const getSubmissionSelectedInfo = ({subMissionsIMustSee,filterSubMissionSelected, personLoginId})=>{

    const subMissionSelectedInfo = subMissionsIMustSee.find(sub => sub.id === filterSubMissionSelected)

    const personLoginIsSubMissionManager = subMissionSelectedInfo.staff.managers.find(person => person.id === personLoginId)
    const personLoginIsSubMissionEmployee = subMissionSelectedInfo.staff.employees.find(person => person.id === personLoginId);

    const subMissionAllStaff = [...subMissionSelectedInfo.staff.managers, ...subMissionSelectedInfo.staff.employees]
    const subMissionEmployees = subMissionSelectedInfo.staff.employees

    return { personLoginIsSubMissionManager, personLoginIsSubMissionEmployee, subMissionAllStaff, subMissionEmployees}
}

export const getMissionAndSubmissionSelectedStaff =({missionSelectedInfo, subMissionsIMustSee, filterSubMissionSelected}) => {

    const missionStaff = [...missionSelectedInfo.staff.managers.flatMap(m => m.IDRes), ...missionSelectedInfo.staff.employees.flatMap(e => e.IDRes)]
    const subMissionSelectedInfo = subMissionsIMustSee.find(sub => sub.id === filterSubMissionSelected)
    const subMissionAllStaff = [...subMissionSelectedInfo.staff.managers, ...subMissionSelectedInfo.staff.employees]
    const { temporary_IDRes: submissionAllStaffTemporalIDRes, permanentIDRes: submissionAllStaffPermanentIDRes } = getSubMissionTempVsPerm(subMissionAllStaff)
    const allStaffToSearch = {
        permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionStaff])],
        temporary_IDRes: submissionAllStaffTemporalIDRes
    }
    return {allStaffToSearch}
}

export const getAllSubmissionStaffFromMissionSelected =({missionSelectedInfo, subMissionsIMustSee})=>{
    const missionStaff = [...missionSelectedInfo.staff.managers.flatMap(m => m.IDRes), ...missionSelectedInfo.staff.employees.flatMap(e => e.IDRes)]
    const subMissionsStaff = subMissionsIMustSee.flatMap(sub => [...sub.staff.managers, ...sub.staff.employees]).flatMap(e => e)
    const { temporary_IDRes: submissionAllStaffTemporalIDRes, permanentIDRes: submissionAllStaffPermanentIDRes } = getSubMissionTempVsPerm(subMissionsStaff)
    const allStaffToSearch = {
        permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionStaff])],
        temporary_IDRes: submissionAllStaffTemporalIDRes
    }
    return {allStaffToSearch}
}



export const getAllStaffFromMissionsAndSubmissionIMustSee =({missionsIMustSee, subMissionsIMustSee})=>{
    const missionsStaffs = missionsIMustSee.flatMap(mission => [...mission.staff.managers, ...mission.staff.employees]).flatMap(e => e.IDRes)
    const subMissionsStaff = subMissionsIMustSee.flatMap(sub => [...sub.staff.managers, ...sub.staff.employees]).flatMap(e => e)
    const { temporary_IDRes: submissionAllStaffTemporalIDRes, permanentIDRes: submissionAllStaffPermanentIDRes } = getSubMissionTempVsPerm(subMissionsStaff)
    const allStaffToSearch = {
        permanent_IDRes: [...new Set([...submissionAllStaffPermanentIDRes, ...missionsStaffs])],
        temporary_IDRes: submissionAllStaffTemporalIDRes
    }
    return {allStaffToSearch}
}

export const setNotRepeatAndNotNullValues = (value, valuesArr) => {
    if (!value) return valuesArr
    else if (valuesArr.includes(value)) return valuesArr
    else return [...valuesArr, value]
}




export const setSectionManagers =({sectionToFindManagers, section_father_key, filterFatherSelected, filterFatherList}) => {
 

    let sonsFromFatherSelected

    if(filterFatherSelected !== -1){
     sonsFromFatherSelected = sectionToFindManagers.filter(section => section[section_father_key] === filterFatherSelected)
    }
    else {
        const idsFilterFatherList = getIdsFromArr(filterFatherList)
        sonsFromFatherSelected = sectionToFindManagers.filter(section => idsFilterFatherList.includes(section[section_father_key]))
    }

    let managersList = sonsFromFatherSelected.flatMap(section => section.staff.managers)
    managersList = getUniqueValuesFromObjArrWithId(managersList)

    managersList = managersList.length ? [ALL_MANAGERS_OPTION,...managersList] : []



    return managersList
}

// Ç Todos padres todos managers => Lista que dependa de los padres que estan apareciendo en el filtro
// Ç No todos padres todos managers => secciones hijas
// Ç No todos padres no todos managers => secciones hijas con managers select
// Ç Todos padres no todos managers => todas secciones de manager
export const getSectionsFromCascade=({sectionList, managerSelected,fatherSelected,fatherCascadeList,fatherKey})=> {

    const fatherAllSelected = fatherSelected === -1
    const managersAllSelected = managerSelected === -1
    let sections = []
    
    if(fatherAllSelected && managersAllSelected) {
        const idsFilterFatherList = getIdsFromArr(fatherCascadeList)

        sections =  sectionList.filter(section => idsFilterFatherList.includes(section[fatherKey]))
    }

    else if(!managersAllSelected && fatherAllSelected){
        sections = sectionList.filter(section=> {
            const managers = section.staff.managers
            const managerInSection = managers.some(manager => manager.id === managerSelected )
            if(managerInSection) return section
        })
    }
    else if (managersAllSelected && !fatherAllSelected){
        sections = sectionList.filter(section=> {
            const sectionIsSonFromFather = section[fatherKey] === fatherSelected
            if(sectionIsSonFromFather) return section
        })
    }
    else {
        sections = sectionList.filter(section=> {
            const managers = section.staff.managers
            const managerInSection = managers.some(manager => manager.id === managerSelected )
            const sectionIsSonFromFather = section[fatherKey] === fatherSelected
            if(managerInSection && sectionIsSonFromFather) return section
        })
    }

    return sections.length ? [ALL_RECORDS_OPTION, ...sections] : []
    

}
    



export const setAllMissionsAgressoAndVTG =(missions) => {
    return missions.reduce((acc, mission)=>{
        if(mission.id === -1) return acc
        const {code_agresso, visual_time_group} = mission
        acc.agresso = code_agresso ?  [...acc.agresso, ...code_agresso.split(",")] : acc.agresso
        acc.vtg = visual_time_group ?  [...acc.vtg, ...visual_time_group.split(",")] : acc.vtg
        return acc
    },{agresso: [], vtg:[]})
}