export const FMEA_COLUMNS = [
    { Header: "ID", accessor: "id", sortable: true, type: 'string' },
    { Header: "Title", accessor: "title", sortable: true, type: 'string' },
    { Header: "Code", accessor: "code", sortable: true, type: 'string' },
    { Header: "Revision date", accessor: "date", sortable: true, type: 'string' },
    { Header: "Manager", accessor: "fullNameManager", sortable: true, type: 'list' },
    { Header: "Mission", accessor: "mision_name", sortable: true, type: 'list' },
    { Header: "R&O identified", accessor: "nRisk", sortable: true, type: 'string' }
];


export const RYO_COLUMNS = [
    { Header: "Id", accessor: "id", sortable: true, type: 'string' },
    { Header: "FMEA", accessor: "amfe_name", sortable: true, type: 'string' },
    { Header: "Risk description", accessor: "risk_description", sortable: true, type: 'string' },
    { Header: "Opportunity description", accessor: "opportunity_description", sortable: true, type: 'string' },
    { Header: "Detection date", accessor: "d_detection", sortable: true, type: 'string' },
    { Header: "NPR", accessor: "npr", sortable: true, type: 'string' },
    { Header: "Severity", accessor: "gravity", sortable: true, type: 'string' },
    { Header: "Frequency", accessor: "idea", sortable: true, type: 'string' },
    { Header: "Detection", accessor: "detection", sortable: true, type: 'string' },
    { Header: "Effect", accessor: "glitch_effect", sortable: true, type: 'string' },
    { Header: "Cause", accessor: "cause_failure", sortable: true, type: 'string' },
    { Header: "Current controls", accessor: "current_controls", sortable: true, type: 'string' },
    { Header: "Priorization", accessor: "priorization", sortable: true, type: 'list' },
    { Header: "Actions identified", accessor: "nActions", sortable: true, type: 'string' },
    { Header: "Revision", accessor: "rev", sortable: true, type: 'string' },
    { Header: "Remarks", accessor: "observations", sortable: true, type: 'string' },
    { Header: "Category", accessor: "categorizacion_name", sortable: true, type: 'list' }
];


  export const RYO_ACTIONS_COLUMNS = [
    { Header: "ID", accessor: "id", sortable: true, type: 'string' },
    { Header: "R&O ID", accessor: "id_record", sortable: true, type: 'string' },
    { Header: "Proporsal", accessor: "proposal", sortable: true, type: 'string' },
    { Header: "Responsible", accessor: "responsablesName", sortable: true, type: 'list' },
    { Header: "Date planned", accessor: "d_planned", sortable: true, type: 'date' },
    { Header: "Date closed", accessor: "d_closed", sortable: true, type: 'date' },
    { Header: "Observations", accessor: "observations", sortable: true, type: 'string' },
  ];
