import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD,
    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR,

    INSERTAR_NUEVA_TARJETA_KANBAN,
    INSERTAR_NUEVA_TARJETA_KANBAN_EXITO,
    INSERTAR_NUEVA_TARJETA_KANBAN_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS,
    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR,

    MOSTRAR_TARJETAS_KANBAN,
    MOSTRAR_TARJETAS_KANBAN_EXITO,
    MOSTRAR_TARJETAS_KANBAN_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {
    tarjetasKanbanListAPI: [],
    visibilidadModalInsertarCard: false,
    visibilidadModalEstadoIncidencias: false,
    modo: '',
    error: null,
    loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {

        //********** MOSTRAR PROGRAMAS API**********************************/ 


        case MOSTRAR_TARJETAS_KANBAN:
            return {
                ...state,
                loading: action.payload
            }
        
        case MOSTRAR_TARJETAS_KANBAN_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                tarjetasKanbanListAPI: action.payload

            }
        
        case MOSTRAR_TARJETAS_KANBAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********VISIBILIDAD**********************************/

        case CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalEstadoIncidencias: action.payload

            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

     //**********VISIBILIDAD**********************************/

        case CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertarCard: action.payload

            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
      
     //********** INSERTAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case INSERTAR_NUEVA_TARJETA_KANBAN:
            return {
                ...state,
                loading: action.payload
            }
        
        case INSERTAR_NUEVA_TARJETA_KANBAN_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case INSERTAR_NUEVA_TARJETA_KANBAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

}