import axios from "axios"
import { getCookie } from 'app/js/generalFunctions'
import store from "app/store"
import { getAllStaffFromMissionAndSubmission } from "app/js/generalFunctions"
import { method } from "lodash"
const REQUIREMENTS_URL = `${process.env.REACT_APP_URL_DJANGO}/api/get_requirements/`
const TEAM_TRAINING_URL = `${process.env.REACT_APP_URL_DJANGO}/api/get_team_training/`
const EXPERIENCE_URL = `${process.env.REACT_APP_URL_DJANGO}/api/get_experience/`


const getQueryString = (vtgs) => {
    return new URLSearchParams({
        vt_groups: vtgs,
    }).toString();
}

const getQueryStringTeamTraining = ({staff, moodleTrainingCodes}) => {
    return new URLSearchParams({
        trainingCodes: moodleTrainingCodes?.join(','),
        people: staff?.join(','),
    }).toString();
}

const getExperienceQueryString=({allStaff, missionAgressoCodes})=>{
    return new URLSearchParams({
        missionAgressoCodes: missionAgressoCodes,
        people: allStaff?.join(",")
    }).toString()
}

const getHeaders = () => ({ 'Authorization': `Token ${getCookie('token')}` })


export const requirementsRequestAction = async (mission) => {
    try {
        const { visual_time_group } = mission



        const queryString = getQueryString(visual_time_group)

        const request = await axios({
            method: "GET",
            url: `${REQUIREMENTS_URL}?${queryString}`,
            headers: getHeaders()
        });

        // const data = request.data
        return request
    }
    catch (e)  { return {status: 500}}
}





export const teamTrainingAction =async({allStaff, moodleTrainingCodes}) => {
    try {

        const queryString = getQueryStringTeamTraining({staff: allStaff, moodleTrainingCodes: [...moodleTrainingCodes]})

        const request = await axios({
            method: "GET",
            url: `${TEAM_TRAINING_URL}?${queryString}`,
            headers: getHeaders()
        });

        return request
    }
    catch (e) {
        console.log("🚀 ~ teamTrainingAction ~ e:", e)
        return {status: 500}
    }
}


export const getExperienceAction =async ({allStaff, missionAgressoCodes})=>{
    try{
        const queryString = getExperienceQueryString({allStaff, missionAgressoCodes})

        const request = await axios({
            method:"GET",
            url:  `${EXPERIENCE_URL}?${queryString}`,
            headers: getHeaders()
        })
        return request

    }
    catch(e){
        console.log("🚀 ~ getExperienceAction ~ e:", e)
        return {status: 500}
    }
}