//UPDATE RISK_MANAGEMENT
export const MOSTRAR_CATEGORY = 'MOSTRAR_CATEGORY'
export const MOSTRAR_CATEGORY_EXITO = 'MOSTRAR_CATEGORY_EXITO'
export const MOSTRAR_CATEGORY_ERROR = 'MOSTRAR_CATEGORY_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_NEW_CATEGORY = 'CAMBIAR_MODAL_NEW_CATEGORY'
export const CAMBIAR_MODAL_NEW_CATEGORY_EXITO = 'CAMBIAR_MODAL_NEW_CATEGORY_EXITO'
export const CAMBIAR_MODAL_NEW_CATEGORY_ERROR = 'CAMBIAR_MODAL_NEW_CATEGORY_ERROR'

//SESION CATEGORY
export const CAMBIAR_VALOR_SELECCION_GRID_CATEGORY = 'CAMBIAR_VALOR_SELECCION_GRID_CATEGORY'
export const CAMBIAR_VALOR_SELECCION_GRID_CATEGORY_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_CATEGORY_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_CATEGORY_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_CATEGORY_ERROR'

//UPDATE CATEGORY
export const PUT_CATEGORY = 'PUT_CATEGORY'
export const PUT_CATEGORY_EXITO = 'PUT_CATEGORY_EXITO'
export const PUT_CATEGORY_ERROR = 'PUT_CATEGORY_ERROR'

//INSERTAR CATEGORY
export const INSERTAR_NEW_CATEGORY = 'INSERTAR_NEW_CATEGORY'
export const INSERTAR_NEW_CATEGORY_EXITO = 'INSERTAR_NEW_CATEGORY_EXITO'
export const INSERTAR_NEW_CATEGORY_ERROR = 'INSERTAR_NEW_CATEGORY_ERROR'

//TIPOS DE CATEGORIAS
export const MOSTRAR_TIPOS_CATEGORIA = 'MOSTRAR_TIPOS_CATEGORIA'
export const MOSTRAR_TIPOS_CATEGORIA_EXITO = 'MOSTRAR_TIPOS_CATEGORIA_EXITO'
export const MOSTRAR_TIPOS_CATEGORIA_ERROR = 'MOSTRAR_TIPOS_CATEGORIA_ERROR'