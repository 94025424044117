//UPDATE RISK_MANAGEMENT
export const MOSTRAR_RULES = 'MOSTRAR_RULES'
export const MOSTRAR_RULES_EXITO = 'MOSTRAR_RULES_EXITO'
export const MOSTRAR_RULES_ERROR = 'MOSTRAR_RULES_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_NEW_RULES = 'CAMBIAR_MODAL_NEW_RULES'
export const CAMBIAR_MODAL_NEW_RULES_EXITO = 'CAMBIAR_MODAL_NEW_RULES_EXITO'
export const CAMBIAR_MODAL_NEW_RULES_ERROR = 'CAMBIAR_MODAL_NEW_RULES_ERROR'

//SESION RULES
export const CAMBIAR_VALOR_SELECCION_GRID_RULES = 'CAMBIAR_VALOR_SELECCION_GRID_RULES'
export const CAMBIAR_VALOR_SELECCION_GRID_RULES_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_RULES_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_RULES_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_RULES_ERROR'

//UPDATE RULES
export const PUT_RULES = 'PUT_RULES'
export const PUT_RULES_EXITO = 'PUT_RULES_EXITO'
export const PUT_RULES_ERROR = 'PUT_RULES_ERROR'

//INSERTAR RULES
export const INSERTAR_NEW_RULES = 'INSERTAR_NEW_RULES'
export const INSERTAR_NEW_RULES_EXITO = 'INSERTAR_NEW_RULES_EXITO'
export const INSERTAR_NEW_RULES_ERROR = 'INSERTAR_NEW_RULES_ERROR'