import {
    SET_SUMMARY_ABC,
    SET_HOLI_AND_BAG_FOR_SUMMARY,
    SET_HOLI_FOR_SUMMARY,
    SET_EXTRA_HOURS_NCS,
    SET_GM,
    SET_PEOPLE_TRANSFERRED,
    SET_HOLI_UNTIL_MARCH,
    RESET_SUMMARY_VALUES,
    SET_TM_SUMMARY_NO_DATA,
    SET_PERFORMANCE_SUMMARY_NO_DATA,
    SET_FOD_AUDIT_SUMMARY,
    SET_PRL_AUDIT_SUMMARY
} from './types';


// cada reducer tiene su propio state
const initialState = {
    totalABC : null,
    holiPlusBag: null,
    holidays: null,
    extraHoursNcs: null,
    grossMargin: null,
    peopleTransferred: null,
    holiUntilMarch: null,
    prlAudit: null,
    fodAudit: null,
    reset:false,
}


export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 

        case SET_SUMMARY_ABC:
            const {abc} = action.payload
            return {
                ...state,
                totalABC: abc,
            }

    
            case SET_HOLI_AND_BAG_FOR_SUMMARY:
                return {
                    ...state,
                    holiPlusBag: action.payload
                }
            case SET_HOLI_FOR_SUMMARY:
                return {
                    ...state,
                    holidays: action.payload
                }
            case SET_EXTRA_HOURS_NCS:
                return {
                    ...state,
                    extraHoursNcs: action.payload
                }
            case SET_GM:
                return {
                    ...state,
                    grossMargin: action.payload
                }
            case SET_PEOPLE_TRANSFERRED:
                return {
                    ...state,
                    peopleTransferred: action.payload
                }
            case SET_HOLI_UNTIL_MARCH:
                return {
                    ...state,
                    holiUntilMarch: action.payload
                }
            case SET_FOD_AUDIT_SUMMARY:
                return {
                    ...state,
                    fodAudit: action.payload
                }
            case SET_PRL_AUDIT_SUMMARY:
                return {
                    ...state,
                    prlAudit: action.payload
                }
            case RESET_SUMMARY_VALUES:
                return initialState

            case SET_TM_SUMMARY_NO_DATA:

                return {
                    ...state,
                    holiPlusBag: -1,
                    holidays: -1,
                    extraHoursNcs: -1,
                    holiUntilMarch: -1,
                }

            case SET_PERFORMANCE_SUMMARY_NO_DATA:
            console.log("RESET SUMMARY")

                return {
                    ...state,
                    totalABC : -1,
                    grossMargin: -1,
                    peopleTransferred: -1,
                }
    


        default:
            return state

    }

}