import {

    MOSTRAR_KPI_TIME_MANAGEMENT,
    MOSTRAR_KPI_TIME_MANAGEMENT_EXITO,
    MOSTRAR_KPI_TIME_MANAGEMENT_ERROR,
    DATOS_CONSULTA_TIME_MANAGEMENT,
    MOSTRAR_KPI_INCIDENCIAS_EXITO,
    RESET_TIME_MANAGEMENT,
    LOADING_EXTRA_HOURS,
    SET_EXTRA_HOURS,
    LOADING_HOLIDAYS,
    SET_HOLIDAYS,
    LOADING_BAG,
    SET_BAG,
    LOADING_ABSENTEEISM,
    SET_ABSENTEEISM,
    LOADING_TM_PAGE,
    SET_EMPTY_VALUES_TM

} from './types';


// cada reducer tiene su propio state
const initialState = {
    horasExtra: [],
    vacaciones: [],
    extraHoursStore: [],
    holidaysStore: [],
    bagHoursStore: [],
    horasBolsa: [],
    incidencias: [],
    holiHoursFromLastYear: [],
    absenteeismStore: [],
    error: null,
    pageLoading: true,
    loadingExtraHours: true,
    loadingHolidays: true,
    loadingBag: true,
    loadingAbsenteeism: true,


    // datosConsultaTimeManagement: {
    //     user: null,
    //     dateSelect: [],
    //     mision: -1,
    //     userPermanentMissionAgressoCode: null,
    // }
}


export default function (state = initialState, action) {

    const { payload } = action
    switch (action.type) {

        //********** MOSTRAR KPI API**********************************/ 
        // ??????????????????????? REFACTORIZACION ????????????????????????????

        // ? page loadiing
        case LOADING_TM_PAGE:
            return {
                ...state,
                pageLoading: false
            }

        // ? Extra hours
        case LOADING_EXTRA_HOURS:
            return {
                ...state,
                loadingExtraHours: true,
                extraHoursStore: []
            }

        case SET_EXTRA_HOURS:
            return {
                ...state,
                loadingExtraHours: false,
                extraHoursStore: payload
            }

        // ? Holidays
        case LOADING_HOLIDAYS:
            return {
                ...state,
                loadingHolidays: true,
                holidaysStore: []
            }

        case SET_HOLIDAYS:
            return {
                ...state,
                loadingHolidays: false,
                holidaysStore: payload
            }

        // ? Bag
        case LOADING_BAG:
            return {
                ...state,
                loadingBag: true,
                bagHoursStore: []
            }

        case SET_BAG:
            return {
                ...state,
                loadingBag: false,
                bagHoursStore: payload
            }

        // ? absenteeismStore


        case LOADING_ABSENTEEISM:
            return {
                ...state,
                loadingAbsenteeism: true,
                absenteeismStore: []
            }

        case SET_ABSENTEEISM:
            return {
                ...state,
                loadingAbsenteeism: false,
                absenteeismStore: payload
            }

        case SET_EMPTY_VALUES_TM:
            return {
                ...state,
                loadingAbsenteeism: false,
                absenteeismStore: [],
                loadingBag: false,
                bagHoursStore: [],
                loadingHolidays: false,
                holidaysStore: [],
                extraHoursStore: [],
                loadingExtraHours: false
            }

        // ??????????????????????? REFACTORIZACION ????????????????????????????




        case MOSTRAR_KPI_INCIDENCIAS_EXITO:
            return {
                ...state,
                incidencias: action.payload
            }
        case RESET_TIME_MANAGEMENT:
            return initialState





        default:
            return state

    }

}