export const power_bi_management_colums = [
    { Header: "Id", accessor: "id", sortable: true, type: 'string' },
    { Header: "KPI title", accessor: "kpi_title", sortable: true, type: 'string' },
    { Header: "calc method", accessor: "calc_method", sortable: true, type: 'string' },
    { Header: "Objetive for 100%", accessor: "objetive_max", sortable: true, type: 'string' },
    { Header: "Objetive for 50%", accessor: "objetive_half", sortable: true, type: 'string' },
    { Header: "Objetive for 0%", accessor: "objetive_min", sortable: true, type: 'string' },
    { Header: "Unit", accessor: "unity_max", sortable: true, type: 'string' },
    // { Header: "Severity", accessor: "unity_half", sortable: true, type: 'string' },
    // { Header: "Frequency", accessor: "unity_min", sortable: true, type: 'string' },
    { Header: "Calc mode for 100%", accessor: "calc_mode_max", sortable: true, type: 'string' },
    { Header: "Calc mode for 50%", accessor: "calc_mode_half", sortable: true, type: 'list' },
    { Header: "Calc mode for 0%", accessor: "calc_mode_min", sortable: true, type: 'string' },
];

export const KPIS_NAMES = ["ABC", "Gross margin", "People transferred", "Holidays", "Rest between working days", "Extra hours", "PRL audits", "FOD audits"]
export const KPIS_NAMES_OBJ = {
    abc: "ABC",
    gross_margin: "Gross margin",
    people_tranferred: "People transferred",
    // holidays_plus_bag: "Holidays plus bag",
    holidays: "Holidays",
    rest: "Rest between working days",
    extra_hours: "Extra hours",
    holi_until_march: "Holidays until march",
    prl_audit: "PRL audits",
    fod_audit: "FOD audits"
}



export const CALC_MODE = ["<", "<=", "=", ">=", ">"]

export const UNITS = {
    percent: "Percentage",
    // number: "Number",
}
export const AUDITS_UNITS = {
    majorNC: "Major non conformity",
    minorNC: "Minor non conformity"
}
export const NC_UNITS = {
    nc: "NC"
}

export const ALL_UNITS = {
    1: "%",
    // 2: "Units",
    3: "NC",
    4: "nc",
    5: "NC",
}

export const MAX_MIN_HALF_ARRAY = ["max", "half", "min"]

export const ADD_COMB_KEY_VALUES = ["objetive", "calc_mode", "unity"]


export const STATES_OBJ_KEY_NAMES = {
    max: ["objetive_max", "calc_mode_max", "unity_max"],
    half: ["objetive_half", "calc_mode_half", "unity_half"],
    min: ["objetive_min", "calc_mode_min", "unity_min"]

};
export const ADD_COMB_GRADES = {
    max: "add_or_comb_max",
    half: "add_or_comb_half",
    min: "add_or_comb_min"
}

export const PERFORMANCE_KPIS = [KPIS_NAMES_OBJ.abc, KPIS_NAMES_OBJ.people_tranferred]

export const TIME_MANAGEMENT_KPIS = [KPIS_NAMES_OBJ.holidays, KPIS_NAMES_OBJ.rest, KPIS_NAMES_OBJ.extra_hours, KPIS_NAMES_OBJ.holi_until_march]

export const AUDITS_KPIS = [KPIS_NAMES_OBJ.prl_audit, KPIS_NAMES_OBJ.fod_audit]

export const SALES_VOLUME_KPIS = [KPIS_NAMES_OBJ.gross_margin]

export const COLOR_CLASS_DEPENDS_KPI_OBJ = {
    timeManagement: "time-management-color",
    performance: "performance-color",
    audits: "audits-color",
    salesVolume: "sales-volume-color",

}

