import * as global from 'global'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: {},
    error: null,
    actionPlan: [],
    modalVisibilityAction: false,
    modalActionPlanValues: {
        proposal: '',
        plannedDate: null,
        closedDate: null,
        observations: '',
        completed: false,
        rev: 1,
        members: [],
        id_record: '',
    },
    isEditMode: false,
    showCloseDate: false,
    permissionRoles: [global.rolN4, global.rolN5, global.rolN6, global.rolN7],
    filteredUsers: [],
    mtf_team: [],
}

const improvementProposalActionPlanSlice = createSlice({
    name: 'improvementProposalActionPlan',
    initialState,
    reducers: {
        setModalVisibilityAction: (state, action) => {
            state.modalVisibilityAction = action.payload
        },
        setModalActionPlanValues: (state, action) => {
            state.modalActionPlanValues = { ...state.modalActionPlanValues, ...action.payload }
        },
        resetModalActionPlanValues: (state) => {
            state.modalActionPlanValues = initialState.modalActionPlanValues
        },
        filterUsers: (state, action) => {
            state.filteredUsers = action.payload.filter(user =>
                state.permissionRoles.includes(user.rolUser)
            );
        },
        setIsEditMode: (state, action) => {
            state.isEditMode = action.payload
        },
        setShowCloseDate: (state, action) => {
            state.showCloseDate = action.payload
        },
        setMFT_team: (state, action) => {
            state.mft_team = [...action.payload.equipoMFT_team_members, ...action.payload.equipoMFT_managers]
            console.log("🚀 ~ state:", state.mft_team)
        },
    },

})

export const {
    setModalVisibilityAction,
    setModalActionPlanValues,
    setIsEditMode,
    setShowCloseDate,
    resetModalActionPlanValues,
    filterUsers,
    setMFT_team
} = improvementProposalActionPlanSlice.actions

export const actionPlan = state => state.fuse.improvementProposalActionPlan.actionPlan
export const showCloseDateStatus = state => state.fuse.improvementProposalActionPlan.showCloseDate
export const modalVisibilityAction = state => state.fuse.improvementProposalActionPlan.modalVisibilityAction
export const modalActionPlanValues = state => state.fuse.improvementProposalActionPlan.modalActionPlanValues
export const isEditModeStatus = state => state.fuse.improvementProposalActionPlan.isEditMode
export const filteredUsers = state => state.fuse.improvementProposalActionPlan.filteredUsers
export const mft_team = state => state.fuse.improvementProposalActionPlan.mft_team

export default improvementProposalActionPlanSlice.reducer
