
export const GET_CONTRATO_SERVICIO = 'GET_CONTRATO_SERVICIO'
export const GET_CONTRATO_SERVICIO_EXITO = 'GET_CONTRATO_SERVICIO_EXITO'
export const GET_CONTRATO_SERVICIO_ERROR = 'GET_CONTRATO_SERVICIO_ERROR'

export const POST_CONTRATO_SERVICIO = 'POST_CONTRATO_SERVICIO'
export const POST_CONTRATO_SERVICIO_EXITO = 'POST_CONTRATO_SERVICIO_EXITO'
export const POST_CONTRATO_SERVICIO_ERROR = 'POST_CONTRATO_SERVICIO_ERROR'

export const PUT_CONTRATO_SERVICIO = 'PUT_CONTRATO_SERVICIO'
export const PUT_CONTRATO_SERVICIO_EXITO = 'PUT_CONTRATO_SERVICIO_EXITO'
export const PUT_CONTRATO_SERVICIO_ERROR = 'PUT_CONTRATO_SERVICIO_ERROR'

export const VER_MODAL_ASSIGNED_PEOPLE = 'VER_MODAL_ASSIGNED_PEOPLE'
export const VER_MODAL_ASSIGNED_PEOPLE_EXITO = 'VER_MODAL_ASSIGNED_PEOPLE_EXITO'
export const VER_MODAL_ASSIGNED_PEOPLE_ERROR = 'VER_MODAL_ASSIGNED_PEOPLE_ERROR'

export const GET_PEOPLE_CONTRATO = 'GET_PEOPLE_CONTRATO'
export const GET_PEOPLE_CONTRATO_EXITO = 'GET_PEOPLE_CONTRATO_EXITO'
export const GET_PEOPLE_CONTRATO_ERROR = 'GET_PEOPLE_CONTRATO_ERROR'

// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO = 'CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO'
export const CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_CONTRATO_SERVICIO_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONTRATO_SERVICIO_ERROR'

export const POST_PEOPLE_CONTRATO = 'POST_PEOPLE_CONTRATO'
export const POST_PEOPLE_CONTRATO_EXITO = 'POST_PEOPLE_CONTRATO_EXITO'
export const POST_PEOPLE_CONTRATO_ERROR = 'POST_PEOPLE_CONTRATO_ERROR'

export const ELIMINAR_CONTRACT_USER = 'ELIMINAR_CONTRACT_USER'
export const ELIMINAR_CONTRACT_USER_EXITO = 'ELIMINAR_CONTRACT_USER_EXITO'
export const ELIMINAR_CONTRACT_USER_ERROR = 'ELIMINAR_CONTRACT_USER_ERROR'

export const GET_APP_CONTRACT = 'GET_APP_CONTRACT'
export const GET_APP_CONTRACT_EXITO = 'GET_APP_CONTRACT_EXITO'
export const GET_APP_CONTRACT_ERROR = 'GET_APP_CONTRACT_ERROR'

export const GET_USER_APP_CONTRACT = 'GET_USER_APP_CONTRACT'
export const GET_USER_APP_CONTRACT_EXITO = 'GET_USER_APP_CONTRACT_EXITO'
export const GET_USER_APP_CONTRACT_ERROR = 'GET_USER_APP_CONTRACT_ERROR'

export const GET_LOCATIONS_MISION_CONTRACT = 'GET_LOCATIONS_MISION_CONTRACT'
export const GET_LOCATIONS_MISION_CONTRACT_EXITO = 'GET_LOCATIONS_MISION_CONTRACT_EXITO'
export const GET_LOCATIONS_MISION_CONTRACT_ERROR = 'GET_LOCATIONS_MISION_CONTRACT_ERROR'