import { method } from "lodash"
import { WP_URL } from "./consts"
import {getCookie} from 'app/js/generalFunctions'

const getAuth =()=>{
    return {'Authorization': `Token ${getCookie('token')}`}
}

export const POST_WP_REQUEST_OBJ=(workPackage) =>( {
    method: "POST",
    url: WP_URL,
    data: workPackage,
    headers: getAuth()

})

export const PUT_WP_REQUEST_OBJ=(idWorkPackage, workPackageEdited) =>({
    method: "PUT",
    url: `${WP_URL}${idWorkPackage}`,
    data: workPackageEdited,
    headers: getAuth()
})


export const GET_ALL_WPS=()=>({
    method: "GET",
    url: WP_URL,
    headers:  getAuth()
})