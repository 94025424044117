//**********************IMPORTACIONES****************************

import { React, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import IconButton from "@mui/material/IconButton";

import AddTaskIcon from '@mui/icons-material/AddTask';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Tooltip from '@mui/material/Tooltip';

import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';

//Grid importaciones

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux'


import {
  cambiarEstadoRmTasksAction,
  mostrarRmAccionAPIAction
} from '../tasks/store/actions'

import {
  cambiarVisibilidadModalPrincipalAction,
  cambiarVisibilidadModalInsertarRyOAction,
  mostrarRyOAPIAction,
  cambiarVisibilidadModalHistoricoActionsAction,
  consultaRiskOpportunityOrganigramaAPIAction,
  cambiarEstadoRyOAction,
  cambiarValorSeleccionRyOAction,
  cambiarValorSeleccionRyOHistricModalAction
} from '../../store2/RyO/actions.js'

import {
  verModalDetallesLogRiskAPIAction,
  mostrarLogRiskAmfeAPIAction,
  mostrarLogRiskRoAPIAction,
  mostrarLogRiskAccionesByRoAPIAction,
  mostrarLogRiskAPIAction
} from '../../../../Managment/LogRisk/store/actions.js'


import TableModules from '../../../../tables/TableModules.js'

import { RYO_ACTIONS_COLUMNS } from '../../utils/sectionColumns.js'

import { useGetRyOHistoryRows } from '../../hooks/RyO/RyOHistoryModalHooks.js'

import { useStyles } from '../../utils/RyOUtils.js'
import { useGetActions } from '../../hooks/actions/actionsHooks';

//**********************END_IMPORTACIONES ***********************/


// ! TODO => LANZAR DISPATCH MOSTAR ACCIONES


export const HistorialActions = () => {

  const classes = useStyles();
  const dispatch = useDispatch()

  // const { getRyOHistoryRows } = useGetRyOHistoryRows()
  const {mostrarActions} = useGetActions();
  const loading = useSelector(state => state.fuse.RyOComponente.loading)
  const [actionsRows, setActionsRows] = useState(null);
  const visibilidadModalHistoricoActions = useSelector(state => state.fuse.RyOComponente.visibilidadModalHistoricoActions)
  const RyOHistoryFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGridHistory)
  const actionsList = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI)

  const cambiarVisibilidadModalHistoricoActions = (valor) => dispatch(cambiarVisibilidadModalHistoricoActionsAction(valor));

  useEffect(()=> !actionsList.length && mostrarActions(),[])

  useEffect(()=>{
    if(!visibilidadModalHistoricoActions && !RyOHistoryFilaSeleccionada) return;
    const RyOActions = actionsList.filter(action => action.id_record === RyOHistoryFilaSeleccionada);
    setActionsRows(RyOActions)
  },[visibilidadModalHistoricoActions, RyOHistoryFilaSeleccionada])

  return (

    <Dialog open={visibilidadModalHistoricoActions} disableScrollLock={false} onClose={() => { cambiarVisibilidadModalHistoricoActions(false) }} fullWidth maxWidth='xl'>


      <DialogTitle classes={{ root: classes.customDialogTitle }} >

       {`R&O ${RyOHistoryFilaSeleccionada} actions history`}

      </DialogTitle>

      <DialogContent>
        <div style={{ width: '100%' }}>
          {/* {botonesSuperiores()} */}
          {actionsRows &&
            <TableModules rowsProp={actionsRows} columnsProp={RYO_ACTIONS_COLUMNS} loading={loading} />
          }

        </div>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => { cambiarVisibilidadModalHistoricoActions(false) }}>Close</Button>
      </DialogActions>


    </Dialog>
  )
}

