export const CONTRACT_TABS_VALUES = {
    contractsServices: "contracts",
    workPagckages: "workPackages",
    missions: "missions",
    submissions: "submissions"
}
export const CONTRACT_TAB_LABEL = {
    contractsServices: "Contracts/Services",
    workPagckages: "Work Packages",
    missions: "Missions",
    submissions: "Sub Missions"
}

export const DEFAULT_TAB_VALUE_PROPS = {
    textColor: "secondary",
    indicatorColor: "secondary",
    ["aria-label"]: "secondary tabs example"
}


