import { SUBMISSIONS_TYPES } from './types';
import { POST_SUBMISSION_REQUEST_OBJ, PUT_SUBMISSION_REQUEST_OBJ, GET_JOB_IN_SUBMISSION_REQUEST_OBJ, POST_JOB_IN_SUBMISSION_REQUEST_OBJ, PUT_JOB_IN_SUBMISSION_REQUEST_OBJ, POST_CONTRACT_USER, PUT_CONTRACT_USER_REQUEST_OBJ, GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ, DELETE_CONTRACT_USER_REQUEST_OBJ, GET_ALL_SUBMISSIONS_REQUEST_OBJ, GET_USER_CONTRACTS_REQUEST_OBJ } from './utils/functions';
import { SUCESS_MESSAGE_CREATE_SUBMISSION, SUCESS_MESSAGE_EDIT_SUBMISSION, ERROR_MESSAGE_CREATE_SUBMISSION, DELETE_USER_FROM_SUBMISSION, SUCESS_CREATE_JOB, SUCESS_EDIT_JOB,  SUCESS_DELETE_JOB, SUCESS_ASIGN_PERSON, SUCESS_CHANGE_PERSON, ERROR_TRY_AGAIN, ERROR_GET_ALL_SUBMISSIONS } from './utils/consts';
import { inserSectionInSectionViews, insertSectionInModuleStructure, editSectionInSectionsViews, editSectionInModuleStructure } from 'components/Managment/Users/store/actions';
import { STRUCTURE_CATEGORY_TYPES_NAMES } from 'components/Managment/Users/store/utils/consts';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import store from "app/store/index"
import { getUserOrganizationChart } from 'components/Managment/Users/store/actions';
import { peopleMovementsLogs, sectionsActionsLogs } from 'app/js/generalRequests';

export const setCreateModal = (createModalState) => {
  return (dispatch) => {
    dispatch({
      type: SUBMISSIONS_TYPES.SET_CREATE_MODAL,
      payload: createModalState
    })
  }
}

export const setEditModal = (editModalState) => {
  return (dispatch) => {
    dispatch({
      type: SUBMISSIONS_TYPES.SET_EDIT_MODAL,
      payload: editModalState
    })
  }
}

export const setJobModal = (jobModalState) => {
  return (dispatch) => {
    dispatch({
      type: SUBMISSIONS_TYPES.SET_JOB_MODAL,
      payload: jobModalState
    })
  }
}

export const setPeopleModal = (peopleModal) => {
  return (dispatch) => {
    dispatch({
      type: SUBMISSIONS_TYPES.SET_PEOPLE_MOAL,
      payload: peopleModal
    })
  }
}

export const setSubmissionsSelected = (rowSelected) => {

  return (dispatch) => {
    dispatch({
      type: SUBMISSIONS_TYPES.SET_SUBMISSIONS_SELECTED,
      payload: rowSelected
    })
  }
}

export const postSubmissionAction = (submission) => {
  return async (dispatch) => {
    try {
      const postSubmissionResponse = await axios(POST_SUBMISSION_REQUEST_OBJ(submission))
      if (postSubmissionResponse.status !== 201) throw new Error()
      dispatch(showMessage(SUCESS_MESSAGE_CREATE_SUBMISSION))
      const submissionPosted = await Promise.resolve(postSubmissionResponse.data);
      const submissionInfo = { id: submissionPosted.id, ...submission }
      const {staff, prevStaff, id: submissionId} = submissionInfo


      const missionFather = submissionInfo.id_mision
      const {departamentalAndNoDepartamentalMissionsIds, person} = store.getState().fuse.userComponente

      // Ç Post sub mission
      dispatch({type: SUBMISSIONS_TYPES.POST_SUBMISSION, payload: submissionInfo})

      // Ç Logs
      sectionsActionsLogs({ moduleName: "Sub mission", moduleId: submissionId, action: "Create" })
      peopleMovementsLogs({ moduleId: submissionId, moduleTableName: "subMision_relacionada", staff})
      
      if(departamentalAndNoDepartamentalMissionsIds.includes(missionFather)){
        dispatch(inserSectionInSectionViews({ sectionInfo: submissionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.submission}))
        dispatch(insertSectionInModuleStructure({ sectionInfo: submissionInfo,  sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.submission}))
      }
      else dispatch(getUserOrganizationChart({idUser: person.id}))

      return submissionPosted
    }
    catch (e) {
      console.log("🚀 ~ return ~ e:", e)
      dispatch(showMessage(ERROR_MESSAGE_CREATE_SUBMISSION))
      return Promise.reject(false)
    }
  }
}

export const putSubmissionAction = (idSubmission, submissionEdited) => {
  return async (dispatch) => {
    try {
      const putSubmissionResponse = await axios(PUT_SUBMISSION_REQUEST_OBJ(idSubmission, submissionEdited))

      if (putSubmissionResponse.status !== 200) throw new Error()
      dispatch(showMessage(SUCESS_MESSAGE_EDIT_SUBMISSION))
      const submissionPushed = await Promise.resolve(putSubmissionResponse.data);
      const submissionInfo = { id: idSubmission, ...submissionEdited }
      const {staff, prevStaff, id: submissionId} = submissionInfo
      // Ç Put sub mission
      dispatch({type: SUBMISSIONS_TYPES.PUT_SUBMISSION, payload: submissionInfo})

      // Ç Logs
      sectionsActionsLogs({ moduleName: "Sub mission", moduleId: submissionId, action: "Edit" })
      peopleMovementsLogs({ moduleId: submissionId, moduleTableName: "subMision_relacionada", staff, prevStaff})
      
      const {departamentalAndNoDepartamentalSubMissionsIds,person} = store.getState().fuse.userComponente
      if(departamentalAndNoDepartamentalSubMissionsIds.includes(submissionInfo.id)){
        dispatch(editSectionInSectionsViews({ sectionInfo: submissionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.submission}))
        dispatch(editSectionInModuleStructure({ sectionInfo: submissionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.submission}))
      }
      else{
        dispatch(getUserOrganizationChart({idUser: person.id}))
      }
      return submissionPushed
    }
    catch (e) {
      console.log("🚀 ~ return ~ e:", e)
      dispatch(showMessage(ERROR_MESSAGE_CREATE_SUBMISSION))
      return Promise.reject(false)
    }
  }
}

export const getStaffInSubmissionAction = (idSubMision, submissionToSwitch = false) => {
  return async (dispatch) => {
    try {
      const getJobResponse = await axios(GET_PERSONEL_IN_SUBMISSION_REQUEST_OBJ(idSubMision));

      const type = submissionToSwitch ? SUBMISSIONS_TYPES.GET_PEOPLE_IN_SUBMISSION_SWITCH : SUBMISSIONS_TYPES.GET_PEOPLE_IN_SUBMISSION_SELECTED

      dispatch({type, payload: getJobResponse.data})
      return Promise.resolve(getJobResponse.data);
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      console.log("🚀 ~ return ~ error:", error)
      return Promise.reject(error);
    }
  };
};



export const getJobInSubmissionAction = (idSubMision, jobsFromSubmissionToSwitch = false) => {
  return async (dispatch) => {
    try {
      const getJobResponse = await axios(GET_JOB_IN_SUBMISSION_REQUEST_OBJ(idSubMision));
      const type = jobsFromSubmissionToSwitch ? SUBMISSIONS_TYPES.SET_JOB_IN_SUBMISSION_TO_SWITCH : SUBMISSIONS_TYPES.SET_JOB_IN_SUBMISSION_SELECTED
      if(getJobResponse.status !== 200) throw new Error()
      dispatch({type, payload: getJobResponse.data})
      return Promise.resolve(getJobResponse.data);
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      console.log("🚀 ~ return ~ error:", error)
      return Promise.reject(error);
    }
  };
};

export const postJobInSubmissionAction = (job) => {
  return async (dispatch) => {
    try {
      const getJobResponse = await axios(POST_JOB_IN_SUBMISSION_REQUEST_OBJ(job));
      dispatch(getJobInSubmissionAction(job.sub_mision))
      dispatch(showMessage(SUCESS_CREATE_JOB))
      return Promise.resolve(getJobResponse.data);
    } catch (error) {
      console.log("🚀 ~ return ~ error:", error)
      return Promise.reject(error);
    }
  };
}



export const putJobInSubmissionAction = (job) => {

  return async (dispatch) => {

    try {
      const getJobResponse = await axios(PUT_JOB_IN_SUBMISSION_REQUEST_OBJ(job));
      dispatch(getJobInSubmissionAction(job.sub_mision))
      !!job.active ?  dispatch(showMessage(SUCESS_EDIT_JOB)) : dispatch(showMessage(SUCESS_DELETE_JOB))
      return Promise.resolve(getJobResponse.data);
    } catch (error) {
      console.log("🚀 ~ return ~ error:", error)
      return Promise.reject(error);
    }
  }
}


export const postPersonelInSubmission = (contractUserData, throwMesagge, submissionId) => {
  return async (dispatch) => {
    try {
      const getJobResponse = await axios(POST_CONTRACT_USER(contractUserData));
      throwMesagge && dispatch(showMessage(SUCESS_ASIGN_PERSON))
      return Promise.resolve(getJobResponse.data);
    } catch (error) {
      console.log("🚀 ~ return ~ error:", error)
      throwMesagge && dispatch(showMessage(ERROR_TRY_AGAIN))
      return Promise.reject(null);
    }
  };
}

export const insertNewPersonInPeopleInSubmissionSelected=(newItemInPeopleInSubmissionSelected) => {
  return dispatch=>{
    const {peopleInSubmissionSelected} = store.getState().fuse.submissionsReducer
    const submissionPeopleWithNewUser = [...peopleInSubmissionSelected, newItemInPeopleInSubmissionSelected]

    const {id: userId, subMision_id} = newItemInPeopleInSubmissionSelected
    // Ç Da igual que lo inserte o manager o empleado
    const staff = {managers: [{id: userId}], employees: []}
    peopleMovementsLogs({moduleId: subMision_id, moduleTableName: "subMision_relacionada", staff})
    dispatch({type: SUBMISSIONS_TYPES.GET_PEOPLE_IN_SUBMISSION_SELECTED, payload: submissionPeopleWithNewUser})
  }
}

export const putPersonelInSubmission=({idContractUser, contractUserData})=>{
  return async dispatch=>{
    try{
      const putRequestContractUser = await axios(PUT_CONTRACT_USER_REQUEST_OBJ({idContractUser, contractUserData}))
      dispatch(showMessage(SUCESS_CHANGE_PERSON))
      return Promise.resolve(putRequestContractUser.data)
    }
    catch(error){
      console.log("🚀 ~ return ~ error:", error)
      dispatch(showMessage(ERROR_TRY_AGAIN))
      return Promise.reject(null);
    }
  }
}

export const insertEditedPersonInPeopleInSubmissionSelected=(editedItemInPeopleInSubmissionSelected) => {
  
  return dispatch=>{
    const {peopleInSubmissionSelected} = store.getState().fuse.submissionsReducer
    const contractUserToChangeIndex = peopleInSubmissionSelected.findIndex(({contractUserId}) => contractUserId === editedItemInPeopleInSubmissionSelected.contractUserId)
    const submissionPeopleWitheditedUser = peopleInSubmissionSelected.with(contractUserToChangeIndex, editedItemInPeopleInSubmissionSelected)
    dispatch({type: SUBMISSIONS_TYPES.GET_PEOPLE_IN_SUBMISSION_SELECTED, payload: submissionPeopleWitheditedUser})
  }
}



export const deleteContractUserAction = (userAndSubmissionIds, message = true) => {
  return async (dispatch) => {

    try {
      const deleteUser = await axios(DELETE_CONTRACT_USER_REQUEST_OBJ(userAndSubmissionIds));
      if(deleteUser.status !== 204) throw new Error()
      if (message) dispatch(showMessage(DELETE_USER_FROM_SUBMISSION))
      return Promise.resolve(true);
    } catch (error) {
      dispatch(showMessage(ERROR_TRY_AGAIN))
      console.log("🚀 ~ return ~ error:", error)
      return Promise.reject(false);
    }
  }
}

export const deletePersonInPeopleInSubmissionSelected=(userId)=>{
  return dispatch=>{
    const {peopleInSubmissionSelected} = store.getState().fuse.submissionsReducer
    const contractUserToChangeIndex = peopleInSubmissionSelected.findIndex(({id}) => id === userId)
    const personDeleted = peopleInSubmissionSelected.toSpliced(contractUserToChangeIndex,1)
    dispatch({type: SUBMISSIONS_TYPES.GET_PEOPLE_IN_SUBMISSION_SELECTED, payload: personDeleted})
  }
}



export const getAllSubmissionsAction =()=>{
  return async (dispatch) =>{
    try{
      const submissionsRequest = await axios(GET_ALL_SUBMISSIONS_REQUEST_OBJ())
      if(submissionsRequest.status !== 200) throw new Error()
      dispatch({type: SUBMISSIONS_TYPES.GET_ALL_SUBMISSIONS, payload: submissionsRequest.data})
    }
    catch{
      dispatch(showMessage(ERROR_GET_ALL_SUBMISSIONS))
    }
  }
}

export const getUserContractsAction=(idUser)=>{
  return async dispatch =>{
    try{
      const userContractcs = await axios(GET_USER_CONTRACTS_REQUEST_OBJ(idUser))
      if(userContractcs.status !== 200) throw new Error()
      return Promise.resolve(userContractcs.data)
  }
  catch(error){
    dispatch(showMessage(ERROR_TRY_AGAIN))
    console.log("🚀 ~ getUserContractsAction ~ e:", e)
    return Promise.reject(null);
    }
  }
}

export const resetSubmissionReducerValuesAction =()=>{
  return dispatch =>  dispatch({type: SUBMISSIONS_TYPES.RESET_SUBMISSION_STORE_VALUES })

}