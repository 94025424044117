import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';

import { useKnowIfPersonLoginCanAddInspectors } from '../utils/hooks.js';
import { setCreateInspectorModalVisibility } from '../store/AuthorizationOfInspectorsSlice.js';

export const InspectorsTopButtoms = () => {

  const dispach = useDispatch()

    const canAddInspectors = useKnowIfPersonLoginCanAddInspectors()
    const openCreateInspectorModal =()=> dispach(setCreateInspectorModalVisibility(true))



  //   * Component
  return (
    < div className='flex items-center h-full ml-[10px] gap-[10px]'>

      {
        !!canAddInspectors && (
          <Tooltip title="New" placement="top">
            <AddCircleIcon variant="outlined" onClick={openCreateInspectorModal} />
          </Tooltip>
        )
      }

{/* 
      {
        !!filtersSet && !!deliverables_list.length && (
          <Tooltip title="Edit quantities with csv file" placement="top" >
            <UploadIcon variant="outlined" onClick={() => setPutModalVisibility(true)} />
          </Tooltip>
        )
      }

      {
        !!filtersSet && !!deliverables_list.length && (
          <Tooltip title="Edit quantities in table" placement="top">
            <EditIcon variant="outlined" onClick={() => setEditQuantityInTableModalVisibility(true)} />
          </Tooltip>
        )
      }

      {
        canViewAllDataDeliverables && !allDeliverablesView && filtersDontSet && (
          <Tooltip title="See all data deliverables from all missions" placement="top">
            <VisibilityIcon variant="outlined" onClick={getAllDataDeliverables} />
          </Tooltip>
        )
      }

      {
        allDeliverablesView && (
          <Tooltip title="Don´t see all data deliverables from all missions" placement="top">
            <VisibilityOffIcon variant="outlined" onClick={resetDataDeliverablesFilter} />
          </Tooltip>
        )
      } */}

    </div>
  );

}

