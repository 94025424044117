import { findIndexSectionById } from "app/js/generalFunctions"
import { MISSIONS_TYPES } from "./types"

const INITIAL_STATE = {
    createModal: false,
    editModal: false,
    missionSelected: null,
    visualTimeGroupsList: [],
    agressoCodesList:[],
    allMissionsList: [],
    departamentalMissionsList: [],
    noDepartamentalMissionsList: [],
    loadingMissions: true,
}

export default function (state = INITIAL_STATE, action) {
    const { SET_CREATE_MODAL, SET_EDIT_MODAL, SET_MISSION_SELECTED, GET_VISUAL_TIME_GROUPS, GET_AGRESSO_CODES, GET_ALL_MISSIONS, POST_MISSION, PUT_MISSION } = MISSIONS_TYPES
    

    const { type, payload } = action

    const MISSIONS_ACTIONS = {

        [SET_CREATE_MODAL]:(state, action)=> ({
            ...state,
            createModal: payload
        }),

        [SET_EDIT_MODAL]:(state, action)=> ({
            ...state,
            editModal: payload
        }),

        [SET_MISSION_SELECTED]:(state, action)=> ({
            ...state,
            missionSelected: payload
        }),

        [GET_VISUAL_TIME_GROUPS]:(state, action)=>({
            ...state,
            visualTimeGroupsList: payload
        }),

        [GET_AGRESSO_CODES]:(state, action)=>({
            ...state,
            agressoCodesList: payload
        }),

        [GET_ALL_MISSIONS]:(state, action)=>{
            const dptoAndNodpto = payload.reduce((acc, mission) =>{
                if(mission.esDepartamento) acc.dpto = [...acc.dpto, mission]
                else acc.noDpto = [...acc.noDpto, mission]
                return acc;
            }, {dpto: [], noDpto: []})
            return{
                ...state,
                allMissionsList: payload,
                noDepartamentalMissionsList: dptoAndNodpto.noDpto,
                departamentalMissionsList: dptoAndNodpto.dpto,
                loadingMissions: false
            }
        },

        [POST_MISSION]:(state, action)=>{

            return{
                ...state,
                noDepartamentalMissionsList: [action.payload, ...state.noDepartamentalMissionsList],
                allMissionsList: [action.payload, ...state.allMissionsList],
            }
        },

        [PUT_MISSION]:(state, action)=>{
            const newNoDptoList = state.noDepartamentalMissionsList.with(action.payload.indexValue, action.payload);
            const indexInMissionList = findIndexSectionById({sectionList: state.allMissionsList, sectionToFindInList: action.payload})
            const newAllList = state.allMissionsList.with(indexInMissionList, action.payload)
            return{
                ...state,
                noDepartamentalMissionsList: newNoDptoList,
                allMissionsList: newAllList
            }
        },

    }
    return MISSIONS_ACTIONS[type] ? MISSIONS_ACTIONS[type](state, action) : state
}



// import {

//     GET_TEAM,
//     GET_TEAM_EXITO,
//     GET_TEAM_ERROR,

//     ELIMINAR_CONTRACT_USER,
//     ELIMINAR_CONTRACT_USER_EXITO,
//     ELIMINAR_CONTRACT_USER_ERROR,

//     VER_MODAL_ASSIGNED_PEOPLE,
//     VER_MODAL_ASSIGNED_PEOPLE_EXITO,
//     VER_MODAL_ASSIGNED_PEOPLE_ERROR,

//     GET_PEOPLE_CONTRATO,
//     GET_PEOPLE_CONTRATO_EXITO,
//     GET_PEOPLE_CONTRATO_ERROR,

//     POST_PEOPLE_CONTRATO,
//     POST_PEOPLE_CONTRATO_EXITO,
//     POST_PEOPLE_CONTRATO_ERROR,

//     GET_APP_SUB_MISION,
//     GET_APP_SUB_MISION_EXITO,
//     GET_APP_SUB_MISION_ERROR,

//     GET_USER_APP_SUB_MISION_CONTRACT,
//     GET_USER_APP_SUB_MISION_CONTRACT_EXITO,
//     GET_USER_APP_SUB_MISION_CONTRACT_ERROR,

//     GET_ALL_SUB_MISION,
//     GET_ALL_SUB_MISION_EXITO,
//     GET_ALL_SUB_MISION_ERROR,

//     CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION,
//     CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_EXITO,
//     CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_ERROR,

//     VER_MODAL_INSERT_SUB_MISION,
//     VER_MODAL_INSERT_SUB_MISION_EXITO,
//     VER_MODAL_INSERT_SUB_MISION_ERROR,

//     POST_SUB_MISION,
//     POST_SUB_MISION_EXITO,
//     POST_SUB_MISION_ERROR,

//     PUT_SUB_MISION,
//     PUT_SUB_MISION_EXITO,
//     PUT_SUB_MISION_ERROR,

//     PUT_PEOPLE_CONTRATO,
//     PUT_PEOPLE_CONTRATO_EXITO,
//     PUT_PEOPLE_CONTRATO_ERROR,

//     VER_MODAL_ASSIGNED_APP,
//     VER_MODAL_ASSIGNED_APP_EXITO,
//     VER_MODAL_ASSIGNED_APP_ERROR,

//     POST_CONTRACT_APP,
//     POST_CONTRACT_APP_EXITO,
//     POST_CONTRACT_APP_ERROR,

//     ELIMINAR_CONTRACT_APP,
//     ELIMINAR_CONTRACT_APP_EXITO,
//     ELIMINAR_CONTRACT_APP_ERROR,

//     GET_ALL_JOB,
//     GET_ALL_JOB_EXITO,
//     GET_ALL_JOB_ERROR,

//     VER_MODAL_GESTION_TRABAJOS,
//     VER_MODAL_GESTION_TRABAJOS_EXITO,
//     VER_MODAL_GESTION_TRABAJOS_ERROR,

//     GET_ALL_SUB_MISION_DEPARTAMENTO,
//     GET_ALL_SUB_MISION_DEPARTAMENTO_EXITO,
//     GET_ALL_SUB_MISION_DEPARTAMENTO_ERROR,

//     GET_ALL_SUB_MISION_NO_DEPARTAMENTOS,
//     GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_EXITO,
//     GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_ERROR


// } from './types';


// // cada reducer tiene su propio state
// const initialState = {
//     listTeams: [],
//     listSubMisiones: [],
//     listSubMisionesDepartamentales: [],
//     listSubMisionesNoDepartamentales: [],
//     visibilidadModalInsertarMision: false,
//     visibilidadModalAssignedPeople: '',
//     visibilidadModalAssignedApp: false,
//     visibilidadModalGestionTrabajos: false,
//     listPeopleContratoAPI: [],
//     error: null,
//     loading: false,
//     filaSeleccionadaGrid: '',
//     listContractApp: [],
//     listUserApp: [],
//     listJobSubMision: [],
//     modoApertura: '',
//     modoAperturaInsert: '',
//     newSubMisionCreated: '',
//     deleteUserSubMision: '' //SE VA A ALMACENAR EL ID DE LA SUBMISION PARA RECALCULAR
// }

// export default function (state = initialState, action) {

//     switch (action.type) {

//         //**********LISTA DE SUB MISIONES DEPARTAMENTALES**********************************/

//         case GET_ALL_SUB_MISION_DEPARTAMENTO:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_ALL_SUB_MISION_DEPARTAMENTO_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listSubMisionesDepartamentales: action.payload
//             }

//         case GET_ALL_SUB_MISION_DEPARTAMENTO_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//             //**********LISTA DE SUB MISIONES NO DEPARTAMENTALES**********************************/

//             case GET_ALL_SUB_MISION_NO_DEPARTAMENTOS:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listSubMisionesNoDepartamentales: action.payload
//             }

//         case GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********CAMBIAR VISIBILIDAD MODAL VER TRABAJOS ASIGNADOS A CONTRATOS**********************************/

//         case VER_MODAL_GESTION_TRABAJOS:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case VER_MODAL_GESTION_TRABAJOS_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 visibilidadModalGestionTrabajos: action.payload

//             }

//         case VER_MODAL_GESTION_TRABAJOS_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********GET JOB DE SUB MISION **********************************/

//         case GET_ALL_JOB:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_ALL_JOB_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listJobSubMision: action.payload
//             }

//         case GET_ALL_JOB_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//     //**********FILA SELECIONADA GRID **********************************/

//     case CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION:
//         return {
//             ...state,
//             loading: action.payload
//         }
    
//     case CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_EXITO:
//         return {
//             ...state,
//             loading: false,
//             filaSeleccionadaGrid: action.payload

//         }
    
//     case CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_ERROR:
//         return {
//             ...state,
//             loading: false,
//             error: action.payload

//         }

//         case GET_ALL_SUB_MISION:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_ALL_SUB_MISION_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listSubMisiones: action.payload
//             }

//         case GET_ALL_SUB_MISION_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//     //deleteee CONTRACT APP
//     case ELIMINAR_CONTRACT_APP:
//         return {
//             ...state,
//             loading: action.payload
//         }

//     case ELIMINAR_CONTRACT_APP_EXITO:
//         return {
//             ...state,
//             loading: false,
//             error: null

//         }

//     case ELIMINAR_CONTRACT_APP_ERROR:
//         return {
//             ...state,
//             loading: false,
//             error: action.payload

//         }

//         //deleteee
//         case ELIMINAR_CONTRACT_USER:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case ELIMINAR_CONTRACT_USER_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 deleteUserSubMision: action.payload
//             }

//         case ELIMINAR_CONTRACT_USER_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********GET TEAM **********************************/

//         case GET_TEAM:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_TEAM_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listTeams: action.payload
//             }

//         case GET_TEAM_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********CAMBIAR VISIBILIDAD MODAL INSERTAR SUB MISION**********************************/

//         case VER_MODAL_INSERT_SUB_MISION:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case VER_MODAL_INSERT_SUB_MISION_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 visibilidadModalInsertarMision: action.payload,
//                 modoAperturaInsert: action.modoApertura

//             }

//         case VER_MODAL_INSERT_SUB_MISION_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********CAMBIAR VISIBILIDAD MODAL VER APPS ASIGNADAS A CONTRATOS**********************************/

//         case VER_MODAL_ASSIGNED_APP:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case VER_MODAL_ASSIGNED_APP_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 visibilidadModalAssignedApp: action.payload

//             }

//         case VER_MODAL_ASSIGNED_APP_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }
//         //**********CAMBIAR VISIBILIDAD MODAL VER PERSONAS ASIGNADAS A CONTRATOS**********************************/

//         case VER_MODAL_ASSIGNED_PEOPLE:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case VER_MODAL_ASSIGNED_PEOPLE_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 visibilidadModalAssignedPeople: action.payload,
//                 modoApertura: action.modoApertura

//             }

//         case VER_MODAL_ASSIGNED_PEOPLE_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }
//         //**********GET PERSONAS EN CONTRATO**********************************/

//         case GET_PEOPLE_CONTRATO:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_PEOPLE_CONTRATO_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listPeopleContratoAPI: action.payload
//             }

//         case GET_PEOPLE_CONTRATO_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********PUT PEOPLE CONTRACT**********************************/

//         case PUT_PEOPLE_CONTRATO:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case PUT_PEOPLE_CONTRATO_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null
//             }

//         case PUT_PEOPLE_CONTRATO_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//         //**********PUT SUB MISION**********************************/

//         case PUT_SUB_MISION:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case PUT_SUB_MISION_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null
//             }

//         case PUT_SUB_MISION_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//             //**********POST CONTRACT APP**********************************/

//         case POST_CONTRACT_APP:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case POST_CONTRACT_APP_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null
//             }

//         case POST_CONTRACT_APP_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//         //**********POST SUB MISION**********************************/

//         case POST_SUB_MISION:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case POST_SUB_MISION_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 newSubMisionCreated: action.payload['id']
//             }

//         case POST_SUB_MISION_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//         //**********POST persona a contrato**********************************/

//         case POST_PEOPLE_CONTRATO:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case POST_PEOPLE_CONTRATO_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null

//             }

//         case POST_PEOPLE_CONTRATO_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********GET PERSONAS EN CONTRATO**********************************/

//         case GET_APP_SUB_MISION:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_APP_SUB_MISION_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listContractApp: action.payload
//             }

//         case GET_APP_SUB_MISION_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         //**********GET PERSONAS EN CONTRATO**********************************/

//         case GET_USER_APP_SUB_MISION_CONTRACT:
//             return {
//                 ...state,
//                 loading: action.payload
//             }

//         case GET_USER_APP_SUB_MISION_CONTRACT_EXITO:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 listUserApp: action.payload
//             }

//         case GET_USER_APP_SUB_MISION_CONTRACT_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload

//             }

//         default:
//             return state

//     }

// }