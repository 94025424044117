//CONSULTA A API RM_ACCION 
export const MOSTRAR_RM_ACCION = 'MOSTRAR_RM_ACCION'
export const MOSTRAR_RM_ACCION_EXITO = 'MOSTRAR_RM_ACCION_EXITO'
export const MOSTRAR_RM_ACCION_ERROR = 'MOSTRAR_RM_ACCION_ERROR'

//CAMBIAR ESTADO DEL COMPONENTE
export const CAMBIAR_ESTADO_RM_ACCION = 'CAMBIAR_ESTADO_RM_ACCION'
export const CAMBIAR_ESTADO_RM_ACCION_EXITO = 'CAMBIAR_ESTADO_RM_ACCION_EXITO'
export const CAMBIAR_ESTADO_RM_ACCION_ERROR = 'CAMBIAR_ESTADO_RM_ACCION_ERROR'


//INSERTAR RM ACCION
export const INSERTAR_RM_ACCION = 'INSERTAR_RM_ACCION'
export const INSERTAR_RM_ACCION_EXITO = 'INSERTAR_RM_ACCION_EXITO'
export const INSERTAR_RM_ACCION_ERROR = 'INSERTAR_RM_ACCION_ERROR'

//UPDATE RM ACCION
export const UPDATE_RM_ACCION = 'UPDATE_RM_ACCION'
export const UPDATE_RM_ACCION_EXITO = 'UPDATE_RM_ACCION_EXITO'
export const UPDATE_RM_ACCION_ERROR = 'UPDATE_RM_ACCION_ERROR'

//RESET STATES RM ACCION
export const RESET_STATES_RM_ACCION = 'RESET_STATES_RM_ACCION'
export const RESET_STATES_RM_ACCION_EXITO = 'RESET_STATES_RM_ACCION_EXITO'
export const RESET_STATES_RM_ACCION_ERROR = 'RESET_STATES_RM_ACCION_ERROR'

//RESET STATES RM ACCION
export const DELETE_RM_ACCION = 'DELETE_RM_ACCION'
export const DELETE_RM_ACCION_EXITO = 'DELETE_RM_ACCION_EXITO'
export const DELETE_RM_ACCION_ERROR = 'DELETE_RM_ACCION_ERROR'
