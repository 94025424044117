import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA,
    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA,
    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR,

    ELIMINAR_PROGRAMA,
    ELIMINAR_PROGRAMA_EXITO,
    ELIMINAR_PROGRAMA_ERROR,

    MOSTRAR_PROGRAMAS_API,
    MOSTRAR_PROGRAMAS_EXITO,
    MOSTRAR_PROGRAMAS_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR,

    INSERTAR_PROGRAMA_MODAL_INSERTAR,
    INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR,

    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO,
    CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR,

    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR,
    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {

        visibilidad: true,
        programasListAPI: [],
        subdivisionesListAPI: [],
        filaSeleccionadaGrid: '',
        visibilidadModalInsertar: false,
        modo: '',
        error: null,
        loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********VISIBILIDAD**********************************/

        case CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_EXITO:
            return {
                ...state,
                loading: false,
                visibilidad: action.payload

            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


     //**********FILA SELECIONADA GRID **********************************/

         case CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO:
            return {
                ...state,
                loading: false,
                filaSeleccionadaGrid: action.payload

            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

     //********** ELIMINAR PROGRAMA **********************************/ 


     case ELIMINAR_PROGRAMA:
        return {
            ...state,
            loading: action.payload
        }
    
    case ELIMINAR_PROGRAMA_EXITO:
        return {
            ...state,
            loading: false,
            error: null
            

        }
    
    case ELIMINAR_PROGRAMA_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


     //********** MOSTRAR PROGRAMAS API**********************************/ 


        case MOSTRAR_PROGRAMAS_API:
            return {
                ...state,
                loading: action.payload
            }
        
        case MOSTRAR_PROGRAMAS_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                programasListAPI: action.payload

            }
        
        case MOSTRAR_PROGRAMAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
    
     //**********VISIBILIDAD MODAL INSERTAR**********************************/

       case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertar: action.payload,
                modo: action.visibilidadModal
            }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        

      
     //********** INSERTAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case INSERTAR_PROGRAMA_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** ACTUALIZAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case ACTUALIZAR_PROGRAMA_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


      //********** MUESTRA SUBDIVISIONES MODAL **********************************/ 


           case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA:
            return {
                ...state,
                loading: action.payload
            }
        
        case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                subdivisionesListAPI: action.payload

            }
        
        case CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

}