import dayjs from 'dayjs';
import axios from 'axios';
import { getCookie, getIdsFromArr } from './generalFunctions';
import store from 'app/store';
const sectionsLogsActionsURL = process.env.REACT_APP_URL_DJANGO + "/api/log/"
const peopleMovementsLogsURL = process.env.REACT_APP_URL_DJANGO + "/api/logPersonas/"
const fecha_accion = dayjs().format("YYYY-MM-DD")
const hora_accion = dayjs().format('HH:mm:ss');

const getPersona = () => {
    return store.getState().fuse.userComponente.person.id
}

export const sectionsActionsLogs =({action, moduleName, moduleId})=>{
    const persona = getPersona()
    const descripcion = `${action} ${moduleName} ${moduleId}`
    const modulo = moduleName

    axios({
        method: "POST",
        url: sectionsLogsActionsURL,
        headers: { 'Authorization': `Token ${getCookie('token')}`},
        data:{ descripcion, persona, fecha_accion, hora_accion, modulo}
    })
}

export const peopleMovementsLogs = async ({ moduleId, moduleTableName, staff, prevStaff }) => {
    const persona = getPersona()
    try {
        const { employees, managers } = staff
        const currentStaffIds = getIdsFromArr([...managers,...employees])
        let action = []
        let staffInfo = []
        if (prevStaff) {
            const { managers: prevManagers, employees: prevEmployees } = prevStaff
            const prevStaffIds = getIdsFromArr([...prevManagers, ...prevEmployees])

            const peopleAdded = currentStaffIds.filter(person => !prevStaffIds.includes(person))
            const peopleRemove = prevStaffIds.filter(person => !currentStaffIds.includes(person))

            action = [
                ...Array.from({ length: peopleAdded.length }).fill("Add"),
                ...Array.from({ length: peopleRemove.length }).fill("Remove")
            ]
            staffInfo = [...peopleAdded, ...peopleRemove]
        }
        else {
            action = Array.from({ length: currentStaffIds.length }).fill("Add")
            staffInfo = currentStaffIds
        }

        const loopRequest =staffInfo.map((person, index) => {
            axios({
                method: "POST",
                url: peopleMovementsLogsURL,
                headers: { 'Authorization': `Token ${getCookie('token')}` },
                data: { accion: action[index], persona, persona_receptora: person, fecha_accion, hora_accion, [moduleTableName]: moduleId }
            })

        })
        await Promise.all(loopRequest)
    }
    catch (e) {
        console.log("error", e)
    }


}