import { MISSION_URL,VISUAL_TIME_GROUPS_URL, AGRESSO_CODES_URL } from "./consts"
import {getCookie} from 'app/js/generalFunctions'

const getAuth =()=>{
    return {'Authorization': `Token ${getCookie('token')}`}
}

export const POST_MISSION_REQUEST_OBJ=(mission) =>( {
    method: "POST",
    url: MISSION_URL,
    data: mission,
    headers: getAuth()

})

export const PUT_MISSION_REQUEST_OBJ=(idMission, missionEdited) =>({
    method: "PUT",
    url: `${MISSION_URL}${idMission}`,
    data: missionEdited,
    headers: getAuth()
})

export const GET_VISUAL_TIME_GROUPS_REQUEST_OBJ=()=>({
    method: "GET",
    url: VISUAL_TIME_GROUPS_URL,
    headers: getAuth()
})


export const GET_AGRESSO_CODES_REQUEST_OBJ=()=>({
    method: "GET",
    url: AGRESSO_CODES_URL,
    headers: getAuth()
})

export const GET_ALL_MISSIONS_REQUEST_OBJ=()=>({
    method: "GET",
    url: MISSION_URL,
    headers: getAuth()
})