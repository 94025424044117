import {

    GET_CONJUNTO,
    GET_CONJUNTO_EXITO,
    GET_CONJUNTO_ERROR,

    POST_CONJUNTO,
    POST_CONJUNTO_EXITO,
    POST_CONJUNTO_ERROR,

    PUT_CONJUNTO,
    PUT_CONJUNTO_EXITO,
    PUT_CONJUNTO_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO,
    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO,
    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {
    listConjuntosAPI: [],
    visibilidadModalInsertarConjunto: false,
    error: null,
    loading: false,
    filaSeleccionadaGrid: '',
    modo: ''
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********GET CONJUNTOS**********************************/

        case GET_CONJUNTO:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listConjuntosAPI: action.payload
            }
        
        case GET_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


     //**********POST CONJUNTO**********************************/

         case POST_CONJUNTO:
            return {
                ...state,
                loading: action.payload
            }
        
        case POST_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case POST_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

     //********** PUT CONJUNTO **********************************/ 


     case PUT_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case PUT_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            error: null
        }
    
    case PUT_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }

    //**********VISIBILIDAD**********************************/

    case CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            visibilidad: action.payload

        }
    
    case CAMBIAR_VALOR_VISIBILIDAD_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


 //**********FILA SELECIONADA GRID **********************************/

     case CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_EXITO:
        return {
            ...state,
            loading: false,
            filaSeleccionadaGrid: action.payload

        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_CONJUNTO_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }
    //**********VISIBILIDAD MODAL INSERTAR**********************************/

    case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertarConjunto: action.payload,
                modo: action.modo
            }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_CONJUNTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

    

}