export const SKILL_MATRIX_TAB_LABELS = {
    requirements: "Requirements",
    teamTrainig: "Team training",
    experience: "Experience"
}
export const SKILL_MATRIX_TAB_VALUES = {
    requirements: "Requirements",
    teamTrainig: "TeamTraining",
    experience: "Experience"
}

export const REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE = {
    loading: true,
    missionWithNoVTG: false,
    noContentSkillMatrix: false,
    noContentTeamTrainig: false,
    errorSkillMatrix: false,
    errorTeamTraining: false,
    requirements: {
        data: null,
        columns: null
    },
    teamTraining: {
        data: null,
        columns: null,
    },

}

export const EXPERIENCE_INITIAL_STATE = {
    loadingExperience: true,
    missionWithNoAgressoCode: false,
    noContentExperience: false,
    errorExperience: false,
    experienceData: null
}


export const FORM_CONTROL_STYLES = {
    width: "400px",
    position: "relative",
    height: "100%",
    marginRight: "30px",
    marginTop: "10px"
}

export const CONTAINER_STYLES = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}

export const STATIC_ROW_SKILL_MATRIX = {
    ['CSK Code']: 'FBH',
    ['Skill']: 'INITIAL TRAINING',
    ['Skill Description']: 'Basic training + FOD + Human Factor',
    ['moodle_training_arr']: '{FB.001,CAL.010,SIS.032}',
    ['experience_qa']: '',
    ['evidence']: ''
}




export const REQUIREMENTS_COLUMS_NAMES_OBJ = {
    ["CSK Code"]: "Code",
    ["Skill"]: "Name",
    ["Skill Description"]: "Description",
    ["Evidence"]: "Evidenced",
    ["moodle_training_arr"]: "Training",
    ["experience_qa"]: "Correspondence"
}

export const TEAM_TRAINING_COLUMNS_NAMES_OBJ = {
    ["employee"]: "Employee",
    ["registered"]: "Registered",
    ["noRegistered"]: "No registered",
    ["doneCourses"]: "Done",
    ["noRegisteredCoursesNames"]: "No registered courses names",
    ["Progress"]: "Progress"
}

export const REQUIREMENTS_CEELS_WIDTH_VALUES = {
    ["Name"]: "100px",
    ["Description"]: "100px",
    ["Correspondence"]: "60px",
    ["Training"]: "200px"
}

export const TEAM_TRAINING_CEELS_WIDTH_VALUES = {
    ["Employee"]: "100px",
    ["Progress"]: "150px",
    ["No registered courses names"]: "200px",

}

export const EXPERIENCE_COLUMNS = [
    { Header: "Employee Name", accessor: "NAME", sortable: true, type: 'string' },
    { Header: "Agresso Code", accessor: "AGRESSO CODE", sortable: true, type: 'string' },
    { Header: "Hours", accessor: "HOURS", sortable: true, type: 'string' }
]

export const EXPERIENCE_ERROR_CONTROL = {
    errorExperience: {
        ...EXPERIENCE_INITIAL_STATE,
        loadingExperience: false,
        errorExperience: true
    },
    noContentExperience: {
        ...EXPERIENCE_INITIAL_STATE,
        loadingExperience: false,
        noContentExperience: true
    }
}


export const NOT_AVALIABLE_COURSES = ["DOC.039", "DOC.040", "DOC.041", "DOC.042", "DOC.043", "CAL.027"]