import { findIndexSectionById } from "app/js/generalFunctions"
import { WPS_TYPES } from "./types"
const INITIAL_STATE = {
    createModal: false,
    editModal: false,
    workPackageSelected: null,
    visualTimeGroupsList: [],
    agressoCodesList:[],
    allWpsList: [],
    departamentalWps: [],
    noDepartamentalWps: [],
    loadingWps: true,
}

export default function (state = INITIAL_STATE, action) {
    const { SET_CREATE_MODAL, SET_EDIT_MODAL, SET_WP_SELECTED, GET_ALL_WPS, POST_WP, PUT_WP } = WPS_TYPES
    

    const { type, payload } = action

    const WPS_ACTIONS=  {

        [SET_CREATE_MODAL]:(state, action)=> ({
            ...state,
            createModal: action.payload
        }),

        [SET_EDIT_MODAL]:(state, action)=> ({
            ...state,
            editModal: action.payload
        }),

        [SET_WP_SELECTED]: (state, action)=> ({
            ...state,
            workPackageSelected: action.payload
        }),


        [GET_ALL_WPS]:(state, action)=> {
            const dptoAndNodpto = action.payload.reduce((acc, wp) =>{
                if(wp.esDepartamento) acc.dpto = [...acc.dpto, wp]
                else acc.noDpto = [...acc.noDpto, wp]
                return acc;
            }, {dpto: [], noDpto: []})
            return{
                ...state,
                allWpsList: action.payload,
                departamentalWps: dptoAndNodpto.dpto,
                noDepartamentalWps: dptoAndNodpto.noDpto,
                loadingWps: false
            }
        },

        [POST_WP]: (state, action)=>{

            return {
                ...state,
                noDepartamentalWps: [action.payload, ...state.noDepartamentalWps],
                allWpsList: [action.payload, ...state.allWpsList], 

            }
        },

        [PUT_WP]: (state, action)=>{
            const newNoDptoWpList = state.noDepartamentalWps.with(action.payload.indexValue, action.payload)
            const indexInWpsList = findIndexSectionById({sectionList: state.allWpsList, sectionToFindInList: action.payload})
            const newAllList = state.allWpsList.with(indexInWpsList, action.payload)
            return {
                ...state,
                allWpsList: newAllList, 
                noDepartamentalWps: newNoDptoWpList
            }

        }


    }
    return WPS_ACTIONS[type] ? WPS_ACTIONS[type](state, action) : state
}
