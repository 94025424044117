import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled, useTheme } from '@mui/material/styles';
import FuseSettings from '@fuse/core/FuseSettings';
import Button from '@mui/material/Button';
import { red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import FuseThemeSchemes from '@fuse/core/FuseThemeSchemes';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';

import { memo, forwardRef, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, useLocation, useParams } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupportIcon from '@mui/icons-material/Support';
import Backdrop from '@mui/material/Backdrop';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import {
  cambiarValorVisibilidadAction,
  cambiarValorVisibilidadEstadoIncidenciasAction
} from '../../../components/Gestion/Kanban/store/actions'

import ModalInsertar from '../../../components/Gestion/Kanban/modals/insertar.js'
import MisIncidencias from '../../../components/Gestion/Kanban/modals/misIncidencias.js'

const actions = [
  { icon: <DashboardIcon />, name: 'Incident dashboard' },
  { icon: <LibraryBooksIcon />, name: 'User Guide' },
  { icon: <SupportIcon />, name: 'Support' },
];

const Root = styled('div')(({ theme }) => ({
  
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    position: 'fixed',
    width: 380,
    maxWidth: '90vw',
    backgroundColor: theme.palette.background.paper,
    top: 0,
    height: '100%',
    minHeight: '100%',
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    borderRadius: 0,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  const theme = useTheme();
  return <Slide direction={theme.direction === 'ltr' ? 'left' : 'right'} ref={ref} {...props} />;
});

function SettingsPanel() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);

  const cambiarValorVisibilidad = (valorNuevo) => dispatch(cambiarValorVisibilidadAction(valorNuevo))
  const cambiarValorVisibilidadEstadoIncidencias = (valorNuevo) => dispatch(cambiarValorVisibilidadEstadoIncidenciasAction(valorNuevo))

  const handlerOptions = {
    onSwipedLeft: () => {
      return open && theme.direction === 'rtl' && handleClose();
    },
    onSwipedRight: () => {
      return open && theme.direction === 'ltr' && handleClose();
    },
  };

  const settingsHandlers = useSwipeable(handlerOptions);
  const shemesHandlers = useSwipeable(handlerOptions);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function functionDestino(nombreDestino) {

    if (nombreDestino == "User Guide") {
      window.open('https://trigo-group.gitbook.io/csi-guides/', '_blank', 'noreferrer')
    }

    if (nombreDestino == "Incident dashboard") {
      cambiarValorVisibilidadEstadoIncidencias(true)
    }

    if (nombreDestino == "Support") {
      cambiarValorVisibilidad(true)
    }

    handleClose()
  }

  return (
    <>
      <Root id="fuse-settings-schemes" className="buttonWrapper">
        <SpeedDial
          className={clsx('min-h-10 max-h-25 px-0 py-0 items-end')}
          //sx={{ position: 'absolute', bottom: 160, right: 160 }}
          ariaLabel="SpeedDial tooltip example"
          icon={<QuestionMarkIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => { functionDestino(action.name) }}
            />
          ))}
        </SpeedDial>
      </Root>

      <ModalInsertar />
      <MisIncidencias />

      {/*<Root id="fuse-settings-schemes" className="buttonWrapper">
        <Button
          className="settingsButton min-w-40 w-40 h-40 m-0"
          onClick={() => handleOpen('settings')}
          variant="text"
          color="inherit"
        >
          <Icon className="text-20">settings</Icon>
        </Button>

        <Button
          className="min-w-40 w-40 h-40 m-0"
          onClick={() => handleOpen('schemes')}
          variant="text"
          color="inherit"
        >
          <Icon className="text-20">palette</Icon>
        </Button>
          </Root>*/}

      {/*<StyledDialog
        TransitionComponent={Transition}
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={open === 'settings'}
        keepMounted
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: 'shadow-lg',
        }}
        {...settingsHandlers}
      >
        <FuseScrollbars className="p-16 sm:p-32">
          <IconButton
            className="fixed top-0 ltr:right-0 rtl:left-0 z-10"
            onClick={handleClose}
            size="large"
          >
            <Icon>close</Icon>
          </IconButton>

          <Typography className="mb-32 font-semibold" variant="h6">
            Theme Settings
          </Typography>

          <FuseSettings />
        </FuseScrollbars>
      </StyledDialog>
      <StyledDialog
        TransitionComponent={Transition}
        aria-labelledby="schemes-panel"
        aria-describedby="schemes"
        open={open === 'schemes'}
        keepMounted
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: 'shadow-lg',
        }}
        {...shemesHandlers}
      >
        <FuseScrollbars className="p-16 sm:p-32">
          <IconButton
            className="fixed top-0 ltr:right-0 rtl:left-0 z-10"
            onClick={handleClose}
            size="large"
          >
            <Icon>close</Icon>
          </IconButton>

          <Typography className="mb-32" variant="h6">
            Theme Color Schemes
          </Typography>

          <Typography className="mb-24 text-12 italic text-justify" color="textSecondary">
            * Selected color scheme will be applied to all theme layout elements (navbar, toolbar,
            etc.). You can also select a different color scheme for each layout element at theme
            settings.
          </Typography>

          <FuseThemeSchemes />
        </FuseScrollbars>
      </StyledDialog>*/}
    </>
  );
}

export default memo(SettingsPanel);
