import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
export const LinearLoader=()=>{
    return(
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            width: "100%"
        }}>

            <Typography className="text-13 sm:text-20 mb-16" color="textSecondary">
                Loading...
            </Typography>
            <LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
        </div>
    )
}