import FuseUtils from '@fuse/utils'
import { Navigate } from 'react-router-dom'

import FuseLoading from '@fuse/core/FuseLoading'
import Error404Page from 'app/main/404/Error404Page'
import LoginConfig from 'components/Login/LoginConfig'
import { ContractModalView } from 'components/Managment/Contract/main-page/ContractModalView'
import { DeliverablesManagementContextProvider } from 'components/Managment/Deliverables/utils/DeliManagementProvider'
import { DepartmentView } from 'components/Managment/Department/main-page/DepartmentView'
import { DataDeliverablesProvider } from 'components/TabsExcel/Deliverables/utils/deliverablesProvider'
import { ImprovementContextProvider } from 'components/TabsExcel/ImprovementProposals2/utils/ImprovementProvider'
import Tasks from 'components/TabsExcel/RiskManagement/modals2/tasks/TasksAppConfig'
import { SkillMatrix } from 'components/TabsExcel/SkillMatrix/SkillMatrix'
import { Training } from 'components/TabsExcel/Training/TrainingMainPage'
import { RequirementsPage } from 'components/Managment/Requirements/RequirementsMainPage'
import { AuthorizationOfInspectors } from 'components/TabsExcel/AuthorizationOfInspectors/AuthorizationInspectorsMainPage'
import React from 'react'


// * INFO
// + Importamos el componente con React.Lazy y su ruta
// + Configuramos su path (ruta que establecemos en navigationConfig) y como element asignamos el componente

//power bi
const Performance = React.lazy(() => import('components/PowerBI/Performance/Performance'))
const PowerBITimeManagement = React.lazy(() => import('components/PowerBI/TimeManagement/TimeManagement'))
const SalesVolume = React.lazy(() => import('components/PowerBI/SalesVolume/SalesVolume'))
const Audits = React.lazy(() => import('components/PowerBI/Audits/Audits'))
const PowerBiSummary = React.lazy(() => import('components/PowerBI/Summary/Summary'))
const PowerBiManagement = React.lazy(() => import('components/Managment/PowerBi_management/PowerBi_management'))



//settings
const Divisiones = React.lazy(() => import('components/Managment/Divisions/division'))
const Programas = React.lazy(() => import('components/Managment/Programs/programa'))
const Users = React.lazy(() => import('components/Managment/Users/Users'))
const Customers = React.lazy(() => import('components/Managment/Customers/Customers'))
const MainApp = React.lazy(() => import('components/Managment/App/MainApp'))
const Process = React.lazy(() => import('components/Managment/Process/Process'))
const Kpi = React.lazy(() => import('components/Managment/Kpi/Kpi'))
const Category = React.lazy(() => import('components/Managment/Category/Category'))
const Department = React.lazy(() => import('components/Gestion/Departamentos/departamentos'))
const Certifications = React.lazy(() => import('components/Managment/Rules/Rules'))
const Log = React.lazy(() => import('components/Managment/Log/Log'))
const LogPersona = React.lazy(() => import('components/Managment/LogCambiosPersonas/LogCambioPersonas'))
const Home = React.lazy(() => import('components/Home/home'))
const Permisos = React.lazy(() => import('components/Managment/Permisos/Permisos'))

const RiskManagementModalView = React.lazy(() => import('components/TabsExcel/RiskManagement/RiskManagementModalView'))
const PeopleManagement = React.lazy(() => import('components/TabsExcel/PeopleManagement/PeopleManagement'))
const SearchUser = React.lazy(() => import('components/TabsExcel/PeopleManagement/Items/SearchUser/SearchUser'))
const ImprovementProposals = React.lazy(() => import('components/TabsExcel/ImprovementProposals/ImprovementProposals'))
const Deliverables = React.lazy(() => import('components/Managment/Deliverables/Deliverables_management.jsx'))
const DataDeliverables = React.lazy(() => import('components/TabsExcel/Deliverables/Deliverables'))
const DataKpi = React.lazy(() => import('components/TabsExcel/DataKpi/DataKpi'))
const ImprovementProposals2 = React.lazy(() => import('components/TabsExcel/ImprovementProposals2/ImprovementProposals'))


const routeConfigs = [
  LoginConfig,
  Tasks
];

const routes = [

  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),



  // ! CAMBIO ESTRUCTURA
  { path: 'pages/modules/authorizationOfInspectors', name: 'authorizationOfInspectors', element: <AuthorizationOfInspectors /> },
  { path: 'pages/management/contract', name: 'Contracts', element: <ContractModalView /> },
  { path: 'pages/management/department', name: 'Departments', element: <DepartmentView /> },
  // ! CAMBIO ESTRUCTURA
  { path: 'pages/modules/skillMatrix', name: 'Skill matrix', element: <SkillMatrix /> },
  { path: 'pages/modules/training', name: 'Trainng', element: <Training /> },
  { path: 'pages/gestiones/divisiones', name: 'Divisiones', element: <Divisiones /> },
  { path: '/home', name: 'Home', element: <Home /> },
  { path: '/', name: 'Home', element: <Home /> },
  { path: 'pages/gestiones/programas', name: 'Programas', element: <Programas /> },
  { path: 'pages/tabsExcel/Riskmanagement', name: 'Risk Management', element: <RiskManagementModalView /> },
  { path: 'pages/tabsExcel/peoplemanagement', name: 'People Management', element: <PeopleManagement /> },
  // ! IMPROVEMENT PROPORSAL
  // { path: 'pages/tabsExcel/improvementProposals', name: 'Improvement Proposals', element: <ImprovementProposals /> },
  { path: 'pages/tabsExcel/improvementProposals', name: 'Improvement Proposals', element: <ImprovementContextProvider><ImprovementProposals2 /> </ImprovementContextProvider> },

  // ! IMPROVEMENT PROPORSAL
  { path: 'pages/tabsExcel/dataDeliverables', name: 'DataDeliverables', element:   <DataDeliverablesProvider><DataDeliverables /></DataDeliverablesProvider> },

  { path: 'pages/management/deliverables', name: 'Deliverables', element: <DeliverablesManagementContextProvider>
 <Deliverables />   </DeliverablesManagementContextProvider> },

  { path: 'pages/tabsExcel/dataKpi', name: 'Data KPI', element: <DataKpi /> },
  { path: 'pages/gestiones/users', name: 'users', element: <Users /> },
  { path: 'pages/gestiones/process', name: 'process', element: <Process /> },
  { path: 'pages/gestiones/kpi', name: 'KPI', element: <Kpi /> },
  { path: 'pages/gestiones/customers', name: 'customers', element: <Customers /> },
  { path: 'pages/gestiones/categories', name: 'categories', element: <Category /> },
  // { path: 'pages/gestiones/requirements', name: 'requirements', element: <MainApp /> },
  { path: 'pages/management/requirements', name: 'requirements', element: <RequirementsPage /> },
  { path: 'pages/gestiones/department', name: 'department', element: <Department /> },
  { path: 'pages/gestiones/rules', name: 'rules', element: <Certifications /> },
  { path: 'pages/gestiones/log', name: 'log', element: <Log /> },
  { path: 'pages/gestiones/logPerson', name: 'logPerson', element: <LogPersona /> },
  { path: 'pages/gestiones/permissionsGroup', name: 'permissionsGroup', element: <Permisos /> },
  { path: 'pages/powerBI/performance', name: 'Performance', element: <Performance /> },
  { path: 'pages/powerBI/TimeManagement', name: 'Time Management', element: <PowerBITimeManagement /> },
  { path: 'pages/powerBI/SalesVolume', name: 'Sales Volume', element: <SalesVolume /> },
  { path: 'pages/powerBI/Summary', name: 'Power Bi summary', element: <PowerBiSummary /> },
  { path: 'pages/gestiones/PowerBiManagement', name: 'Power Bi Management', element: <PowerBiManagement /> },

  { path: 'pages/powerBI/Audits', name: 'FOD Audits', element: <Audits /> },

  { path: 'pages/tabsExcel/staffManagement', name: 'powerBI', element: <SearchUser /> },
  { path: 'loading', element: <FuseLoading /> },
  { path: '404', element: <Error404Page /> },
  { path: '*', element: <Navigate to="404" /> },

];

export default routes;
