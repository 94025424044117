import {

    // COMPONENTE PRINCIPAL ------------------

    POST_REQUEST_USER,
    POST_REQUEST_USER_EXITO,
    POST_REQUEST_USER_ERROR,

    CAMBIAR_VALOR_USER_SELECTED,
    CAMBIAR_VALOR_USER_SELECTED_EXITO,
    CAMBIAR_VALOR_USER_SELECTED_ERROR,

    GET_REQUEST_USER,
    GET_REQUEST_USER_EXITO,
    GET_REQUEST_USER_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {
    listRequestUser: [],
    listUserSearch: [],
    userSelected: '',
    error: null,
    loading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        //**********get request select **********************************/

        case GET_REQUEST_USER:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_REQUEST_USER_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listRequestUser: action.payload
            }
        
        case GET_REQUEST_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********USUARIO SELECTED TABLE **********************************/

        case CAMBIAR_VALOR_USER_SELECTED:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_USER_SELECTED_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                userSelected: action.payload
            }
        
        case CAMBIAR_VALOR_USER_SELECTED_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET USUARIOS CON LOS REQUISITOS CITADOS **********************************/

        case POST_REQUEST_USER:
            return {
                ...state,
                loading: action.payload
            }
        
        case POST_REQUEST_USER_EXITO:
            return {
                ...state,
                loading: false,
                error: null
            }
        
        case POST_REQUEST_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        
        default: 
            return state

    }

}
