export const MISSIONS_TYPES = {
    SET_CREATE_MODAL: "SET_CREATE_MODAL",
    SET_EDIT_MODAL: "SET_EDIT_MODAL", 
    SET_MISSION_SELECTED: "SET_MISSION_SELECTED",
    POST_MISSION: "POST_MISSION",
    PUT_MISSION: "PUT_MISSION",
    POST_MISSION_SUCCESS: "POST_MISSION_SUCCESS",
    POST_MISSION_ERROR: "POST_MISSION_ERROR",
    GET_VISUAL_TIME_GROUPS: "GET_VISUAL_TIME_GROUPS",
    GET_AGRESSO_CODES: "GET_AGRESSO_CODES",
    GET_ALL_MISSIONS: "GET_ALL_MISSIONS",
}

// export const GET_TEAM = 'GET_TEAM'
// export const GET_TEAM_EXITO = 'GET_TEAM_EXITO'
// export const GET_TEAM_ERROR = 'GET_TEAM_ERROR'

// export const ELIMINAR_CONTRACT_USER = 'ELIMINAR_CONTRACT_USER'
// export const ELIMINAR_CONTRACT_USER_EXITO = 'ELIMINAR_CONTRACT_USER_EXITO'
// export const ELIMINAR_CONTRACT_USER_ERROR = 'ELIMINAR_CONTRACT_USER_ERROR'

// export const VER_MODAL_ASSIGNED_PEOPLE = 'VER_MODAL_ASSIGNED_PEOPLE'
// export const VER_MODAL_ASSIGNED_PEOPLE_EXITO = 'VER_MODAL_ASSIGNED_PEOPLE_EXITO'
// export const VER_MODAL_ASSIGNED_PEOPLE_ERROR = 'VER_MODAL_ASSIGNED_PEOPLE_ERROR'

// export const GET_PEOPLE_CONTRATO = 'GET_PEOPLE_CONTRATO'
// export const GET_PEOPLE_CONTRATO_EXITO = 'GET_PEOPLE_CONTRATO_EXITO'
// export const GET_PEOPLE_CONTRATO_ERROR = 'GET_PEOPLE_CONTRATO_ERROR'

// export const POST_PEOPLE_CONTRATO = 'POST_PEOPLE_CONTRATO'
// export const POST_PEOPLE_CONTRATO_EXITO = 'POST_PEOPLE_CONTRATO_EXITO'
// export const POST_PEOPLE_CONTRATO_ERROR = 'POST_PEOPLE_CONTRATO_ERROR'

// export const GET_APP_SUB_MISION = 'GET_APP_SUB_MISION'
// export const GET_APP_SUB_MISION_EXITO = 'GET_APP_SUB_MISION_EXITO'
// export const GET_APP_SUB_MISION_ERROR = 'GET_APP_SUB_MISION_ERROR'

// export const GET_USER_APP_SUB_MISION_CONTRACT = 'GET_USER_APP_SUB_MISION_CONTRACT'
// export const GET_USER_APP_SUB_MISION_CONTRACT_EXITO = 'GET_USER_APP_SUB_MISION_CONTRACT_EXITO'
// export const GET_USER_APP_SUB_MISION_CONTRACT_ERROR = 'GET_USER_APP_SUB_MISION_CONTRACT_ERROR'

// export const GET_ALL_SUB_MISION = 'GET_ALL_SUB_MISION'
// export const GET_ALL_SUB_MISION_EXITO = 'GET_ALL_SUB_MISION_EXITO'
// export const GET_ALL_SUB_MISION_ERROR = 'GET_ALL_SUB_MISION_ERROR'

// export const CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION'
// export const CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_EXITO'
// export const CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_MISION_ERROR'

// export const VER_MODAL_INSERT_SUB_MISION = 'VER_MODAL_INSERT_SUB_MISION'
// export const VER_MODAL_INSERT_SUB_MISION_EXITO = 'VER_MODAL_INSERT_SUB_MISION_EXITO'
// export const VER_MODAL_INSERT_SUB_MISION_ERROR = 'VER_MODAL_INSERT_SUB_MISION_ERROR'

// export const POST_SUB_MISION = 'POST_SUB_MISION'
// export const POST_SUB_MISION_EXITO = 'POST_SUB_MISION_EXITO'
// export const POST_SUB_MISION_ERROR = 'POST_SUB_MISION_ERROR'

// export const PUT_SUB_MISION = 'PUT_SUB_MISION'
// export const PUT_SUB_MISION_EXITO = 'PUT_SUB_MISION_EXITO'
// export const PUT_SUB_MISION_ERROR = 'PUT_SUB_MISION_ERROR'

// export const PUT_PEOPLE_CONTRATO = 'PUT_PEOPLE_CONTRATO'
// export const PUT_PEOPLE_CONTRATO_EXITO = 'PUT_PEOPLE_CONTRATO_EXITO'
// export const PUT_PEOPLE_CONTRATO_ERROR = 'PUT_PEOPLE_CONTRATO_ERROR'

// export const VER_MODAL_ASSIGNED_APP = 'VER_MODAL_ASSIGNED_APP'
// export const VER_MODAL_ASSIGNED_APP_EXITO = 'VER_MODAL_ASSIGNED_APP_EXITO'
// export const VER_MODAL_ASSIGNED_APP_ERROR = 'VER_MODAL_ASSIGNED_APP_ERROR'

// export const POST_CONTRACT_APP = 'POST_CONTRACT_APP'
// export const POST_CONTRACT_APP_EXITO = 'POST_CONTRACT_APP_EXITO'
// export const POST_CONTRACT_APP_ERROR = 'POST_CONTRACT_APP_ERROR'

// export const ELIMINAR_CONTRACT_APP = 'ELIMINAR_CONTRACT_APP'
// export const ELIMINAR_CONTRACT_APP_EXITO = 'ELIMINAR_CONTRACT_APP_EXITO'
// export const ELIMINAR_CONTRACT_APP_ERROR = 'ELIMINAR_CONTRACT_APP_ERROR'

// export const GET_ALL_JOB = 'GET_ALL_JOB'
// export const GET_ALL_JOB_EXITO = 'GET_ALL_JOB_EXITO'
// export const GET_ALL_JOB_ERROR = 'GET_ALL_JOB_ERROR'

// export const VER_MODAL_GESTION_TRABAJOS = 'VER_MODAL_GESTION_TRABAJOS'
// export const VER_MODAL_GESTION_TRABAJOS_EXITO = 'VER_MODAL_GESTION_TRABAJOS_EXITO'
// export const VER_MODAL_GESTION_TRABAJOS_ERROR = 'VER_MODAL_GESTION_TRABAJOS_ERROR'

// export const POST_JOB_SUB_MISION = 'POST_JOB_SUB_MISION'
// export const POST_JOB_SUB_MISION_EXITO = 'POST_JOB_SUB_MISION_EXITO'
// export const POST_JOB_SUB_MISION_ERROR = 'POST_JOB_SUB_MISION_ERROR'

// export const PUT_JOB_SUB_MISION = 'PUT_JOB_SUB_MISION'
// export const PUT_JOB_SUB_MISION_EXITO = 'PUT_JOB_SUB_MISION_EXITO'
// export const PUT_JOB_SUB_MISION_ERROR = 'PUT_JOB_SUB_MISION_ERROR'

// export const GET_ALL_SUB_MISION_DEPARTAMENTO = 'GET_ALL_SUB_MISION_DEPARTAMENTO'
// export const GET_ALL_SUB_MISION_DEPARTAMENTO_EXITO = 'GET_ALL_SUB_MISION_DEPARTAMENTO_EXITO'
// export const GET_ALL_SUB_MISION_DEPARTAMENTO_ERROR = 'GET_ALL_SUB_MISION_DEPARTAMENTO_ERROR'

// export const GET_ALL_SUB_MISION_NO_DEPARTAMENTOS = 'GET_ALL_SUB_MISION_NO_DEPARTAMENTOS'
// export const GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_EXITO = 'GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_EXITO'
// export const GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_ERROR = 'GET_ALL_SUB_MISION_NO_DEPARTAMENTOS_ERROR'