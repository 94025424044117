//******************** COMPONENTE PRINCIPAL *****************************************************


//OBTENER TOKEN
export const OBTENER_TOKEN = 'OBTENER_TOKEN'
export const OBTENER_TOKEN_ERROR = 'OBTENER_TOKEN_ERROR'

//CHECK TOKEN
export const CHECK_TOKEN = 'OBTENER_TOKEN'
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR'

//  SET TOKEN
export const SET_TOKEN = 'SET_TOKEN'
