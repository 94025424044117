import {

    // COMPONENTE PRINCIPAL ------------------


    CAMBIAR_VALOR_TAB_PEOPLE,
    CAMBIAR_VALOR_TAB_PEOPLE_EXITO,
    CAMBIAR_VALOR_TAB_PEOPLE_ERROR,

    VER_MODAL_SOLICITAR_PERSONAL,
    VER_MODAL_SOLICITAR_PERSONAL_EXITO,
    VER_MODAL_SOLICITAR_PERSONAL_ERROR,

    POST_CORREO_SOLICITUD,
    POST_CORREO_SOLICITUD_EXITO,
    POST_CORREO_SOLICITUD_ERROR,

    POST_NUEVA_NOTIFICACION,
    POST_NUEVA_NOTIFICACION_EXITO,
    POST_NUEVA_NOTIFICACION_ERROR,

    POST_INVENTARIO_PCS,
    POST_INVENTARIO_PCS_EXITO,
    POST_INVENTARIO_PCS_ERROR,

    GET_NOTIFICACION,
    GET_NOTIFICACION_EXITO,
    GET_NOTIFICACION_ERROR,

    VER_MODAL_ANADIR_PERSONAL,
    VER_MODAL_ANADIR_PERSONAL_EXITO,
    VER_MODAL_ANADIR_PERSONAL_ERROR,

    POST_NUEVA_PERSONA_TEAM,
    POST_NUEVA_PERSONA_TEAM_EXITO,
    POST_NUEVA_PERSONA_TEAM_ERROR,

    GET_MY_GROUP,
    GET_MY_GROUP_EXITO,
    GET_MY_GROUP_ERROR,

    ELIMINAR_PERSON_TEAM,
    ELIMINAR_PERSON_TEAM_EXITO,
    ELIMINAR_PERSON_TEAM_ERROR,

    GET_SOLICITUD,
    GET_SOLICITUD_EXITO,
    GET_SOLICITUD_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR,

    VER_MODAL_INSERTAR_OBSERVACIONES,
    VER_MODAL_INSERTAR_OBSERVACIONES_EXITO,
    VER_MODAL_INSERTAR_OBSERVACIONES_ERROR,

    PUT_NOTIFICACION,
    PUT_NOTIFICACION_EXITO,
    PUT_NOTIFICACION_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {

    valorTabPeople: 'miPersonal',
    error: null,
    loading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********FILA SELECIONADA GRID **********************************/

         case CAMBIAR_VALOR_TAB_PEOPLE:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_TAB_PEOPLE_EXITO:
            return {
                ...state,
                loading: false,
                valorTabPeople: action.payload

            }
        
        case CAMBIAR_VALOR_TAB_PEOPLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        
        default: 
            return state

    }

}
