
const components = {};

export function registerComponent(name, Component) {
  components[name] = Component;
}


export default function FuseNavItem(props) {
  const C = components[props.type];
  let cantViewClass = {}
  if(!!props?.noPermission || props.item?.childrem?.noPermission || !!props.item?.noPermission){
    cantViewClass= {display: "none"}
  }
  else{
    cantViewClass= {}
  }
  return (
    <div style={cantViewClass}>
      <C {...props}  /> 
    </div>

  )
}
