//**********************IMPORTACIONES****************************

import { React } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";

import {
  verModalDetallesLogRiskAPIAction
} from '../store/actions'

import TableModules from '../../../tables/TableModules'

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({

  customDialogTitle: {
    backgroundColor: 'rgb(37, 47, 62)',
    color: 'rgb(255, 255, 255)',
    marginBottom: '2em'
  }

});

export default function DetallesLogRisk() {

  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(state => state.fuse.logRiskComponente.loading)
  const verModalInsertarObservaciones = useSelector(state => state.fuse.logRiskComponente.visibilidadDiaologoDetallesLogRisk)
  const modoModalInsertarObservaciones = useSelector(state => state.fuse.logRiskComponente.modoDialogoDetallesLogRisk)
  const logRiskListAPI = useSelector(state => state.fuse.logRiskComponente.logRiskListAPI)

  const verModalDetallesLogRiskAPI = (modo, valor) => dispatch(verModalDetallesLogRiskAPIAction(modo, valor))

  const columnasDataTable = [
    { Header: "ID", accessor: "id", sortable: true, type: 'string' },
    { Header: "Action", accessor: "accion", sortable: true, type: 'string' },
    { Header: "Person", accessor: "emailPersona", sortable: true, type: 'list' },
    { Header: "Date", accessor: "fecha_accion", sortable: true, type: 'date' },
    { Header: "Time", accessor: "hora_accion", sortable: true, type: 'string' },
    { Header: "Description", accessor: "description", sortable: true, type: 'string' }
  ]

  const RYO_ACTIONS_COLUMNS = [
    { Header: "ID", accessor: "id", sortable: true, type: 'string' },
    { Header: "Name Action", accessor: "accion_name", sortable: true, type: 'string' },
    { Header: "Action", accessor: "accion", sortable: true, type: 'string' },
    { Header: "Person", accessor: "emailPersona", sortable: true, type: 'list' },
    { Header: "Date", accessor: "fecha_accion", sortable: true, type: 'date' },
    { Header: "Time", accessor: "hora_accion", sortable: true, type: 'string' },
    { Header: "Description", accessor: "description", sortable: true, type: 'string' }
  ]

  return (
    <>

      <Dialog open={verModalInsertarObservaciones} fullWidth maxWidth='lg' onClose={() => { verModalDetallesLogRiskAPI(false, '') }}>

        <DialogTitle classes={{ root: classes.customDialogTitle }} >
          Version history of {modoModalInsertarObservaciones}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: '100%' }}>
            <TableModules rowsProp={logRiskListAPI} columnsProp={modoModalInsertarObservaciones == "R&O actions" ? RYO_ACTIONS_COLUMNS : columnasDataTable} loading={loading} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => verModalDetallesLogRiskAPI(false, '')}>Close</Button>
        </DialogActions>

      </Dialog>
    </>
  );
}

