import {
    SET_ERROR,
    SET_LOADING,
    GET_MANAGEMENT_TABLE_DATA,
    SET_INSERT_MODAL_VISIBILITY,
    SET_KPI_NAME_SELECTED,
    SET_EDIT_MODE_ON,
    SET_DELETE_MODE_ON,
    SET_PRL_AUDIT_LENGTH,
    SET_FOD_AUDIT_LENGTH
} from './types';


// cada reducer tiene su propio state
const initialState = {
    error: false,
    loading: false,
    management_table_data : null,
    insertModalVisibility : false,
    kpiId: null,
    kpiNameSelected: null,
    editModeOn: false,
    deleteModeOn: false,
    lengthPRLAuditRulesManagement: -1,
    lengthFODAuditRulesManagement: -1
}

const resetValues=()=>(
    initialState
    // {
    //     error: false,
    //     loading: false,
    //     management_table_data : null,
    //     insertModalVisibility : false,
    //     kpiId: null,
    //     kpiNameSelected: null,
    //     editModeOn: false,
    //     deleteModeOn: false,
    //     lengthPRLAuditRulesManagement: -1,
    //     lengthFODAuditRulesManagement: -1
    // }
)

export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 

        case SET_ERROR:
            return{
                ...resetValues(),
                error: true,
            }

        case SET_LOADING:
            return{
                ...state,
                loading: true,
            }

        case GET_MANAGEMENT_TABLE_DATA:
            return {
                ...state,
                loading: false,
                management_table_data: action.payload
            }

        case SET_INSERT_MODAL_VISIBILITY:
            return{
                ...state,
                insertModalVisibility: action.payload
            }
        case SET_KPI_NAME_SELECTED:
            return{
                ...state,
                kpiNameSelected: action.payload.kpiName,
                kpiId: action.payload.kpiId
            }
        case SET_EDIT_MODE_ON:
            return{
                ...state,
                editModeOn: action.payload
            }
        case SET_DELETE_MODE_ON:
            return{
                ...state,
                deleteModeOn: action.payload
            }
        case SET_PRL_AUDIT_LENGTH:
            return{
                ...state,
                lengthPRLAuditRulesManagement: action.payload
            }
        case SET_FOD_AUDIT_LENGTH:
            return{
                ...state,
                lengthFODAuditRulesManagement: action.payload
            }


        default:
            return state

    }

}