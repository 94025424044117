//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_TAB_PEOPLE = 'CAMBIAR_VALOR_TAB_PEOPLE'
export const CAMBIAR_VALOR_TAB_PEOPLE_EXITO = 'CAMBIAR_VALOR_TAB_PEOPLE_EXITO'
export const CAMBIAR_VALOR_TAB_PEOPLE_ERROR = 'CAMBIAR_VALOR_TAB_PEOPLE_ERROR'

export const VER_MODAL_SOLICITAR_PERSONAL = 'VER_MODAL_SOLICITAR_PERSONAL'
export const VER_MODAL_SOLICITAR_PERSONAL_EXITO = 'VER_MODAL_SOLICITAR_PERSONAL_EXITO'
export const VER_MODAL_SOLICITAR_PERSONAL_ERROR = 'VER_MODAL_SOLICITAR_PERSONAL_ERROR'

export const POST_CORREO_SOLICITUD = 'POST_CORREO_SOLICITUD'
export const POST_CORREO_SOLICITUD_EXITO = 'POST_CORREO_SOLICITUD_EXITO'
export const POST_CORREO_SOLICITUD_ERROR = 'POST_CORREO_SOLICITUD_ERROR'

export const POST_NUEVA_NOTIFICACION = 'POST_NUEVA_NOTIFICACION'
export const POST_NUEVA_NOTIFICACION_EXITO = 'POST_NUEVA_NOTIFICACION_EXITO'
export const POST_NUEVA_NOTIFICACION_ERROR = 'POST_NUEVA_NOTIFICACION_ERROR'

export const POST_INVENTARIO_PCS = 'POST_INVENTARIO_PCS'
export const POST_INVENTARIO_PCS_EXITO = 'POST_INVENTARIO_PCS_EXITO'
export const POST_INVENTARIO_PCS_ERROR = 'POST_INVENTARIO_PCS_ERROR'

export const GET_NOTIFICACION = 'GET_NOTIFICACION'
export const GET_NOTIFICACION_EXITO = 'GET_NOTIFICACION_EXITO'
export const GET_NOTIFICACION_ERROR = 'GET_NOTIFICACION_ERROR'

export const VER_MODAL_ANADIR_PERSONAL = 'VER_MODAL_ANADIR_PERSONAL'
export const VER_MODAL_ANADIR_PERSONAL_EXITO = 'VER_MODAL_ANADIR_PERSONAL_EXITO'
export const VER_MODAL_ANADIR_PERSONAL_ERROR = 'VER_MODAL_ANADIR_PERSONAL_ERROR'

export const GET_MY_GROUP = 'GET_MY_GROUP'
export const GET_MY_GROUP_EXITO = 'GET_MY_GROUP_EXITO'
export const GET_MY_GROUP_ERROR = 'GET_MY_GROUP_ERROR'

export const GET_SOLICITUD = 'GET_SOLICITUD'
export const GET_SOLICITUD_EXITO = 'GET_SOLICITUD_EXITO'
export const GET_SOLICITUD_ERROR = 'GET_SOLICITUD_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD = 'CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD'
export const CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR'

export const VER_MODAL_INSERTAR_OBSERVACIONES = 'VER_MODAL_INSERTAR_OBSERVACIONES'
export const VER_MODAL_INSERTAR_OBSERVACIONES_EXITO = 'VER_MODAL_INSERTAR_OBSERVACIONES_EXITO'
export const VER_MODAL_INSERTAR_OBSERVACIONES_ERROR = 'VER_MODAL_INSERTAR_OBSERVACIONES_ERROR'

export const PUT_NOTIFICACION = 'PUT_NOTIFICACION'
export const PUT_NOTIFICACION_EXITO = 'PUT_NOTIFICACION_EXITO'
export const PUT_NOTIFICACION_ERROR = 'PUT_NOTIFICACION_ERROR'

export const GET_USER_ACCESO_PEOPLE_MANAGEMENT = 'GET_USER_ACCESO_PEOPLE_MANAGEMENT'
export const GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO = 'GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO'
export const GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR = 'GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR'

export const GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION = 'GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION'
export const GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO = 'GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO'
export const GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR = 'GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR'

export const SET_MEMBER_SELECT_PEOPLE = 'SET_MEMBER_SELECT_PEOPLE'
export const SET_MEMBER_SELECT_PEOPLE_EXITO = 'SET_MEMBER_SELECT_PEOPLE_EXITO'
export const SET_MEMBER_SELECT_PEOPLE_ERROR = 'SET_MEMBER_SELECT_PEOPLE_ERROR'

export const SET_SUB_MISION_SELECT_MY_TEAM= 'SET_SUB_MISION_SELECT_MY_TEAM'
export const SET_SUB_MISION_SELECT_MY_TEAM_EXITO = 'SET_SUB_MISION_SELECT_MY_TEAM_EXITO'
export const SET_SUB_MISION_SELECT_MY_TEAM_ERROR = 'SET_SUB_MISION_SELECT_MY_TEAM_ERROR'

export const GET_MY_MANAGER = 'GET_MY_MANAGER'
export const GET_MY_MANAGER_EXITO = 'GET_MY_MANAGER_EXITO'
export const GET_MY_MANAGER_ERROR = 'GET_MY_MANAGER_ERROR'

export const GET_ESTRUCTURA_PERSONAL = 'GET_ESTRUCTURA_PERSONAL'
export const GET_ESTRUCTURA_PERSONAL_EXITO = 'GET_ESTRUCTURA_PERSONAL_EXITO'
export const GET_ESTRUCTURA_PERSONAL_ERROR = 'GET_ESTRUCTURA_PERSONAL_ERROR'

export const GET_USER_ESTRUCTURA_PERSONAL = 'GET_USER_ESTRUCTURA_PERSONAL'
export const GET_USER_ESTRUCTURA_PERSONAL_EXITO = 'GET_USER_ESTRUCTURA_PERSONAL_EXITO'
export const GET_USER_ESTRUCTURA_PERSONAL_ERROR = 'GET_USER_ESTRUCTURA_PERSONAL_ERROR'

export const GET_ESTRUCTURA_PERSONAL_SECUNDARIA = 'GET_ESTRUCTURA_PERSONAL_SECUNDARIA'
export const GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO = 'GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO'
export const GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR = 'GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR'

export const SET_SUB_MISION_ESTRUCTURA_SELECCIONADA = 'SET_SUB_MISION_ESTRUCTURA_SELECCIONADA'
export const SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO = 'SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO'
export const SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR = 'SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR'