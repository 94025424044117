export const STRUCTURE_CATEGORY_TYPES_NAMES={
    division: "Division",
    subdivision: "Department directorate",
    departmentDirectorate: "Subdivision",
    program: "Program",
    service: "Service",
    department: "Department",
    workPackage: "Work package",
    mission: "Mission",
    submission: "Sub mission",
}



export const STRUCTURE_FATHERS_CATEGORY_TYPES_NAMES={
    
    [STRUCTURE_CATEGORY_TYPES_NAMES.division]: null,

    [STRUCTURE_CATEGORY_TYPES_NAMES.subdivision]: STRUCTURE_CATEGORY_TYPES_NAMES.division,

    [STRUCTURE_CATEGORY_TYPES_NAMES.departmentDirectorate]: STRUCTURE_CATEGORY_TYPES_NAMES.subdivision,

    [STRUCTURE_CATEGORY_TYPES_NAMES.program]: STRUCTURE_CATEGORY_TYPES_NAMES.subdivision,

    [STRUCTURE_CATEGORY_TYPES_NAMES.service]:  STRUCTURE_CATEGORY_TYPES_NAMES.program,

    [STRUCTURE_CATEGORY_TYPES_NAMES.department]: STRUCTURE_CATEGORY_TYPES_NAMES.departmentDirectorate,

    departamentalWP : STRUCTURE_CATEGORY_TYPES_NAMES.department,

    noDepartamentalWP: STRUCTURE_CATEGORY_TYPES_NAMES.service,


    [STRUCTURE_CATEGORY_TYPES_NAMES.mission]: STRUCTURE_CATEGORY_TYPES_NAMES.workPackage,

    [STRUCTURE_CATEGORY_TYPES_NAMES.submission]: STRUCTURE_CATEGORY_TYPES_NAMES.mission,
}


export const STORE_CATEGORY_NAMES = {

    [STRUCTURE_CATEGORY_TYPES_NAMES.division]: {
        idsSectionName: ["divisionsIds"],
        infoSectionName: ["divisionsInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.subdivision]: {
        idsSectionName: ["subdivisionsIds"],
        infoSectionName: ["subdivisionsInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.departmentDirectorate]: {
        idsSectionName: ["departmentDiectoratesIds"],
        infoSectionName: ["departmentDiectoratesInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.program]: {
        idsSectionName: ["programsIds"],
        infoSectionName: ["programsInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.service]: {
        idsSectionName: ["servicesIds"],
        infoSectionName: ["servicesInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.department]: {
        idsSectionName: ["departmentsIds"],
        infoSectionName: ["departmentsInfo"]
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.workPackage]: {
        idsSectionName: ["wpsIds", "departamentalAndNoDepartamentalWpsIds"],
        infoSectionName: ["wpsInfo", "departamentalAndNoDepartamentalWpsInfo"],
        
        departamental: {
            idsSectionName: ["departamentalWpsIds", "departamentalAndNoDepartamentalWpsIds"],
            infoSectionName: ["departamentalWpsInfo", "departamentalAndNoDepartamentalWpsInfo"]
        },
    },
    
    [STRUCTURE_CATEGORY_TYPES_NAMES.mission]: {
        idsSectionName: ["missionsIds","departamentalAndNoDepartamentalMissionsIds"],
        infoSectionName: ["missionsInfo", "departamentalAndNoDepartamentalMissionsInfo"],
        
        departamental: {
            idsSectionName: ["departamentalMissionsIds", "departamentalAndNoDepartamentalMissionsIds"],
            infoSectionName: ["departamentalMissionsInfo", "departamentalAndNoDepartamentalMissionsInfo"]
        },
    },
    [STRUCTURE_CATEGORY_TYPES_NAMES.submission]: {
        idsSectionName: ["submissionsIds", "departamentalAndNoDepartamentalSubMissionsIds"],
        infoSectionName: ["submissionsInfo", "departamentalAndNoDepartamentalSubMissionsInfo"],

        departamental: {
            idsSectionName: ["departamentalSubmissionsIds", "departamentalAndNoDepartamentalSubMissionsIds"],
            infoSectionName: ["departamentalSubmissionsInfo", "departamentalAndNoDepartamentalSubMissionsInfo"]
        },
    },

}








