
export const GET_SUB_CONJUNTO = 'GET_SUB_CONJUNTO'
export const GET_SUB_CONJUNTO_EXITO = 'GET_SUB_CONJUNTO_EXITO'
export const GET_SUB_CONJUNTO_ERROR = 'GET_SUB_CONJUNTO_ERROR'

export const POST_SUB_CONJUNTO = 'POST_SUB_CONJUNTO'
export const POST_SUB_CONJUNTO_EXITO = 'POST_SUB_CONJUNTO_EXITO'
export const POST_SUB_CONJUNTO_ERROR = 'POST_SUB_CONJUNTO_ERROR'

export const PUT_SUB_CONJUNTO = 'PUT_SUB_CONJUNTO'
export const PUT_SUB_CONJUNTO_EXITO = 'PUT_SUB_CONJUNTO_EXITO'
export const PUT_SUB_CONJUNTO_ERROR = 'PUT_SUB_CONJUNTO_ERROR'

export const CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO = 'CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO'
export const CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO = 'CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO_ERROR = 'CAMBIAR_VALOR_VISIBILIDAD_SUB_CONJUNTO'

export const CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO'
export const CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_SUB_CONJUNTO_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUB_CONJUNTO_ERROR'
