import { lazy, Suspense, useState } from 'react'
import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { CONTAINER_STYLES } from 'components/TabsExcel/SkillMatrix/utils/consts';
import { InspectorsTopButtoms } from './Components/TopButtoms';
import { CreateInspectorModal } from './Modals/CreateInspector';




export const AuthorizationOfInspectors = () => {


    


    return (
        <FusePageCarded

            content={
                    <Box >
                      
                    <InspectorsTopButtoms />
                    <CreateInspectorModal />
                    </Box>

            }
        />
    );
}
