//******************** COMPONENTE PRINCIPAL *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION = 'CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION'
export const CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_EXITO = 'CAMBIAR_VALOR_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_ERROR = 'CAMBIAR_VALOR_ERROR'

// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION = 'CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION'
export const CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_SUBDIVISION = 'ELIMINAR_SUBDIVISION'
export const ELIMINAR_SUBDIVISION_EXITO = 'ELIMINAR_SUBDIVISION_EXITO'
export const ELIMINAR_SUBDIVISION_ERROR = 'ELIMINAR_SUBDIVISION_ERROR'

//CONSULTA A API PROGRAMAS PARA GRID
export const MOSTRAR_SUBDIVISION_API = 'MOSTRAR_SUBDIVISION_API'
export const MOSTRAR_SUBDIVISION_EXITO = 'MOSTRAR_SUBDIVISION_EXITO'
export const MOSTRAR_SUBDIVISION_ERROR = 'MOSTRAR_SUBDIVISION_ERROR'




//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR'

//INSERTAR NUEVO PROGRAMA
export const INSERTAR_SUBDIVISION_MODAL_INSERTAR = 'INSERTAR_SUBDIVISION_MODAL_INSERTAR'
export const INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO = 'INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO'
export const INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR = 'INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR'

//ACTUALIZAR NUEVO PROGRAMA
export const ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR = 'ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR'
export const ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO = 'ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO'
export const ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR = 'ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR'

//CONSULTA LAS SUBDIVISIONES
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR = 'CONSULTA_SUBDIVISIONES_MODAL'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_EXITO'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_ERROR'
