import {

    // COMPONENTE PRINCIPAL ------------------

    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION,
    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION,
    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR,

    ELIMINAR_SUBDIVISION,
    ELIMINAR_SUBDIVISION_EXITO,
    ELIMINAR_SUBDIVISION_ERROR,

    MOSTRAR_SUBDIVISION_API,
    MOSTRAR_SUBDIVISION_EXITO,
    MOSTRAR_SUBDIVISION_ERROR,

    // MODAL INSERTAR  -----------------------

    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR,

    INSERTAR_SUBDIVISION_MODAL_INSERTAR,
    INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR,

    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR,
    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO,
    ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR

} from './types';


// cada reducer tiene su propio state
const initialState = {
        newSDCreated: '',
        visibilidad: true,
        subdivisionesListAPI: [],
        filaSeleccionadaGrid: '',
        visibilidadModalInsertar: false,
        modo: '',
        error: null,
        loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {

     //**********VISIBILIDAD**********************************/

        case CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_EXITO:
            return {
                ...state,
                loading: false,
                visibilidad: action.payload

            }
        
        case CAMBIAR_VALOR_VISIBILIDAD_SUBDIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        

     //**********FILA SELECIONADA GRID **********************************/

         case CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_EXITO:
            return {
                ...state,
                loading: false,
                filaSeleccionadaGrid: action.payload

            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_SUBDIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

     //********** ELIMINAR PROGRAMA **********************************/ 


     case ELIMINAR_SUBDIVISION:
        return {
            ...state,
            loading: action.payload
        }
    
    case ELIMINAR_SUBDIVISION_EXITO:
        return {
            ...state,
            loading: false,
            error: null
            

        }
    
    case ELIMINAR_SUBDIVISION_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


     //********** MOSTRAR PROGRAMAS API**********************************/ 


        case MOSTRAR_SUBDIVISION_API:
            return {
                ...state,
                loading: action.payload
            }
        
        case MOSTRAR_SUBDIVISION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                subdivisionesListAPI: action.payload

            }
        
        case MOSTRAR_SUBDIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
    
     //**********VISIBILIDAD MODAL INSERTAR**********************************/

       case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadModalInsertar: action.payload,
                modo: action.visibilidadModal
            }
        
        case CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_SUBDIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        

      
     //********** INSERTAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case INSERTAR_SUBDIVISION_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case INSERTAR_SUBDIVISION_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                newSDCreated: action.payload
            }
        
        case INSERTAR_SUBDIVISION_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** ACTUALIZAR PROGRAMA API MODAL INSERTAR**********************************/ 


        case ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR:
            return {
                ...state,
                loading: action.payload
            }
        
        case ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_EXITO:
            return {
                ...state,
                loading: false,
                error: null

            }
        
        case ACTUALIZAR_SUBDIVISION_MODAL_INSERTAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default: 
            return state

    }

}