import { lazy, Suspense, useState } from 'react'

import FusePageCarded from '@fuse/core/FusePageCarded'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader.jsx'
import { DEFAULT_TAB_VALUE_PROPS, DEPARTMENT_TAB_LABEL, DEPARTMENT_TABS_VALUES } from './utils/consts.js'

const Directorate = lazy(() => import('../Directorate/Directorate.jsx'))
const Departments = lazy(() => import('../Departments/Departments.jsx'))
const Area = lazy(() => import('../Area/Area.jsx'))
const Team = lazy(() => import('../Team/Team.jsx'))
const Technician = lazy(() => import('../Technician/Technician.jsx'))

export const DepartmentView = () => {
    const [tabvalue, setTabValue] = useState(DEPARTMENT_TABS_VALUES.directorate)

    const modalView = {
        [DEPARTMENT_TABS_VALUES.directorate]:
            <Suspense fallback={<LinearLoader />}>
                <Directorate />
            </Suspense>,

        [DEPARTMENT_TABS_VALUES.departments]:
            <Suspense fallback={<LinearLoader />}>
                <Departments />
            </Suspense>,

        [DEPARTMENT_TABS_VALUES.area]:
            <Suspense fallback={<LinearLoader />}>
                <Area />
            </Suspense>,

        [DEPARTMENT_TABS_VALUES.team]:
            <Suspense fallback={<LinearLoader />}>
                <Team />
            </Suspense>,

        [DEPARTMENT_TABS_VALUES.technician]:
            <Suspense fallback={<LinearLoader />}>
                <Technician />
            </Suspense>
    }

    return (
        <FusePageCarded
            content={
                <Box sx={{ width: '100%' }}>

                    <Tabs
                        value={tabvalue}
                        onChange={(event, value) => setTabValue(value)}
                        {...DEFAULT_TAB_VALUE_PROPS}
                    >
                        <Tab value={DEPARTMENT_TABS_VALUES.directorate} label={DEPARTMENT_TAB_LABEL.directorate} />
                        <Tab value={DEPARTMENT_TABS_VALUES.departments} label={DEPARTMENT_TAB_LABEL.departments} />
                        <Tab value={DEPARTMENT_TABS_VALUES.area} label={DEPARTMENT_TAB_LABEL.area} />
                        <Tab value={DEPARTMENT_TABS_VALUES.team} label={DEPARTMENT_TAB_LABEL.team} />
                        <Tab value={DEPARTMENT_TABS_VALUES.technician} label={DEPARTMENT_TAB_LABEL.technician} />
                    </Tabs>
                    {modalView[tabvalue]}

                </Box>
            }
        />
    )
}
