//******************** COMPONENTE PRINCIPAL *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_VALOR_VISIBILIDAD_DIVISION = 'CAMBIAR_VALOR_VISIBILIDAD_DIVISION'
export const CAMBIAR_VALOR_VISIBILIDAD_DIVISION_EXITO = 'CAMBIAR_VALOR_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_DIVISION_ERROR = 'CAMBIAR_VALOR_ERROR'

// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_DIVISION = 'CAMBIAR_VALOR_SELECCION_GRID_DIVISION'
export const CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_DIVISION_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_DIVISION_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_DIVISION = 'ELIMINAR_DIVISION'
export const ELIMINAR_DIVISION_EXITO = 'ELIMINAR_DIVISION_EXITO'
export const ELIMINAR_DIVISION_ERROR = 'ELIMINAR_DIVISION_ERROR'

//CONSULTA A API PROGRAMAS PARA GRID
export const MOSTRAR_DIVISION_API = 'MOSTRAR_DIVISION_API'
export const MOSTRAR_DIVISION_EXITO = 'MOSTRAR_DIVISION_EXITO'
export const MOSTRAR_DIVISION_ERROR = 'MOSTRAR_DIVISION_ERROR'




//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DIVISION_ERROR'

//INSERTAR NUEVO PROGRAMA
export const INSERTAR_DIVISION_MODAL_INSERTAR = 'INSERTAR_DIVISION_MODAL_INSERTAR'
export const INSERTAR_DIVISION_MODAL_INSERTAR_EXITO = 'INSERTAR_DIVISION_MODAL_INSERTAR_EXITO'
export const INSERTAR_DIVISION_MODAL_INSERTAR_ERROR = 'INSERTAR_DIVISION_MODAL_INSERTAR_ERROR'

//ACTUALIZAR NUEVO PROGRAMA
export const ACTUALIZAR_DIVISION_MODAL_INSERTAR = 'ACTUALIZAR_DIVISION_MODAL_INSERTAR'
export const ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO = 'ACTUALIZAR_DIVISION_MODAL_INSERTAR_EXITO'
export const ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR = 'ACTUALIZAR_DIVISION_MODAL_INSERTAR_ERROR'

//CONSULTA LAS SUBDIVISIONES
export const CONSULTA_DIVISIONES_MODAL_INSERTAR = 'CONSULTA_DIVISIONES_MODAL'
export const CONSULTA_DIVISIONES_MODAL_INSERTAR_EXITO = 'CONSULTA_DIVISIONES_MODAL_INSERTAR_EXITO'
export const CONSULTA_DIVISIONES_MODAL_INSERTAR_ERROR = 'CONSULTA_DIVISIONES_MODAL_INSERTAR_ERROR'