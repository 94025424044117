//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES = 'CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES'
export const CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_DELIVERABLES_ERROR'

//CONSULTA A API IMPROVEMENT_PROPOSALS PARA GRID
export const MOSTRAR_DELIVERABLES = 'MOSTRAR_DELIVERABLES'
export const MOSTRAR_DELIVERABLES_EXITO = 'MOSTRAR_DELIVERABLES_EXITO'
export const MOSTRAR_DELIVERABLES_ERROR = 'MOSTRAR_DELIVERABLES_ERROR'

//UPDATE IMPROVEMENT_PROPOSALS
export const UPDATE_DELIVERABLES = 'UPDATE_DELIVERABLES'
export const UPDATE_DELIVERABLES_EXITO = 'UPDATE_DELIVERABLES_EXITO'
export const UPDATE_DELIVERABLES_ERROR = 'UPDATE_DELIVERABLES_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_DELIVERABLE_ERROR'

//INSERTAR NUEVO RISK_MANAGER
export const INSERTAR_DELIVERABLES = 'INSERTAR_DELIVERABLES'
export const INSERTAR_DELIVERABLES_EXITO = 'INSERTAR_DELIVERABLES_EXITO'
export const INSERTAR_DELIVERABLES_ERROR = 'INSERTAR_DELIVERABLES_ERROR'

export const ELIMINAR_DELIVERABLE = 'ELIMINAR_DELIVERABLE'
export const ELIMINAR_DELIVERABLE_EXITO = 'ELIMINAR_DELIVERABLE_EXITO'
export const ELIMINAR_DELIVERABLE_ERROR = 'ELIMINAR_DELIVERABLE_ERROR'
