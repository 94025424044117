//UPDATE RISK_MANAGEMENT
export const MOSTRAR_KPI_PERFORMANCE = 'MOSTRAR_KPI_PERFORMANCE'
export const MOSTRAR_KPI_PERFORMANCE_EXITO = 'MOSTRAR_KPI_PERFORMANCE_EXITO'
export const MOSTRAR_KPI_PERFORMANCE_ERROR = 'MOSTRAR_KPI_PERFORMANCE_ERROR'
export const DATOS_CONSULTA_PERFORMANCE = "DATOS_CONSULTA_PERFORMANCE"
export const SET_PEOPLE_TRANSFERRED_LOADING = "SET_PEOPLE_TRANSFERRED_LOADING"
export const SET_PEOPLE_TRANSFERRED_RESPONSE = "SET_PEOPLE_TRANSFERRED_RESPONSE"
export const SET_PEOPLE_TRANSFERRED_ERROR = "SET_PEOPLE_TRANSFERRED_ERROR"
export const SET_ABC_LOADING = "SET_ABC_LOADING"
export const SET_ABC_RESPONSE = "SET_ABC_RESPONSE"
export const SET_ABC_ERROR = "SET_ABC_ERROR"
export const SET_EMPTY_VALUES_IN_PERFORMANCE = "SET_EMPTY_VALUES_IN_PERFORMANCE"





