import {
    GET_SUMMARY_VALUES
} from './types';


// cada reducer tiene su propio state
const initialState = {
    summaryValues : [],
}


export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 

        case GET_SUMMARY_VALUES:
            return {
                ...state,
                summaryValues: action.payload
            }


        default:
            return state

    }

}