//**********************IMPORTACIONES****************************

import { useEffect, useState, useRef } from 'react'
import {  useLocation } from "react-router-dom";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//Grid importaciones

import Grid from '@mui/material/Grid';

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux'

import {
  insertarNuevaTarjetaKanbanAPIAction,
  cambiarValorVisibilidadAction
} from '../store/actions'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
//**********************END_IMPORTACIONES ***********************/


const useStyles = makeStyles({

  customDialogTitle: {
    backgroundColor: 'rgb(37, 47, 62)',
    color: 'rgb(255, 255, 255)',
    marginBottom: '2em'
  }

});

export default function ModalInsertar() {

  const classes = useStyles();
  const dispatch = useDispatch()

  const location = useLocation();

  //estados locales del formulario
  const [tituloActual, setTituloActual] = useState('')
  const [descripcionActual, setDescripcionActual] = useState('')
  const [moduloActual, setModuloActual] = useState('')
  const [tipoIncidencia, setTipoIncidencia] = useState('');
  const [file, setFile] = useState(null)
  const [botonControlSave, setBotonControl] = useState(true);

  const inputFile = useRef(null)



  //TAB DE CONTRACT
  const valorTabContract = useSelector(state => state.fuse.programasView.valorTab)

  //TABS DE DIVISION
  const valorTabDivision = useSelector(state => state.fuse.divisionViewComponente.valorTabDivision)

  // Obtener los states de Redux
  const visibilidadModalInsertarCard = useSelector(state => state.fuse.kanbanComponente.visibilidadModalInsertarCard)
  const personLogin = useSelector(state => state.fuse.userComponente.person)

  //Creamos funciones para hacer uso de Actions Redux
  const cambiarValorVisibilidad = (valorNuevo) => dispatch(cambiarValorVisibilidadAction(valorNuevo))
  const insertarNuevaTarjetaKanbanAPI = (data) => dispatch(insertarNuevaTarjetaKanbanAPIAction(data))

  useEffect(() => {
    if (tituloActual.trim() != '' && descripcionActual.trim() != '' && moduloActual.trim() != '') {
      setBotonControl(false)

    } else {
      setBotonControl(true)

    }
  })

  function funcionModuloDivision() {
    switch (valorTabDivision) {
      case "division":
        setModuloActual("Division")
        break;
      case "subDivision":
        setModuloActual("Sub Division")
        break;
      case "programa":
        setModuloActual("Program")
        break;
      case "conjunto":
        setModuloActual("Asembly")
        break;
      case "subconjunto":
        setModuloActual("Sub Asembly")
        break;
    }
  }

  function funcionModuloContrato() {
    switch (valorTabContract) {
      case "contrato":
        setModuloActual("Contract/Service")
        break;
      case "workPackage":
        setModuloActual("Work Package")
        break;
      case "mision":
        setModuloActual("Mission")
        break;
      case "subMision":
        setModuloActual("Sub Mission")
        break;
    }
  }

  function gestionModuloActual() {
    let moduleActual = location.pathname.split("/")

    if (moduleActual[3] != undefined) {
      switch (moduleActual[3]) {
        case "riskmanagement":
          setModuloActual("Risk")
          break;
        case "peoplemanagement":
          setModuloActual("People - My information")
          break;
        case "staffManagement":
            setModuloActual("People - Staff Management")
            break;
        case "improvementProposals":
          setModuloActual("Improvement Proposals")
          break;
        case "dataKpi":
          setModuloActual("Data KPI")
          break;
        case "divisiones":
          funcionModuloDivision()
          break;
        case "programas":
          funcionModuloContrato()
          break;
        case "logPerson":
          setModuloActual("Movements of people")
          break;

        default:
          setModuloActual(moduleActual[3].charAt(0).toUpperCase() + moduleActual[3].slice(1))
          break
      }
    }
    else{
      setModuloActual("Home")
    }


  }

  useEffect(() => gestionModuloActual() , [valorTabDivision, valorTabContract, visibilidadModalInsertarCard])

  useEffect(() =>  gestionModuloActual() , [])



  const resetValues =()=>{
    [setTituloActual, setDescripcionActual, setTipoIncidencia].forEach(set => set(''))
    setFile(null)
  }
  
  function crearTarjeta() {
    insertarNuevaTarjetaKanbanAPI({
      title: tituloActual,
      tipo: tipoIncidencia,
      usuario: personLogin.email,
      module: moduloActual,
      description: descripcionActual,
      file: file
    })

    cambiarValorVisibilidad(false);
    resetValues()
  }





  return (
    <Dialog open={visibilidadModalInsertarCard} onClose={() => cambiarValorVisibilidad(false)} fullWidth maxWidth='sm'>
      <DialogTitle classes={{ root: classes.customDialogTitle }} >
        Contact with Support
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={2} columns={8} style={{ marginTop: "2px" }}>
          <Grid item xs={4}>

            <TextField
              label="Module"
              id="nombre"
              value={moduloActual}
              size="small"
              fullWidth
              disabled
              onChange={e => setModuloActual(e.target.value)}
            />

          </Grid>

          <Grid item xs={4}>

            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Support request</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Support request"
                size="small"
                fullWidth
                onChange={e => setTipoIncidencia(e.target.value)}
              >
                <MenuItem value={"Report bug"}>Report bug</MenuItem>
                <MenuItem value={"Improvement"}>Improvement</MenuItem>
                <MenuItem value={"Opinion"}>Opinion</MenuItem>
              </Select>
            </FormControl>

          </Grid>

          <Grid item xs={8}>

            <TextField
              label="Subject"
              id="title"
              value={tituloActual}
              size="small"
              fullWidth
              onChange={e => setTituloActual(e.target.value)}
            />

          </Grid>
          <Grid item xs={8}>

            <TextField
              id="descripcion"
              label="Message"
              multiline
              rows={5}
              value={descripcionActual}
              size="small"
              fullWidth
              onChange={e => setDescripcionActual(e.target.value)}
            />

          </Grid>

          <Grid item xs={8}>

            <div className='d-flex align-items-center'>
              <AddAPhotoIcon onClick={() => inputFile.current.click()} style={{ cursor: 'pointer' }} />
              <input
                ref={inputFile}
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={(e) => setFile(e.target.files[0])}
              />
              <label className='ml-3'>{!file ? 'Select a file' : 'File upload'}</label>
            </div>

          </Grid>

        </Grid>



      </DialogContent>
      <DialogActions>


        <Button onClick={() => cambiarValorVisibilidad(false)}>Close</Button>
        <Button disabled={botonControlSave} onClick={() => crearTarjeta()}>Save</Button>

      </DialogActions>
    </Dialog>
  )
}

