import { SUBMISSIONS_TYPES } from "./types"
import { findIndexSectionById } from "app/js/generalFunctions"
const INITIAL_STATE = {
    createModal: false,
    editModal: false,
    jobModal: false,
    peopleModal: false,
    submissionSelected: null,
    jobsInSubmissionSelected: [],
    jobsInSubmissionToSwitch:[],
    peopleInSubmissionSelected: [],
    peopleInSubmissionToSwitch: [],
    allSubmissionsList: [],
    departamentalSubmissionsList: [],
    noDepartamentalSubmissionsList: [],
    loadingSubMissions: true,

}

export default function (state = INITIAL_STATE, action) {
    const { SET_CREATE_MODAL, SET_EDIT_MODAL, SET_JOB_MODAL, SET_PEOPLE_MOAL, SET_SUBMISSIONS_SELECTED, SET_JOB_IN_SUBMISSION_SELECTED, GET_PEOPLE_IN_SUBMISSION_SELECTED, SET_JOB_IN_SUBMISSION_TO_SWITCH, GET_ALL_SUBMISSIONS, RESET_SUBMISSION_STORE_VALUES, GET_PEOPLE_IN_SUBMISSION_SWITCH, POST_SUBMISSION,  PUT_SUBMISSION } = SUBMISSIONS_TYPES
    const { type, payload } = action

    const SUBMISSIONS_ACTIONS = {

        [SET_CREATE_MODAL]: (state, action)=>({
            ...state,
            createModal: action.payload
        }),

        [SET_EDIT_MODAL]: (state, action)=>({
            ...state,
            editModal: action.payload
        }),

        [SET_JOB_MODAL]: (state, action)=>({
            ...state,
            jobModal: action.payload
        }),

        [SET_PEOPLE_MOAL]: (state, action)=>({
            ...state,
            peopleModal: action.payload
        }),

        [SET_SUBMISSIONS_SELECTED]: (state, action)=>({
            ...state,
            submissionSelected: action.payload
        }),

        [SET_JOB_IN_SUBMISSION_SELECTED]: (state, action)=>({
            ...state,
            jobsInSubmissionSelected: action.payload
        }),

        [SET_JOB_IN_SUBMISSION_TO_SWITCH]: (state, action)=>({
            ...state,
            jobsInSubmissionToSwitch: action.payload
        }),

        [GET_PEOPLE_IN_SUBMISSION_SELECTED]: (state, action)=>({
            ...state,
            peopleInSubmissionSelected: action.payload
        }),

        [GET_PEOPLE_IN_SUBMISSION_SWITCH]: (state, action)=>({
            ...state,
            peopleInSubmissionToSwitch: action.payload
        }),


        [GET_ALL_SUBMISSIONS]: (state, action)=>{
            const dptoAndNodpto = action.payload.reduce((acc, sub) =>{
                if(sub.esDepartamento) acc.dpto = [...acc.dpto, sub]
                else acc.noDpto = [...acc.noDpto, sub]
                return acc;
            }, {dpto: [], noDpto: []})

            return{
                ...state,
                allSubmissionsList: action.payload,
                departamentalSubmissionsList: dptoAndNodpto.dpto,
                noDepartamentalSubmissionsList: dptoAndNodpto.noDpto,
                loadingSubMissions: false
            }
        },

        [POST_SUBMISSION]: (state, action)=>{
            return {
                ...state,
                allSubmissionsList: [action.payload,...state.allSubmissionsList],
                noDepartamentalSubmissionsList: [action.payload,...state.noDepartamentalSubmissionsList],

            }
        },

        [PUT_SUBMISSION]: (state, action)=>{
            const newNoDptoSubsList = state.noDepartamentalSubmissionsList.with(action.payload.indexValue, action.payload)

            const indexInSubsList = findIndexSectionById({sectionList: state.allSubmissionsList, sectionToFindInList: action.payload})
            const newAllList = state.allSubmissionsList.with(indexInSubsList, action.payload)
            return {
                ...state,
                allSubmissionsList: newAllList,
                noDepartamentalSubmissionsList: newNoDptoSubsList,

            }
        },

        [RESET_SUBMISSION_STORE_VALUES]: (state, action)=> ({...INITIAL_STATE, allSubmissionsList: state.allSubmissionsList, submissionSelected: state.submissionSelected, departamentalSubmissionsList: state.departamentalSubmissionsList, noDepartamentalSubmissionsList: state.noDepartamentalSubmissionsList})
    }

    return SUBMISSIONS_ACTIONS[type] ? SUBMISSIONS_ACTIONS[type](state,action) : state
}