import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';

//IMPORTACIÓN DE REDUCERS VISTAS
import programasViewReducer from '../../../components/Managment/Programs/store/reducer'
import divisionViewReducer from '../../../components/Managment/Divisions/store/reducer'



//IMPORTACIÓN DE REDUCERS COMPONENTES GENERALES
import homeReducer from '../../../components/Home/store/reducer'
import programaReducer from '../../../components/Gestion/Programa/store/reducer'
import conjuntoReducer from '../../../components/Gestion/Conjunto/store/reducer'
import subDivisionReducer from '../../../components/Gestion/SubDivision/store/reducer'
import divisionReducer from '../../../components/Gestion/Division/store/reducer'
import subConjuntoReducer from '../../../components/Gestion/SubConjunto/store/reducer'
// ! Change contract
import contratoReducer from '../../../components/Gestion/ContratoServicio/store/reducer'

import contractServiceReducer from '../../../components/Managment/Contract/ContractService/store/reducer'
import workPackageReducer from  '../../../components/Managment/Contract/WorkPackage/store/reducer'
import missionsReducer from '../../../components/Managment/Contract/Missions/store/reducer'
import submissionsReducer from '../../../components/Managment/Contract/Submissions/store/reducer'

// ! Change contract
import misionPaqueteReducer from '../../../components/Gestion/PaqueteTrabajo/store/reducer'
// !!!!
import riskManagementReducer2 from '../../../components/TabsExcel/RiskManagement/store2/Anfe/reducer'
import RyOReducer from '../../../components/TabsExcel/RiskManagement/store2/RyO/reducer'
import tasksAccionesReducer from '../../../components/TabsExcel/RiskManagement/store2/actions/reducer'
import filterReducer from "../../../components/Filter/store/reduce"
import globalPowerBiReducer from '../../../components/PowerBI/GlobalStore/reducer'
import powerBiSummaryReducer from '../../../components/PowerBI/Summary/store/reducer'
import powerBiManagementReducer from '../../../components/Managment/PowerBi_management/store/reducer'
import salesVolumeReducer from '../../../components/PowerBI/SalesVolume/store/reducer'
// !!!!

// ! Improvement proporsal
import improvementProposalsReducer from '../../../components/TabsExcel/ImprovementProposals/store/reducer'
import actionImprovementProposalsReducer from '../../../components/TabsExcel/ImprovementProposals/ImprovementActions/store/reducer'

import improvementProposalsReducer2 from '../../../components/TabsExcel/ImprovementProposals2/store/reducer'
import actionImprovementProposalsReducer2 from '../../../components/TabsExcel/ImprovementProposals2/ImprovementActions/store/reducer'


// ! Improvement proporsal
import peopleManagementReducer from '../../../components/TabsExcel/PeopleManagement/store/reducer'
import userComponenteReducer from '../../../components/Managment/Users/store/reducer'
import deliverableReducer from '../../../components/TabsExcel/Deliverables/store/reducer'
import loginReducer from '../../../components/Login/store/reducer'
import newUserComponenteReducer from '../../../components/Managment/Users/modals/NewUser/store/reducer'
import customerReducer from '../../../components/Managment/Customers/store/reducer'
import insertPermissionsComponenteReducer from '../../../components/Managment/Users/modals/UserPermissions/store/reducer'
import taskModalSinPermisosComponenteReducer from '../../../components/Managment/Users/modals/Task/store/reducer'
import aplicationReducer from '../../../components/TabsExcel/PeopleManagement/Items/Aplications/store/reducer'
import acreditationReducer from '../../../components/TabsExcel/PeopleManagement/Items/Accreditation/store/reducer'
import misionReducer from '../../../components/Gestion/Mision/store/reducer'
import subMisionReducer from '../../../components/Gestion/SubMision/store/reducer'
import appReducer from '../../../components/Managment/App/store/reducer'
import processReducer from '../../../components/Managment/Process/store/reducer'
import subProcessReducer from '../../../components/Managment/Process/store/subProcessStore/reducer'
import categoriaReducer from '../../../components/Managment/Category/store/reducer'
import gestionAplicationReducer from '../../../components/Managment/App/store/reducer'
import kpiReducer from '../../../components/Managment/Kpi/store/reducer'
import PerformanceReducer from '../../../components/PowerBI/Performance/store/reducer'
import timeManagementReducer from '../../../components/PowerBI/TimeManagement/store/reducer'

import auditReducer from '../../../components/PowerBI/Audits/store/reducer'
import sectionsDataInfoReducer from '../../../components/tables/store/reducer'
import rulesDataInfoReducer from '../../../components/Managment/Rules/store/reducer'
import dataKpiReducer from '../../../components/TabsExcel/DataKpi/items/store/reducer'
import gestionDataKpiReducer from '../../../components/TabsExcel/DataKpi/store/reducer'
import logReducer from '../../../components/Managment/Log/store/reducer'
import logPersonaReducer from '../../../components/Managment/LogCambiosPersonas/store/reducer'
import departamentoViewReducer from '../../../components/Gestion/Departamentos/store/reducer'
import detallerUserReducer from '../../../components/Managment/Users/modals/NewUser copy/store/reducer'
import logRiskReducer from '../../../components/Managment/LogRisk/store/reducer'
import kanbanReducer from '../../../components/Gestion/Kanban/store/reducer'
import permisosReducer from '../../../components/Managment/Permisos/store/reducer'
import notificacionesReducer from '../../../components/Managment/Notifications/store/reducer'
import powerBIReducer from '../../../components/PowerBI/Performance/store/reducer'
import staffUserReducer from '../../../components/TabsExcel/PeopleManagement/Items/SearchUser/store/reducer'
import requestReducer from '../../../components/TabsExcel/PeopleManagement/Items/Request/store/reducer'
import skillMatrixReducer from 'components/TabsExcel/SkillMatrix/store/skillMatrixSlice'
import trainingReducer from 'components/TabsExcel/Training/store/trainingSlice'

const fuseReducers = combineReducers({

  skillMatrixReducer: skillMatrixReducer,
  trainingReducer: trainingReducer,

  //VISTAS
  programasView: programasViewReducer,

  //COMPONENTES GENERALES
  programaComponente: programaReducer,
  conjuntoComponente: conjuntoReducer,
  // !!!!
  anfeComponente: riskManagementReducer2,
  RyOComponente : RyOReducer,
  // !!!!
  userComponente: userComponenteReducer,
  filterComponente: filterReducer,
  powerBiGlobalStore: globalPowerBiReducer,
  powerBiSalesVolume: salesVolumeReducer,
  powerBiManagement: powerBiManagementReducer,
  powerBiSummary: powerBiSummaryReducer,
  loginComponente: loginReducer,
  tasksAccionesComponente: tasksAccionesReducer,
  newUserComponente: newUserComponenteReducer,
  insertPermissionsComponente: insertPermissionsComponenteReducer,
  taskModalSinPermisosComponente: taskModalSinPermisosComponenteReducer,
  subConjuntoComponente: subConjuntoReducer,
  // ! CHANGE CONTRACT
  contractServiceReducer: contractServiceReducer,
  submissionsReducer: submissionsReducer,
  missionsReducer: missionsReducer,
  workPackageReducer: workPackageReducer,
  // ! CHANGE CONTRACT

  // ! Improvement proporsal
  improvementProposalsComponent: improvementProposalsReducer,
  actionImprovementProposalsComponent: actionImprovementProposalsReducer,
  improvementProposalsReducer: improvementProposalsReducer2,
  actionImprovementProposalsReducer: actionImprovementProposalsReducer2,
  // ! Improvement proporsal
  contratoComponente: contratoReducer,
  misionPaqueteComponente: misionPaqueteReducer,
  subDivisionComponente: subDivisionReducer,
  divisionViewComponente: divisionViewReducer,
  divisionComponente: divisionReducer,
  sectionInfoComponente: sectionsDataInfoReducer,
  homeComponente: homeReducer,
  peopleManagementComponente: peopleManagementReducer,
  aplicationComponent: aplicationReducer,
  acreditationComponent: acreditationReducer,
  misionComponent: misionReducer,
  rulesComponent: rulesDataInfoReducer,
  subMisionComponent: subMisionReducer,

  customerComponent: customerReducer,
  processComponent: processReducer,
  subProcessComponent: subProcessReducer,
  appComponent: appReducer,
  deliverableComponent: deliverableReducer,
  categoriaComponent: categoriaReducer,
  gestionAplicationComponent: gestionAplicationReducer,
  kpiComponent: kpiReducer,
  performanceComponent: PerformanceReducer,
  timeManagementComponent: timeManagementReducer,
  auditComponent: auditReducer,
  dataKpiComponent: dataKpiReducer,
  gestionDataKpiComponent: gestionDataKpiReducer,
  logComponent: logReducer,
  logPersonaComponent: logPersonaReducer,
  departamentoViewComponente: departamentoViewReducer,
  detallerUserComponente: detallerUserReducer,
  logRiskComponente: logRiskReducer,
  kanbanComponente: kanbanReducer,
  permisosComponente: permisosReducer,
  notificacionesComponente: notificacionesReducer,
  powerBiComponente: powerBIReducer,
  staffUserComponente: staffUserReducer,
  requestComponente: requestReducer,

  //OTROS
  navigation,
  settings,
  navbar,
  message,
  dialog,
});

export default fuseReducers;
