import {

    // COMPONENTE PRINCIPAL ------------------
   
   
            OBTENER_PHOTO,
            OBTENER_PHOTO_EXITO,
            OBTENER_PHOTO_ERROR,

            CHECK_TOKEN,
            CHECK_TOKEN_EXITO,
            CHECK_TOKEN_ERROR,
   
   
   } from './types';


// cada reducer tiene su propio state
const initialState = {

        dataImage: {},
        login: false,
        error: null,
        loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {


     //**********OBTENER TOKEN**********************************/

        case  OBTENER_PHOTO:
            return {
                ...state,
                loading: action.payload
            }
        
        case OBTENER_PHOTO_EXITO:
            return {
                ...state,
                loading: false,
                dataImage: action.payload,
                login: true

            }
        
        case OBTENER_PHOTO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                dataImage: 'assets/images/avatars/profile.jpg',

            }

        default: 
            return state

    }

}