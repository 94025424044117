import { createSlice } from "@reduxjs/toolkit";
import { getN2andN1FromStructure } from "./AuthOfInspectorsThunk";

const INITIAL_STATE = {
    createInspectorModalVisibility: false,
    // possibleInspectorsList: []
}


const reducersActions = {
    setCreateInspectorModalVisibility:(state, {payload})=>{
        state.createInspectorModalVisibility= payload
    }
}


const extraReducersActions = {
    // [getN2andN1FromStructure.fulfilled]:(state, {payload})=> {state.possibleInspectorsList = payload}
}



const authorizationOfInspectorsSlice= createSlice({
    name: 'authorizationOfInspectors',
    initialState:INITIAL_STATE,
    reducers:reducersActions,
    extraReducers: extraReducersActions
})

export const {setCreateInspectorModalVisibility} = authorizationOfInspectorsSlice.actions

export default authorizationOfInspectorsSlice.reducer