import {

    MOSTRAR_SUBPROCESS,
    MOSTRAR_SUBPROCESS_EXITO,
    MOSTRAR_SUBPROCESS_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS,
    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR,

    PUT_SUBPROCESS,
    PUT_SUBPROCESS_EXITO,
    PUT_SUBPROCESS_ERROR,

    INSERTAR_NEW_SUBPROCESS,
    INSERTAR_NEW_SUBPROCESS_EXITO,
    INSERTAR_NEW_SUBPROCESS_ERROR,

    CAMBIAR_MODAL_NEW_SUBPROCESS,
    CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO,
    CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {
        subProcessListAPI:[],
        filaSeleccionadaGrid: '',
        error: null,
        loading: false,
        visibilidadNewSubProcess: false,
        modoDialogSubProcess: '',
}


export default function(state = initialState, action) {

    switch(action.type) {

        //**********VISIBILIDAD MODAL INSERTAR**********************************/
    case CAMBIAR_MODAL_NEW_SUBPROCESS:
        return {
            ...state,
            loading: action.payload
        }
        
        case CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadNewSubProcess: action.payload,
                modoDialogSubProcess: action.nombre
            }
        
        case CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //post user

        case INSERTAR_NEW_SUBPROCESS:
            return {
                ...state,
                loading: action.payload
            }
        
        case INSERTAR_NEW_SUBPROCESS_EXITO:
            return {
                ...state,
                loading: false,
                error: null
            }
        
        case INSERTAR_NEW_SUBPROCESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //UPDATE SUBPROCESS
    case PUT_SUBPROCESS:
        return {
            ...state,
            loading: action.payload
        }
    
    case PUT_SUBPROCESS_EXITO:
        return {
            ...state,
            loading: false,
            error: null

        }
    
    case PUT_SUBPROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


    //********** MOSTRAR SUBPROCESS API**********************************/ 

    case MOSTRAR_SUBPROCESS:
        return {
            ...state,
            loading: action.payload
        }
    
    case MOSTRAR_SUBPROCESS_EXITO:
        return {
            ...state,
            loading: false,
            error: null,
            subProcessListAPI: action.payload
        }
    
    case MOSTRAR_SUBPROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }
    //**********FILA SELECIONADA GRID **********************************/

    case CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS:
        return {
            ...state,
            loading: action.payload
        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO:
        return {
            ...state,
            loading: false,
            filaSeleccionadaGrid: action.payload

        }
    
    case CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }

        default: 
            return state

    }

}