export const SERVICES_TYPES = {
    GET_SERVICES_LIST: "GET_SERVICES_LIST",
    SET_CREATE_MODAL: "SET_CREATE_MODAL",
    SET_EDIT_MODAL: "SET_EDIT_MODAL", 
    SET_SERVICE_SELECTED: "SET_SERVICE_SELECTED",
    POST_SERVICE: "POST_SERVICE",
    POST_SERVICE_SUCCESS: "POST_SERVICE_SUCCESS",
    POST_SERVICE_ERROR: "POST_SERVICE_ERROR",
    INSERT_NEW_SERVICE:"INSERT_NEW_SERVICE",
    EDIT_SERVICE:"EDIT_SERVICE"
}
