import {

    MOSTRAR_RULES,
    MOSTRAR_RULES_EXITO,
    MOSTRAR_RULES_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_RULES,
    CAMBIAR_VALOR_SELECCION_GRID_RULES_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_RULES_ERROR,

    PUT_RULES,
    PUT_RULES_EXITO,
    PUT_RULES_ERROR,

    INSERTAR_NEW_RULES,
    INSERTAR_NEW_RULES_EXITO,
    INSERTAR_NEW_RULES_ERROR,

    CAMBIAR_MODAL_NEW_RULES,
    CAMBIAR_MODAL_NEW_RULES_EXITO,
    CAMBIAR_MODAL_NEW_RULES_ERROR


} from './types';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import {getCookie} from 'app/js/generalFunctions'

//Constantes globales
const urlRulesApi = process.env.REACT_APP_URL_DJANGO + "/api/rules/"

//************************CAMBIAR VISIBILIDAD MODAL NUEVO USUARIO**********************************************

export function cambiarVisibilidadModalInsertarRulesAPI(valorNuevo, modoDialogRules) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalNuevoRules())

        try {
            dispatch (cambiarVisibilidadModalNuevoRulesExito(valorNuevo, modoDialogRules))

        } catch (error) {

            dispatch (cambiarVisibilidadModalNuevoRulesError(true))
        }
    }
}

const cambiarVisibilidadModalNuevoRules = () => ({
    type: CAMBIAR_MODAL_NEW_RULES,
    payload: true

})

const cambiarVisibilidadModalNuevoRulesExito = (valorNuevo, modoDialogRules) => ({
    type: CAMBIAR_MODAL_NEW_RULES_EXITO,
    nombre: modoDialogRules,
    payload: valorNuevo

})

const cambiarVisibilidadModalNuevoRulesError = estado => ({
  type:  CAMBIAR_MODAL_NEW_RULES_ERROR,
  payload: estado
})

//************************ INSERTA RM_ACCION MODAL INSERTAR**********************************************

export function insertarNewRulesAPIAction(rulesData) {

    return async (dispatch) => {
        dispatch (createNewRules())
             axios({
                    method: "POST",
                    url: urlRulesApi,
                    data: rulesData,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (createNewRulesExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Rules successfully created",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarRulesAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (createNewRulesError(true))

                dispatch(
                    showMessage({
                        message: "Error creating Rules",
                        variant: "error"
                    })
                )
            })

    }
}

const createNewRules = (customer) => ({
    type: INSERTAR_NEW_RULES,
    payload: customer

})

const createNewRulesExito = estado => ({
    type: INSERTAR_NEW_RULES_EXITO,
    payload: estado

})

const createNewRulesError = estado => ({
    type:  INSERTAR_NEW_RULES_ERROR,
    payload: estado
})

//PUT RULES

export function putRulesAPIAction(id,rule) {

    return async (dispatch) => {
        dispatch (putRules())
             axios({
                    method: "PUT",
                    url: urlRulesApi + id,
                    data: rule,
                    headers: {
                        'Authorization': `Token ${getCookie('token')}` 
                            }
                       
              })   
              .then(response => {
                 dispatch (putRulesExito(response.data))

                 dispatch(
                    showMessage({
                        message: "Rules successfully modified",
                        variant: "success"
                    })
                 ) 

                 dispatch(mostrarRulesAPIAction())

             })
             .catch(error => {
                console.log(error.response)
                dispatch (putRulesError(true))

                dispatch(
                    showMessage({
                        message: "Error modified Rules",
                        variant: "error"
                    })
                )
            })

    }
}

const putRules = () => ({
    type: PUT_RULES,
    payload: true

})

const putRulesExito = estado => ({
    type: PUT_RULES_EXITO,
    payload: estado

})

const putRulesError = estado => ({
    type:  PUT_RULES_ERROR,
    payload: estado
})

//************************ CAMBIAR SELECCION GRID ACTION**********************************************

export function cambiarValorSeleccionAction(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarValorSeleccion())

        try {
            dispatch (cambiarValorSeleccionExito(valorNuevo))
            

        } catch (error) {

            dispatch (cambiarValorSeleccionError(true))
        }

    }

}

const cambiarValorSeleccion = () => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_RULES,
    payload: false

})

const cambiarValorSeleccionExito = valorNuevo => ({
    type: CAMBIAR_VALOR_SELECCION_GRID_RULES_EXITO,
    payload: valorNuevo

})

const cambiarValorSeleccionError = estado => ({
  type: CAMBIAR_VALOR_SELECCION_GRID_RULES_ERROR,
  payload: estado
})

//************************ OBTENER PROCESOS RELACIONADOS **********************************************

export function mostrarRulesAPIAction() {

    return async (dispatch) => {
        dispatch (mostrarRulesAPI(true))

        
            await axios({
                method: "GET",
                url: urlRulesApi,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarRulesAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarRulesAPIError(true))
                    })

          }
}

const mostrarRulesAPI = () => ({
    type: MOSTRAR_RULES,
    payload: false

})

const mostrarRulesAPIExito = programas => ({
    type: MOSTRAR_RULES_EXITO,
    payload: programas

})

const mostrarRulesAPIError = estado => ({
  type: MOSTRAR_RULES_ERROR,
  payload: estado
})