import {

    // COMPONENTE PRINCIPAL ------------------


    CAMBIAR_VALOR_TAB_PEOPLE,
    CAMBIAR_VALOR_TAB_PEOPLE_EXITO,
    CAMBIAR_VALOR_TAB_PEOPLE_ERROR,

    VER_MODAL_SOLICITAR_PERSONAL,
    VER_MODAL_SOLICITAR_PERSONAL_EXITO,
    VER_MODAL_SOLICITAR_PERSONAL_ERROR,

    POST_CORREO_SOLICITUD,
    POST_CORREO_SOLICITUD_EXITO,
    POST_CORREO_SOLICITUD_ERROR,

    POST_NUEVA_NOTIFICACION,
    POST_NUEVA_NOTIFICACION_EXITO,
    POST_NUEVA_NOTIFICACION_ERROR,

    POST_INVENTARIO_PCS,
    POST_INVENTARIO_PCS_EXITO,
    POST_INVENTARIO_PCS_ERROR,

    GET_NOTIFICACION,
    GET_NOTIFICACION_EXITO,
    GET_NOTIFICACION_ERROR,

    VER_MODAL_ANADIR_PERSONAL,
    VER_MODAL_ANADIR_PERSONAL_EXITO,
    VER_MODAL_ANADIR_PERSONAL_ERROR,

    GET_MY_GROUP,
    GET_MY_GROUP_EXITO,
    GET_MY_GROUP_ERROR,

    ELIMINAR_PERSON_TEAM,
    ELIMINAR_PERSON_TEAM_EXITO,
    ELIMINAR_PERSON_TEAM_ERROR,

    GET_SOLICITUD,
    GET_SOLICITUD_EXITO,
    GET_SOLICITUD_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR,

    VER_MODAL_INSERTAR_OBSERVACIONES,
    VER_MODAL_INSERTAR_OBSERVACIONES_EXITO,
    VER_MODAL_INSERTAR_OBSERVACIONES_ERROR,

    PUT_NOTIFICACION,
    PUT_NOTIFICACION_EXITO,
    PUT_NOTIFICACION_ERROR,

    GET_USER_ACCESO_PEOPLE_MANAGEMENT,
    GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO,
    GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR,

    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION,
    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO,
    GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR,

    SET_MEMBER_SELECT_PEOPLE,
    SET_MEMBER_SELECT_PEOPLE_EXITO,
    SET_MEMBER_SELECT_PEOPLE_ERROR,
    
    SET_SUB_MISION_SELECT_MY_TEAM,
    SET_SUB_MISION_SELECT_MY_TEAM_EXITO,
    SET_SUB_MISION_SELECT_MY_TEAM_ERROR,

    GET_MY_MANAGER,
    GET_MY_MANAGER_EXITO,
    GET_MY_MANAGER_ERROR,

    GET_ESTRUCTURA_PERSONAL,
    GET_ESTRUCTURA_PERSONAL_EXITO,
    GET_ESTRUCTURA_PERSONAL_ERROR,

    GET_USER_ESTRUCTURA_PERSONAL,
    GET_USER_ESTRUCTURA_PERSONAL_EXITO,
    GET_USER_ESTRUCTURA_PERSONAL_ERROR,

    GET_ESTRUCTURA_PERSONAL_SECUNDARIA,
    GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO,
    GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR,

    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA,
    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO,
    SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR,


} from './types';


// cada reducer tiene su propio state
const initialState = {

    valorTabPeople: 'miPersonalNew',
    subMisionSelectMyTeam: '',
    setSubMisionSeleccionadaEstructura: {},
    verModalSolicitudPersonal: false,
    verModalAnadirPersonal: false,
    verModalInsertarObservaciones: false,
    modoModalInsertarObservaciones: '',
    error: null,
    loading: false,
    listNotificactions: [],
    listArbolPersonal: [],
    listArbolPersonalSecundario: [],
    listSolicitudes: [],
    listMyGroup: [],
    listMyManager: [],
    listUserEstructuraPersonal: [],
    listInventoryPcs: {},
    filaSeleccionadaGridSolicitudes: '',
    listUserAccesoPeopleManagment: [],
    listResponsablesSubMision: [],
    memberSelectSubMision: ''
}

export default function(state = initialState, action) {

    switch(action.type) {

        //**********GET ESTRUCTURA PERSONAL SECUNDARIA **********************************/

        case SET_SUB_MISION_ESTRUCTURA_SELECCIONADA:
            return {
                ...state,
                loading: action.payload
            }
        
        case SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                setSubMisionSeleccionadaEstructura: action.payload
            }
        
        case SET_SUB_MISION_ESTRUCTURA_SELECCIONADA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET ESTRUCTURA PERSONAL SECUNDARIA **********************************/

        case GET_ESTRUCTURA_PERSONAL_SECUNDARIA:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_ESTRUCTURA_PERSONAL_SECUNDARIA_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listArbolPersonalSecundario: action.payload
            }
        
        case GET_ESTRUCTURA_PERSONAL_SECUNDARIA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET MANAGER **********************************/

        case GET_USER_ESTRUCTURA_PERSONAL:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_USER_ESTRUCTURA_PERSONAL_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listUserEstructuraPersonal: action.payload
            }
        
        case GET_USER_ESTRUCTURA_PERSONAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET MANAGER **********************************/

        case GET_ESTRUCTURA_PERSONAL:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_ESTRUCTURA_PERSONAL_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listArbolPersonal: action.payload
            }
        
        case GET_ESTRUCTURA_PERSONAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET MANAGER **********************************/

        case GET_MY_MANAGER:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_MY_MANAGER_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listMyManager: action.payload
            }
        
        case GET_MY_MANAGER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********SELECCIONAR SUB MISION SOBRE LA QUE SE ESTA TRABAJANDO EN MY TEAM **********************************/

        case SET_SUB_MISION_SELECT_MY_TEAM:
            return {
                ...state,
                loading: action.payload
            }
        
        case SET_SUB_MISION_SELECT_MY_TEAM_EXITO:
            return {
                ...state,
                loading: false,
                subMisionSelectMyTeam: action.payload

            }
        
        case SET_SUB_MISION_SELECT_MY_TEAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********FILA SELECIONADA MEMBER DE PEOPLE **********************************/

        case SET_MEMBER_SELECT_PEOPLE:
            return {
                ...state,
                loading: action.payload
            }
        
        case SET_MEMBER_SELECT_PEOPLE_EXITO:
            return {
                ...state,
                loading: false,
                memberSelectSubMision: action.payload

            }
        
        case SET_MEMBER_SELECT_PEOPLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********FILA SELECIONADA GRID **********************************/

        case GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_EXITO:
            return {
                ...state,
                loading: false,
                listResponsablesSubMision: action.payload

            }
        
        case GET_RESPONSABLES_MISION_ASOCIADA_SUB_MISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********FILA SELECIONADA GRID **********************************/

        case GET_USER_ACCESO_PEOPLE_MANAGEMENT:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_USER_ACCESO_PEOPLE_MANAGEMENT_EXITO:
            return {
                ...state,
                loading: false,
                listUserAccesoPeopleManagment: action.payload

            }
        
        case GET_USER_ACCESO_PEOPLE_MANAGEMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********FILA SELECIONADA GRID **********************************/

        case CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_EXITO:
            return {
                ...state,
                loading: false,
                filaSeleccionadaGridSolicitudes: action.payload

            }
        
        case CAMBIAR_VALOR_SELECCION_GRID_SOLICITUD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET TEAM **********************************/

        case GET_MY_GROUP:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_MY_GROUP_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listMyGroup: action.payload
            }
        
        case GET_MY_GROUP_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET NOTIFICACION **********************************/

        case GET_SOLICITUD:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_SOLICITUD_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listSolicitudes: action.payload
            }
        
        case GET_SOLICITUD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********GET NOTIFICACION **********************************/

        case GET_NOTIFICACION:
            return {
                ...state,
                loading: action.payload
            }
        
        case GET_NOTIFICACION_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                listNotificactions: action.payload
            }
        
        case GET_NOTIFICACION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

            //**********PUT_NOTIFICACION **********************************/

        case PUT_NOTIFICACION:
            return {
                ...state,
                loading: action.payload
            }
        
        case PUT_NOTIFICACION_EXITO:
            return {
                ...state,
                loading: false,
                error: null
            }
        
        case PUT_NOTIFICACION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //**********POST_CORREO_SOLICITUD **********************************/

        case POST_NUEVA_NOTIFICACION:
            return {
                ...state,
                loading: action.payload
            }
        
        case POST_NUEVA_NOTIFICACION_EXITO:
            return {
                ...state,
                loading: false,
                error: null
            }
        
        case POST_NUEVA_NOTIFICACION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        

        //**********POST_CORREO_SOLICITUD **********************************/

        case POST_CORREO_SOLICITUD:
            return {
                ...state,
                loading: action.payload
            }
        
        case POST_CORREO_SOLICITUD_EXITO:
            return {
                ...state,
                loading: false
            }
        
        case POST_CORREO_SOLICITUD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

     //**********FILA SELECIONADA GRID **********************************/

         case CAMBIAR_VALOR_TAB_PEOPLE:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_TAB_PEOPLE_EXITO:
            return {
                ...state,
                loading: false,
                valorTabPeople: action.payload

            }
        
        case CAMBIAR_VALOR_TAB_PEOPLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
        
            //MODAL INSERTAR OBSERVACIONES
        case VER_MODAL_INSERTAR_OBSERVACIONES:
            return {
                ...state,
                loading: action.payload
            }
        
        case VER_MODAL_INSERTAR_OBSERVACIONES_EXITO:
            return {
                ...state,
                loading: false,
                verModalInsertarObservaciones: action.payload,
                modoModalInsertarObservaciones: action.modoDialogo

            }
        
        case VER_MODAL_INSERTAR_OBSERVACIONES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //MODAL ANADIR PERSONAL
        case VER_MODAL_ANADIR_PERSONAL:
            return {
                ...state,
                loading: action.payload
            }
        
        case VER_MODAL_ANADIR_PERSONAL_EXITO:
            return {
                ...state,
                loading: false,
                verModalAnadirPersonal: action.payload

            }
        
        case VER_MODAL_ANADIR_PERSONAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //MODAL SOLICITUD DE PERSONAL
        case VER_MODAL_SOLICITAR_PERSONAL:
            return {
                ...state,
                loading: action.payload
            }
        
        case VER_MODAL_SOLICITAR_PERSONAL_EXITO:
            return {
                ...state,
                loading: false,
                verModalSolicitudPersonal: action.payload

            }
        
        case VER_MODAL_SOLICITAR_PERSONAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //********** GET PC INVENTORY **********************************/
        case  POST_INVENTARIO_PCS:
            return {
                ...state,
                loading: action.payload
            }
        
        case  POST_INVENTARIO_PCS_EXITO:
            return {
                ...state,
                loading: false,
                listInventoryPcs: action.payload

            }
        
        case  POST_INVENTARIO_PCS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }
            

        default: 
            return state

    }

}
