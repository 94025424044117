import {

    CAMBIAR_VALOR_TAB_DIVISION,
    CAMBIAR_VALOR_TAB_DIVISION_EXITO,
    CAMBIAR_VALOR_TAB_DIVISION_ERROR

} from './types';


// cada reducer tiene su propio state
const initialState = {
    valorTabDivision: 'divisiones',
    error: null,
    loading: false
}

export default function(state = initialState, action) {

    switch(action.type) {
        case CAMBIAR_VALOR_TAB_DIVISION:
            return {
                ...state,
                loading: action.payload
            }
        
        case CAMBIAR_VALOR_TAB_DIVISION_EXITO:
            return {
                ...state,
                loading: false,
                valorTabDivision: action.payload

            }
        
        case CAMBIAR_VALOR_TAB_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }    

        default: 
            return state

    }

}