
export const GET_ALL_MISION = 'GET_ALL_MISION'
export const GET_ALL_MISION_EXITO = 'GET_ALL_MISION_EXITO'
export const GET_ALL_MISION_ERROR = 'GET_ALL_MISION_ERROR'

export const PUT_MISION = 'PUT_MISION'
export const PUT_MISION_EXITO = 'PUT_MISION_EXITO'
export const PUT_MISION_ERROR = 'PUT_MISION_ERROR'

export const POST_MISION = 'POST_MISION'
export const POST_MISION_EXITO = 'POST_MISION_EXITO'
export const POST_MISION_ERROR = 'POST_MISION_ERROR'

export const VER_MODAL_INSERT_MISION = 'VER_MODAL_INSERT_MISION'
export const VER_MODAL_INSERT_MISION_EXITO = 'VER_MODAL_INSERT_MISION_EXITO'
export const VER_MODAL_INSERT_MISION_ERROR = 'VER_MODAL_INSERT_MISION_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_MISION = 'CAMBIAR_VALOR_SELECCION_GRID_MISION'
export const CAMBIAR_VALOR_SELECCION_GRID_MISION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_MISION_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_MISION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_MISION_ERROR'

export const GET_RO_MISION = 'GET_RO_MISION'
export const GET_RO_MISION_EXITO = 'GET_RO_MISION_EXITO'
export const GET_RO_MISION_ERROR = 'GET_RO_MISION_ERROR'

export const GET_ALL_MISION_RESPALDO = 'GET_ALL_MISION_RESPALDO'
export const GET_ALL_MISION_RESPALDO_EXITO = 'GET_ALL_MISION_RESPALDO_EXITO'
export const GET_ALL_MISION_RESPALDO_ERROR = 'GET_ALL_MISION_RESPALDO_ERROR'

export const VER_MODAL_REQUERIMENTS_MISION = 'VER_MODAL_REQUERIMENTS_MISION'
export const VER_MODAL_REQUERIMENTS_MISION_EXITO = 'VER_MODAL_REQUERIMENTS_MISION_EXITO'
export const VER_MODAL_REQUERIMENTS_MISION_ERROR = 'VER_MODAL_REQUERIMENTS_MISION_ERROR'

export const GET_REQUERIMENTS_BY_MISION = 'GET_REQUERIMENTS_BY_MISION'
export const GET_REQUERIMENTS_BY_MISION_EXITO = 'GET_REQUERIMENTS_BY_MISION_EXITO'
export const GET_REQUERIMENTS_BY_MISION_ERROR = 'GET_REQUERIMENTS_BY_MISION_ERROR'

export const POST_MISION_APP = 'POST_MISION_APP'
export const POST_MISION_APP_EXITO = 'POST_MISION_APP_EXITO'
export const POST_MISION_APP_ERROR = 'POST_MISION_APP_ERROR'

export const ELIMINAR_MISION_APP = 'ELIMINAR_MISION_APP'
export const ELIMINAR_MISION_APP_EXITO = 'ELIMINAR_MISION_APP_EXITO'
export const ELIMINAR_MISION_APP_ERROR = 'ELIMINAR_MISION_APP_ERROR'

export const GET_CONTRACT_AGRESSO = 'GET_CONTRACT_AGRESSO'
export const GET_CONTRACT_AGRESSO_EXITO = 'GET_CONTRACT_AGRESSO_EXITO'
export const GET_CONTRACT_AGRESSO_ERROR = 'GET_CONTRACT_AGRESSO_ERROR'

export const GET_VISUAL_TIME_GROUP = 'GET_VISUAL_TIME_GROUP'
export const GET_VISUAL_TIME_GROUP_EXITO = 'GET_VISUAL_TIME_GROUP_EXITO'
export const GET_VISUAL_TIME_GROUP_ERROR = 'GET_VISUAL_TIME_GROUP_ERROR'