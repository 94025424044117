//**********************IMPORTACIONES****************************

import React, { useEffect, useState, useMemo, useRef, Fragment, useCallback } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux'
import {
    cambiarVisibilidadModalPrincipalAction,
    cambiarVisibilidadModalInsertarRyOAction,
    cambiarEstadoRyOAction,
    actionIsRequiredAction
} from '../../store2/RyO/actions'
import { useCreateRyO, useEditRyo, useInputsControl, useIsEditMode, useNprControl, useNprCalc, useHandleMissionChange, useHandleSecMissionsChangeInCreate, useHandleSecMissionsChangeInEdit, useCanHaveChildremControl, useDispatchModuleDependencies } from '../../hooks/RyO/RyOMainSectionHooks.js'
import { useActionsButtom } from '../../hooks/RyO/RyOTopButtomsHooks';
import { encabezadoTitulo, labelBotonCreate, useStyles, rowsSeverity, rowsFrequency, rowsDetection, nprChartOptions, nprChartFunction } from '../../utils/RyOUtils.js'
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip as ChartTooltip,
    Legend,
    Filler,
    ArcElement,
    scales
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    RadialLinearScale,
    Title,
    ChartTooltip,
    Legend,
    Filler
);
import dayjs from "dayjs";
import { getIdsFromArr } from 'app/js/generalFunctions';

export function ModalInsertarRyO() {

    const classes = useStyles();
    const dispatch = useDispatch();


    // ? Component states
    const [riesgoActual, setRiesgoActual] = useState('')
    const [valuesRyO, setvaluesRyO] = useState(null)
    const [opportunityActual, setopportunityActual] = useState('')
    const [fechaDeteccionActual, setFechaDeteccionActual] = useState("")
    const [efectoFalloActual, setEfectoFalloActual] = useState("")
    const [causaFalloActual, setCausaFalloActual] = useState("")
    const [controlesActual, setControlesActual] = useState("")
    const [gravedadActual, setGravedadActual] = useState()
    const [ocurrenciaActual, setOcurrenciaActual] = useState()
    const [deteccionActual, setDeteccionActual] = useState()
    const [nprActual, setNprActual] = useState('Severity, frequency and detection are required')
    const [priorizacionActual, setPriorizacionActual] = useState('NPR is necessary')
    const [observacionesActual, setObservacionesActual] = useState('')
    const [categoriaActual, setCategoriaActual] = useState()
    const [rulesActual, setRulesActual] = useState()
    const [processSelectedState, setprocessSelectedState] = useState();
    const [subProcessRelatedState, setsubProcessRelatedState] = useState(null)
    const [subProcessSelectedState, setsubProcessSelectedState] = useState();
    const [revalorando, setRevalorando] = useState(false)
    const [porcentajeNPR, setPorcentajeNPR] = useState([0])
    const [hiddenGrafico, setHiddenGrafico] = useState(true)
    const [tomarAccion, setTomarAccion] = useState('No action necessary')
    const [alertaTipo, setAlertaTipo] = useState('info')
    const [valorTabRO, setvalorTabRO] = useState("RODescription")
    const [missionSelected, setmissionSelected] = useState(null);
    const [secMissionsSelected, setSecMissionsSelected] = useState([]);
    // const [missionsWithAnfes, setmissionsWithAnfes] = useState(null);
    const [secMissionPrevEditMode, setsecMissionPrevEditMode] = useState([])
    const [missionSelectedCantHaveChildren, setmissionSelectedCantHaveChildren] = useState(null)
    const [saveIsDisable, setsaveIsDisable] = useState(true)
    const [ayudaNPR, setAyudaNPR] = useState(false)
    const [ayudaSeverity, setAyudaSeverity] = useState(false)
    const [ayudaFrequency, setAyudaFrequency] = useState(false)
    const [ayudaDetection, setAyudaDetection] = useState(false)
    const [recordatorioClose, setRecordatorioClose] = useState(false)
    const [nprChart, setnprChart] = useState(null)
    const [RyOFilaSeleccionada, setRyOFilaSeleccionada] = useState(null)
    const [newSecMissionsAddInEditMode, setNewSecMissionsAddInEditMode] = useState([])
    const [ryoAldredyHaveAction, setryoAldredyHaveAction] = useState(null)
    

    // ? Component selectors
    const visibilidadModalInsertarRO = useSelector(state => state.fuse.RyOComponente.visibilidadModalInsertar)
    const modoRyO = useSelector(state => state.fuse.RyOComponente.modo)
    const {anfeListAPI, myAnfesList} = useSelector(state => state.fuse.anfeComponente)

    const actionIsRequired = useSelector(state => state.fuse.RyOComponente.actionIsRequired)
    const filaSeleccionadaGridRyOStore = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
    const idRyOWhenIsNoSelectedrow = useSelector(state => state.fuse.RyOComponente.idRyOWhenNoSelectedRow);
    const categoriaListAPI = useSelector(state => state.fuse.categoriaComponent.categoriaListAPI)
    const tipoCategoriasAPI = useSelector(state => state.fuse.categoriaComponent.tipoCategoriasAPI)
    const rulesListAPI = useSelector(state => state.fuse.rulesComponent.rulesListAPI);
    const processListAPI = useSelector(state => state.fuse.processComponent.processListAPI);
    const subProcessListAPI = useSelector(state => state.fuse.subProcessComponent.subProcessListAPI);
    // const listMisionAndDepartmentAPI = useSelector(state => state.fuse.misionComponent.listMisionAPI).filter(ele => ele.active === true)
    const personLoginId = useSelector(state => state.fuse.userComponente.person)?.id
    const {departamentalAndNoDepartamentalMissionsInfo} =  useSelector(state => state.fuse.userComponente)
    const listMisionAndDepartmentAPI = useSelector(state => state.fuse.misionComponent.listAllMisionAPI)
    const rmAccionesListAPI = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI)
    

    useEffect(()=>{
        if (!visibilidadModalInsertarRO) return
        const fila = filaSeleccionadaGridRyOStore || idRyOWhenIsNoSelectedrow
        setRyOFilaSeleccionada(fila)
    },[visibilidadModalInsertarRO, idRyOWhenIsNoSelectedrow, filaSeleccionadaGridRyOStore])

    // ? Component dispatchs
    const cambiarVisibilidadModalInsertar = (valor) => dispatch(cambiarVisibilidadModalInsertarRyOAction(valor))

    // ? Custom Hooks functions declaration
    const { inputControl } = useInputsControl()
    const { isEditMode } = useIsEditMode()
    const { createRyO } = useCreateRyO();
    const { editRyo } = useEditRyo();
    const { actionsButtom } = useActionsButtom()
    const { nprCalc } = useNprCalc()
    const { nprControl } = useNprControl()
    const { handleMissionChangeHook } = useHandleMissionChange();
    const { handleSecMissionsChangeInCreate } = useHandleSecMissionsChangeInCreate()
    const { handleSecMissionsChangeInEdit } = useHandleSecMissionsChangeInEdit()
    const { canHaveChildremControl } = useCanHaveChildremControl()
    const { dispatchModuleDependencies } = useDispatchModuleDependencies()

    // ? Dispatch module dependencies
    useEffect(() => dispatchModuleDependencies(), [])

    // ? Sates RyO object
    const satesRyoObj = {
        riesgoActual: riesgoActual,
        opportunityActual: opportunityActual,
        fechaDeteccionActual: fechaDeteccionActual,
        efectoFalloActual: efectoFalloActual,
        causaFalloActual: causaFalloActual,
        controlesActual: controlesActual,
        gravedadActual: gravedadActual,
        ocurrenciaActual: ocurrenciaActual,
        deteccionActual: deteccionActual,
        nprActual: nprActual,
        priorizacionActual: priorizacionActual,
        observacionesActual: observacionesActual,
        categoriaActual: categoriaActual,
        processSelectedState: processSelectedState,
        subProcessSelectedState: subProcessSelectedState,
        missionSelected: missionSelected,
        secMissionsSelected: secMissionsSelected,
        rulesActual: rulesActual,
    }

    // ? Inputs control for save buttom 
    const inputControlResponse = inputControl(satesRyoObj)
    useEffect(() => {
        inputControlResponse ? setsaveIsDisable(false) : setsaveIsDisable(true)
    }, [inputControlResponse])

    // ? Edit mode control
    useEffect(() => {
        (function () {
            const values = isEditMode(visibilidadModalInsertarRO, modoRyO, RyOFilaSeleccionada)
            setvaluesRyO(values)
        })()
    }, [visibilidadModalInsertarRO, modoRyO, RyOFilaSeleccionada])

    // ? Set input values. Empty if is create mode, fill if is edit or rev mode
    useEffect(() => {
        if (!valuesRyO) return
        setCategoriaActual(valuesRyO.categoriaActual);
        setCausaFalloActual(valuesRyO.causaFalloActual)
        setControlesActual(valuesRyO.controlesActual)
        setDeteccionActual(valuesRyO.deteccionActual)
        setEfectoFalloActual(valuesRyO.efectoFalloActual)
        setFechaDeteccionActual(valuesRyO.fechaDeteccionActual)
        setGravedadActual(valuesRyO.gravedadActual)
        setmissionSelected(valuesRyO.missionSelected)
        setNprActual(valuesRyO.nprActual)
        setObservacionesActual(valuesRyO.observacionesActual)
        setOcurrenciaActual(valuesRyO.ocurrenciaActual)
        setopportunityActual(valuesRyO.opportunityActual)
        setPriorizacionActual(valuesRyO.priorizacionActual)
        setprocessSelectedState(valuesRyO.processSelectedState)
        setRiesgoActual(valuesRyO.riesgoActual)
        setRulesActual(valuesRyO.rulesActual)
        setSecMissionsSelected(valuesRyO.secMissionsSelected)
        setsecMissionPrevEditMode(valuesRyO.secMissionsSelected)
        setsubProcessSelectedState(valuesRyO.subProcessSelectedState)
    }, [valuesRyO])

    // ? Know if ryo selected have missions
    useEffect(()=>{
        if(!visibilidadModalInsertarRO) return;
        const findRyoAldredyHaveAction = rmAccionesListAPI.find(action => action.id_record === RyOFilaSeleccionada)
        setryoAldredyHaveAction(!!findRyoAldredyHaveAction)
    },[RyOFilaSeleccionada, visibilidadModalInsertarRO])

    // ? Save. Create / Edit function
    async function callCreateHook() {
        const copy = { ...satesRyoObj }
        copy.subProcessSelectedState = copy.subProcessSelectedState ?? subProcessSelectedState;


        if (modoRyO === 'editar' || modoRyO === "rev") {
            copy.secMissionsSelected = copy.secMissionsSelected ?? newSecMissionsAddInEditMode;
            editRyo(RyOFilaSeleccionada, copy, modoRyO);
            setNewSecMissionsAddInEditMode([])
        } else {
            copy.secMissionsSelected = copy.secMissionsSelected ?? secMissionsSelected;
            createRyO(copy);
        }
        cambiarVisibilidadModalInsertar(false);
        setnprChart(null)
        setvalorTabRO("RODescription")

        // *  Show actions modal if npr > 100 and ryo have no actions
        
        if (satesRyoObj.nprActual > 100 && !ryoAldredyHaveAction) actionsButtom()

    }

    // ? Npr calc
    useEffect(() => {
        const { npr } = nprCalc(gravedadActual, ocurrenciaActual, deteccionActual, setNprActual);
        setNprActual(npr)
    }, [gravedadActual, ocurrenciaActual, deteccionActual]);

    // ? Priorization calc 
    useEffect(() => {
        const { priorizacionActual, tomarAccion, alertaTipo, porcentajeNPR, hiddenGrafico } = nprControl(nprActual);
        setPriorizacionActual(priorizacionActual);
        setTomarAccion(tomarAccion);
        setAlertaTipo(alertaTipo);
        setPorcentajeNPR(porcentajeNPR)
        setHiddenGrafico(hiddenGrafico)
    }, [nprActual]);

    // ? Subprocess control
    useEffect(() => {
        if (processSelectedState) {
            const subProcessRelated = subProcessListAPI?.filter(subProcess => subProcess?.id_proceso === processSelectedState);
            subProcessRelated.length ? setsubProcessRelatedState(subProcessRelated) : setsubProcessRelatedState(null);
            if (modoRyO === "crear" || !subProcessRelated.length) setsubProcessSelectedState(null)
        }
    }, [processSelectedState])


    // ? Missions with anfes control
    const missionsWithAnfes = useMemo(()=>{
        if(!myAnfesList.length) return []
        const myAnfesListIMissionsIds  = myAnfesList.map(({mision})=> mision)
        const missions = departamentalAndNoDepartamentalMissionsInfo?.filter(mission => myAnfesListIMissionsIds.includes(mission.id));

        return missions
    },[myAnfesList, departamentalAndNoDepartamentalMissionsInfo])


    // ? Childrem control. If the mission is alredy a childrem it cant have childrem
    useEffect(() => {
        const { misionSelectedHasFather } = canHaveChildremControl({ visibilidadModalInsertarRO: visibilidadModalInsertarRO, missionSelected: missionSelected });
        setmissionSelectedCantHaveChildren(misionSelectedHasFather ?? misionSelectedHasFather)
    }, [visibilidadModalInsertarRO, missionSelected]);

    // ? Rev mode control
    useEffect(() => {
        modoRyO === "rev" ? setRevalorando(true) : setRevalorando(false);
        modoRyO === "rev" ? setvalorTabRO("ECC") : setvalorTabRO("RODescription");
    }, [modoRyO])

    // ? Input mission selected control
    const handleMissionChange = event => {
        const { missionSelected, newSecMissions } = handleMissionChangeHook(event, secMissionsSelected);
        setmissionSelected(missionSelected);
        setSecMissionsSelected(newSecMissions ?? newSecMissions)
    }


    // ? Input related missions selected control
    const handleSecMissionsChange = event => {
        const newValue = event?.target?.value;
        if (modoRyO === "crear") {
            const { secMissionsSelectedResult } = handleSecMissionsChangeInCreate({ newValue, secMissionsSelected: secMissionsSelected });
            setSecMissionsSelected(secMissionsSelectedResult)
        }
        else if (modoRyO === "editar") {
            const { secMissionsSelectedResult, newValuesInEditMode } = handleSecMissionsChangeInEdit({ newValue, secMissionPrevEditMode: secMissionPrevEditMode });
            setSecMissionsSelected(secMissionsSelectedResult)
            setNewSecMissionsAddInEditMode(newValuesInEditMode)
        }
    }

    // ? Npr chart
    useEffect(() => {
        if (nprActual > 0 && nprActual <= 1000) {
            setnprChart(nprChartFunction({ npr: nprActual, color: nprActual < 80 ? 'rgb(109, 213, 255)' : (nprActual > 80 && nprActual <= 100) ? 'rgb(255, 173, 66)' : 'rgb(255, 0, 0)' }));
        }
    }, [nprActual])

    // ? Change tab value function
    const changeTabValue = (event, newValue) => setvalorTabRO(newValue);


    // ? Return the component
    return (

        <Dialog open={visibilidadModalInsertarRO} disableScrollLock={false} onClose={() => {cambiarVisibilidadModalInsertar(false), dispatch(cambiarVisibilidadModalPrincipalAction(false)) }} fullWidth maxWidth='lg'>

            <DialogTitle classes={{ root: classes.customDialogTitle }} >

                {encabezadoTitulo({ modoRyO })}

            </DialogTitle>
            <Tabs value={valorTabRO}
                onChange={changeTabValue}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
            >

                <Tab value="RODescription" label="R&O Description" />
                <Tab value="ECC" label="Effect/Cause/Controls" />
            </Tabs>
            {
                valorTabRO === "RODescription" && (

                        <DialogContent>
                            <div className="radialbar" style={revalorando ? { display: 'block' } : { display: 'none' }}>
                                <Alert severity="info">
                                    <AlertTitle>You are reevaluating the R&O as all actions created have been completed.</AlertTitle>
                                </Alert>
                            </div>

                            <Grid container spacing={1} columns={24}>

                                <Grid item xs={12} >

                                    <TextField
                                        disabled={revalorando}
                                        multiline
                                        label="Risk description"
                                        id="riesgo"
                                        value={riesgoActual}
                                        size="small"
                                        fullWidth
                                        rows={8}
                                        onChange={e => setRiesgoActual(e.target.value)}
                                    />

                                </Grid>

                                <Grid item xs={12} >

                                    <TextField
                                        disabled={revalorando}
                                        multiline
                                        label="Opportunity description"
                                        id="riesgo"
                                        value={opportunityActual}
                                        size="small"
                                        fullWidth
                                        rows={8}
                                        onChange={e => setopportunityActual(e.target.value)}
                                    />

                                </Grid>



                                <Grid item xs={12}>

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="grouped-select">Category</InputLabel>
                                        <Select
                                            id="Category"
                                            disabled={revalorando}
                                            fullWidth
                                            native
                                            label="Category"
                                            onChange={e => setCategoriaActual(e.target.value)}
                                            value={categoriaActual != undefined ? categoriaActual : ''}
                                        >


                                            <option value="" disabled selected hidden></option>
                                            {tipoCategoriasAPI.map((elemento) => (
                                                <optgroup key={elemento} label={elemento}>
                                                    {categoriaListAPI.filter(item => item.tipo == elemento).map((subCategory) => (

                                                        <option key={subCategory.id} value={subCategory.id}>{subCategory.titulo}</option>
                                                    ))}
                                                </optgroup>

                                            ))}
                                        </Select>
                                    </FormControl>



                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={revalorando}
                                                label="Detection date"
                                                fullWidth
                                                id="fechaDeteccion"
                                                inputFormat="yyyy-MM-dd"
                                                format="yyyy-MM-dd"
                                                value={fechaDeteccionActual}
                                                onChange={(newValue) => {
                                                    if (newValue == "Invalid Date" || !newValue)  setFechaDeteccionActual(newValue)
                                                    else {
                                                        const ryoDate = dayjs(newValue).format('YYYY-MM-DD').toString()
                                                        setFechaDeteccionActual(ryoDate);
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                    />
                                                }
                                            />
                                        </LocalizationProvider>

                                    </FormControl>

                                </Grid>


                                {/* !!!!!!!!!!!!!!!!!!!!!******************************!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

                                <Grid item xs={12} className='d-flex flex-column justify-content-between' style={{ gap: "16px" }}>

                                    <Grid container className=''>
                                        <Grid item xs={subProcessRelatedState ? 12 : 24}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="grouped-select">Process</InputLabel>
                                                <Select
                                                    id="process"
                                                    fullWidth
                                                    disabled={revalorando}
                                                    label="Process"
                                                    onChange={e => setprocessSelectedState(e.target.value)}
                                                    value={processSelectedState ? processSelectedState : ''}
                                                >
                                                    {
                                                        processListAPI.filter(process=>!!process.active).map((process, index) => (
                                                            <MenuItem key={process.id} value={process.id}> {process.nombre_proceso} </MenuItem>
                                                        ))}


                                                </Select>

                                            </FormControl>






                                        </Grid>

                                        <Grid item xs={11} style={subProcessRelatedState ? { display: "block" } : { display: "none" }}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="grouped-select">Subprocess</InputLabel>
                                                <Select
                                                    id="subprocess"
                                                    fullWidth
                                                    disabled={revalorando}
                                                    label="Subprocess"
                                                    onChange={e => setsubProcessSelectedState(e.target.value)}
                                                    value={subProcessSelectedState ? subProcessSelectedState : ''}
                                                >

                                                    {subProcessRelatedState?.filter(subprocess=>!!subprocess.active).map((subProcess) => (

                                                        <MenuItem key={subProcess.id} value={subProcess.id}> {subProcess.nombre_sub_proceso} </MenuItem>
                                                    ))}


                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>




                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="rules">Rules / Regulations affected</InputLabel>
                                        <Select
                                            id="rules"
                                            fullWidth
                                            disabled={revalorando}
                                            label="Rules / Regulations affected"
                                            onChange={e => setRulesActual(e.target.value)}
                                            value={rulesActual ? rulesActual : ''}
                                        >

                                            {rulesListAPI.map((rule) => (

                                                <MenuItem key={rule.id} value={rule.id}> {`${rule.code} - ${rule.name}`} </MenuItem>
                                            ))}


                                        </Select>

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className='d-flex flex-column justify-content-between' style={{ gap: "16px" }}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="mission">Main Mission / Department</InputLabel>
                                        <Select
                                            id="mission"
                                            fullWidth
                                            disabled={revalorando}
                                            label="Main Mission / Department"
                                            onChange={handleMissionChange}
                                            value={missionSelected ? missionSelected : ""} // Usamos un array de IDs seleccionados
                                        >
                                            {missionsWithAnfes?.map((ele) => {
                                                return (
                                                    <MenuItem key={ele.id} value={ele.id}>
                                                        {ele.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="submission">Affected FMEAs</InputLabel>
                                        <Select
                                            id="submission"
                                            fullWidth
                                            label="Affected FMEAs"
                                            onChange={handleSecMissionsChange}
                                            disabled={!missionSelected || revalorando || missionSelectedCantHaveChildren}
                                            value={Array.isArray(secMissionsSelected) ? secMissionsSelected : []}
                                            multiple
                                            renderValue={(secMissionsSelected) => (
                                                <div>
                                                    {secMissionsSelected.length && secMissionsSelected.map((value) => {
                                                        return (
                                                            <Chip key={value} label={missionsWithAnfes?.find((mission) => mission.id === value)?.name} />
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        >
                                            {missionsWithAnfes?.filter(e => e?.id !== missionSelected).map((ele, i) => {
                                                return (
                                                    <MenuItem key={ele.id} value={ele.id}>
                                                        {ele.name}
                                                    </MenuItem>
                                                );
                                            })}

                                        </Select>
                                    </FormControl>




                                </Grid>


                                <Grid item xs={24}>
                                    <FormControl style={{ width: "100%" }}>
                                        <TextField
                                            id="observaciones"
                                            label="Remarks"
                                            multiline
                                            rows={3}
                                            value={observacionesActual}
                                            size="small"
                                            fullWidth
                                            onChange={e => setObservacionesActual(e.target.value)}
                                        />

                                    </FormControl>



                                </Grid>
                            </Grid>

                        </DialogContent>
                )
            }
            {
                valorTabRO === "ECC" && (
                    // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <DialogContent>
                            
                        
                        <div style={{ width: '95%' }}>

                            <Grid container spacing={2} columns={12}>

                                <Grid item xs={4}>


                                    <TextField
                                        disabled={revalorando}
                                        label="Effect"
                                        multiline
                                        rows={11}
                                        id="efectoFallo"
                                        value={efectoFalloActual}
                                        size="small"
                                        sx={{ width: '100%' }}
                                        // sx={{ m: 1,  }}
                                        onChange={e => setEfectoFalloActual(e.target.value)}
                                    />

                                </Grid>

                                <Grid item xs={4}>

                                    <TextField
                                        disabled={revalorando}
                                        label="Cause"
                                        multiline
                                        rows={11}
                                        id="causaFallo"
                                        value={causaFalloActual}
                                        size="small"
                                        sx={{ width: '100%' }}
                                        // sx={{ m: 1, width: '37ch' }}
                                        onChange={e => setCausaFalloActual(e.target.value)}
                                    />

                                </Grid>

                                <Grid item xs={4}>

                                    <TextField
                                        disabled={revalorando}
                                        label="Current controls"
                                        id="controlesActuales"
                                        multiline
                                        rows={11}
                                        value={controlesActual}
                                        size="small"
                                        sx={{ width: '100%' }}

                                        onChange={e => setControlesActual(e.target.value)}
                                    />

                                </Grid>


                                <Grid item xs={4}>

                                    <IconButton onClick={() => setAyudaSeverity(true)}><InfoOutlined /></IconButton>

                                    <FormControl variant="standard" sx={{ m: 1, width: '34ch' }} size="small">

                                        <InputLabel id="label-select-risk-management">Severity</InputLabel>
                                        <Select
                                            labelId="label-select-risk-management"
                                            id="gravedad"
                                            label="Severity"
                                            onChange={e => { setGravedadActual(e.target.value); }}
                                            value={gravedadActual}
                                            disabled={revalorando}

                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>


                                        </Select>
                                    </FormControl>


                                    <Dialog open={ayudaSeverity} onClose={() => setAyudaSeverity(false)} fullWidth maxWidth="md" >
                                        <DialogTitle>Severity</DialogTitle>
                                        <List sx={{ pt: 0 }} style={{ margin: '20px' }}>


                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Severity</TableCell>
                                                            <TableCell align="left">Criteria</TableCell>
                                                            <TableCell align="left">Value</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rowsSeverity.map((row) => (
                                                            <TableRow
                                                                key={row.value}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {row.severity}
                                                                </TableCell>
                                                                <TableCell align="left">{row.criteria}</TableCell>
                                                                <TableCell align="left">{row.value}</TableCell>

                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </List>
                                    </Dialog>

                                </Grid>

                                <Grid item xs={4}>

                                    <IconButton onClick={() => setAyudaFrequency(true)}><InfoOutlined /></IconButton>
                                    <FormControl variant="standard" sx={{ m: 1, width: '34ch' }} size="small">
                                        <InputLabel id="label-select-risk-management">Frequency</InputLabel>
                                        <Select
                                            labelId="label-select-idea"
                                            id="ocurrencia"
                                            label="Frequency"
                                            onChange={e => { setOcurrenciaActual(e.target.value); }}
                                            value={ocurrenciaActual}

                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>


                                        </Select>
                                    </FormControl>


                                    <Dialog open={ayudaFrequency} onClose={() => setAyudaFrequency(false)} fullWidth maxWidth="md" >
                                        <DialogTitle>Frequency</DialogTitle>
                                        <List sx={{ pt: 0 }} style={{ margin: '20px' }}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Frequency</TableCell>
                                                            <TableCell align="left">Criteria</TableCell>
                                                            <TableCell align="left">Value</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rowsFrequency.map((row) => (
                                                            <TableRow
                                                                key={row.value}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {row.severity}
                                                                </TableCell>
                                                                <TableCell align="left">{row.criteria}</TableCell>
                                                                <TableCell align="left">{row.value}</TableCell>

                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </List>
                                    </Dialog>

                                </Grid>


                                <Grid item xs={4}>
                                    <IconButton onClick={() => setAyudaDetection(true)}><InfoOutlined /></IconButton>
                                    <FormControl variant="standard" sx={{ m: 1, width: '34ch' }} size="small">
                                        <InputLabel id="label-select-risk-management">Detection</InputLabel>
                                        <Select
                                            labelId="label-select-detection"
                                            id="deteccion"
                                            label="Detection"
                                            onChange={e => { setDeteccionActual(e.target.value); }}
                                            value={deteccionActual}

                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>


                                        </Select>
                                    </FormControl>


                                    <Dialog open={ayudaDetection} onClose={() => setAyudaDetection(false)} fullWidth maxWidth="md" >
                                        <DialogTitle>Detection</DialogTitle>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Detection</TableCell>
                                                        <TableCell align="left">Criteria</TableCell>
                                                        <TableCell align="left">Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rowsDetection.map((row) => (
                                                        <TableRow
                                                            key={row.value}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.severity}
                                                            </TableCell>
                                                            <TableCell align="left">{row.criteria}</TableCell>
                                                            <TableCell align="left">{row.value}</TableCell>

                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Dialog>

                                </Grid>
                            </Grid>
                        </div>
                        <div className="radialbar d-flex justify-content-center">


                            <Alert severity={alertaTipo} style={{ marginTop: "20px" }} sx={{
                                '.MuiAlert-icon': {
                                    display: 'none',
                                },
                                '.muiltr-acap47-MuiAlert-message': {
                                    width: 'fit-content',
                                },
                            }}>
                                <section>
                                    {
                                        <AlertTitle style={{ fontSize: '20px', textAlign: 'center', width: '100%' }}>{
                                            nprActual == 'Severity, frequency and detection are required' ? nprActual : `NPR: ${nprActual}`
                                        } </AlertTitle>

                                    }
                                </section>
                                <section className='d-flex justify-content-center align-items-center'>
                                    <strong style={{ textAlign: 'center' }}>{priorizacionActual}</strong>
                                    <IconButton onClick={() => setAyudaNPR(true)}><InfoOutlined /></IconButton>
                                </section>
                                {
                                    nprChart && (
                                        <div className="row" style={{ height: "20px", width: "fit-content" }}>
                                            <Bar options={nprChartOptions} data={nprChart} />
                                        </div>
                                    )
                                }
                            </Alert>



                            <Dialog open={ayudaNPR} onClose={() => setAyudaNPR(false)} >
                                <DialogTitle>Score</DialogTitle>
                                <List sx={{ pt: 0 }} style={{ margin: '20px' }}>


                                    <ListItemText primary="NPR>100 - It is necessary to evaluate and take action" />
                                    <ListItemText primary="NPR>80  - The manager will decide whether to take action" />
                                    <ListItemText primary="NPR≤80  - No action required" />

                                </List>
                            </Dialog>
                        </div>
                        </DialogContent>
       
                )
            }

            <DialogActions>

                        <Button variant="outlined" onClick={() => { setRecordatorioClose(true) }}>{'Close without saving'}</Button>


                <Button variant="outlined" disabled={saveIsDisable} onClick={callCreateHook}>
                    {labelBotonCreate({ modoRyO, alertaTipo, ryoAldredyHaveAction })}
                </Button>


            </DialogActions>


            <Dialog open={recordatorioClose} onClose={() => setRecordatorioClose(false)} fullWidth maxWidth="xs" >
                <DialogTitle>Are you sure?</DialogTitle>
                <List sx={{ pt: 0 }} style={{ margin: '20px' }}>
                    Changes will not be saved, are you sure to close?

                </List>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setRecordatorioClose(false); cambiarVisibilidadModalInsertar(false); dispatch(cambiarVisibilidadModalPrincipalAction(false)); dispatch(cambiarEstadoRyOAction('modo', '')); setnprChart(null) }}>Yes</Button>
                    <Button variant="outlined" onClick={() => setRecordatorioClose(false)}>No</Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    )
}

