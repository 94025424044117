export const MOSTRAR_LOG_RISK = 'MOSTRAR_LOG_RISK'
export const MOSTRAR_LOG_RISK_EXITO = 'MOSTRAR_LOG_RISK_EXITO'
export const MOSTRAR_LOG_RISK_ERROR = 'MOSTRAR_LOG_RISK_ERROR'

export const INSERTAR_NEW_LOG_RISK = 'INSERTAR_NEW_LOG_RISK'
export const INSERTAR_NEW_LOG_RISK_EXITO = 'INSERTAR_NEW_LOG_RISK_EXITO'
export const INSERTAR_NEW_LOG_RISK_ERROR = 'INSERTAR_NEW_LOG_RISK_ERROR'

export const VER_MODAL_DETALLES_LOG_RISK = 'VER_MODAL_DETALLES_LOG_RISK'
export const VER_MODAL_DETALLES_LOG_RISK_EXITO = 'VER_MODAL_DETALLES_LOG_RISK_EXITO'
export const VER_MODAL_DETALLES_LOG_RISK_ERROR = 'VER_MODAL_DETALLES_LOG_RISK_ERROR'