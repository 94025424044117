//UPDATE RISK_MANAGEMENT
export const MOSTRAR_PROCESS = 'MOSTRAR_PROCESS'
export const MOSTRAR_PROCESS_EXITO = 'MOSTRAR_PROCESS_EXITO'
export const MOSTRAR_PROCESS_ERROR = 'MOSTRAR_PROCESS_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_NEW_PROCESS = 'CAMBIAR_MODAL_NEW_PROCESS'
export const CAMBIAR_MODAL_NEW_PROCESS_EXITO = 'CAMBIAR_MODAL_NEW_PROCESS_EXITO'
export const CAMBIAR_MODAL_NEW_PROCESS_ERROR = 'CAMBIAR_MODAL_NEW_PROCESS_ERROR'

//SESION PROCESS
export const CAMBIAR_VALOR_SELECCION_GRID_PROCESS = 'CAMBIAR_VALOR_SELECCION_GRID_PROCESS'
export const CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR'

//UPDATE PROCESS
export const PUT_PROCESS = 'PUT_PROCESS'
export const PUT_PROCESS_EXITO = 'PUT_PROCESS_EXITO'
export const PUT_PROCESS_ERROR = 'PUT_PROCESS_ERROR'

//INSERTAR PROCESS
export const INSERTAR_NEW_PROCESS = 'INSERTAR_NEW_PROCESS'
export const INSERTAR_NEW_PROCESS_EXITO = 'INSERTAR_NEW_PROCESS_EXITO'
export const INSERTAR_NEW_PROCESS_ERROR = 'INSERTAR_NEW_PROCESS_ERROR'