//UPDATE RISK_MANAGEMENT
export const MOSTRAR_KPI_TIME_MANAGEMENT = 'MOSTRAR_KPI_TIME_MANAGEMENT'
export const MOSTRAR_KPI__TIME_MANAGEMENT = 'MOSTRAR_KPI__TIME_MANAGEMENT'
export const MOSTRAR_KPI_TIME_MANAGEMENT_EXITO = "MOSTRAR_KPI_TIME_MANAGEMENT_EXITO"
export const MOSTRAR_KPI_TIME_MANAGEMENT_ERROR = 'MOSTRAR_KPI_TIME_MANAGEMENT_ERROR'
export const DATOS_CONSULTA_TIME_MANAGEMENT = "DATOS_CONSULTA_TIME_MANAGEMENT"
export const MOSTRAR_KPI_INCIDENCIAS_EXITO = "MOSTRAR_KPI_INCIDENCIAS_EXITO"
export const RESET_TIME_MANAGEMENT = "RESET_TIME_MANAGEMENT"
export const LOADING_EXTRA_HOURS = "LOADING_EXTRA_HOURS"
export const SET_EXTRA_HOURS = "SET_EXTRA_HOURS"
export const LOADING_TM_PAGE = "LOADING_TM_PAGE"
export const LOADING_HOLIDAYS = "LOADING_HOLIDAYS"
export const SET_HOLIDAYS = "SET_HOLIDAYS"
export const LOADING_BAG = "LOADING_BAG"
export const SET_BAG = "SET_BAG"
export const LOADING_ABSENTEEISM = "LOADING_ABSENTEEISM"
export const SET_ABSENTEEISM = "SET_ABSENTEEISM"
export const SET_EMPTY_VALUES_TM = "SET_EMPTY_VALUES_TM"


