export const MOSTRAR_APP = 'MOSTRAR_APP'
export const MOSTRAR_APP_EXITO = 'MOSTRAR_APP_EXITO'
export const MOSTRAR_APP_ERROR = 'MOSTRAR_APP_ERROR'

export const PUT_APP = 'PUT_APP'
export const PUT_APP_EXITO = 'PUT_APP_EXITO'
export const PUT_APP_ERROR = 'PUT_APP_ERROR'

export const INSERTAR_NEW_APP = 'INSERTAR_NEW_APP'
export const INSERTAR_NEW_APP_EXITO = 'INSERTAR_NEW_APP_EXITO'
export const INSERTAR_NEW_APP_ERROR = 'INSERTAR_NEW_APP_ERROR'

export const CAMBIAR_MODAL_INSERTAR_APLICATION = 'CAMBIAR_MODAL_INSERTAR_APLICATION'
export const CAMBIAR_MODAL_INSERTAR_APLICATION_EXITO = 'CAMBIAR_MODAL_INSERTAR_APLICATION_EXITO'
export const CAMBIAR_MODAL_INSERTAR_APLICATION_ERROR = 'CAMBIAR_MODAL_INSERTAR_APLICATION_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_APLICATION = 'CAMBIAR_VALOR_SELECCION_GRID_APLICATION'
export const CAMBIAR_VALOR_SELECCION_GRID_APLICATION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_APLICATION_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_APLICATION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_APLICATION_ERROR'

export const MOSTRAR_TYPES_APP = 'MOSTRAR_TYPES_APP'
export const MOSTRAR_TYPES_APP_EXITO = 'MOSTRAR_TYPES_APP_EXITO'
export const MOSTRAR_TYPES_APP_ERROR = 'MOSTRAR_TYPES_APP_ERROR'

export const CAMBIAR_VALOR_TAB_REQUERIMENTS = 'CAMBIAR_VALOR_TAB_REQUERIMENTS'
export const CAMBIAR_VALOR_TAB_REQUERIMENTS_EXITO = 'CAMBIAR_VALOR_TAB_REQUERIMENTS_EXITO'
export const CAMBIAR_VALOR_TAB_REQUERIMENTS_ERROR = 'CAMBIAR_VALOR_TAB_REQUERIMENTS_ERROR'

export const MOSTRAR_GRUPOS_REQUERIMIENTOS = 'MOSTRAR_GRUPOS_REQUERIMIENTOS'
export const MOSTRAR_GRUPOS_REQUERIMIENTOS_EXITO = 'MOSTRAR_GRUPOS_REQUERIMIENTOS_EXITO'
export const MOSTRAR_GRUPOS_REQUERIMIENTOS_ERROR = 'MOSTRAR_GRUPOS_REQUERIMIENTOS_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_GRUPO = 'CAMBIAR_VALOR_SELECCION_GRID_GRUPO'
export const CAMBIAR_VALOR_SELECCION_GRID_GRUPO_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_GRUPO_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_GRUPO_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_GRUPO_ERROR'

export const CAMBIAR_MODAL_INSERTAR_GRUPO = 'CAMBIAR_MODAL_INSERTAR_GRUPO'
export const CAMBIAR_MODAL_INSERTAR_GRUPO_EXITO = 'CAMBIAR_MODAL_INSERTAR_GRUPO_EXITO'
export const CAMBIAR_MODAL_INSERTAR_GRUPO_ERROR = 'CAMBIAR_MODAL_INSERTAR_GRUPO_ERROR'

export const INSERTAR_NEW_GROUP = 'INSERTAR_NEW_GROUP'
export const INSERTAR_NEW_GROUP_EXITO = 'INSERTAR_NEW_GROUP_EXITO'
export const INSERTAR_NEW_GROUP_ERROR = 'INSERTAR_NEW_GROUP_ERROR'

export const MOSTRAR_REQUERIMENTS_WITH_DETAILS = 'MOSTRAR_REQUERIMENTS_WITH_DETAILS'
export const MOSTRAR_REQUERIMENTS_WITH_DETAILS_EXITO = 'MOSTRAR_REQUERIMENTS_WITH_DETAILS_EXITO'
export const MOSTRAR_REQUERIMENTS_WITH_DETAILS_ERROR = 'MOSTRAR_REQUERIMENTS_WITH_DETAILS_ERROR'

export const INSERTAR_REQUERIMENTS_WITH_DETAILS = 'INSERTAR_REQUERIMENTS_WITH_DETAILS'
export const INSERTAR_REQUERIMENTS_WITH_DETAILS_EXITO = 'INSERTAR_REQUERIMENTS_WITH_DETAILS_EXITO'
export const INSERTAR_REQUERIMENTS_WITH_DETAILS_ERROR = 'INSERTAR_REQUERIMENTS_WITH_DETAILS_ERROR'

export const CAMBIAR_ESTADO_VALUES_REQUERIMENTS = 'CAMBIAR_ESTADO_VALUES_REQUERIMENTS'
export const CAMBIAR_ESTADO_VALUES_REQUERIMENTS_EXITO = 'CAMBIAR_ESTADO_VALUES_REQUERIMENTS_EXITO'
export const CAMBIAR_ESTADO_VALUES_REQUERIMENTS_ERROR = 'CAMBIAR_ESTADO_VALUES_REQUERIMENTS_ERROR'

export const DELETE_REQUERIMENT = 'DELETE_REQUERIMENT'
export const DELETE_REQUERIMENT_EXITO = 'DELETE_REQUERIMENT_EXITO'
export const DELETE_REQUERIMENT_ERROR = 'DELETE_REQUERIMENT_ERROR'

export const COMPROBAR_ITEM_LIST_REQUERIMENT = 'COMPROBAR_ITEM_LIST_REQUERIMENT'
export const COMPROBAR_ITEM_LIST_REQUERIMENT_EXITO = 'COMPROBAR_ITEM_LIST_REQUERIMENT_EXITO'
export const COMPROBAR_ITEM_LIST_REQUERIMENT_ERROR = 'COMPROBAR_ITEM_LIST_REQUERIMENT_ERROR'

export const DELETE_GROUP_REQUERIMENT = 'DELETE_GROUP_REQUERIMENT'
export const DELETE_GROUP_REQUERIMENT_EXITO = 'DELETE_GROUP_REQUERIMENT_EXITO'
export const DELETE_GROUP_REQUERIMENT_ERROR = 'DELETE_GROUP_REQUERIMENT_ERROR'

export const PUT_GROUP_REQUERIMENTS = 'PUT_GROUP_REQUERIMENTS'
export const PUT_GROUP_REQUERIMENTS_EXITO = 'PUT_GROUP_REQUERIMENTS_EXITO'
export const PUT_GROUP_REQUERIMENTS_ERROR = 'PUT_GROUP_REQUERIMENTS_ERROR'