import axios from "axios"
import { getCookie } from 'app/js/generalFunctions'
import store from "app/store"
import { getAllStaffFromMissionAndSubmission } from "app/js/generalFunctions"
import { method } from "lodash"
import { useEffect } from "react"
import { showMessage } from "app/store/fuse/messageSlice"
import { useDispatch } from "react-redux"
import { setFilterSearchButtom } from "components/Filter/store/actions"
const TRAINING_URL = `${process.env.REACT_APP_URL_DJANGO}/api/training/`


const getHeaders = () => ({ 'Authorization': `Token ${getCookie('token')}` })


export const trainingRequestAction = async (staffForSearch) => {
    try {
        const staffQueryString = new URLSearchParams({ staff: staffForSearch.join(",") })

        const { data } = await axios({
            method: "GET",
            url: `${TRAINING_URL}?${staffQueryString}`,
            headers: getHeaders()
        });
        return { data, error: false }
    }
    catch (e) { console.log(e); return { data: [], error: true } }
}



