//**********************IMPORTACIONES****************************

import { React, useEffect, useState, useRef, useMemo } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux'
import {
  cambiarVisibilidadModalHistoricoRyOAction,
  cambiarValorSeleccionRyOHistricModalAction,
  cambiarVisibilidadModalHistoricoActionsAction
} from '../../store2/RyO/actions.js'
import TableModules from '../../../../tables/TableModules.js'
import { RYO_COLUMNS } from '../../utils/sectionColumns.js'
// import { useGetRyOHistoryRows } from '../../hooks/RyO/RyOHistoryModalHooks.js'
import { useStyles } from '../../utils/RyOUtils.js'
import { useButtomStyleSelectedRow } from "../../../../../app/js/customHooks/ButtomsHooks.js"


export const ModalRyOHistory = () => {

  // ? Declare dependencies
  const classes = useStyles();
  const dispatch = useDispatch()
  // const { getRyOHistoryRows } = useGetRyOHistoryRows();
  const { getButtomStyleSelectedRow } = useButtomStyleSelectedRow()

  // ? Section states
  const [RyOHistoryRows, setRyOHistoryRows] = useState([]);
  const [buttomColorStyle, setbuttomColorStyle] = useState(null);

  // ? Store selectors
  const loading = useSelector(state => state.fuse.RyOComponente.loading)
  const visibilidadModalHistoricoRyO = useSelector(state => state.fuse.RyOComponente.visibilidadModalHistoricoRyO)
  const RyOFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
  const RyOHistoryFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGridHistory)
  const {noRevRyOICanSee, revRyOICanSee} = useSelector(state => state.fuse.RyOComponente);


  // ? Store dispatchs
  const cambiarVisibilidadModalHistoricoRyO = (valor) => dispatch(cambiarVisibilidadModalHistoricoRyOAction(valor))
  const cambiarValorSeleccion = (valor) => dispatch(cambiarValorSeleccionRyOHistricModalAction(valor))

  // ? Get RyO history records
  // useEffect(() => {
  //   (async function () {
  //     const RyOHistory = await getRyOHistoryRows({ RyOListAPI, visibilidadModalHistoricoRyO, RyOFilaSeleccionada })
  //     setRyOHistoryRows(prev => RyOHistory || []);
  //   })()
  // }, [RyOFilaSeleccionada, visibilidadModalHistoricoRyO]);

  // ? Get RyO history records
  const ryosRevHistory = useMemo(()=>{
    // Ç Mireamos si el ryo seleccionado tiene padreRiskOpportunity
    // Ç Si lo tiene, buscamos en los reevaluados cual tiene ese id y lo devolvemos
    // Ç Quitamos el que acabamos de devolver de la lista, y establecemos su padreRiskOpportunity como nuevo id para la nueva busqueda
    // Ç Lo ordenamos de ultimo a 1º porque la 1º revision no tiene padre
    if(!visibilidadModalHistoricoRyO || !RyOFilaSeleccionada) return []
    let revisionHistory = []
    let revisionId = Number(noRevRyOICanSee.find(({id})=> id === RyOFilaSeleccionada).padreRiskOpportunity)
    if(isNaN(revisionId)) return []
    let revRyOICanSeeForSearch = [...revRyOICanSee]

    const setRyOHistory =(ryoRevList) => {

      for(let index in ryoRevList){
        const ryo = ryoRevList[index]
        const revRyO = ryo.id === revisionId
        if(revRyO){
          revisionHistory = [ryo, ...revisionHistory]
          revisionId = parseInt(ryo.padreRiskOpportunity)
          revRyOICanSeeForSearch.splice(index,1)
          setRyOHistory(revRyOICanSeeForSearch)
        }
      }
    }

    setRyOHistory(revRyOICanSeeForSearch.sort((a,b)=> b.id-a.id))

    return revisionHistory

  }, [visibilidadModalHistoricoRyO, RyOFilaSeleccionada])

  // ? Function for dispatch the RyO actions record
  function RyOActionsLogButtom() {
    dispatch(cambiarVisibilidadModalHistoricoActionsAction(true))
  }

  // ? Get buttom styles states depends of select row
  useEffect(() => {
    const { buttomColorStyle } = getButtomStyleSelectedRow(RyOHistoryFilaSeleccionada);
    setbuttomColorStyle(buttomColorStyle)
  }, [RyOHistoryFilaSeleccionada])


  // ? Return the component
  return (

    <Dialog open={!visibilidadModalHistoricoRyO ? false : true} disableScrollLock={false} onClose={() => { cambiarVisibilidadModalHistoricoRyO(false), cambiarValorSeleccion('') }} fullWidth maxWidth='xl' >
      <DialogTitle classes={{ root: classes.customDialogTitle }} >
        {`R&O ${RyOFilaSeleccionada} revision history`}
      </DialogTitle>
      <Tooltip title="R&O action release history" placement="top">
        <ManageSearchIcon style={{ ...buttomColorStyle, margin: "10px 0 0 10px" }} onClick={RyOHistoryFilaSeleccionada && RyOActionsLogButtom} />
      </Tooltip>

      <TableModules rowsProp={ryosRevHistory} columnsProp={RYO_COLUMNS} loading={loading} funcionSetValue={cambiarValorSeleccion} />

      <DialogActions>
        <Button variant="outlined" onClick={() => { cambiarVisibilidadModalHistoricoRyO(false), cambiarValorSeleccion('') }}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

