//UPDATE RISK_MANAGEMENT
export const SET_SUMMARY_ABC = 'SET_SUMMARY_ABC'
export const SET_HOLI_AND_BAG_FOR_SUMMARY = 'SET_HOLI_AND_BAG_FOR_SUMMARY'
export const SET_HOLI_FOR_SUMMARY = 'SET_HOLI_FOR_SUMMARY'
export const SET_EXTRA_HOURS_NCS = 'SET_EXTRA_HOURS_NCS'
export const SET_GM = 'SET_GM'
export const SET_PEOPLE_TRANSFERRED = "SET_PEOPLE_TRANSFERRED"
export const SET_HOLI_UNTIL_MARCH = "SET_HOLI_UNTIL_MARCH"
export const SET_FOD_AUDIT_SUMMARY = "SET_FOD_AUDIT_SUMMARY"
export const SET_PRL_AUDIT_SUMMARY = "SET_PRL_AUDIT_SUMMARY"
export const RESET_SUMMARY_VALUES = "RESET_SUMMARY_VALUES"
export const SET_TM_SUMMARY_NO_DATA = "SET_TM_SUMMARY_NO_DATA"
export const SET_PERFORMANCE_SUMMARY_NO_DATA = "SET_PERFORMANCE_SUMMARY_NO_DATA"

