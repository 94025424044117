import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestAuthHeader } from "app/js/generalFunctions";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

const urlN2AndN1 = process.env.REACT_APP_URL_DJANGO + "/api/get_n1_and_n2_from_people_id"
const urlProgramsFromStructure = process.env.REACT_APP_URL_DJANGO + "/api/get_programs_from_structure"
const uulSetInspector = process.env.REACT_APP_URL_DJANGO + "/api/set_inspector"


export const getActivitiesThunk= createAsyncThunk('activities', async ()=> {
    const request = await axios({
        method: 'GET',
        headers: getRequestAuthHeader(),

    })
})


export const getN2andN1FromStructure = createAsyncThunk('n2sAdnsN1s', async(idsList, {dispatch})=>{
    try{
        const queryString = new URLSearchParams({ids: idsList}).toString()
        const request = await axios({
            method: 'GET',
            headers: getRequestAuthHeader(),
            url: `${urlN2AndN1}?${queryString}`
        })

        if(request.status!==200) throw new Error('error')
        return request.data
    
    }
    catch(e){
        dispatch(showMessage({message:'Error getting possibles inspectors', variant: 'error'}))
        console.log("🚀 ~ getN2andN1FromStructure ~ e:", e)
        return []
        
    }
})


export const getProgramsFromStructure = createAsyncThunk('getProgramsFromStructure', async({allWpsIds, allMissionsIds}, {dispatch})=>{
    try{
        const queryString = new URLSearchParams({allWpsIds, allMissionsIds}).toString()
        const request = await axios({
            method: 'GET',
            headers: getRequestAuthHeader(),
            url: urlProgramsFromStructure
        })

        if(request.status!==200) throw new Error('error')
            console.log("🚀 ~ getProgramsFromStructure ~ request.data:", request.data)
        return request.data
    
    }
    catch(e){
        dispatch(showMessage({message:'Error getting programs', variant: 'error'}))
        console.log("🚀 ~ getN2andN1FromStructure ~ e:", e)
        return []
        
    }
})

// ? INSPECTOR
// export const getProgramsFromStructure = createAsyncThunk('getProgramsFromStructure', async({allWpsIds, allMissionsIds}, {dispatch})=>{
//     try{
//         const queryString = new URLSearchParams({allWpsIds, allMissionsIds}).toString()
//         const request = await axios({
//             method: 'GET',
//             headers: getRequestAuthHeader(),
//             url: urlProgramsFromStructure
//         })

//         if(request.status!==200) throw new Error('error')
//             console.log("🚀 ~ getProgramsFromStructure ~ request.data:", request.data)
//         return request.data
    
//     }
//     catch(e){
//         dispatch(showMessage({message:'Error getting programs', variant: 'error'}))
//         console.log("🚀 ~ getN2andN1FromStructure ~ e:", e)
//         return []
        
//     }
// })


