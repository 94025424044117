import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Divider from '@mui/material/Divider';
import { useCreateButtom, useEditButtom, useDeleteButtom, useHistoryButtom, useActionsButtom } from '../../hooks/RyO/RyOTopButtomsHooks.js'
import { useButtonPermissionStyle, useButtomStyleSelectedRow } from "../../../../../app/js/customHooks/ButtomsHooks.js"
import { getIdsFromArr } from 'app/js/generalFunctions.js';

export function BotonesSuperioresRyO() {

    // ? Store selectors
    const RyOFilaSeleccionada = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
    const { myRyOsList, noRevRyOICanSee } = useSelector(state => state.fuse.RyOComponente)

    // ? Buttoms actions
    const { createButtom } = useCreateButtom()
    const { editButtom } = useEditButtom()
    const { deleteButtom } = useDeleteButtom()
    const { historyButtom } = useHistoryButtom()
    const { actionsButtom } = useActionsButtom()
    const { getButtomPermissionStyle } = useButtonPermissionStyle()
    const { getButtomStyleSelectedRow } = useButtomStyleSelectedRow()

    // ? Buttons styles states
    const [canAddRyOButtom, setCanAddRyOButtom] = useState(null)
    const [canEditButtom, setcanEditButtom] = useState(null)
    const [canDeleteButtom, setcanDeleteButtom] = useState(null)
    const [canAddActionButtom, setCanAddActionButtom] = useState(null)
    const [buttomColorStyle, setbuttomColorStyle] = useState(null)

    // ? Get buttom styles states depends of permissions
    useEffect(() => {

        const { canAddPermissionStyle, canEditPermissionStyle, canDeletePermissionStyle } = getButtomPermissionStyle("rm risk opportunity");
        const canAddActionsPermissionStyle = getButtomPermissionStyle("rm action").canAddPermissionStyle;
        setCanAddRyOButtom(canAddPermissionStyle);
        setcanEditButtom(canEditPermissionStyle)
        setcanDeleteButtom(canDeletePermissionStyle)
        setCanAddActionButtom(canAddActionsPermissionStyle)

    }, []);

    const selectedRyOIsMine = useMemo(() => {
        if(!noRevRyOICanSee.length) return
        return getIdsFromArr(myRyOsList).includes(RyOFilaSeleccionada) ? true : false
    }, [RyOFilaSeleccionada,noRevRyOICanSee, myRyOsList])



    // ? Return the component
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <section className="d-flex" style={{ justifyContent: "flex-start", gap: "10px", alignItems: "center", margin: "5px 0 5px 0" }}>

                    <Tooltip title="New" placement="top">
                        <AddCircleIcon style={{ ...canAddRyOButtom, marginLeft: "10px" }} variant="outlined" onClick={createButtom} />
                    </Tooltip>
                    {
                        selectedRyOIsMine && (
                            <>
                                <Tooltip title="Edit" placement="top">
                                    <EditIcon style={canEditButtom} onClick={RyOFilaSeleccionada && editButtom} />
                                </Tooltip>


                                <Tooltip title="Delete" placement="top">
                                    <DeleteIcon style={canDeleteButtom} onClick={RyOFilaSeleccionada && deleteButtom} />
                                </Tooltip>

                                <Tooltip title="Actions" placement="top">
                                    <AddTaskIcon style={canAddActionButtom} onClick={RyOFilaSeleccionada && actionsButtom} />
                                </Tooltip>

                                <Tooltip title="Historical R&O" placement="top">
                                    <HistoryIcon style={buttomColorStyle} onClick={RyOFilaSeleccionada && historyButtom} />
                                </Tooltip>
                            </>
                        )
                    }


                </section>

                <Divider />
            </Box>
        </>
    );
}