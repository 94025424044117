import { useDispatch, useSelector } from "react-redux"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useEditButtom,useActionsButtom} from "../../hooks/RyO/RyOTopButtomsHooks"
import {visibilidadModalActionRequired} from "../../store2/RyO/actions.js"

export const PedingActionAdvise =()=>{
    // ! Esto tiene que lanzar o edit o crear accion del ryo id que npr > 100 y no action
    // ? Clase pendiente
    const dispatch = useDispatch()
    const {editButtom} = useEditButtom();
    const {actionsButtom} = useActionsButtom();
    const closeActionRequiredModal = (bool) => dispatch(visibilidadModalActionRequired(false))

    const actionRequiredModalVisibility = useSelector(state => state.fuse.RyOComponente.visibilidadModalActionRequired)
    const idRyOWhenIsNoSelectedrow = useSelector(state => state.fuse.RyOComponente.idRyOWhenNoSelectedRow);

    return (
        <Dialog open={actionRequiredModalVisibility} fullWidth maxWidth='xs'>
            <DialogTitle  >
                Action for R&O with id {idRyOWhenIsNoSelectedrow} must be taken
            </DialogTitle>
            <DialogContent>
                R&O with id {idRyOWhenIsNoSelectedrow} have a NPR higher than 100, and you are the manager of the FMEA that contains this R&O, that's why you must take an action or edit the R&O NPR.
            </DialogContent>
            <DialogActions>

                <Button variant="outlined" onClick={()=>{editButtom(); closeActionRequiredModal()}}>Edit R&O</Button>
                <Button variant="outlined" onClick={()=>{actionsButtom(); closeActionRequiredModal()}}> Take action</Button>
                <Button variant="outlined" onClick={()=>{dispatch(visibilidadModalActionRequired(false))}}> Take action later</Button>

            </DialogActions>
        </Dialog>
)
}