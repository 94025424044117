import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import history from '@history';
import { Router, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie } from 'app/js/generalFunctions';
import { getSesionActualAPIAction } from 'components/Managment/Users/store/actions';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
function BrowserRouter({ children }) {
  
  const [location, setLocation] = useState({
    action: history.action,
    location: history.location,
  });

  const [pagesView, setPagesView] = useState(true)



  const dispatch = useDispatch()


  const {sectionsViewsIsLoad, person: personLogin} = useSelector(state => state.fuse.userComponente)
  const personPermissions = useSelector(state => state.fuse.userComponente.personPermisos)


  const moveToHome =()=> {
    setLocation(location => ({
      action: location.action,
      location: {
        ...location.location,
        pathname: "/"
      }
    }))
    history.replace("/");
  }

  const moveToLogin =()=>{
    setLocation(location => ({
      action: location.action,
      location: {
        ...location.location,
        pathname: "/login"
      }
    }))
    history.replace("/login");
  }
  
  const token =  getCookie('token')
  const path = location.location.pathname;
  useEffect(() => {
    const personLoginId = personLogin?.id
    // console.log("🚀 ~ token:", token)
    // console.log("🚀 ~ useEffect ~ personLoginId:", personLoginId)
    // console.log("🚀 ~ sectionsViewsIsLoad:", sectionsViewsIsLoad)
    
    const pathIsLogin = path === "/login"
    const canEnter = personLoginId && token && personPermissions.length

    if (!sectionsViewsIsLoad && pathIsLogin) setPagesView(true)
    else if (!sectionsViewsIsLoad) setPagesView(false)
    else setPagesView(true)


    if (canEnter) {
      if (pathIsLogin)  moveToHome()
    }
    else {
      if (token && !personLoginId && !personPermissions.length && !pathIsLogin) {
        dispatch(getSesionActualAPIAction({token}))
      }
      if (!token) moveToLogin()
    }
  }, [token, personLogin, sectionsViewsIsLoad, path, personPermissions])






  useLayoutEffect(() => history.listen(setLocation), [history]);
  // return(
  //   <Router
  //   // basename={basename}
  //   location={location.location}
  //   navigationType={location.action}
  //   navigator={history}
  // >
  //   {children}
  // </Router>
  // )
  return (
    <>
      {
        pagesView ?
          (
            <>
              <Router
                // basename={basename}
                location={location.location}
                navigationType={location.action}
                navigator={history}
              >
                {children}
              </Router>
            </>

          )
          :
          <FuseSplashScreen />

      }
    </>

  );
  // return (
  //   <Router
  //     basename={basename}
  //     location={location.location}
  //     navigationType={location.action}
  //     navigator={history}
  //   >
  //     {children}
  //   </Router>
  // );
}

export default BrowserRouter;
