export const CAMBIAR_VALOR_TAB_KPI = 'CAMBIAR_VALOR_TAB_KPI'
export const CAMBIAR_VALOR_TAB_KPI_EXITO = 'CAMBIAR_VALOR_TAB_KPI_EXITO'
export const CAMBIAR_VALOR_TAB_KPI_ERROR = 'CAMBIAR_VALOR_TAB_KPI_ERROR'

export const CAMBIAR_VALOR_KPI_SELECCIONADO = 'CAMBIAR_VALOR_KPI_SELECCIONADO'
export const CAMBIAR_VALOR_KPI_SELECCIONADO_EXITO = 'CAMBIAR_VALOR_KPI_SELECCIONADO_EXITO'
export const CAMBIAR_VALOR_KPI_SELECCIONADO_ERROR = 'CAMBIAR_VALOR_KPI_SELECCIONADO_ERROR'

export const CAMBIAR_VALOR_MISION_SELECCIONADO = 'CAMBIAR_VALOR_MISION_SELECCIONADO'
export const CAMBIAR_VALOR_MISION_SELECCIONADO_EXITO = 'CAMBIAR_VALOR_MISION_SELECCIONADO_EXITO'
export const CAMBIAR_VALOR_MISION_SELECCIONADO_ERROR = 'CAMBIAR_VALOR_MISION_SELECCIONADO_ERROR'

export const CAMBIAR_VALOR_ANO_SELECCIONADO = 'CAMBIAR_VALOR_ANO_SELECCIONADO'
export const CAMBIAR_VALOR_ANO_SELECCIONADO_EXITO = 'CAMBIAR_VALOR_ANO_SELECCIONADO_EXITO'
export const CAMBIAR_VALOR_ANO_SELECCIONADO_ERROR = 'CAMBIAR_VALOR_ANO_SELECCIONADO_ERROR'