export const FILTER_ACTION_TYPE = {
    SET_FIRST_RENDER: 'SET_FIRST_RENDER',
    SHOW_FILTER: 'SHOW_FILTER',
    SET_INITIAL_DATE: "SET_INITIAL_DATE",
    SET_FINAL_DATE: "SET_FINAL_DATE",
    SET_YEARS_INTERVAL_SELECTED: "SET_YEARS_INTERVAL_SELECTED",
    SET_CHART_OR_TABLE_VIEW_STATE: "SET_CHART_OR_TABLE_VIEW_STATE",
    SET_X_AXIS_VALUES: "SET_X_AXIS_VALUES",
    SET_MISSION_SELECTED: "SET_MISSION_SELECTED",
    SET_SUB_MISSION_SELECTED: "SET_SUB_MISSION_SELECTED",
    SET_MISSION_SELECTED_CONTRACTS: "SET_MISSION_SELECTED_CONTRACTS",
    RESET_VALUES: "RESET_BA_FILTER_VALUES",
    SAVE_MISSIONS_I_MUST_SEE: "SAVE_MISSIONS_I_MUST_SEE",
    SAVE_SUB_MISSIONS_I_MUST_SEE: "SAVE_SUB_MISSIONS_I_MUST_SEE",
    SET_AGRESSO_AND_VTG: "SET_AGRESSO_AND_VTG",
    SET_VT_GROUP_SELECTED: "SET_VT_GROUP_SELECTED", 
    SET_VT_GROUPS_FROM_MISSION_SELECTED: "SET_VT_GROUPS_FROM_MISSION_SELECTED",
    SET_MISSION_MANAGER_SELECTED: "SET_MISSION_MANAGER_SELECTED",
    // !
    SET_PERSON_LOGIN_IS_SITE_MANAGER: "SET_PERSON_LOGIN_IS_SITE_MANAGER",
    PERSON_LOGIN_CAN_SEE_ALL_BA: "PERSON_LOGIN_CAN_SEE_ALL_BA",
    // !
    SET_PERSON_LOGIN_IS_N4_AND_CAN_SEE_ALL_BA: "SET_PERSON_LOGIN_IS_N4_AND_CAN_SEE_ALL_BA",
    SET_MISSIONS_MANAGERS_FROM_SITE_MANAGER_WP: "SET_MISSIONS_MANAGERS_FROM_SITE_MANAGER_WP",
    SET_MANAGER_SELECTED_IDRES: "SET_MANAGER_SELECTED_IDRES",
    SET_PEOPLE_IN_MISSION_AND_SUBMISSION: "SET_PEOPLE_IN_MISSION_AND_SUBMISSION",
    SET_MISSIONS_AND_SUBMISSIONS_STR_ID: "SET_MISSIONS_AND_SUBMISSIONS_STR_ID",
    GET_FILTER_STRUCTURE: "GET_FILTER_STRUCTURE",
    SET_PEOPLE_SEARCH : 'SET_PEOPLE_SEARCH',
    SET_SERACH_BUTTOM: "SET_SERACH_BUTTOM",
    SET_MISSION_AGREESO_AND_VTG: 'SET_MISSION_AGREESO_AND_VTG',
    SET_AUDIT_PERSON_IDRES: 'SET_AUDIT_PERSON_IDRES',
    SET_SUMMARY_REQUES_DONE: 'SET_SUMMARY_REQUES_DONE',
}