//******************** COMPONENTE PRINCIPAL *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA = 'CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA'
export const CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_EXITO = 'CAMBIAR_VALOR_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_PROGRAMA_ERROR = 'CAMBIAR_VALOR_ERROR'

// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA = 'CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA'
export const CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_PROGRAMA_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_PROGRAMA = 'ELIMINAR_PROGRAMA'
export const ELIMINAR_PROGRAMA_EXITO = 'ELIMINAR_PROGRAMA_EXITO'
export const ELIMINAR_PROGRAMA_ERROR = 'ELIMINAR_PROGRAMA_ERROR'

//CONSULTA A API PROGRAMAS PARA GRID
export const MOSTRAR_PROGRAMAS_API = 'MOSTRAR_PROGRAMAS_API'
export const MOSTRAR_PROGRAMAS_EXITO = 'MOSTRAR_PROGRAMAS_EXITO'
export const MOSTRAR_PROGRAMAS_ERROR = 'MOSTRAR_PROGRAMAS_ERROR'




//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_PROGRAMA_ERROR'

//INSERTAR NUEVO PROGRAMA
export const INSERTAR_PROGRAMA_MODAL_INSERTAR = 'INSERTAR_PROGRAMA_MODAL_INSERTAR'
export const INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO = 'INSERTAR_PROGRAMA_MODAL_INSERTAR_EXITO'
export const INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR = 'INSERTAR_PROGRAMA_MODAL_INSERTAR_ERROR'

//ACTUALIZAR NUEVO PROGRAMA
export const ACTUALIZAR_PROGRAMA_MODAL_INSERTAR = 'ACTUALIZAR_PROGRAMA_MODAL_INSERTAR'
export const ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO = 'ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_EXITO'
export const ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR = 'ACTUALIZAR_PROGRAMA_MODAL_INSERTAR_ERROR'

//CONSULTA LAS SUBDIVISIONES
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA = 'CONSULTA_SUBDIVISIONES_MODAL'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_EXITO'
export const CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR = 'CONSULTA_SUBDIVISIONES_MODAL_INSERTAR_PROGRAMA_ERROR'