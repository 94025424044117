export const GET_REQUEST_USER = 'GET_REQUEST_USER'
export const GET_REQUEST_USER_EXITO = 'GET_REQUEST_USER_EXITO'
export const GET_REQUEST_USER_ERROR = 'GET_REQUEST_USER_ERROR'

export const POST_REQUEST_USER = 'POST_REQUEST_USER'
export const POST_REQUEST_USER_EXITO = 'POST_REQUEST_USER_EXITO'
export const POST_REQUEST_USER_ERROR = 'POST_REQUEST_USER_ERROR'

export const CAMBIAR_VALOR_USER_SELECTED = 'CAMBIAR_VALOR_USER_SELECTED'
export const CAMBIAR_VALOR_USER_SELECTED_EXITO = 'CAMBIAR_VALOR_USER_SELECTED_EXITO'
export const CAMBIAR_VALOR_USER_SELECTED_ERROR = 'CAMBIAR_VALOR_USER_SELECTED_ERROR'