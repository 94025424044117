export const MOSTRAR_GROUP = 'MOSTRAR_GROUP'
export const MOSTRAR_GROUP_EXITO = 'MOSTRAR_GROUP_EXITO'
export const MOSTRAR_GROUP_ERROR = 'MOSTRAR_GROUP_ERROR'

export const PUT_GROUP = 'PUT_GROUP'
export const PUT_GROUP_EXITO = 'PUT_GROUP_EXITO'
export const PUT_GROUP_ERROR = 'PUT_GROUP_ERROR'

export const INSERTAR_NEW_GROUP = 'INSERTAR_NEW_GROUP'
export const INSERTAR_NEW_GROUP_EXITO = 'INSERTAR_NEW_GROUP_EXITO'
export const INSERTAR_NEW_GROUP_ERROR = 'INSERTAR_NEW_GROUP_ERROR'

export const CAMBIAR_MODAL_INSERTAR_GROUP = 'CAMBIAR_MODAL_INSERTAR_GROUP'
export const CAMBIAR_MODAL_INSERTAR_GROUP_EXITO = 'CAMBIAR_MODAL_INSERTAR_GROUP_EXITO'
export const CAMBIAR_MODAL_INSERTAR_GROUP_ERROR = 'CAMBIAR_MODAL_INSERTAR_GROUP_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_GROUP = 'CAMBIAR_VALOR_SELECCION_GRID_GROUP'
export const CAMBIAR_VALOR_SELECCION_GRID_GROUP_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_GROUP_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_GROUP_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_GROUP_ERROR'

export const MOSTRAR_PERMISOS = 'MOSTRAR_PERMISOS'
export const MOSTRAR_PERMISOS_EXITO = 'MOSTRAR_PERMISOS_EXITO'
export const MOSTRAR_PERMISOS_ERROR = 'MOSTRAR_PERMISOS_ERROR'

export const MOSTRAR_ALL_PERMISOS = 'MOSTRAR_ALL_PERMISOS'
export const MOSTRAR_ALL_PERMISOS_EXITO = 'MOSTRAR_ALL_PERMISOS_EXITO'
export const MOSTRAR_ALL_PERMISOS_ERROR = 'MOSTRAR_ALL_PERMISOS_ERROR'