import {

    OBTENER_USER_DETAILS,
    OBTENER_USER_DETAILS_EXITO,
    OBTENER_USER_DETAILS_ERROR,

    CAMBIAR_MODAL_VISIBILIDAD_DETALLES,
    CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO,
    CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR,

    OBTENER_SUB_MISION_DIRECTAS,
    OBTENER_SUB_MISION_DIRECTAS_EXITO,
    OBTENER_SUB_MISION_DIRECTAS_ERROR,

    OBTENER_MISION_DIRECTAS,
    OBTENER_MISION_DIRECTAS_EXITO,
    OBTENER_MISION_DIRECTAS_ERROR,

    OBTENER_WP_DIRECTAS,
    OBTENER_WP_DIRECTAS_EXITO,
    OBTENER_WP_DIRECTAS_ERROR,

    OBTENER_DEPARTAMENTOS_DIRECTAS,
    OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO,
    OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR,

    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS,
    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO,
    OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR,

    OBTENER_SUB_DIVISIONES_DIRECTAS,
    OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO,
    OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR

} from './types';
import axios from 'axios'
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'


//Constantes
const urlUserApi = process.env.REACT_APP_URL_DJANGO + "/api/user/"

//************************ OBTENER SUB DIVISION DIRECTAS DEL USUARIO**********************************************

export function obtenerSubDivisionesDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerSubDivisionesDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misSubDivisionesDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerSubDivisionesDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerSubDivisionesDirectasAPIError(true))
            })

    }
}

const obtenerSubDivisionesDirectasAPI = (estado) => ({
    type: OBTENER_SUB_DIVISIONES_DIRECTAS,
    payload: true

})

const obtenerSubDivisionesDirectasAPIExito = contratos_user => ({
    type: OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerSubDivisionesDirectasAPIError = estado => ({
    type: OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR,
    payload: estado
})

//************************ OBTENER DireccionDepartamental DIRECTAS DEL USUARIO**********************************************

export function obtenerDireccionDepartamentalDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerDireccionDepartamentalDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misDireccionDepartamentalDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerDireccionDepartamentalDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerDireccionDepartamentalDirectasAPIError(true))
            })

    }
}

const obtenerDireccionDepartamentalDirectasAPI = (estado) => ({
    type: OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS,
    payload: true

})

const obtenerDireccionDepartamentalDirectasAPIExito = contratos_user => ({
    type: OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerDireccionDepartamentalDirectasAPIError = estado => ({
    type: OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR,
    payload: estado
})

//************************ OBTENER DEPARTAMENTOS DIRECTAS DEL USUARIO**********************************************

export function obtenerDepartamentosDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerDepartamentosDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misDepartamentosDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerDepartamentosDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerDepartamentosDirectasAPIError(true))
            })

    }
}

const obtenerDepartamentosDirectasAPI = (estado) => ({
    type: OBTENER_DEPARTAMENTOS_DIRECTAS,
    payload: true

})

const obtenerDepartamentosDirectasAPIExito = contratos_user => ({
    type: OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerDepartamentosDirectasAPIError = estado => ({
    type: OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR,
    payload: estado
})

//************************ OBTENER WP DIRECTAS DEL USUARIO**********************************************

export function obtenerWPDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerWPDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misWPDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerWPDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerWPDirectasAPIError(true))
            })

    }
}

const obtenerWPDirectasAPI = (estado) => ({
    type: OBTENER_WP_DIRECTAS,
    payload: true

})

const obtenerWPDirectasAPIExito = contratos_user => ({
    type: OBTENER_WP_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerWPDirectasAPIError = estado => ({
    type: OBTENER_WP_DIRECTAS_ERROR,
    payload: estado
})

//************************ OBTENER MISIONES DIRECTAS DEL USUARIO**********************************************

export function obtenerMisionesDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerMisionesDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misMisionesDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerMisionesDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerMisionesDirectasAPIError(true))
            })

    }
}

const obtenerMisionesDirectasAPI = (estado) => ({
    type: OBTENER_MISION_DIRECTAS,
    payload: true

})

const obtenerMisionesDirectasAPIExito = contratos_user => ({
    type: OBTENER_MISION_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerMisionesDirectasAPIError = estado => ({
    type: OBTENER_MISION_DIRECTAS_ERROR,
    payload: estado
})

//************************ OBTENER SUB MISIONES DIRECTAS DEL USUARIO**********************************************

export function obtenerSubMisionesDirectasAPIAction(idPersona) {

    return async (dispatch) => {
        dispatch(obtenerSubMisionesDirectasAPI(true))


        await axios({
            method: "GET",
            url: urlUserApi + "misSubMisionesDirectas/" + idPersona,
            headers: {
                'Authorization': `Token ${getCookie('token')}`
            }

        })
            .then(response => {
                dispatch(obtenerSubMisionesDirectasAPIExito(response.data))

            })
            .catch(error => {
                console.log(error.response)
                dispatch(obtenerSubMisionesDirectasAPIError(true))
            })

    }
}

const obtenerSubMisionesDirectasAPI = (estado) => ({
    type: OBTENER_SUB_MISION_DIRECTAS,
    payload: true

})

const obtenerSubMisionesDirectasAPIExito = contratos_user => ({
    type: OBTENER_SUB_MISION_DIRECTAS_EXITO,
    payload: contratos_user

})

const obtenerSubMisionesDirectasAPIError = estado => ({
    type: OBTENER_SUB_MISION_DIRECTAS_ERROR,
    payload: estado
})

//************************CAMBIAR VISIBILIDAD MODAL NUEVO USUARIO**********************************************

export function setValueUserSeleccionadoAPIAction(valorNuevo) {

    return (dispatch) => {
        dispatch (setValueUserSeleccionadoAPI())

        try {
            dispatch (setValueUserSeleccionadoAPIExito(valorNuevo))

        } catch (error) {

            dispatch (setValueUserSeleccionadoAPIError(true))
        }
    }
}

const setValueUserSeleccionadoAPI = () => ({
    type: OBTENER_USER_DETAILS,
    payload: true
})

const setValueUserSeleccionadoAPIExito = (valorNuevo) => ({
    type: OBTENER_USER_DETAILS_EXITO,
    payload: valorNuevo
})

const setValueUserSeleccionadoAPIError = estado => ({
  type:  OBTENER_USER_DETAILS_ERROR,
  payload: estado
})
//************************CAMBIAR VISIBILIDAD MODAL NUEVO USUARIO**********************************************

export function cambiarVisibilidadModalInsertarUserAPI(valorNuevo) {

    return (dispatch) => {
        dispatch (cambiarVisibilidadModalNuevoUser())

        try {
            dispatch (cambiarVisibilidadModalNuevoUserExito(valorNuevo))

        } catch (error) {

            dispatch (cambiarVisibilidadModalNuevoUserError(true))
        }
    }
}

const cambiarVisibilidadModalNuevoUser = () => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_DETALLES,
    payload: true
})

const cambiarVisibilidadModalNuevoUserExito = (valorNuevo) => ({
    type: CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO,
    payload: valorNuevo
})

const cambiarVisibilidadModalNuevoUserError = estado => ({
  type:  CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR,
  payload: estado
})