import {
    MOSTRAR_KPI_PERFORMANCE,
    MOSTRAR_KPI_PERFORMANCE_EXITO,
    MOSTRAR_KPI_PERFORMANCE_ERROR,
    SET_PEOPLE_TRANSFERRED_LOADING,
    SET_PEOPLE_TRANSFERRED_RESPONSE,
    SET_PEOPLE_TRANSFERRED_ERROR,
    SET_ABC_LOADING,
    SET_ABC_RESPONSE,
    SET_ABC_ERROR,
    SET_EMPTY_VALUES_IN_PERFORMANCE,
} from './types';

import dayjs from "dayjs";


// cada reducer tiene su propio state
const initialState = {
    soldHoursBrakeDown: [],
    error: null,
    loading: false,
    soldVsWorkHours: [],
    workHoursPermanent: [],
    workHoursTemporal: [],
    bestWorstPerformance: [],
    peopleTransferredStore:[],
    loadingPeopleTransferred: true,
    errorPeopleTransferred: false,
    loadingABC: true,
    errorABC: false,
    fRequestMade:false,
}


export default function (state = initialState, action) {

    switch (action.type) {


        //********** MOSTRAR KPI API**********************************/ 


        case SET_ABC_LOADING:
            return {
                ...state,
                loadingABC: true
            }
        
        case SET_ABC_ERROR:
            return{
                ...state,
                loadingABC: false,
                errorABC: true
            }
        
        case SET_ABC_RESPONSE:
            return {
                ...state,
                loadingABC: false,
                errorABC: false,
                soldVsWorkHours: action.payload,
                bestWorstPerformance: action.payload,
                fRequestMade: true
            }

        case SET_PEOPLE_TRANSFERRED_LOADING:
            return {
                ...state,
                loadingPeopleTransferred: true
            }
        
        case SET_PEOPLE_TRANSFERRED_ERROR:
            return{
                ...state,
                loadingPeopleTransferred: false,
                errorPeopleTransferred: true
            }
        
        case SET_PEOPLE_TRANSFERRED_RESPONSE:
            return{
                ...state,
                loadingPeopleTransferred: false,
                errorPeopleTransferred: false,
                peopleTransferredStore: action.payload
            }

        case MOSTRAR_KPI_PERFORMANCE:
            return {
                ...state,
                soldHoursBrakeDown: [],
                error: null,
                soldVsWorkHours: [],
                workHoursPermanent: [],
                workHoursTemporal: [],
                bestWorstPerformance: [],
                loading: action.payload,
            }

        case MOSTRAR_KPI_PERFORMANCE_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                // soldVsWorkHours: action.payload,
                // bestWorstPerformance: action.payload,
                soldVsWorkHours: action.payload[1],
                bestWorstPerformance: action.payload[1],
                peopleTransferredStore: action.payload[0]


            }

        case MOSTRAR_KPI_PERFORMANCE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        case SET_EMPTY_VALUES_IN_PERFORMANCE:
            return {
                ...state,
                soldHoursBrakeDown: [],
                soldVsWorkHours: [],
                workHoursPermanent: [],
                workHoursTemporal: [],
                bestWorstPerformance: [],
                peopleTransferredStore:[],
                loadingPeopleTransferred: false,
                loadingABC: false,

            }



        default:
            return state

    }

}