
//**************IMPORTACIONES*************************************************

import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import TasksHeader from './TasksHeader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import List from '@mui/material/List';
import Help from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import { lighten, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

//Redux importaciones
import { useDispatch, useSelector } from 'react-redux';
import {
        mostrarRyOAccionAPIAction,
        cambiarEstadoRyOTasksAction,
} from '../../store2/actions/actions.js'
import store from "app/store/index"

import {
    cambiarVisibilidadModalInsertarRyOAction,
    reevaluateRyOAction,
    cambiarEstadoRyOAction
} from '../../store2/RyO/actions.js'

import TasksList from './TasksList';
import TaskForm from './task/TaskForm.js'
import { useMemo } from 'react';
// import TaskListItem from './TaskListItem';




const useStyles = makeStyles({

        customDialogTitle: {
                backgroundColor: 'rgb(37, 47, 62)',
                color: 'rgb(255, 255, 255)',
                marginBottom: '0.5em'
        }

});



const Root = styled(FusePageSimple)(({ theme }) => ({
        '& .FusePageSimple-header': {
                backgroundColor: theme.palette.background.paper,
        },
}));

export function ModalRyOActions(props) {

    const dispatch = useDispatch();
    const classes = useStyles();

    //Obtener los states de Redux
    const rmAccionesListAPI = useSelector(state => state.fuse.tasksAccionesComponente.rmAccionesListAPI)
    const visibilidadModalTasks = useSelector(state => state.fuse.tasksAccionesComponente.visibilidad)
    const visibilidadNuevaAccion = useSelector(state => state.fuse.tasksAccionesComponente.visibilidadNuevaAccion)
    const filaSeleccionadaGridRyOStore = useSelector(state => state.fuse.RyOComponente.filaSeleccionadaGrid)
    const idRyOWhenIsNoSelectedrow = useSelector(state => state.fuse.RyOComponente.idRyOWhenNoSelectedRow);
    const personLoginPermisos = useSelector(state => state.fuse.userComponente.personPermisos)
    const actionIsRequired = useSelector(state => state.fuse.RyOComponente.actionIsRequired)
    
    // !!!!!!!!!!!!!!!! MIS ESTADOS
    const [allActionsCompleted, setAllActionsCompleted] = useState(null);
    const [filaSeleccionadaGridRyO, setfilaSeleccionadaGridRyO] = useState(null)
    
    useEffect(()=>{
        if (!visibilidadModalTasks) return
        setfilaSeleccionadaGridRyO(prev => filaSeleccionadaGridRyOStore || idRyOWhenIsNoSelectedrow)
    },[visibilidadModalTasks, idRyOWhenIsNoSelectedrow, filaSeleccionadaGridRyOStore])


    //Creamos funciones para hacer uso de Actions Redux
    const mostrarRyOAccionAPI = () => dispatch(mostrarRyOAccionAPIAction())






    useEffect(() => !rmAccionesListAPI.length && mostrarRyOAccionAPI(), [])

    useEffect(() => {
        const RyOActions = rmAccionesListAPI.filter(action => action.id_record === filaSeleccionadaGridRyO)
        const allActionsCompleted = RyOActions.length &&  RyOActions?.every(action => action.completed === true)
        setAllActionsCompleted(()=>allActionsCompleted ? true : false)
    }, [rmAccionesListAPI,filaSeleccionadaGridRyO])


    const AllTaskCompleteComponent = () => {
        const [allCompleteModalVisibility, setallCompleteModalVisibility] = useState(true)
        useMemo(()=> setallCompleteModalVisibility(!!allActionsCompleted ? true : false) ,[allActionsCompleted])
        function decline() {
            setallCompleteModalVisibility(false)
            dispatch(cambiarEstadoRyOTasksAction('visibilidad', false))
        }

        function confirm(){
            setallCompleteModalVisibility(false)
            dispatch(cambiarEstadoRyOTasksAction('visibilidad', false));
            
            dispatch(cambiarEstadoRyOAction("modo", "rev"))
            dispatch(reevaluateRyOAction(true));
            dispatch(cambiarVisibilidadModalInsertarRyOAction(true));
  
        }

        return (
                <Dialog open={allCompleteModalVisibility} fullWidth maxWidth='xs'>
                    <DialogTitle classes={{ root: classes.customDialogTitle }} >
                        Re evaluate
                    </DialogTitle>
                    <DialogContent>
                        All your actions are completed. Click "Confirm" to re evaluate de R&O
                    </DialogContent>
                    <DialogActions>

                        <Button variant="outlined" onClick={decline}>Decline</Button>
                        <Button variant="outlined" onClick={confirm}> Confirm</Button>

                    </DialogActions>
                </Dialog>
        )
    }


    return (

        <Dialog open={visibilidadModalTasks} onClose={() => dispatch(cambiarEstadoRyOTasksAction('visibilidad', false))} fullWidth maxWidth='xl'>
            {
                allActionsCompleted ?
                    (
                        <AllTaskCompleteComponent />
                    )
                    :
                    (
                        <>
                            <DialogTitle classes={{ root: classes.customDialogTitle }} >

                                Actions

                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TasksList />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TaskForm />
                                    </Grid>
                                </Grid>
                                <DialogActions>

                                    <Button variant="outlined" onClick={() => dispatch(cambiarEstadoRyOTasksAction('visibilidad', false))}>Close</Button>

                                </DialogActions>

                            </DialogContent>


                        </>
                    )
            }

        </Dialog>


    );
}

