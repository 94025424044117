import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { styled, alpha } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FuseNavBadge from '../../FuseNavBadge';
import FuseNavItem from '../../FuseNavItem';
import { useSelector } from 'react-redux';

const Root = styled('ul')(({ theme, ...props }) => ({
  padding: 0,
  '&.open': {},
  '& > .fuse-list-item': {
    height: 40,
    width: '100%',
    borderRadius: '6px',
    margin: '0 0 4px 0',
    paddingRight: 12,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    color: alpha(theme.palette.text.primary, 0.7),
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& > .fuse-list-item-icon': {
      marginRight: 12,
      color: 'inherit',
    },
  },
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true;
    }
  }

  return false;
}

function FuseNavVerticalCollapse(props) {

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const personLoginPermisos = useSelector(state => state.fuse.userComponente.personPermisos)
  // console.log("🚀 ~ FuseNavVerticalCollapse ~ personLoginPermisos:", personLoginPermisos)
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!




  const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));
  const { item, nestedLevel, onItemClick } = props;
  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;


  const location = useLocation();

  useEffect(() => {
    if (needsToBeOpened(location, props.item)) {
      if (!open) {
        setOpen(true);
      }
    }
  }, [location, props.item]);





  const findPermission = (permissionName) => {
    // const elPermiso = personLoginPermisos.find((item) => item['name'] === `Can view data kpi`)
    // if(elPermiso) console.log("LO TENGO")
    // else console.log("NO LO TENGO")

    return personLoginPermisos.find((item) => item['name'] === permissionName)
  }

  const childremPermissions = ({ item, permissionList, childrenPermissionsList }) => {
    for (let children in item.children) {
      const theItem = item.children[children]
      // console.log("🚀 ~ ~ theItem:", theItem)

      const havePermission = findPermission(permissionList[children]);
      // console.log("🚀 ~  havePermission:", permissionList[children], havePermission)
      if (!havePermission && !theItem.children) theItem.noPermission = true


      if (theItem.children) {
        const theChildrenChildren = theItem.children
        for (let subChildren in theChildrenChildren) {
          const childrenHavePermission = findPermission(childrenPermissionsList[subChildren]);
          if (!childrenHavePermission) theChildrenChildren[subChildren].noPermission = true
          if (theChildrenChildren.every(subChildrem => !!subChildrem.noPermission)) theItem.noPermission = true
        }
      }

    }
    if (item.children.every(children => !!children.noPermission)) item.noPermission = true
  }

  const itemsWithPermissions = useMemo(() => {

    const addCanViewToName = (permissionsName) => {
      return permissionsName.map(name => `Can view ${name}`)
    }

    if (personLoginPermisos.length > 0) {
      const { title } = item
      // Ç Si uno de los hijos es un deplegable, ponemos "" en permissionList, y los permisos de cada hijx de dentro en childrenPermissionsList
      if (title === "Modules") {
        childremPermissions({
          item,
          permissionList: addCanViewToName(["risk management", "", "improvement", "deliverable", "data kpi", "skill matrix","training"]),
          // permissionList: addCanViewToName(["risk management", "", "improvement", "improvement", "deliverable", "data kpi", "skill matrix","training"]),

          childrenPermissionsList: [...Array(2)].fill(addCanViewToName(["sub mision"])[0])
        })


      }
      else if (title === "Management") {
        childremPermissions({
          item,
          permissionList: [...addCanViewToName(["division", "service", "direccion departamental", "process", "categorizacion", "kpi", "aplication", "log accion", "rules"]), "Can Management Power BI"]

        })
      }
      else if (title === "User management") {
        childremPermissions({
          item,
          permissionList: addCanViewToName(["user", "customer", "log cambios personas", "group"])

        })
      }
      else if (title === "Business analytics") {
        childremPermissions({
          item,
          permissionList: addCanViewToName(["BA Performance", "BA Time Management", "BA Audits", "BA Sales Volume", "BA Summary",])
        })
      }

      return item
    }
  },
    [
      item.badge,
      item.children,
      item.icon,
      item.iconClass,
      item.title,
      item.url,
      itempadding,
      nestedLevel,
      onItemClick,
      open,
      personLoginPermisos
    ])

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  if (!itemsWithPermissions && itemsWithPermissions?.noPermission) return null

  return (
    <Root className={clsx(open && 'open')} itempadding={itempadding}>
      <ListItem
        component={item.url ? NavLinkAdapter : 'li'}
        button
        className="fuse-list-item"
        onClick={() => setOpen(!open)}
        to={item.url}
        end={item.end}
        role="button"
      >
        {item.icon && (
          <Icon
            className={clsx('fuse-list-item-icon text-20 shrink-0', item.iconClass)}
            color="action"
          >
            {item.icon}
          </Icon>
        )}

        <ListItemText
          className="fuse-list-item-text"
          primary={item.title}
          classes={{ primary: 'text-13 font-medium' }}
        />

        {item.badge && <FuseNavBadge className="mx-4" badge={item.badge} />}

        <IconButton
          disableRipple
          className="w-40 h-40 -mx-12 p-0 focus:bg-transparent hover:bg-transparent"
          onClick={(ev) => ev.preventDefault()}
          size="large"
        >
          <Icon className="text-16 arrow-icon" color="inherit">
            {open ? 'expand_less' : 'expand_more'}
          </Icon>
        </IconButton>
      </ListItem>


      {item.children && (
        <Collapse in={open} className="collapse-children">
          {item.children.map((_item) =>
          (
            <FuseNavItem
              key={_item.id}
              type={`vertical-${_item.type}`}
              item={_item}
              nestedLevel={nestedLevel + 1}
              onItemClick={onItemClick}
              noPermission={_item.noPermission}

            />
          ))}
        </Collapse>
      )}
    </Root>
  )



  return useMemo(
    () => {

      if (itemsWithPermissions && itemsWithPermissions.noPermission === undefined) {
        return (
          <Root className={clsx(open && 'open')} itempadding={itempadding}>
            <ListItem
              component={item.url ? NavLinkAdapter : 'li'}
              button
              className="fuse-list-item"
              onClick={() => setOpen(!open)}
              to={item.url}
              end={item.end}
              role="button"
            >
              {item.icon && (
                <Icon
                  className={clsx('fuse-list-item-icon text-20 shrink-0', item.iconClass)}
                  color="action"
                >
                  {item.icon}
                </Icon>
              )}

              <ListItemText
                className="fuse-list-item-text"
                primary={item.title}
                classes={{ primary: 'text-13 font-medium' }}
              />

              {item.badge && <FuseNavBadge className="mx-4" badge={item.badge} />}

              <IconButton
                disableRipple
                className="w-40 h-40 -mx-12 p-0 focus:bg-transparent hover:bg-transparent"
                onClick={(ev) => ev.preventDefault()}
                size="large"
              >
                <Icon className="text-16 arrow-icon" color="inherit">
                  {open ? 'expand_less' : 'expand_more'}
                </Icon>
              </IconButton>
            </ListItem>


            {item.children && (
              <Collapse in={open} className="collapse-children">
                {item.children.map((_item) =>
                (
                  <FuseNavItem
                    key={_item.id}
                    type={`vertical-${_item.type}`}
                    item={_item}
                    nestedLevel={nestedLevel + 1}
                    onItemClick={onItemClick}
                    noPermission={_item.noPermission}

                  />
                ))}
              </Collapse>
            )}
          </Root>
        )
      }
      else return (<></>)


    },

    [
      itemsWithPermissions,
      open,
      personLoginPermisos
    ]
  );
}


FuseNavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
FuseNavVerticalCollapse.defaultProps = {};

const NavVerticalCollapse = FuseNavVerticalCollapse;

export default NavVerticalCollapse;
