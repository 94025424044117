import store from "app/store";

//Obtener cookie
export function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res
}

// export const deleteCookies = async () => {
//   const cDecoded = decodeURIComponent(document.cookie); //to be carefulconst clearCookies = document.cookie
//   const cArr = cDecoded.split('; ');
//   cArr.forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
// };

export const deleteCookies = async () => {
// Decodificar las cookies
const cDecoded = decodeURIComponent(document.cookie);

// Separar las cookies en un array
const cArr = cDecoded.split('; ');

// Iterar sobre cada cookie para eliminarla
cArr.forEach(cookie => {
  // Obtener el nombre de la cookie
  const cookieName = cookie.split('=')[0].trim();
  
  // Eliminar la cookie configurando una fecha de expiración pasada y la ruta raíz
  document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()};path=/`;
});

// Asegurarse de eliminar específicamente la cookie `sessionid`
document.cookie = `sessionid=;expires=${new Date(0).toUTCString()};path=/`;

};

export const allFieldsCompleteChecker = ({cantBeEmptyStrings = ["ok"], mustHaveLength = [["ok"]], mustBeTrue=[true]}) => {
  const strFieldsCompleted = cantBeEmptyStrings.every(field => !!field?.trim()?.length)
  const managersCompleted = mustHaveLength.every(item => !!item?.length) 
  const missionSelected = mustBeTrue.every(item => !!item)
  if (strFieldsCompleted && managersCompleted && missionSelected) return true
  return false
}

export const getAllStaffFromMissionAndSubmission =(mission)=>{
  const submissions = store.getState().fuse.userComponente.departamentalAndNoDepartamentalSubMissionsInfo
  const submissionFromMissionStaffIDRes = submissions
  .filter(sub => sub.id_mision === mission.id)
  .flatMap(sub => [...sub.staff.employees.map(({IDRes})=> IDRes), ...sub.staff.managers.map(({IDRes})=> IDRes)]);

  const { staff: missionSaff } = mission

  const missionStaffIDRes = [...missionSaff.managers.map(({ IDRes }) => IDRes), ...missionSaff.employees.map(({ IDRes }) => IDRes)]

  const allStaff = [...new Set([...missionStaffIDRes, ...submissionFromMissionStaffIDRes])]

  return {allStaff}
}

export const getUsersListModalNeededInfo =(usersArr)=> usersArr.map(({id, IDRes, first_name, last_name})=> ({id, IDRes, first_name, last_name}))

export const getUsersStringsInfo = usersArr => usersArr.map(user => `${user.last_name}, ${user.first_name}`).join(' / ')

export const getUserStringsInfo = user => `${user.last_name}, ${user.first_name}`

export const getIdsFromArr = (arr) =>  arr.map(({id})=> id)
export const getIDRessFromPeopleArr = (arr) =>  arr.map(({IDRes})=> IDRes)

export const findIndexSectionById =({sectionList, sectionToFindInList})=>{
  return sectionList.findIndex(section => section.id === sectionToFindInList.id)
}

export const sortListByProperty = ({ list, propetyName }) => list.sort((a, b) => a[propetyName].localeCompare(b.name));
        
export const getActiveItems = (list) => list.filter(({active}) => active)

export const arraysWithSameItems =(arr1, arr2) => {
  if(!arr1 || !arr2) return false
  if (arr1.length !== arr2.length) return false;
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return [...set1].every(item => set2.has(item));
};

export const getUniqueValuesFromObjArrWithId =(arr)=>{

  const idsSet = new Set()
  let result = []
  for(let item of arr){
    if(idsSet.has(item.id)) continue
      idsSet.add(item.id)
      result = [...result, item]
  }
  return result

}


export const getRequestAuthHeader=()=> ({ 'Authorization': `Token ${getCookie('token')}` })
