//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID_ACTION = 'CAMBIAR_VALOR_SELECCION_GRID_ACTION'
export const CAMBIAR_VALOR_SELECCION_GRID_ACTION_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_ACTION_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_ACTION_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_ACTION_ERROR'

//ELIMINAR PROGRAMA
export const ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS = 'ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS'
export const ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS_EXITO = 'ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS_EXITO'
export const ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS_ERROR = 'ELIMINAR_ACTION_IMPROVEMENT_PROPOSALS_ERROR'


//CONSULTA A API ACTION_IMPROVEMENT_PROPOSALS PARA GRID
export const MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS = 'MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS'
export const MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS_EXITO = 'MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS_EXITO'
export const MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS_ERROR = 'MOSTRAR_ACTION_IMPROVEMENT_PROPOSALS_ERROR'

//UPDATE ACTION_IMPROVEMENT_PROPOSALS
export const UPDATE_ACTION_IMPROVEMENT_PROPOSALS = 'UPDATE_ACTION_IMPROVEMENT_PROPOSALS'
export const UPDATE_ACTION_IMPROVEMENT_PROPOSALS_EXITO = 'UPDATE_ACTION_IMPROVEMENT_PROPOSALS_EXITO'
export const UPDATE_ACTION_IMPROVEMENT_PROPOSALS_ERROR = 'UPDATE_ACTION_IMPROVEMENT_PROPOSALS_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_ACTIONS = 'CAMBIAR_MODAL_VISIBILIDAD_ACTIONS'
export const CAMBIAR_MODAL_VISIBILIDAD_ACTIONS_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_ACTIONS_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_ACTIONS_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_ACTIONS_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ACTION_ERROR'

export const POST_ACTION_IMPROVEMENT_PROPOSALS = 'POST_ACTION_IMPROVEMENT_PROPOSALS'
export const POST_ACTION_IMPROVEMENT_PROPOSALS_EXITO = 'POST_ACTION_IMPROVEMENT_PROPOSALS_EXITO'
export const POST_ACTION_IMPROVEMENT_PROPOSALS_ERROR = 'POST_ACTION_IMPROVEMENT_PROPOSALS_ERROR'