import {
    GUARDAR_INFO_SECCION_DIVISION,
    GUARDAR_INFO_SECCION_DEPARTAMENTO,
    GUARDAR_INFO_SECCION_CONTRATO
} from './types.js';

const initialState = {
    listApiSelectors: null,
    sonsListApi: null,
    sonsIds: null,
    sonsNames: null,
    fathersListApi: null,
    fathersIds: null,
    fathersNames: null,
};

export default function (state = initialState, action) {

    switch (action.type) {

        case GUARDAR_INFO_SECCION_DIVISION:
            return {
                ...state,
                listApiSelectors: action.payload.listApiSelectors,
                sonsListApi: action.payload.sonsListApi,
                sonsIds: action.payload.sonsIds,
                sonsNames: action.payload.sonsNames,
                fathersListApi: action.payload.fathersListApi,
                fathersIds: action.payload.fathersIds,
                fathersNames: action.payload.fathersNames,
            }

        case GUARDAR_INFO_SECCION_DEPARTAMENTO:
            return {
                ...state,
                listApiSelectors: action.payload.listApiSelectors,
                sonsListApi: action.payload.sonsListApi,
                sonsIds: action.payload.sonsIds,
                sonsNames: action.payload.sonsNames,
                fathersListApi: action.payload.fathersListApi,
                fathersIds: action.payload.fathersIds,
                fathersNames: action.payload.fathersNames,
            }
        
        case GUARDAR_INFO_SECCION_CONTRATO:
            return {
                ...state,
                listApiSelectors: action.payload.listApiSelectors,
                sonsListApi: action.payload.sonsListApi,
                sonsIds: action.payload.sonsIds,
                sonsNames: action.payload.sonsNames,
                fathersListApi: action.payload.fathersListApi,
                fathersIds: action.payload.fathersIds,
                fathersNames: action.payload.fathersNames,
            }
        default:
            return state

    }

}