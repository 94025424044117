import {

 // COMPONENTE PRINCIPAL ------------------


         OBTENER_PHOTO,
         OBTENER_PHOTO_EXITO,
         OBTENER_PHOTO_ERROR,


} from './types';
import axios from 'axios'

import { showMessage } from 'app/store/fuse/messageSlice'
import {getCookie} from 'app/js/generalFunctions'

//Constantes
const urlGetPhoto = "https://graph.microsoft.com/v1.0/me/photo/$value"


//************************ OBTENER_PHOTO ACTION**********************************************

export function getImagenAPIAction(credenciales) {

    return (dispatch) => {
         dispatch (getImagenAPI(true))

            
             axios({
                    method: "GET",
                    url: urlGetPhoto,
                    data: credenciales,
                    headers: {
                        'Authorization': 'Bearer '+credenciales
                      },
                      responseType: 'blob'
              })   
              .then(response => {
                console.log(response)
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(response.data);
                //self.setState({ imageUrl: blobUrl });
                dispatch (getImagenAPIExito(blobUrl))
             })
             .catch(error => {
                    // console.log(error)
                    /*dispatch(
                        showMessage({
                        message: "INCORRECTO",
                        variant: "error",
                        })
                    )*/
              
                    dispatch (getImagenAPIError(true))
            })

    }
}

const getImagenAPI = loading => ({
    type: OBTENER_PHOTO,
    payload: loading

})

const getImagenAPIExito = dataImage => ({
  
    type: OBTENER_PHOTO_EXITO,
    payload: dataImage

})

const getImagenAPIError = error => ({
    type:   OBTENER_PHOTO_ERROR,
    payload: error
})