//UPDATE RISK_MANAGEMENT
export const MOSTRAR_SUBPROCESS = 'MOSTRAR_SUBPROCESS'
export const MOSTRAR_SUBPROCESS_EXITO = 'MOSTRAR_SUBPROCESS_EXITO'
export const MOSTRAR_SUBPROCESS_ERROR = 'MOSTRAR_SUBPROCESS_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_NEW_SUBPROCESS = 'CAMBIAR_MODAL_NEW_SUBPROCESS'
export const CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO = 'CAMBIAR_MODAL_NEW_SUBPROCESS_EXITO'
export const CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR = 'CAMBIAR_MODAL_NEW_SUBPROCESS_ERROR'

//SESION SUBPROCESS
export const CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS = 'CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS'
export const CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_SUBPROCESS_ERROR'

//UPDATE SUBPROCESS
export const PUT_SUBPROCESS = 'PUT_SUBPROCESS'
export const PUT_SUBPROCESS_EXITO = 'PUT_SUBPROCESS_EXITO'
export const PUT_SUBPROCESS_ERROR = 'PUT_SUBPROCESS_ERROR'

//INSERTAR SUBPROCESS
export const INSERTAR_NEW_SUBPROCESS = 'INSERTAR_NEW_SUBPROCESS'
export const INSERTAR_NEW_SUBPROCESS_EXITO = 'INSERTAR_NEW_SUBPROCESS_EXITO'
export const INSERTAR_NEW_SUBPROCESS_ERROR = 'INSERTAR_NEW_SUBPROCESS_ERROR'