//******************** COMPONENTE PRINCIPAL *****************************************************


// CAMBIAR FILA SELECCIONADA GRID
export const CAMBIAR_VALOR_SELECCION_GRID = 'CAMBIAR_VALOR_SELECCION_GRID'
export const CAMBIAR_VALOR_SELECCION_GRID_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_ERROR'

//ELIMINAR ACCION
export const ELIMINAR_RM_ACCION = 'ELIMINAR_RM_ACCION'
export const ELIMINAR_RM_ACCION_EXITO = 'ELIMINAR_RM_ACCION_EXITO'
export const ELIMINAR_RM_ACCION_ERROR = 'ELIMINAR_RM_ACCION_ERROR'

//CONSULTA A API RM_ACCION PARA GRID
export const MOSTRAR_RM_ACCION = 'MOSTRAR_RM_ACCION'
export const MOSTRAR_RM_ACCION_EXITO = 'MOSTRAR_RM_ACCION_EXITO'
export const MOSTRAR_RM_ACCION_ERROR = 'MOSTRAR_RM_ACCION_ERROR'

//OPEN DIALOGO NUEVO USUARIO
export const CAMBIAR_MODAL_INSERT_PERMISSIONS = 'CAMBIAR_MODAL_INSERT_PERMISSIONS'
export const CAMBIAR_MODAL_INSERT_PERMISSIONS_EXITO = 'CAMBIAR_MODAL_INSERT_PERMISSIONS_EXITO'
export const CAMBIAR_MODAL_INSERT_PERMISSIONS_ERROR = 'CAMBIAR_MODAL_INSERT_PERMISSIONS_ERROR'


//******************** MODAL INSERTAR  *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_INSERTAR_ERROR'

//INSERTAR NUEVO RM_ACCION
export const INSERTAR_RM_ACCION_MODAL_INSERTAR = 'INSERTAR_RM_ACCION_MODAL_INSERTAR'
export const INSERTAR_RM_ACCION_MODAL_INSERTAR_EXITO = 'INSERTAR_RM_ACCION_MODAL_INSERTAR_EXITO'
export const INSERTAR_RM_ACCION_MODAL_INSERTAR_ERROR = 'INSERTAR_RM_ACCION_MODAL_INSERTAR_ERROR'

//CONSULTA RM REGISTRO
export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR = 'CONSULTA_RM_REGISTRO_MODAL_INSERTAR'
export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO = 'CONSULTA_RM_REGISTRO_MODAL_INSERTAR_EXITO'
export const CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR = 'CONSULTA_RM_REGISTRO_MODAL_INSERTAR_ERROR'


