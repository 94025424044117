import { useEffect, useState, useRef } from 'react'
import { requirementsRequestAction, teamTrainingAction, getExperienceAction } from '../store/actions';
import { SKILL_MATRIX_COLUMNS_FUNC, normalizedRequirementsDataFunc, normalizedTeamTrainingFunc, REQUIREMENTS_AND_TEAM_TRAINING_ERROR_CONTROL } from '../utils/functions';
import { REQUIREMENTS_COLUMS_NAMES_OBJ, TEAM_TRAINING_COLUMNS_NAMES_OBJ, REQUIREMENTS_CEELS_WIDTH_VALUES, TEAM_TRAINING_CEELS_WIDTH_VALUES, REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE, EXPERIENCE_INITIAL_STATE, EXPERIENCE_ERROR_CONTROL, STATIC_ROW_SKILL_MATRIX } from '../utils/consts';
import { getAllStaffFromMissionAndSubmission } from 'app/js/generalFunctions';

export const useSetRequirementsAndTeamTrainingData = (missionSelected) => {

    const [skillMatrixStateResponse, setResponse] = useState(REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE)

    const missionSelectedId = useRef(null)

    let responseRequirements;


    useEffect(() => {
        if (!missionSelected) {setResponse(REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE); missionSelectedId.current = null; return}
        if (missionSelected.id === missionSelectedId.current) return skillMatrixStateResponse
        setResponse(REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE)

        const fetchData = async () => {
            try{
                // Ç Comprobamos que haya vtgs
                const vtgsColumns = missionSelected.visual_time_group ? missionSelected.visual_time_group.split(",") : null

                // Ç La mision seleccionada no tiene vtgs asociados
                if(!vtgsColumns) throw new Error("noContentSkillMatrix") 

                // Ç Obtenemos todas las personas de la mision seleccionada y sus submisiones para peticion training y experience
                const {allStaff} = getAllStaffFromMissionAndSubmission(missionSelected)
                
                // Ç Peticion de requerimientos
                const requirementsRequest = await requirementsRequestAction(missionSelected)

                // Ç La respuesta no tiene datos
                if(requirementsRequest.status === 204) throw new Error("noContentSkillMatrix") 


                // Ç Control error requirementsRequest
                if(requirementsRequest.status !== 200) throw new Error("errorSkillMatrix") 

                // Ç No hay error / Extraemos los datos de la peticion
                let requirementsData = requirementsRequest.data
                
                // Ç Obtenemos los nombres de las columnas para la normalizacion de los datos
                const requirementsdataColumnsNames = Object.keys(requirementsData[0])
                
                // Ç Introducimos la fila estatica, con los valores por defecto
                // Ç La columna de VTG no sabemnos como se llama, asi que extraemos del 1º dato de la peticion el nombre
                const staticRow ={...STATIC_ROW_SKILL_MATRIX}
                requirementsdataColumnsNames.forEach(columnName => {
                    if(!staticRow[columnName]) staticRow[columnName] = true
                })
                
                // Ç Establecemos requirementsData definitivo incluyendo la fila estatica
                requirementsData = [staticRow, ...requirementsData]
                
                // Ç Sacamos los datos formalizados y los codigos de moodle para la peticion de team training
                const { normalizedRequirementsData, moodleTrainingCodes } = normalizedRequirementsDataFunc({ dataArr: requirementsData, columnsNames: requirementsdataColumnsNames, vtgsColumns })

                // Ç Creamos las columnas con los vtgs sin el punto para que los datos se asocien correctamente
                const requirementsColumns = SKILL_MATRIX_COLUMNS_FUNC(Object.keys(normalizedRequirementsData[0]), REQUIREMENTS_COLUMS_NAMES_OBJ, REQUIREMENTS_CEELS_WIDTH_VALUES)

                // Ç Guardamos la respuesta de requirements para mostarla en caso de que team training de error
                
                responseRequirements = {
                    ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
                    loading: false,
                    requirements: {
                        data: normalizedRequirementsData,
                        columns: requirementsColumns
                    },
                }

                // Ç Peticion team training
                let teamTrainingRequest = await teamTrainingAction({ allStaff, moodleTrainingCodes })

                //  Ç La respuesta no tiene datos
                if(teamTrainingRequest.status === 204) throw new Error("noContentTeamTrainig") 

                // Ç Control error teamTrainingRequest
                if(teamTrainingRequest.status !== 200) throw new Error("teamTrainingError") 

                // Ç No hay error / Extraemos los datos de la peticion
                const teamTrainingData = teamTrainingRequest.data

                // Ç Obtenemos los nombres de las columnas para la normalizacion de los datos
                const teamTrainingColumnsNames = Object.keys(teamTrainingData[0])

                // Ç Sacamos los datos formalizados
                const { normalizedTeamTraining } = normalizedTeamTrainingFunc({ teamTrainingData, teamTrainingColumnsNames, moodleTrainingCodes })


                // Ç Creamos las columnas con los codigos de moodle sin el punto para que los datos se asocien correctamente
                const teamTrainigColumns = SKILL_MATRIX_COLUMNS_FUNC(Object.keys(normalizedTeamTraining[0]),TEAM_TRAINING_COLUMNS_NAMES_OBJ,TEAM_TRAINING_CEELS_WIDTH_VALUES)


                // Ç Devolvemos respuesta
                const responseSkillMatrix = {
                    ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
                    ...responseRequirements,
                    teamTraining:{
                        data: normalizedTeamTraining,
                        columns: teamTrainigColumns,
                    }
                }

                missionSelectedId.current = missionSelected.id
                setResponse(responseSkillMatrix)
            }
            catch(error){
                console.log("error", error);
                const errorMessage = error.message
                const errorResponse = REQUIREMENTS_AND_TEAM_TRAINING_ERROR_CONTROL(responseRequirements)[errorMessage] ?? REQUIREMENTS_AND_TEAM_TRAINING_ERROR_CONTROL(responseRequirements).errorSkillMatrix
                missionSelectedId.current = missionSelected.id
                setResponse(errorResponse)
            }
        }
        fetchData()
    }, [missionSelected])

    return skillMatrixStateResponse
}

export const useSetExperienceData =(missionSelected)=>{
    const [experienceResponse, setResponse] = useState(EXPERIENCE_INITIAL_STATE)

    const missionSelectedId = useRef(null)

    useEffect(() => {
        if (!missionSelected) {setResponse(EXPERIENCE_INITIAL_STATE); missionSelectedId.current = null; return}
        if (missionSelected.id === missionSelectedId.current) return experienceResponse
        setResponse(EXPERIENCE_INITIAL_STATE)

        const fetchData = async () => {
            try{
                // Ç Comprobamos que haya codigos agresso
                const {code_agresso} = missionSelected
                // console.log("🚀 ~ fetchData ~ missionSelected:", missionSelected)

                // Ç La mision seleccionada no tiene vtgs asociados
                if(!code_agresso) throw new Error("noContentExperience") 

                // Ç Peticion de requerimientos
                const experienceRequest =  await getExperienceAction({missionAgressoCodes: missionSelected.code_agresso})

                // Ç La respuesta no tiene datos
                if(experienceRequest.status === 204) throw new Error("noContentExperience") 

                // Ç Control error experienceRequest
                if(experienceRequest.status !== 200) throw new Error("errorExperience") 

                // Ç No hay error / Extraemos los datos de la peticion
                const experienceData = experienceRequest.data

                setResponse({
                    loadingExperience: false,
                    missionWithNoAgressoCode: false,
                    noContentExperience: false,
                    errorExperience: false,
                    experienceData: experienceData
                })
            }
            catch(error){
                console.log("error", error);
                const errorMessage = error.message
                const errorResponse = EXPERIENCE_ERROR_CONTROL[errorMessage]
                missionSelectedId.current = missionSelected.id
                setResponse(errorResponse)
            }
        }
        fetchData()
    }, [missionSelected])

    return experienceResponse
}