//******************** COMPONENTE PRINCIPAL *****************************************************

// CAMBIAR VISIBILIDAD
export const CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD = 'CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD'
export const CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO = 'CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR = 'CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR'

//INSERTAR NUEVO PROGRAMA
export const INSERTAR_NUEVA_TARJETA_KANBAN = 'INSERTAR_NUEVA_TARJETA_KANBAN'
export const INSERTAR_NUEVA_TARJETA_KANBAN_EXITO = 'INSERTAR_NUEVA_TARJETA_KANBAN_EXITO'
export const INSERTAR_NUEVA_TARJETA_KANBAN_ERROR = 'INSERTAR_NUEVA_TARJETA_KANBAN_ERROR'

//INSERTAR NUEVO PROGRAMA
export const CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS = 'CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS'
export const CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO = 'CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO'
export const CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR = 'CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR'

//CONSULTA A API PROGRAMAS PARA GRID
export const MOSTRAR_TARJETAS_KANBAN = 'MOSTRAR_TARJETAS_KANBAN'
export const MOSTRAR_TARJETAS_KANBAN_EXITO = 'MOSTRAR_TARJETAS_KANBAN_EXITO'
export const MOSTRAR_TARJETAS_KANBAN_ERROR = 'MOSTRAR_TARJETAS_KANBAN_ERROR'