import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getRequestAuthHeader } from 'app/js/generalFunctions';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios'
const urlDataDeliverablesApi = process.env.REACT_APP_URL_DJANGO + "/api/data_deliverables"
const urlAllDataDeliverablesApi = process.env.REACT_APP_URL_DJANGO + "/api/all_data_deliverables"
const urlñPatchDataDeliverablesQuantity = process.env.REACT_APP_URL_DJANGO + "/api/patch_data_deliverables_quantity"
import dayjs from 'dayjs';


const initialState = {
  deliverables_list: [],
  loading: false,
  error: false,
  canAddDataDeliverables: false,
  canViewAllDataDeliverables: false,

}



export const getDataDeliverablesList = createAsyncThunk(
  'deliverables/getDataDeliverables',
  async ({ missionSelected, dateSelected }, thunkAPI) => {
    try {

      const queryString = new URLSearchParams({
        missionSelected: missionSelected, dateSelected: dateSelected
      }).toString()


      const response = await axios({
        url: `${urlDataDeliverablesApi}?${queryString}`,
        headers: getRequestAuthHeader(),
      });

      if (response.status !== 200) throw new Error('error getting data delibverables')

      const deliverablesWithDateFormat = response.data.map(deliverable => ({ ...deliverable, delivery_date: dayjs(deliverable.delivery_date).format('DD-MM-YYYY') }))
      return deliverablesWithDateFormat;
    } catch (error) {
      console.log("🚀 ~ error:", error)
      // Usamos thunkAPI para devolver un error más específico o manejar errores.
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : new Error('Network error')
      );
    }
  }
);

export const getAllDataDeliverablesList = createAsyncThunk(
  'deliverables/getAllDataDeliverables',
  async ( thunkAPI) => {
    try {



      const response = await axios({
        url: urlAllDataDeliverablesApi,
        headers: getRequestAuthHeader(),
      });

      if (response.status !== 200) throw new Error('error getting data delibverables')

      const deliverablesWithDateFormat = response.data.map(deliverable => ({ ...deliverable, delivery_date: dayjs(deliverable.delivery_date).format('DD-MM-YYYY') }))
      return deliverablesWithDateFormat;
    } catch (error) {
      console.log("🚀 ~ error:", error)
      // Usamos thunkAPI para devolver un error más específico o manejar errores.
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : new Error('Network error')
      );
    }
  }
);


export const patchDataDeliverablesQuantity = createAsyncThunk(
  'deliverables/patchDeliverablesQuantity',
  async (patchQuantityRequestData, {rejectWithValue, dispatch}) => {
    try {


      const response = await axios({
        method: 'PATCH',
        url: urlñPatchDataDeliverablesQuantity,
        headers: getRequestAuthHeader(),
        data: patchQuantityRequestData
      });

      if (response.status !== 200) throw new Error('error updating data deliverables quantity')
      if (!response.data.length) return []

      const deliverablesWithDateFormat = response.data.map(deliverable => ({ ...deliverable, delivery_date: dayjs(deliverable.delivery_date).format('DD-MM-YYYY') }))
      dispatch(showMessage({ message: 'Quantities edited correctly', variant: 'success' }))
      return deliverablesWithDateFormat;
    } catch (error) {
      console.log("🚀 ~ error:", error)
      // Usamos thunkAPI para devolver un error más específico o manejar errores.
      return rejectWithValue(
        error.response ? error.response.data : new Error('Network error')
      );
    }
  }
)



const deliverablesSlice = createSlice({
  name: 'deliverables',
  initialState,

  // ? Get data deliverables
  extraReducers: (builder) => {
    builder.addCase(getDataDeliverablesList.pending, (state) => {
      state.loading = true;
      state.error = false
      state.deliverables_list = []
    });
    builder.addCase(getDataDeliverablesList.fulfilled, (state, action) => {
      state.loading = false
      state.deliverables_list = action.payload
      state.error = false
    })
    builder.addCase(getDataDeliverablesList.rejected, (state, action) => {
      state.loading = false
      state.deliverables_list = []
      state.error = action.error.message
    });

    // ? Get all data deliverables

    builder.addCase(getAllDataDeliverablesList.pending, (state) => {
      state.loading = true;
      state.error = false
    });
    builder.addCase(getAllDataDeliverablesList.fulfilled, (state, action) => {
      state.loading = false
      state.deliverables_list = action.payload
      state.error = false
    })
    builder.addCase(getAllDataDeliverablesList.rejected, (state, action) => {
      state.loading = false
      state.deliverables_list = []
      state.error = action.error.message
    });

    // ? Update quantity

    builder.addCase(patchDataDeliverablesQuantity.pending, (state) => {
      state.loading = true;
      state.error = false
    });

    builder.addCase(patchDataDeliverablesQuantity.fulfilled, (state, action) => {
      state.deliverables_list = action.payload
      state.loading = false;
      state.error = false
    })

    builder.addCase(patchDataDeliverablesQuantity.rejected, (state, action) => {
      state.loading = false
      state.deliverables_list = []
      state.error = action.error.message
    });
  },

  reducers: {
    setDeliverablesList: (state, action) => {
      state.deliverables_list = action.payload
    },
    setDeliverablesSpecialPermissions:(state, action)=>{
      const {canAdd, viewAll} = action.payload
      state.canAddDataDeliverables = canAdd
      state.canViewAllDataDeliverables = viewAll
    }
  },
})

// Action creators are generated for each case reducer function
export const { setDeliverablesList, setDeliverablesSpecialPermissions } = deliverablesSlice.actions

export default deliverablesSlice.reducer