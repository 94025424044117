//**********************IMPORTACIONES****************************

import { lazy, Suspense, useState, useContext } from 'react'
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { SKILL_MATRIX_TAB_LABELS, SKILL_MATRIX_TAB_VALUES, FORM_CONTROL_STYLES, CONTAINER_STYLES } from './utils/consts';
import { useSelector } from 'react-redux'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { SkillMatrixProvider } from './utils/providerSkillMatrix';


const Requirements = lazy(() => import('./Requirements/Requirements.jsx'))
const TeamTraining = lazy(() => import('./TeamTraining/TeamTraining.jsx'))
const Experience = lazy(() => import('./Experience/Experience.jsx'))




export const SkillMatrix = () => {

    const [tabValue, settabValue] = useState(SKILL_MATRIX_TAB_VALUES.requirements)
    const [missionSelected, setMissionSelected] = useState(null)
    const setTabValue = (e, tabValue) => settabValue(tabValue);
    const { departamentalAndNoDepartamentalMissionsInfo: missionsList } = useSelector(state => state.fuse.userComponente)


    const modalView = {
        [SKILL_MATRIX_TAB_VALUES.requirements]:
            <Suspense fallback={<LinearLoader />}>
                <Requirements missionSelected={missionSelected} />
            </Suspense>,
        [SKILL_MATRIX_TAB_VALUES.teamTrainig]:
            <Suspense fallback={<LinearLoader />}>
                <TeamTraining missionSelected={missionSelected} />
            </Suspense>,
        [SKILL_MATRIX_TAB_VALUES.experience]:
            <Suspense fallback={<LinearLoader />}>
                <Experience missionSelected={missionSelected} />
            </Suspense>,
    }

    return (
        <FusePageCarded

            content={
                    <Box >
                        <div style={CONTAINER_STYLES}>

                            <TabContext value={tabValue}>
                                <Tabs
                                    value={tabValue}
                                    onChange={setTabValue}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                >
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.requirements} label={SKILL_MATRIX_TAB_LABELS.requirements} />
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.teamTrainig} label={SKILL_MATRIX_TAB_LABELS.teamTrainig} />
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.experience} label={SKILL_MATRIX_TAB_LABELS.experience} />

                                </Tabs>
                                <FormControl variant="standard" fullWidth={true} size="small" sx={FORM_CONTROL_STYLES}>
                                    <Autocomplete
                                        id="tags-outlined"
                                        options={missionsList}
                                        getOptionLabel={(option) => option?.name ?? ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={missionSelected}
                                        onChange={(e, value) => setMissionSelected(value)}
                                        filterSelectedOptions
                                        renderOption={(props, option) => <li {...props} key={option?.id}>{option?.name}</li>}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Mission'
                                                placeholder='Mission'
                                                size="small"
                                                fullWidth={true}
                                            />
                                        )}
                                    />
                                </FormControl>





                            </TabContext>
                        </div>

                <SkillMatrixProvider missionSelected={missionSelected}>


                        {modalView[tabValue]}



                </SkillMatrixProvider>


                    </Box>

            }
        />
    );
}
