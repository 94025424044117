import {

    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD,
    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR,

    INSERTAR_NUEVA_TARJETA_KANBAN,
    INSERTAR_NUEVA_TARJETA_KANBAN_EXITO,
    INSERTAR_NUEVA_TARJETA_KANBAN_ERROR,

    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS,
    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO,
    CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR,

    MOSTRAR_TARJETAS_KANBAN,
    MOSTRAR_TARJETAS_KANBAN_EXITO,
    MOSTRAR_TARJETAS_KANBAN_ERROR,


} from './types';
import axios from 'axios'
import { getCookie } from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

//Constantes
const urlKanban = process.env.REACT_APP_URL_DJANGO + "/api/post_card_kanban/"
const urlGetCardKanban = process.env.REACT_APP_URL_DJANGO + "/api/get_cards_kanban/"

export function mostrarKanbanCardsAPIAction(idPerson) {

    return async (dispatch) => {
        dispatch (mostrarKanbanCardsAPI(true))

        
            await axios({
                method: "GET",
                url: urlGetCardKanban + idPerson,
                headers: {
                    'Authorization': `Token ${getCookie('token')}` 
                        }
                
                    })
                     .then(response=>{
                        dispatch (mostrarKanbanCardsAPIExito(response.data))
                    })
                    .catch(error => {
                        console.log(error.response)
                        dispatch (mostrarKanbanCardsAPIError(true))

                        
                    })

          }
}

const mostrarKanbanCardsAPI = () => ({
    type: MOSTRAR_TARJETAS_KANBAN,
    payload: true

})

const mostrarKanbanCardsAPIExito = programas => ({
    type: MOSTRAR_TARJETAS_KANBAN_EXITO,
    payload: programas

})

const mostrarKanbanCardsAPIError = estado => ({
  type: MOSTRAR_TARJETAS_KANBAN_ERROR,
  payload: estado
})

//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadEstadoIncidenciasAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarValorVisibilidadEstadoIncidencias())

        try {
            dispatch(cambiarValorVisibilidadEstadoIncidenciasExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarValorVisibilidadEstadoIncidenciasError(true))
        }

    }

}

const cambiarValorVisibilidadEstadoIncidencias = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS,
    payload: true

})

const cambiarValorVisibilidadEstadoIncidenciasExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadEstadoIncidenciasError = estado => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_ESTADO_INCIDENCIAS_ERROR,
    payload: estado
})

//************************ INSERTAR PROGRAMA MODAL INSERTAR**********************************************

// export function insertarNuevaTarjetaKanbanAPIAction(cardData) {

//     return (dispatch) => {
//         dispatch(insertarNuevaTarjetaKanbanAPI())


//         axios({
//             method: "POST",
//             url: urlKanban,
//             data: cardData,
//             headers: {
//                 'Authorization': `Token ${getCookie('token')}`
//             }

//         })
//             .then(response => {
//                 dispatch(insertarNuevaTarjetaKanbanAPIExito(response.data))

//                 dispatch(
//                     showMessage({
//                         message: "Report created",
//                         variant: "success"
//                     })
//                 )

//             })
//             .catch(error => {
//                 console.log(error.response)
//                 dispatch(insertarNuevaTarjetaKanbanAPIError(true))

//                 dispatch(
//                     showMessage({
//                         message: "Error creating the report",
//                         variant: "error"
//                     })
//                 )
//             })

//     }
// }
export function insertarNuevaTarjetaKanbanAPIAction(cardData) {

    return (dispatch) => {
        dispatch(insertarNuevaTarjetaKanbanAPI())

        const formData = new FormData();
        formData.append('title', cardData.title);
        formData.append('tipo', cardData.tipo);
        formData.append('usuario', cardData.usuario);
        formData.append('module', cardData.module);
        formData.append('description', cardData.description);
        formData.append('file', cardData.file);


        axios({
            method: "POST",
            url: urlKanban,
            data: formData,
            headers: {
                'Authorization': `Token ${getCookie('token')}`,
                'Content-Type': 'multipart/form-data'
            }

        })
            .then(response => {
                dispatch(insertarNuevaTarjetaKanbanAPIExito(response.data))

                dispatch(
                    showMessage({
                        message: "Report created",
                        variant: "success"
                    })
                )

            })
            .catch(error => {
                console.log(error.response)
                dispatch(insertarNuevaTarjetaKanbanAPIError(true))

                dispatch(
                    showMessage({
                        message: "Error creating the report",
                        variant: "error"
                    })
                )
            })

    }
}


const insertarNuevaTarjetaKanbanAPI = (cardData) => ({
    type: INSERTAR_NUEVA_TARJETA_KANBAN,
    payload: cardData

})

const insertarNuevaTarjetaKanbanAPIExito = estado => ({
    type: INSERTAR_NUEVA_TARJETA_KANBAN_EXITO,
    payload: estado

})

const insertarNuevaTarjetaKanbanAPIError = estado => ({
    type: INSERTAR_NUEVA_TARJETA_KANBAN_ERROR,
    payload: estado
})


//************************CAMBIAR VISIBILIDAD ACTION**********************************************

export function cambiarValorVisibilidadAction(valorNuevo) {

    return (dispatch) => {
        dispatch(cambiarValorVisibilidad())

        try {
            dispatch(cambiarValorVisibilidadExito(valorNuevo))

        } catch (error) {

            dispatch(cambiarValorVisibilidadError(true))
        }

    }

}

const cambiarValorVisibilidad = () => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD,
    payload: true

})

const cambiarValorVisibilidadExito = valorNuevo => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_EXITO,
    payload: valorNuevo

})

const cambiarValorVisibilidadError = estado => ({
    type: CAMBIAR_VALOR_VISIBILIDAD_NEW_CARD_ERROR,
    payload: estado
})