import { STRUCTURE_CATEGORY_TYPES_NAMES, STORE_CATEGORY_NAMES } from "./consts";

export const workPackageSectionCategoryTypeDptoOrService =({sectionInfo, sectionCategoryType})=>{
    if(sectionCategoryType === STRUCTURE_CATEGORY_TYPES_NAMES.workPackage){
        return sectionInfo.isDepartamental ? "departamentalWP" : "noDepartamentalWP"
    }
    return sectionCategoryType
}

// Ç Vemos si la submision insertada es dpto o no para meterla en la lista correspondiente
export const getSectionStoreIdAndInfoKeys = ({sectionCategoryType, isDepartamental}) => {
    const sectionTypesInStore = isDepartamental ? STORE_CATEGORY_NAMES[sectionCategoryType].departamental : STORE_CATEGORY_NAMES[sectionCategoryType]

    return sectionTypesInStore 
}


export const setSectionOrgChartStructure =({sectionInfo, sectionCategoryType}) => {

    const {code, id, name} = sectionInfo
    const setStaffList = staff => staff.map(({IDRes, last_name, first_name})=> `${IDRes} - ${last_name}, ${first_name}`)

    const sectionInfoWithOrgCharStructure = {

        [STRUCTURE_CATEGORY_TYPES_NAMES.submission]: {
            code, id, name,
            category_type: STRUCTURE_CATEGORY_TYPES_NAMES.submission,
            father_category_type: STRUCTURE_CATEGORY_TYPES_NAMES.mission,
            father_name: sectionInfo.id_mision_name,
            father_id: [sectionInfo.id_mision],
            employees: [],
            managers: setStaffList(sectionInfo.staff.managers),
            children: [],
        },

        [STRUCTURE_CATEGORY_TYPES_NAMES.mission]: {
            code, id, name,
            category_type: STRUCTURE_CATEGORY_TYPES_NAMES.mission,
            father_category_type: STRUCTURE_CATEGORY_TYPES_NAMES.workPackage,
            father_name: sectionInfo.id_mision_name,
            father_id: [sectionInfo.id_workPackage],
            employees: setStaffList(sectionInfo.staff.employees), 
            managers:  setStaffList(sectionInfo.staff.managers),
            children: [],
        },

        noDepartamentalWP: {
            code, id, name,
            category_type: STRUCTURE_CATEGORY_TYPES_NAMES.workPackage,
            father_category_type: STRUCTURE_CATEGORY_TYPES_NAMES.service,
            father_name: sectionInfo.id_service_name,
            father_id: [sectionInfo.id_service],
            employees: [],
            managers:  setStaffList(sectionInfo.staff.managers),
            children: [],
        },

        departamentalWP: {
            code, id, name,
            category_type: STRUCTURE_CATEGORY_TYPES_NAMES.workPackage,
            father_category_type: STRUCTURE_CATEGORY_TYPES_NAMES.department,
            father_name: sectionInfo.departament_name,
            father_id: [sectionInfo.id_departamento],
            employees: [],
            managers:  setStaffList(sectionInfo.staff.managers),
            children: [],
        },

        [STRUCTURE_CATEGORY_TYPES_NAMES.service]: {
            code, id, name,
            category_type: STRUCTURE_CATEGORY_TYPES_NAMES.service,
            father_category_type: STRUCTURE_CATEGORY_TYPES_NAMES.program,
            father_name: [],
            father_id: sectionInfo.programs,
            managers:  setStaffList(sectionInfo.staff.managers),
            employees: [],
            children: [],
        },
    }


    return  sectionInfoWithOrgCharStructure[sectionCategoryType] 
}
