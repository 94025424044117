export const CAMBIAR_VALOR_TAB_DEPARTAMENTO = 'CAMBIAR_VALOR_TAB_DEPARTAMENTO'
export const CAMBIAR_VALOR_TAB_DEPARTAMENTO_EXITO = 'CAMBIAR_VALOR_TAB_DEPARTAMENTO_EXITO'
export const CAMBIAR_VALOR_TAB_DEPARTAMENTO_ERROR = 'CAMBIAR_VALOR_TAB_DEPARTAMENTO_ERROR'

export const GET_ALL_DEPARTAMENTOS = 'GET_ALL_DEPARTAMENTOS'
export const GET_ALL_DEPARTAMENTOS_EXITO = 'GET_ALL_DEPARTAMENTOS_EXITO'
export const GET_ALL_DEPARTAMENTOS_ERROR = 'GET_ALL_DEPARTAMENTOS_ERROR'

export const GET_ALL_DIRECCION_DEPARTAMENTAL = 'GET_ALL_DIRECCION_DEPARTAMENTAL'
export const GET_ALL_DIRECCION_DEPARTAMENTAL_EXITO = 'GET_ALL_DIRECCION_DEPARTAMENTAL_EXITO'
export const GET_ALL_DIRECCION_DEPARTAMENTAL_ERROR = 'GET_ALL_DIRECCION_DEPARTAMENTAL_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO = 'CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO'
export const CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_DEPARTAMENTO_ERROR'

export const CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL = 'CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL'
export const CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL_EXITO = 'CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL_EXITO'
export const CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL_ERROR = 'CAMBIAR_VALOR_SELECCION_GRID_FUNCION_DEPARTAMENTAL_ERROR'

export const VER_MODAL_INSERT_DEPARTAMENTO = 'VER_MODAL_INSERT_DEPARTAMENTO'
export const VER_MODAL_INSERT_DEPARTAMENTO_EXITO = 'VER_MODAL_INSERT_DEPARTAMENTO_EXITO'
export const VER_MODAL_INSERT_DEPARTAMENTO_ERROR = 'VER_MODAL_INSERT_DEPARTAMENTO_ERROR'

export const VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL = 'VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL'
export const VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL_EXITO = 'VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL_EXITO'
export const VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL_ERROR = 'VER_MODAL_INSERT_FUNCION_DEPARTAMENTAL_ERROR'

export const PUT_DEPARTAMENTO = 'PUT_DEPARTAMENTO'
export const PUT_DEPARTAMENTO_EXITO = 'PUT_DEPARTAMENTO_EXITO'
export const PUT_DEPARTAMENTO_ERROR = 'PUT_DEPARTAMENTO_ERROR'

export const POST_DEPARTAMENTO = 'POST_DEPARTAMENTO'
export const POST_DEPARTAMENTO_EXITO = 'POST_DEPARTAMENTO_EXITO'
export const POST_DEPARTAMENTO_ERROR = 'POST_DEPARTAMENTO_ERROR'

export const PUT_FUNCION_DEPARTAMENTAL = 'PUT_FUNCION_DEPARTAMENTAL'
export const PUT_FUNCION_DEPARTAMENTAL_EXITO = 'PUT_FUNCION_DEPARTAMENTAL_EXITO'
export const PUT_FUNCION_DEPARTAMENTAL_ERROR = 'PUT_FUNCION_DEPARTAMENTAL_ERROR'

export const POST_FUNCION_DEPARTAMENTAL = 'POST_FUNCION_DEPARTAMENTAL'
export const POST_FUNCION_DEPARTAMENTAL_EXITO = 'POST_FUNCION_DEPARTAMENTAL_EXITO'
export const POST_FUNCION_DEPARTAMENTAL_ERROR = 'POST_FUNCION_DEPARTAMENTAL_ERROR'

export const GET_DEPARTAMENTO_USER = 'GET_DEPARTAMENTO_USER'
export const GET_DEPARTAMENTO_USER_EXITO = 'GET_DEPARTAMENTO_USER_EXITO'
export const GET_DEPARTAMENTO_USER_ERROR = 'GET_DEPARTAMENTO_USER_ERROR'

export const VER_MODAL_REQUERIMENTS_DEPARTAMENTO = 'VER_MODAL_REQUERIMENTS_DEPARTAMENTO'
export const VER_MODAL_REQUERIMENTS_DEPARTAMENTO_EXITO = 'VER_MODAL_REQUERIMENTS_DEPARTAMENTO_EXITO'
export const VER_MODAL_REQUERIMENTS_DEPARTAMENTO_ERROR = 'VER_MODAL_REQUERIMENTS_DEPARTAMENTO_ERROR'

export const GET_REQUERIMENTS_BY_DEPARTAMENTO = 'GET_REQUERIMENTS_BY_DEPARTAMENTO'
export const GET_REQUERIMENTS_BY_DEPARTAMENTO_EXITO = 'GET_REQUERIMENTS_BY_DEPARTAMENTO_EXITO'
export const GET_REQUERIMENTS_BY_DEPARTAMENTO_ERROR = 'GET_REQUERIMENTS_BY_DEPARTAMENTO_ERROR'

export const POST_DEPARTAMENTO_APP = 'POST_DEPARTAMENTO_APP'
export const POST_DEPARTAMENTO_APP_EXITO = 'POST_DEPARTAMENTO_APP_EXITO'
export const POST_DEPARTAMENTO_APP_ERROR = 'POST_DEPARTAMENTO_APP_ERROR'

export const ELIMINAR_DEPARTAMENTO_APP = 'ELIMINAR_DEPARTAMENTO_APP'
export const ELIMINAR_DEPARTAMENTO_APP_EXITO = 'ELIMINAR_DEPARTAMENTO_APP_EXITO'
export const ELIMINAR_DEPARTAMENTO_APP_ERROR = 'ELIMINAR_DEPARTAMENTO_APP_ERROR'

export const VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL = 'VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL'
export const VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL_EXITO = 'VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL_EXITO'
export const VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL_ERROR = 'VER_MODAL_REQUERIMENTS_DIRECCION_DEPARTAMENTAL_ERROR'

export const GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL = 'GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL'
export const GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL_EXITO = 'GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL_EXITO'
export const GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL_ERROR = 'GET_REQUERIMENTS_BY_DIRECCION_DEPARTAMENTAL_ERROR'

export const POST_DIRECCION_DEPARTAMENTAL_APP = 'POST_DIRECCION_DEPARTAMENTAL_APP'
export const POST_DIRECCION_DEPARTAMENTAL_APP_EXITO = 'POST_DIRECCION_DEPARTAMENTAL_APP_EXITO'
export const POST_DIRECCION_DEPARTAMENTAL_APP_ERROR = 'POST_DIRECCION_DEPARTAMENTAL_APP_ERROR'

export const ELIMINAR_DIRECCION_DEPARTAMENTAL_APP = 'ELIMINAR_DIRECCION_DEPARTAMENTAL_APP'
export const ELIMINAR_DIRECCION_DEPARTAMENTAL_APP_EXITO = 'ELIMINAR_DIRECCION_DEPARTAMENTAL_APP_EXITO'
export const ELIMINAR_DIRECCION_DEPARTAMENTAL_APP_ERROR = 'ELIMINAR_DIRECCION_DEPARTAMENTAL_APP_ERROR'