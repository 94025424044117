
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

export const SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/subMision/"
export const STAFF_IN_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByContratoUser/"
export const CONTRACT_USER_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuser/"
export const JOB_IN_SUBMISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/jobInSubMision/"
export const USER_CONTRACTS_URL = process.env.REACT_APP_URL_DJANGO + "/api/contractuserByUser/"

export const SUCESS_MESSAGE_CREATE_SUBMISSION = {
    message: "Created sub mision",
    variant: "success"
}

export const SUCESS_MESSAGE_EDIT_SUBMISSION = {
    message: "Updated sub mision",
    variant: "success"
}
export const ERROR_MESSAGE_CREATE_SUBMISSION = {
    message: "Error when created Sub Mission",
    variant: "error"
}

export const SUCESS_CREATE_JOB = {
    message: "Created job in sub mision",
    variant: "success"
}

export const SUCESS_EDIT_JOB = {
    message: "Edited job in sub mision",
    variant: "success"
}

export const SUCESS_DELETE_JOB = {
    message: "Edited job in sub mision",
    variant: "success"
}

export const SUCESS_ASIGN_PERSON = {
    message: "Person correctly assigned",
    variant: "success"
}

export const SUCESS_CHANGE_PERSON = {
    message: "Person correctly modified",
    variant: "success"
}

export const DELETE_USER_FROM_SUBMISSION = {
    message: "User correctly removed",
    variant: "success"
}

export const CANT_DELETE_JOB = {
    message: "There are employees assigned to this position, before eliminating it, place these employees in other positions.",
    variant: "error"
}

export const ERROR_TRY_AGAIN = {
    message: "Error, try again",
    variant: "error"
}

export const ERROR_GET_ALL_SUBMISSIONS = {
    message: "Error when try to get sub missions, please, reload the page",
    variant: "error"
}