export const OBTENER_USER_DETAILS = 'OBTENER_USER_DETAILS'
export const OBTENER_USER_DETAILS_EXITO = 'OBTENER_USER_DETAILS_EXITO'
export const OBTENER_USER_DETAILS_ERROR = 'OBTENER_USER_DETAILS_ERROR'

export const CAMBIAR_MODAL_VISIBILIDAD_DETALLES = 'CAMBIAR_MODAL_VISIBILIDAD_DETALLES'
export const CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO = 'CAMBIAR_MODAL_VISIBILIDAD_DETALLES_EXITO'
export const CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR = 'CAMBIAR_MODAL_VISIBILIDAD_DETALLES_ERROR'

export const OBTENER_SUB_MISION_DIRECTAS = 'OBTENER_SUB_MISION_DIRECTAS'
export const OBTENER_SUB_MISION_DIRECTAS_EXITO = 'OBTENER_SUB_MISION_DIRECTAS_EXITO'
export const OBTENER_SUB_MISION_DIRECTAS_ERROR = 'OBTENER_SUB_MISION_DIRECTAS_ERROR'

export const OBTENER_MISION_DIRECTAS = 'OBTENER_MISION_DIRECTAS'
export const OBTENER_MISION_DIRECTAS_EXITO = 'OBTENER_MISION_DIRECTAS_EXITO'
export const OBTENER_MISION_DIRECTAS_ERROR = 'OBTENER_MISION_DIRECTAS_ERROR'

export const OBTENER_WP_DIRECTAS = 'OBTENER_WP_DIRECTAS'
export const OBTENER_WP_DIRECTAS_EXITO = 'OBTENER_WP_DIRECTAS_EXITO'
export const OBTENER_WP_DIRECTAS_ERROR = 'OBTENER_WP_DIRECTAS_ERROR'

export const OBTENER_DEPARTAMENTOS_DIRECTAS = 'OBTENER_DEPARTAMENTOS_DIRECTAS'
export const OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO = 'OBTENER_DEPARTAMENTOS_DIRECTAS_EXITO'
export const OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR = 'OBTENER_DEPARTAMENTOS_DIRECTAS_ERROR'

export const OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS = 'OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS'
export const OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO = 'OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_EXITO'
export const OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR = 'OBTENER_DIRECCION_DEPARTAMENTAL_DIRECTAS_ERROR'

export const OBTENER_SUB_DIVISIONES_DIRECTAS = 'OBTENER_SUB_DIVISIONES_DIRECTAS'
export const OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO = 'OBTENER_SUB_DIVISIONES_DIRECTAS_EXITO'
export const OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR = 'OBTENER_SUB_DIVISIONES_DIRECTAS_ERROR'