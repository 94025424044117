export const WP_URL = process.env.REACT_APP_URL_DJANGO + "/api/workpackage/"



export const SUCESS_MESSAGE_CREATE_WP = {
    message: "Created work package",
    variant: "success"
}

export const SUCESS_MESSAGE_EDIT_WP = {
    message: "Updated work package",
    variant: "success"
}
export const ERROR_MESSAGE_CREATE_WP = {
    message: "Error when created  work package",
    variant: "error"
}
export const ERROR_MESSAGE_EDIT_WP = {
    message: "Error when edit work package",
    variant: "error"
}
export const ERROR_MESSAGE_GET_WP = {
    message: "Error when getting  work packages, please, reload the page",
    variant: "error"
}


