import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CourseLinearProgress=({value})=> {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        <LinearProgress variant="determinate" value={value} />
      </Box>
    </Box>
  );
}