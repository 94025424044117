
import {getCookie} from 'app/js/generalFunctions'
import { showMessage } from 'app/store/fuse/messageSlice'

export const MISSION_URL = process.env.REACT_APP_URL_DJANGO + "/api/mision/"
export const VISUAL_TIME_GROUPS_URL = process.env.REACT_APP_URL_DJANGO + "/api/agresso/vt_group/"
export const AGRESSO_CODES_URL = process.env.REACT_APP_URL_DJANGO + "/api/agresso/contract/"


export const SUCESS_MESSAGE_CREATE_MISSION = {
    message: "Created mision",
    variant: "success"
}

export const SUCESS_MESSAGE_EDIT_MISSION = {
    message: "Updated mision",
    variant: "success"
}
export const ERROR_MESSAGE_CREATE_MISSION = {
    message: "Error when created mission",
    variant: "error"
}
export const ERROR_MESSAGE_EDIT_MISSION = {
    message: "Mission editing error",
    variant: "error"
}

export const ERROR_MESSAGE_SOME_GET_IN_MISSION = {
    message: "Error when try to get modules dependencies, pelase reload the page",
    variant: "error"
}

export const ERROR_MESSAGE_GET_ALL_MISSION = {
    message: "Error when try to get missions, pelase reload the page",
    variant: "error"
}

