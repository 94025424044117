//**********************IMPORTACIONES****************************

import { useSelector } from 'react-redux'
import { lazy, Suspense, useState, useContext } from 'react'
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { Filters } from 'components/Filter/Filter.jsx';
import { NewFilter } from 'components/Filter/components/filterInternalComponents/newFilter';
import { FilterSearchButtom } from 'components/Filter/components/filterInternalComponents/filterSearchButtom';
import { FilterIcon } from 'components/Filter/components/FilterIcon';

import { TRAINING_TAB_LABELS, TRAINING_TAB_VALUES, CONTAINER_STYLES } from './utils/consts';
import { useTrainingRequest } from './utils/hooks';

import { LinearLoader } from 'components/GlobalComponents/loaders/loader';


const TrainingPage = lazy(() => import('./Pages/Training.jsx'))




export const Training = () => {

    const [tabValue, settabValue] = useState(TRAINING_TAB_VALUES.training)
    const setTabValue = (e, tabValue) => settabValue(tabValue);
    useTrainingRequest()


    const modalView = {
        [TRAINING_TAB_VALUES.training]:
            <Suspense fallback={<LinearLoader />}>
                <TrainingPage />
            </Suspense>,

    }

    return (
        <FusePageCarded

            content={
                <Box sx={{ width: '100%' }} >
                    <Filters>
                        <NewFilter />
                        <FilterSearchButtom />
                    </Filters>
                    <FilterIcon />

                    <div style={CONTAINER_STYLES}>

                        <TabContext value={tabValue}>
                            <Tabs
                                value={tabValue}
                                onChange={setTabValue}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab value={TRAINING_TAB_VALUES.training} label={TRAINING_TAB_LABELS.training} />

                            </Tabs>

                        </TabContext>
                    </div>


                    {modalView[tabValue]}





                </Box>

            }
        />
    );
}
