import { useContext, useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, Grid, DialogActions, Button, Autocomplete, TextField } from '@mui/material';
import { useStyles } from 'app/js/customHooks/GeneralHooks';
import { useGetPossiblesInspectorsIds, useGetPrograms } from '../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateInspectorModalVisibility } from '../store/AuthorizationOfInspectorsSlice';
import { DateYearFilterYMD } from 'components/GlobalComponents/ModalsFilters/DateFilterYMD';



export const CreateInspectorModal = () => {

    const classes = useStyles();
    const dispach = useDispatch()

    // * Store
    const { createInspectorModalVisibility } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    // * Hooks
    const possibleInspectorsList = useGetPossiblesInspectorsIds()
    const { programsList } = useGetPrograms()


    // * Functions
    const closeModal = () => dispach(setCreateInspectorModalVisibility(false))

    const [modalValues, setModalValues] = useState({
        inspectorSelected: null,
        programSelected: null,
        startDate: null,
        finalDate: null,
    })

    const { inspectorSelected, programSelected, startDate, finalDate } = modalValues
    console.log("🚀 ~ CreateInspectorModal ~ modalValues:", modalValues)

    const setInspectorSelected = (value) => setModalValues(state => ({ ...state, inspectorSelected: value }))
    const setProgramSelected = (value) => setModalValues(state => ({ ...state, programSelected: value }))
    const setStartDate = (value) => setModalValues(state => ({ ...state, startDate: value }))
    const setFinalDate = (value) => setModalValues(state => ({ ...state, finalDate: value }))




    return (
        <Dialog open={createInspectorModalVisibility} onClose={closeModal} fullWidth maxWidth="md">
            <DialogTitle classes={{ root: classes.customDialogTitle }}  >
                Create inspector
            </DialogTitle>

            <DialogContent>

                <Grid container spacing={1} style={{ marginTop: '1px' }}>


                    <Grid item xs={3}>

                        <Autocomplete
                            id="tags-outlined"
                            options={possibleInspectorsList}
                            value={inspectorSelected}
                            getOptionLabel={(option) => `${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}
                            isOptionEqualToValue={(option, value) => option?.IDRes === value?.IDRes}
                            renderOption={(props, option) => <li {...props} key={option?.id}>{`${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}</li>}
                            onChange={(event, value) => setInspectorSelected(value)}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Inspector"
                                    placeholder="Inspector"
                                    size="small"
                                    fullWidth={true}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={3}>

                        <Autocomplete
                            id="tags-outlined"
                            options={programsList}
                            value={programSelected}
                            getOptionLabel={(option) => option?.name}
                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                            onChange={(event, value) => setProgramSelected(value)}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Program"
                                    placeholder="Program"
                                    size="small"
                                    fullWidth={true}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={3}>


                        <DateYearFilterYMD componentContextDate={startDate}   setFatherDate={setStartDate}/>

                    </Grid>

                    <Grid item xs={3}>


                        <DateYearFilterYMD componentContextDate={finalDate}   setFatherDate={setFinalDate}/>

                    </Grid>



                </Grid>

            </DialogContent>



        </Dialog>


    )
}





